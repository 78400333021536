import React from 'react';
import GuardedListenerComponent from '../components/guarded_listener_component';
import '../common.css';
import QueueAppbar from '../components/queue_appbar';
import { Grid, Typography, Button } from '@material-ui/core';
import queueAppLogo from '../assets/queue_app_logo.png';
import salesTeamAppLogo from '../assets/sales_team_app_logo.png';
import appStoreLogo from '../assets/app_store_logo.png';
import googlePlayLogo from '../assets/google_play_logo.png';
import facebookLogo from '../assets/facebook_logo.png';

enum TrainingVideoType {
    TWO_DAY = "Two Day",
    THREE_DAY = "Three Day",
}

interface LandingPageState {
    selectTrainingVideo: TrainingVideoType,
}

function QueueAppLogo () {
    return (
        <div>
            <img src={queueAppLogo} max-width='100px' height='100px' alt=""></img>
        </div>
    );
}

function FacebookLogo () {
    return (
        <div>
            <img src={facebookLogo} max-width='100px' height='100px' alt=""></img>
        </div>
    );
}

function SalesTeamAppLogo () {
    return (
        <div>
            <img src={salesTeamAppLogo} max-width='100px' height='100px' alt=""></img>
        </div>
    );
}

function AppStoreLogo () {
    return (
        <img src={appStoreLogo} max-width='100%' height='50px' alt=""></img>
    );
}

function GooglePlayAppLogo () {
    return (
        <img src={googlePlayLogo} max-width='100%' height='50px' alt=""></img>
    );
}

export default class LandingPage extends GuardedListenerComponent<{}, LandingPageState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            selectTrainingVideo: TrainingVideoType.TWO_DAY,
        };


        this.renderTutorialVideo = this.renderTutorialVideo.bind(this);
        this.getVideoButton = this.getVideoButton.bind(this);
    }

    renderTutorialVideo() {
        switch (this.state.selectTrainingVideo){
            case TrainingVideoType.TWO_DAY: {
                return (
                    <div className='padding-example'>
                        <a target="_blank" href="https://docs.google.com/document/d/1FDwIIHuFMKTCzsnx9UQve3dwycNAGAYsGasuz2Vx7y8/edit?usp=sharing" rel="noopener noreferrer">Queue Notes for 2 Day Training</a>
                    </div>
                )
            }
            case TrainingVideoType.THREE_DAY: {
                return (
                         <div className='padding-example'>
                        <a target="_blank" href="https://docs.google.com/document/d/1-j8GU_AuR1zUWp9iCMMcMWBAV5zrUvkFhoGChewEdwc/edit?usp=sharing" rel="noopener noreferrer">Queue Notes for 3 Day Training</a>
                    </div>
                )
            }
        }
    }

    getVideoButton (buttonType: TrainingVideoType) {
       let variant : "contained" | "outlined" = this.state.selectTrainingVideo === buttonType ? "contained" : "outlined";
       let color : "primary" | "default" = this.state.selectTrainingVideo === buttonType ? "primary" : "default"
       let onClick = () => {
           this.setState({
               selectTrainingVideo: buttonType,
           });
       }
       onClick = onClick.bind(this);
       return <Button onClick={onClick} variant={variant} color={color}>{buttonType} Training</Button>
    }

    getEmployedListenerClients() {
        return [];
    }

    getPathOnRefresh() {
        return "/welcome";
    }

    renderGuarded() {
        return (
            <div>
                <div className="App">
                    <QueueAppbar shouldDisplayMenu={true}/>
                </div>
                <br/>
                <p className="centered">Email queue.app.team@gmail.com with questions</p>
                <Grid container direction='column' justify='space-between' alignItems='center'>
                    <Grid item>
                        <h1>How to Incorporate Queue into:</h1>
                    </Grid>
                    <br/>
                    <Grid item>
                        <Grid container direction='row' justify='space-evenly' alignItems='center'>
                            <Grid item>
                                {this.getVideoButton(TrainingVideoType.TWO_DAY)}
                            </Grid>
                            <Grid item>
                                {this.getVideoButton(TrainingVideoType.THREE_DAY)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {this.renderTutorialVideo()}
                    </Grid>
                    <br/><br/>
                    <Grid item>

                    <h1>Shared Google Drive Folder</h1>
                        <a className="centered" href="https://drive.google.com/drive/u/0/folders/1iXyP-KbwzZ-eY0QS-GLUw5_IKED9wPyB" target="_blank" rel="noopener noreferrer">Feel free to view or upload any other Queue Materials Here</a>
                    </Grid>
                    <br/><br/>
                    <h1>Example Training Powerpoint</h1>
                    <iframe title="example_training_PPT" src="https://docs.google.com/presentation/d/e/2PACX-1vRyKl02rTgiZ1KE3LqX1lvOuWRKVjrzdFc4NtMF-FeGuB_YvrZMzuu0ZOityll9C2Di-jEyf3S6xUK5/embed?start=false&loop=false&delayms=3000" width="960" height="569" ></iframe>
                    <br/><br/>

                    <Grid item>
                        <Typography>Join us on Facebook</Typography>
                    </Grid>
                    <br/>
                    <Grid item>
                        <a  href="https://www.facebook.com/plugins/group/join/popup/?group_id=3320613571296964&amp;source=email_campaign_plugin" rel="noopener noreferrer" target="_blank" ><FacebookLogo/></a>
                    </Grid>
                    <br/><br/>
                    <Grid item>
                        <Typography>Sales Rep App</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justify='space-between' alignItems='center'>
                            <Grid item>
                                <QueueAppLogo/>
                            </Grid>
                            <Grid item>
                            <a href='https://apps.apple.com/us/app/queue/id1446705132' rel="noopener noreferrer" target='_blank'><AppStoreLogo/></a>
                            </Grid>
                            <Grid item>
                                <a href='https://play.google.com/store/apps/details?id=com.queue.queue_flutter' target='_blank' rel="noopener noreferrer"><GooglePlayAppLogo/></a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/><br/>
                    <Grid item>
                        <Typography>Manager App</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justify='space-between' alignItems='center'>
                            <Grid item>
                                <SalesTeamAppLogo/>
                            </Grid>
                            <Grid item>
                            <a href='https://apps.apple.com/us/app/queue-sales-team/id1464598616' target='_blank' rel="noopener noreferrer"><AppStoreLogo/></a>
                            </Grid>
                            <Grid item>
                            <a href='https://play.google.com/store/apps/details?id=com.getqueue.queue_sales_team' target='_blank' rel="noopener noreferrer"><GooglePlayAppLogo/></a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br/><br/><br/>
                </Grid>
            </div>

        );
    }
}
