import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import QueueAppbar from '../components/queue_appbar';
import '../common.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import StripeCheckout, { Token } from 'react-stripe-checkout';
import BillingService from '../../services/billing_service';
import { TextField, CircularProgress, Switch, Badge, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import {injectStripe} from 'react-stripe-elements';
import GuardedListenerComponent from '../components/guarded_listener_component';
import TeamStore from '../../services/team_store';
import Loader from './loader_page';
import Team from '../../model/team';
import EasyOrderGif from '../assets/eazy_orders_demo.gif';
import { AuthenticationService } from '../../services/authentication_service';
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';

interface PlanSelectionPageState {
    loading: boolean,
    errorMessage?: string,
}

interface PlanSelectionPageProps {
    stripe: any,
    elements: any
}

class PlanSelectionPage extends GuardedListenerComponent<PlanSelectionPageProps, PlanSelectionPageState> {

    getEmployedListenerClients(): import("../../services/listener_client").default<any>[] {
        return [TeamStore.getTeamListenerClient()];
    }

    constructor(props: PlanSelectionPageProps) {
        super(props);

        this.state = {
            loading: false
        };


        this.onSubscribed = this.onSubscribed.bind(this);
        this.renderLoading = this.renderLoading.bind(this);
        this.renderPageBody = this.renderPageBody.bind(this);
        this.renderUpdateSubscriptionOptions = this.renderUpdateSubscriptionOptions.bind(this);
    }

    getPathOnRefresh() {
        return "/plans";
    }

    onSubscribed(token: Token, planID: string) {
        this.setState({
            loading: true
        });
        BillingService.sharedInstance().subscribeToPlan(planID, token, this.props.stripe, undefined).then((result) => {
            if (result.success) {
                this.setState({
                    loading: false,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: result.errorMessage!
                });
            };
        });
    }


    renderGuarded() {
        let teamStore: TeamStore = TeamStore.instance();
        if (!teamStore.isLoaded()) {
            return (<Loader/>);
        } else {
            return (
                <div className='App'>
               <QueueAppbar shouldDisplayMenu={true}/>
                {this.renderPageBody()}
                </div>
            );
        }
    }

    renderLoading() {
        return <div className="centered">
        Subscribing... This may take a few moments
        <br/>
        <br/>
        <CircularProgress className="centered"/>
        <br/>
        <br/>
        </div>;
    }

    renderUpdateSubscriptionOptions(subscribed: boolean, hasLumpSumOrSilverCup: boolean) {
        const highlightColor = '#1976D2';
        if (hasLumpSumOrSilverCup) {
            return null;
        }
        return <div className="centered">
            <h1>Get Queue</h1>
            <h3>Over 100 offices still trust Queue as their office system in 2021</h3>
            <a href="https://docs.google.com/presentation/d/e/2PACX-1vRZ0PSQtDHd9l3zR8nnID5Z9zmSRY9O7_OAmy6aKjrvnD3IQFZK1mRd8806Fuz0qiQQKxd0XvDep6Vl/pub?start=false&loop=false&delayms=60000" target="_blank" rel="noopener noreferrer">Learn more</a> about Silver Cup Edition
            <div style={{padding: '0px 30px 0px 30px'}}>
            <PricingTable  highlightColor={highlightColor}>
            <PricingSlot buttonClass="hidden" title='BASIC' priceText=''>
                    <PricingDetail> <b>Unlimited</b> Reps</PricingDetail>
                    <PricingDetail> <b>2x</b> Faster Phoning</PricingDetail>
                    <PricingDetail> <b>Real-Time</b> Phoning Leaderboards</PricingDetail>
                    <PricingDetail> <b>PDI Tracking</b> Tools</PricingDetail>
                    <PricingDetail> <b>Manual</b> Demo Tracking</PricingDetail>
                    <PricingDetail strikethrough> <b>Analytics</b> Dashboard and <b>National</b> Leaderboards</PricingDetail>
                    <PricingDetail strikethrough> In-App <b>Instant Messaging</b></PricingDetail>
                    <PricingDetail strikethrough> <b>DVM</b> Mode</PricingDetail>
                    <PricingTable highlightColor={highlightColor}>
                        <PricingSlot highlighted buttonClass="hidden"  title='ANNUAL' priceText='$49/month billed annually'>
                            <PricingDetail> <b>Save $240/year - BEST VALUE</b></PricingDetail>
                            <PricingDetail> <b>Paid for in 1-2 average orders/month</b></PricingDetail>
                            <Button color="primary" variant="contained">
                                <StripeCheckout
                                    name="Basic Edition Annual"
                                    description={"$588"}
                                    token={(token: Token) => {
                                        this.onSubscribed(token, BillingService.sharedInstance().getDiscountedBasicAnnual())
                                    }}
                                    billingAddress={true}
                                    zipCode={true}
                                    panelLabel="Subscribe"
                                    stripeKey={BillingService.sharedInstance().getPublishableKey()}
                                >
                                    Start Saving
                                </StripeCheckout>
                            </Button>
                            <PricingDetail>{"$588" }</PricingDetail>
                        </PricingSlot>
                        <PricingSlot onClick={undefined} buttonClass="hidden"  title='MONTHLY' priceText='$69/month'>
                            <PricingDetail> <b>Best for new offices</b></PricingDetail>
                            <Button color="primary" variant="contained">
                                <StripeCheckout
                                    name="Basic Edition Monthly"
                                    description={"$69" }
                                    token={(token: Token) => {
                                        this.onSubscribed(token, BillingService.sharedInstance().getMonthlyPlanID())
                                    }}
                                    billingAddress={true}
                                    zipCode={true}
                                    panelLabel="Subscribe"
                                    stripeKey={BillingService.sharedInstance().getPublishableKey()}
                                >
                                    Continue with Monthly
                                </StripeCheckout>
                            </Button>
                            <PricingDetail>{"$69"}</PricingDetail>
                        </PricingSlot>
                            
                    </PricingTable>
                </PricingSlot>
                <PricingSlot buttonClass="hidden" title='SILVER CUP' priceText=''>
                    <PricingDetail> <b>Unlimited</b> Reps</PricingDetail>
                    <PricingDetail> <b>2x</b> Faster Phoning</PricingDetail>
                    <PricingDetail> <b>Real-Time</b> Phoning Leaderboards</PricingDetail>
                    <PricingDetail> <b>PDI Tracking</b> Tools</PricingDetail>
                    <PricingDetail> <b>Automatic</b> Rep Demo Checkouts</PricingDetail>
                    <PricingDetail> <b>Analytics</b> Dashboard and <b>National</b> Leaderboards</PricingDetail>
                    <PricingDetail> In-App <b>Instant Messaging</b></PricingDetail>
                    <PricingDetail> <b>DVM</b> Mode</PricingDetail>
                    <PricingTable highlightColor={highlightColor}>
                        <PricingSlot   buttonClass="hidden"  title='ANNUAL' priceText='$79/month billed annually'>
                            <PricingDetail> <b>Save $720/year</b></PricingDetail>
                            <PricingDetail> <b>Paid for in 2-3 average orders/month</b></PricingDetail>
                            <Button color="primary" variant="contained">
                                <StripeCheckout
                                    name="Silver Cup Edition Annual"
                                    description={"$948" }
                                    token={(token: Token) => {
                                        this.onSubscribed(token, BillingService.sharedInstance().getSilverCupAnnual());
                                    }}
                                    billingAddress={true}
                                    zipCode={true}
                                    panelLabel="Subscribe"
                                    stripeKey={BillingService.sharedInstance().getPublishableKey()}
                                >
                                    Upgrade Now
                                </StripeCheckout>
                            </Button>
                            <PricingDetail>{"$948" }</PricingDetail>
                        </PricingSlot>
                        <PricingSlot buttonClass="hidden" title='MONTHLY' priceText='$139/month'>
                        <PricingDetail> <b>Best for new offices</b></PricingDetail>
                        <Button color="primary" variant="contained">
                                <StripeCheckout
                                    name={'Silver Cup Edition Monthly'}
                                    description={"$139" }
                                    token={(token: Token) => {
                                        this.onSubscribed(token, BillingService.sharedInstance().getSilverCupMonthly());
                                    }}
                                    billingAddress={true}
                                    zipCode={true}
                                    panelLabel="Subscribe"
                                    stripeKey={BillingService.sharedInstance().getPublishableKey()}
                                >
                                    Upgrade Now 
                                </StripeCheckout>
                        </Button>
                        <PricingDetail>{"$139" }</PricingDetail>
                        </PricingSlot>
                    </PricingTable>
                </PricingSlot>
            </PricingTable>
            
            </div>
        </div>
    }


    renderPageBody() {
         //we know the team has been loaded
         let teamStore: TeamStore = TeamStore.instance();
         let team: Team = teamStore.getTeam();
        

         if (team.hasQueueOfficeSubscription) {

             if (team.queueSubscriptionCarrier === AuthenticationService.instance().getCurrentUserID()) {

                //if team has discounted basic yearly or any version of silver cup, display their plan only, otherwise display promotion also
                 //render manager plan options
                 let planIsYearly = BillingService.sharedInstance().isYearly(team.plan!);
                 let hasEasyOrders = team.plan === BillingService.sharedInstance().getMonthlyPlanWithEasyOrdersID() || team.plan === BillingService.sharedInstance().getYearlyPlanWithEasyOrdersID();
                 let trailing: string = team.silverCup ? "to the silver cup edition of Queue." : "to the basic office plan."; 
                 return (<div>
                    <p className="centered">Congrats! Your office is currently subscribed on a {planIsYearly ? "yearly basis": "monthly basis"} {trailing} To view invoice history, upgrade your plan, or cancel your subscription, email queue.app.team@gmail.com</p>
                        {team.silverCup || team.basicYearlyDiscounted ? null : <div>
                        {renderErrorMessage(this.state.errorMessage)}
                        
                        {this.state.loading ? this.renderLoading() : this.renderUpdateSubscriptionOptions(team.hasQueueOfficeSubscription, team.hasQueueOfficeSubscription && BillingService.sharedInstance().isLumpSumOrSilverCup(team.plan ?? undefined))}</div>}
                    </div>);
             } else {
                 //render message that only the admin can actually manage this plan
                 return (<div>
                <p className="centered">Only the office admin, {team.queueSubscriptionCarrierName!}, can view or modify the office payment plan.</p>
                     </div>);
             }
         } else {
            return (
                <div>
                        {renderErrorMessage(this.state.errorMessage)}
                        {this.state.loading ? this.renderLoading() : this.renderUpdateSubscriptionOptions(team.hasQueueOfficeSubscription, team.hasQueueOfficeSubscription && BillingService.sharedInstance().isLumpSumOrSilverCup(team.plan ?? undefined))}
                </div>
            );
        }

    }

}

function renderErrorMessage(message: string | undefined) {
    if (message === undefined) {
        return null;
    } else {
        return (<p className="centered">{message!}</p>);
    }
}

export default injectStripe(PlanSelectionPage);
