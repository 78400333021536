import React from 'react';
import '../common.css';
import Grid from '@material-ui/core/Grid';
import QueueAppbar from '../components/queue_appbar';
import GuardedComponent from '../components/guarded_component';

export default class TutorialPage extends GuardedComponent<{}, {}> {

    renderGuarded(){
        return (
            <div>
                <QueueAppbar shouldDisplayMenu={true}/>
                <br/><br/><br/>
                <Grid container direction='column' justify='space-between' alignItems='center'>
                    <Grid item>
                        <div className='iframe-container'>
                        <iframe title="video_1" width="560" height="315" src="https://www.youtube.com/embed/_uFCH77nFHA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <br/><br/>
                    </Grid>
                    <Grid item>
                        <div className='iframe-container'>
                        <iframe title="video_2" width="560" height="315" src="https://www.youtube.com/embed/BpB_57d7F-Y" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <br/><br/>
                    </Grid>
                    <Grid item>
                        <div className='iframe-container'>
                        <iframe title="video_3" width="560" height="315" src="https://www.youtube.com/embed/vr6XqbanpJc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <br/><br/>
                    </Grid>
                    <Grid item>
                        <div className='iframe-container'>
                        <iframe title="video_4" width="560" height="315" src="https://www.youtube.com/embed/mXJ1DW6d3Lk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <br/><br/>
                    </Grid>
                    <Grid item>
                        <div className='iframe-container'>
                        <iframe title="video_5"width="560" height="315" src="https://www.youtube.com/embed/kCliPfaL-mY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <br/><br/>
                    </Grid>
                </Grid>
            </div>
        )
    }
    getPathOnRefresh(): string {
        return "/tutorial";
    }

}
