import Store from "./store";
import PhoneJam from "model/phone_jam";
import PhoneJamConverter from "model/converters/phone_jam_converter";
import ListenerClient from "./listener_client";
import PhoneJamPersistence from "persistence/phone_jam_persistence";
import { StoreOfPhoneJams } from "./phone_jam_store";



export default class DivisionalPhoneJamStore extends StoreOfPhoneJams {

    private phoneJamCache?: Map<string, PhoneJam>;

    static instance: DivisionalPhoneJamStore = new DivisionalPhoneJamStore();

    isLoaded(): boolean {
        return this.phoneJamCache !== undefined;
    }

    clearCache(): void {
        this.phoneJamCache = undefined;
    }

    private constructor() {
        super(new PhoneJamConverter());
    }

    updateCache(phoneJams: PhoneJam[]) {
        if (this.phoneJamCache === undefined) {
            this.phoneJamCache = new Map<string, PhoneJam>();
        }

        phoneJams.forEach((jam) => this.phoneJamCache!.set(jam.userID, jam));
    }

    getCurrentPhoneJams(): PhoneJam[] {
        const now: Date = new Date();
        let currentJams: PhoneJam[] = [];
        this.phoneJamCache!.forEach((jam) => {
            if ((now.getTime() - jam.lastCallTimeStamp.getTime()) <= 10800000) { //3 hours in milliseconds
                currentJams.push(jam);
            }
        });
        return currentJams;
    }

    getListenerClient(teams: string[]): ListenerClient<DivisionalPhoneJamStore> {
        return new ListenerClient(this, PhoneJamPersistence.instance().getDivisionalPhoneJamSubscriptionGenerator(teams));
    }
}