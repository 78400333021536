import { BillingPersistence } from "../persistence/billing_persistence";
import Rep from "../model/rep";
import { AuthenticationService } from "./authentication_service";
import ActionResultVoid from "../model/action_result_void";
import { Token } from "react-stripe-checkout";

const TEST_OFFICE_SUBSCRIPTION_PRODUCT_ID = "prod_GPNsmOtVPifD37";
const TEST_MONTHLY_PLAN_ID = "plan_GPNtgMAoFxVGTw";
const TEST_YEARLY_PLAN_ID = "plan_GPNu3gByiz6ceB";

//basic office plan
const PROD_OFFICE_SUBSCRIPTION_PRODUCT_ID = "prod_G4u99fO03XCsCm";
const PROD_MONTHLY_PLAN_ID = "plan_GPO9TpjOYvwALC";
const PROD_YEARLY_PLAN_ID = "plan_GPO8OEfOAfvTXG";
const PROD_DISCOUNTED_YEARLY_LUMP_SUM = "price_1HFjrvF7xw4iW3d8ZNDe1nle";

//office plan with easy orders
const PROD_OFFICE_SUBSCRIPTION_WITH_EASY_RRDERS_PRODUCT_ID = "prod_GaLRk6ijvwTpbF";
const PROD_YEARLY_PLAN_WITH_EASY_ORDERS_ID = "plan_GlWKIA7JU8mITO";
const PROD_MONTHLY_PLAN_WITH_EASY_ORDERS_ID = "plan_GlWLLKJAVKhHSw";

//silver cup edition
const PROD_SILVER_CUP_EDITION_PRODUCT_ID = "prod_HpOe0JQTJhi5ux";
const PROD_SILVER_CUP_EDITION_MONTHLY_PLAN_ID = "price_1HFjqtF7xw4iW3d83lQs0uyz";
const PROD_SILVER_CUP_EDITION_YEARLY_PLAN_ID = "price_1HFjqsF7xw4iW3d8m40yxhL6"

const TEST_STRIPE_PUBLISHABLE_KEY = "pk_test_7I3wEkehD97RjMKpklfJRJuE00TUMznUnl";
const PROD_STRIPE_PUBLISHABLE_KEY = "pk_live_yqHR45X3WJY1wIiYSllfYXPs00IsrLLOfr";


const PROD = true;

export default class BillingService {
    private readonly prod: boolean;
    private constructor(production: boolean) {
        this.prod = production;
    }

    private static instance: BillingService = new BillingService(PROD);
    private billingPersistene: BillingPersistence = BillingPersistence.instance();

    static sharedInstance(): BillingService {
        return this.instance;
    }

    public getDiscountedBasicAnnual(): string {
        return PROD_DISCOUNTED_YEARLY_LUMP_SUM;
    }

    public isLumpSumOrSilverCup(planID?: string): boolean {
        if (planID === undefined) return false;
        return planID === PROD_SILVER_CUP_EDITION_MONTHLY_PLAN_ID || planID === PROD_SILVER_CUP_EDITION_YEARLY_PLAN_ID || planID === PROD_DISCOUNTED_YEARLY_LUMP_SUM;
    }

    public isYearly(planID: string) {
        return planID === PROD_SILVER_CUP_EDITION_YEARLY_PLAN_ID || planID === PROD_YEARLY_PLAN_ID || planID === PROD_YEARLY_PLAN_WITH_EASY_ORDERS_ID || planID === PROD_DISCOUNTED_YEARLY_LUMP_SUM;
    }

    public isSilverCup(planID: string) {
        return planID === PROD_SILVER_CUP_EDITION_MONTHLY_PLAN_ID || planID === PROD_SILVER_CUP_EDITION_YEARLY_PLAN_ID;
    }

    public getSilverCupAnnual(): string {
        return PROD_SILVER_CUP_EDITION_YEARLY_PLAN_ID;
    }

    public getSilverCupMonthly(): string {
        return PROD_SILVER_CUP_EDITION_MONTHLY_PLAN_ID;
        ;
    }

    public getSubscriptionWithEasyOrdersProductID() {
        return this.prod ? PROD_OFFICE_SUBSCRIPTION_WITH_EASY_RRDERS_PRODUCT_ID : "";
    }

    public getMonthlyPlanWithEasyOrdersID() {
        return this.prod ? PROD_MONTHLY_PLAN_WITH_EASY_ORDERS_ID : "";
    }

    public getYearlyPlanWithEasyOrdersID() {
        return this.prod ? PROD_YEARLY_PLAN_WITH_EASY_ORDERS_ID : "";
    }

    public getMonthlyPlanID() {
        return this.prod ? PROD_MONTHLY_PLAN_ID : TEST_MONTHLY_PLAN_ID;
    }

    public getYearlyPlanID() {
        return this.prod ? PROD_YEARLY_PLAN_ID : TEST_YEARLY_PLAN_ID;
    }

    public getSubscriptionProductID() {
        return this.prod ? PROD_OFFICE_SUBSCRIPTION_PRODUCT_ID : TEST_OFFICE_SUBSCRIPTION_PRODUCT_ID;
    }

    public getPublishableKey() {
        return this.prod ? PROD_STRIPE_PUBLISHABLE_KEY : TEST_STRIPE_PUBLISHABLE_KEY;
    }

    public async subscribeToPlan(planID: string, token: Token, stripe: any, referrerEmail?: string,) : Promise<ActionResultVoid> {
        let currentUser: Rep = AuthenticationService.instance().getCurrentUser();
        let email: string = AuthenticationService.instance().getCurrentUserEmail();
        let createPaymentResult = await BillingPersistence.instance().createPaymentMethod(token, email, stripe);
        if (createPaymentResult.success) {
            let paymentMethodID: string = createPaymentResult.payload!;
            return await this.billingPersistene.subscribeCustomer(planID, paymentMethodID, 
                currentUser.repID, currentUser.phoneNumber, currentUser.officeNumber, 
                email, currentUser.firstName + " " + currentUser.lastName, referrerEmail);
        } else {
            return createPaymentResult;
        }
    }

    
}