import QueueMessage from "../model/queue_message";
import Store from "./store";
import MessageConverter from "../model/converters/message_converter";
import ListenerClient from "./listener_client";
import MessagePersistence from "../persistence/message_persistence";



export default class MessageStore extends Store<QueueMessage> {
    public static DEFAULT_LIMIT: number = 25; 
    private constructor () {
        super (new MessageConverter());

        this.getMessages = this.getMessages.bind(this);
    }

    private static messageStores: Map<string, MessageStore> = new Map(); 

    public static getSpecificMessageStore (chatID: string) {
        if (!this.messageStores.has(chatID)) {   
            this.messageStores.set(chatID, new MessageStore());
        }
        return this.messageStores.get(chatID)!;
    }

    public clearCache(): void {
        this.messageCache = undefined;
    }

    private messageCache?: QueueMessage[];

    public getStoreSize (): number {
        return this.messageCache!.length;
    }

    public isLoaded(): boolean {
        return this.messageCache !== undefined;
    }

    public updateCache (messages: QueueMessage[]): void {
        this.messageCache = messages;
        this.messageCache.sort((a: QueueMessage, b: QueueMessage) => a.sendDate.getTime() - b.sendDate.getTime());
    }

    public getMessages(): QueueMessage[] {
        return this.messageCache!;
    }

    public static getMessageListenerClient(chatID: string): ListenerClient<MessageStore> {
        return new ListenerClient<MessageStore>(
            MessageStore.getSpecificMessageStore(chatID), 
            MessagePersistence.instance().getMessageSubscriptionGenerator(chatID, this.DEFAULT_LIMIT)
        );
    }
}