import OfficeEvent from "../model/office_event";
import Store from "./store";
import OfficeEventConverter from "../model/converters/office_event_converter";
import ListenerClient from "./listener_client";
import OfficeEventPersistence from "../persistence/office_event_persistence";
import { AuthenticationService } from "./authentication_service";
import DateUtils from "./date_utils";

export default class OfficeEventStore extends Store<OfficeEvent> {
    private constructor () {
        super (new OfficeEventConverter());
        
        this.getOfficeEvents = this.getOfficeEvents.bind(this);
        this.getOfficeEvent = this.getOfficeEvent.bind(this);
    }

    private static officeEventStores: Map<string, OfficeEventStore> = new Map<string, OfficeEventStore>();

    public static getOfficeEventStoreForWeekID(weekID: string) {
        if (!OfficeEventStore.officeEventStores.has(weekID)) {
            OfficeEventStore.officeEventStores.set(weekID, new OfficeEventStore());
        }

        return OfficeEventStore.officeEventStores.get(weekID)!;
    }

    public clearCache(): void {
        this.officeEventCache = undefined;
    }

    private officeEventCache?: Map<string, OfficeEvent>;

    public isLoaded(): boolean {
        return this.officeEventCache !== undefined;
    }

    public updateCache (officeEvents: OfficeEvent[]): void {
        this.officeEventCache = new Map<string, OfficeEvent>();
        for (let officeEvent of officeEvents){
            this.officeEventCache.set(officeEvent.id, officeEvent);
        }
    }

    public getOfficeEvent(id: string): OfficeEvent{
        return this.officeEventCache!.get(id)!;
    }

    public getOfficeEvents(): OfficeEvent[]{
        let toReturn: OfficeEvent[] = [];
        this.officeEventCache!.forEach((value: OfficeEvent, _: string) => {
            toReturn.push(value);
        });
        return toReturn; 
    }

    public static getOfficeEventListenerClient (weekID: string): ListenerClient<OfficeEventStore> {

        let firstInstantOfWeek = DateUtils.getFirstInstantOfVectorWeekForWeekID(weekID);
        let lastInstantOfWeek = DateUtils.addDays(firstInstantOfWeek, 7);
        
        
        return new ListenerClient<OfficeEventStore>(
            OfficeEventStore.getOfficeEventStoreForWeekID(weekID),
            OfficeEventPersistence.instance().getOfficeEventSubscriptionGenerator(AuthenticationService.instance().getCurrentUserOfficeNumber(), firstInstantOfWeek, lastInstantOfWeek)
        );
    }
}