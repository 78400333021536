import ActionResultVoid from "../model/action_result_void";
import Chat from "../model/chat";
import ChatPersistence from "../persistence/chat_persistence";
import ChatConverter from "../model/converters/chat_converter";
import { DatabaseDocument } from "../persistence/persisted_object";
import { AuthenticationService } from "./authentication_service";
import QueueMessage from "model/queue_message";
import MessageConverter from "model/converters/message_converter";
import MessagePersistence from "persistence/message_persistence";
import { RepService } from "./rep_service";
import RepStore from "./rep_store";

export class ChatService {
    private constructor () {

    }

    public static getDisplayNameForChat (chat: Chat, includeManagersInName: boolean = true): string {
        if (chat.name !== null) {
            return chat.name;
        }
        let members: string [] = chat.members.filter((member) => !(chat.removedMembers.has(member)));
        members = members.filter((member) => member !== AuthenticationService.instance().getCurrentUserID());
        let toReturn: string = "";
        for (let i = 0; i<members.length-1; i++) {
            toReturn = toReturn.concat(RepService.getUserName(members[i]) + ", ");
        }
        toReturn = toReturn.concat(RepService.getUserName(members[members.length-1])); 
        if (chat.includeManagers && includeManagersInName) {
            toReturn = toReturn.concat(", Managers"); 
        }   
        return toReturn; 
    } 
    
    private static sharedInstance: ChatService = new ChatService(); 

    public static instance() : ChatService{
        return this.sharedInstance;
    }

    public async updateChat (chat: Chat): Promise<ActionResultVoid> {
        let chatConverter = new ChatConverter();
        let updatedChatData: DatabaseDocument = chatConverter.toPersistence(chat);
        return await ChatPersistence.instance().updateChat(updatedChatData);
    }

    public async createNewChat(members: string[], initialMessage: string, includeManagers: boolean, chatID: string): Promise<ActionResultVoid> {
        //create objects
        //use converters
        //delegate to createNewChat on persistence
        let lastOpenedDates: Map<string, Date> = new Map(); 
        let userID = AuthenticationService.instance().getCurrentUserID();
        for (let member of members) {
            lastOpenedDates.set(member, new Date("2008-02-03"));
        }
        let now = new Date();
        let justAfterNow = new Date(now.getTime() + 100);
        lastOpenedDates.set(userID, justAfterNow);
        let chat: Chat = {
            id: chatID, 
            teamid: AuthenticationService.instance().getCurrentUserOfficeNumber(), 
            name: null, 
            members: members,
            lastOpenedDates: lastOpenedDates, 
            removedMembers: new Map(), 
            lastMessageText: initialMessage, 
            lastMessageDate: now, 
            lastMessageSender: userID, 
            includeManagers: includeManagers,
        }
        let message: QueueMessage = {
            id: "", 
            chatId: "", 
            senderId: userID, 
            messageContent: initialMessage,    
            sendDate: now,
        }
        let chatConverter = new ChatConverter(); 
        let msgConverter = new MessageConverter();
        return await ChatPersistence.instance().createNewChat(chatConverter.toPersistence(chat), msgConverter.toPersistence(message));
    }
    
    public async sendNewMessage(chat: Chat, messageText: string): Promise<ActionResultVoid> {
        //create message object
        //use converter on message object
        //delegate to sendNewMessage on persistence
        let message: QueueMessage = {
            id: "",
            chatId: chat.id, 
            senderId: AuthenticationService.instance().getCurrentUserID(),
            messageContent: messageText, 
            sendDate: new Date(),
        };
        let now = new Date();
        let justAfterNow = new Date(now.getTime() + 100);
        let newLastOpenedDates = chat.lastOpenedDates; 
        newLastOpenedDates.set(AuthenticationService.instance().getCurrentUserID(), justAfterNow);
        let newChat: Chat = {
            id: chat.id, 
            teamid: chat.teamid,
            name: chat.name, 
            lastOpenedDates: newLastOpenedDates, 
            members: chat.members, 
            removedMembers: chat.removedMembers, 
            lastMessageText: message.messageContent,
            lastMessageDate: new Date(), 
            lastMessageSender: AuthenticationService.instance().getCurrentUserID(), 
            includeManagers: chat.includeManagers, 
        }
        let msgConverter = new MessageConverter(); 
        let chatConverter = new ChatConverter(); 
        return await ChatPersistence.instance().sendNewMessage(chatConverter.toPersistence(newChat), msgConverter.toPersistence(message)); 
    }
    
    public async markSeen(chatID: string): Promise<ActionResultVoid> {
        //get userID from authservice, delegate to markSeen on Persistence
        let userID = AuthenticationService.instance().getCurrentUserID(); 
        return await ChatPersistence.instance().markSeen(new Date(), chatID, userID);
    }

    public generateChatID (): string {
        return ChatPersistence.instance().getChatID(); 
    }

    public async addChatMember (repID: string, chatID: string): Promise<ActionResultVoid> {
        //adds member to chat by updating the members field of the given chat to add this member with the last date of opening 
        //the chat being february 3, 2008, before any messages have been sent in the chat 
        return await ChatPersistence.instance().markSeen(new Date("2008-02-03"), chatID, repID);
    }
}

