import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText } from '@material-ui/core';
import Rep from 'model/rep';
import { RepService } from 'services/rep_service';
import Loader from 'ui/pages/loader_page';

interface EditRepDialogProps {
    rep: Rep,
    onClose: () => void,
}

interface EditRegDialogState {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    loading: boolean
}

export default class EditRepDialog extends React.Component<EditRepDialogProps, EditRegDialogState> {

    constructor(props: EditRepDialogProps) {
        super(props);
        this.state = {
            firstName: props.rep.firstName,
            lastName: props.rep.lastName,
            phoneNumber: props.rep.phoneNumber,
            loading: false
        };
    }

    render() {
        const rep = this.props.rep;
        const existsEdit = rep.firstName !== this.state.firstName || rep.lastName !== this.state.lastName || rep.phoneNumber !== this.state.phoneNumber;
        const existsError = this.state.firstName.length < 1 || this.state.lastName.length < 1 || this.state.phoneNumber.length < 1;
        return <Dialog open={true} onBackdropClick={this.props.onClose} onEscapeKeyDown={this.props.onClose}>
            <DialogTitle>Edit Your Rep's Details</DialogTitle>
            <DialogContent>
                <DialogContentText>Careful! Once saved, these changes can't be undone.</DialogContentText>
                {this.state.loading ? <Loader /> : <React.Fragment>
                    <TextField
                        variant="outlined"
                        value={this.state.firstName}
                        placeholder={"First Name"}
                        error={this.state.firstName.length < 1}
                        helperText={this.state.firstName.length < 1 ? "Please Enter a First Name" : undefined}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.setState({
                                firstName: event.target.value
                            });
                        }}
                    />
                    <TextField
                        variant="outlined"
                        value={this.state.lastName}
                        placeholder={"Last Name"}
                        error={this.state.lastName.length < 1}
                        helperText={this.state.lastName.length < 1 ? "Please Enter a Last Name" : undefined}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.setState({
                                lastName: event.target.value
                            });
                        }}
                    />
                    <TextField
                        variant="outlined"
                        value={this.state.phoneNumber}
                        placeholder={"Phone Number"}
                        error={this.state.phoneNumber.length < 1}
                        helperText={this.state.phoneNumber.length < 1 ? "Please Enter a Phone Number" : undefined}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            this.setState({
                                phoneNumber: event.target.value
                            });
                        }}
                    />
                </React.Fragment>}

            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} variant="outlined" disabled={this.state.loading}>
                    Cancel
                </Button>
                <Button onClick={() => {
                    this.setState({ loading: true });
                    rep.firstName = this.state.firstName;
                    rep.lastName = this.state.lastName;
                    rep.phoneNumber = this.state.phoneNumber

                    RepService.instance().updateRep(rep).then(this.props.onClose);
                }} color="primary" variant="contained" disabled={!existsEdit || existsError || this.state.loading}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>;
    }

}