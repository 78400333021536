import React from 'react';
import { Redirect } from 'react-router';
import ActionResultVoid from '../../model/action_result_void';
import Loader from './loader_page';


interface AsyncLoaderProps {
    asyncTask: () => Promise<ActionResultVoid>,
    redirectPathOnTaskSuccess: string,
    redirectPathOnTaskFailed: string
}

interface AsyncLoaderState {
    loading: boolean,
    redirectUrl?: string
}


export default class AsyncLoader extends React.Component<AsyncLoaderProps, AsyncLoaderState> {

    constructor(props: AsyncLoaderProps) {
        super(props);
        this.state = {
            loading: true,
        }
    }


    componentDidMount() {
        this.props.asyncTask().then((result: ActionResultVoid) => {
            if (result.success) {
                this.setState({
                    loading: false,
                    redirectUrl: this.props.redirectPathOnTaskSuccess,
                })
            } else {
                this.setState({
                    loading: false,
                    redirectUrl: this.props.redirectPathOnTaskFailed
                });
            }
        }).catch((error) => {
            this.setState({
                loading: false,
                redirectUrl: this.props.redirectPathOnTaskFailed
            });
        });
    }

    render() {
        if (this.state.loading) {
            return (<Loader/>);
        } else {
            return (<Redirect to={this.state.redirectUrl!}/>);
        }
    }
}