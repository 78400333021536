import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import { RepService } from "services/rep_service";

class Notes extends React.Component {

  constructor(props) {
    super();
    const { rep, classes } = props;
    const { notes } = rep;
    this.state = {
      editing: false,
      notes: notes
    }

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);

  }

  render() {
    const { rep, classes } = this.props;
    const { notes } = this.state;

    return (
      <div>
        <TextField
          value={notes}
          multiline
          rows={8}
          variant='outlined'
          placeholder={'Notes'}
          className={classes.container}
          inputProps={{
            className: classes.input
          }}
          onChange={this.onChange}
        />

        {this.renderSave()}
      </div>

    )
  }

  renderSave() {
    const { rep, classes } = this.props;
    const { notes } = this.state;

    if (rep.notes === notes) return;

    return (
      <Button
        variant='contained'
        color="primary"
        onClick={this.onSave}
        className={classes.saveButton}
      >
          Save
      </Button>
    )
  }

  onChange(event) {
    const { value } = event.target;
    this.setState({ notes: value });
  }

  onSave() {
    const { rep } = this.props;
    const { notes } = this.state;
    rep.notes = notes;
    RepService.instance().updateRep(rep);
  }
}

const styles = {
  container: {
    fontSize: 50, //not functional
    marginTop: 20,
    width: '100%',
  },
  input: {
    fontSize: 10, //functional
  },
  saveButton: {
    fontSize: 10,
    float: 'right',
    margin: 5,
  }
}
export default withStyles(styles)(Notes);
