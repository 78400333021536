import Converter from "./converter";
import { DatabaseDocument, DocumentData } from "../../persistence/persisted_object";
import Appointment from "../appointment";

export default class ApptConverter extends Converter<Appointment> {

    toPersistence(a: Appointment): DatabaseDocument {
        let data = {
            "userID": a.repID,
            "appointmentTimeStamp": a.startTimeStamp,
            "loggedOut": a.isLoggedOut,
            "needsToBeCalledIn": a.needsToBeCalledIn,
            "calledIn": a.hasBeenCalledIn,
            "cancelled": a.hasBeenCancelled,
            "isPersonalContact": a.isWithPersonalContact,
            "userName": a.repName,
            "outcome": a.outcome ?? null,
            "cpo": a.cpo ?? null,
            "details": a.details ?? null,
            "recCount": a.recCount ?? null,
            "calledInName": a.calledInName ?? null,
            "calledInID": a.calledInName ?? null
        };

        return {
            data: data,
            id: a.apptID
        };
    }

    fromPersistence(doc: DatabaseDocument): Appointment {
        let data: DocumentData = doc.data;

        let repName: string;
        if (data["userName"] === null) {
            repName = "";
        } else {
            repName = data["userName"];
        }

        let isWithPersonalContact: boolean;
        if (data["isPersonalContact"] === null) {
            isWithPersonalContact = false;
        } else {
            isWithPersonalContact = data["isPersonalContact"];
        }

        return {
            repID: data["userID"],
            apptID:  doc.id,
            repName: repName,
            startTimeStamp:  data["appointmentTimeStamp"].toDate(),
            isLoggedOut:  data["loggedOut"],
            needsToBeCalledIn:  data["needsToBeCalledIn"],
            hasBeenCalledIn:  data["calledIn"],
            hasBeenCancelled:  data["cancelled"],
            isWithPersonalContact:  isWithPersonalContact,
            outcome: data["cancelled"] ? "Cancelled" : data["outcome"],
            cpo: data["cpo"],
            details: data["details"],
            recCount: data["recCount"],
            calledInName: data["calledInName"],
            calledInID: data["calledInID"]
        };
    }
}
