import React from 'react';
import MessageBuilderTextField from './message_builder_text_field';
import { Paper, Button, Typography, Tooltip, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import RepStore from 'services/rep_store';
import ChatStore from 'services/chat_store';
import MessageStore from 'services/message_store';
import { MessageService } from 'services/message_service';
import {Message, ChatBubble} from "react-chat-ui";
import Chat from 'model/chat';
import { ChatService } from 'services/chat_service'; 
import { AuthenticationService } from 'services/authentication_service';
import QueueMessage from 'model/queue_message';
import { RepService } from 'services/rep_service';
import DateUtils from 'services/date_utils';


interface ChatViewProps { 
    chatID: string, 
    onMessageHeaderClicked: () => void,
    updateErrorSnackbarText: (newValue: boolean, errorText: string) => void,
}

interface ChatViewState {
    messageText: string, 
}


//assume that all stores are loaded
export default class ChatView extends React.Component<ChatViewProps, ChatViewState> {
    messagesEnd!: HTMLDivElement | null;
    numMessages: number = 0; 
    lastOpenedChatID: string = ""; 
    constructor(props: ChatViewProps) {
        super(props);
        this.state = {
            messageText: "", 
        }; 
        this.onMessageTextChanged = this.onMessageTextChanged.bind(this); 
        this.onMessageTextSubmitted = this.onMessageTextSubmitted.bind(this); 
        this.getTooltipText = this.getTooltipText.bind(this); 
    }

    componentDidMount () {
        this.scrollToBottom(); 
    }

    componentDidUpdate () {
        this.scrollToBottom(); 
    }

    render() {  
        console.log(this.props.chatID);
        if (this.lastOpenedChatID !== this.props.chatID) {
            ChatService.instance().markSeen(this.props.chatID);
            this.lastOpenedChatID = this.props.chatID; 
            this.numMessages = MessageStore.getSpecificMessageStore(this.props.chatID).getMessages().length;  
        }
        else if (this.numMessages !== MessageStore.getSpecificMessageStore(this.props.chatID).getMessages().length) {
            this.numMessages = MessageStore.getSpecificMessageStore(this.props.chatID).getMessages().length;
            ChatService.instance().markSeen(this.props.chatID); 
        }
        return (
            <div className="flutter-column">
                <div className="flutter-flex-element">
                    {this.renderMessageHeader()}
                </div>
                <div className="flutter-flex-footer">
                    {this.renderMessageBar()}
                </div>
                <div className="flutter-flex-expanded">
                    {this.renderMessages()}
                    {this.renderRemovedMessage()}
                </div>
            </div>
        )
    }

    renderRemovedMessage () {
        if (this.hasBeenRemovedFromChat()) {
            return (
                <Typography variant='h6' align='center'>You were removed from this chat at {ChatStore.instance().getChat(this.props.chatID)?.removedMembers.get(AuthenticationService.instance().getCurrentUserID())?.toLocaleString()}</Typography>
            ); 
        }
        else return null; 
    }

    scrollToBottom () {
        this.messagesEnd?.scrollIntoView({behavior:"smooth"});
    }

    onMessageTextChanged (value: string) {
        this.setState({messageText: value});
    }

    onMessageTextSubmitted () {
        if (this.state.messageText === "") {
            this.props.updateErrorSnackbarText(true, "Please enter message text.");
            return; 
        }
        let chat: Chat = ChatStore.instance().getChat(this.props.chatID)!; 
        ChatService.instance().sendNewMessage(chat, this.state.messageText).then((_) => {this.scrollToBottom()});
        this.setState({
            messageText: ""
        });
    }

    renderMessageHeader() {
        return (
            <Paper elevation={24} >
                <Button variant='outlined' fullWidth onClick={this.props.onMessageHeaderClicked} disabled={ChatStore.instance().hasBeenRemovedFromChat(this.props.chatID)}> 
                    <Typography noWrap style={{maxWidth: "75%"}}>{ChatService.getDisplayNameForChat(ChatStore.instance().getChat(this.props.chatID)!)}</Typography>
                </Button>
            </Paper>
        )
    }

    hasBeenRemovedFromChat() {
        let chat: Chat = ChatStore.instance().getChat(this.props.chatID)!; 
        let uid: string = AuthenticationService.instance().getCurrentUserID(); 
        return chat.removedMembers.has(uid); 
    }

    renderMessageBar() {
        return (
            <MessageBuilderTextField shouldBeDisabled={ChatStore.instance().hasBeenRemovedFromChat(this.props.chatID)} messageText={this.state.messageText} onChanged={this.onMessageTextChanged} onSubmitted={this.onMessageTextSubmitted}/>
        );
    }

    getDateString (date: Date) {
        if (DateUtils.isSameDayAs(date, new Date())) {
            let aHrs:number = date.getHours();
            let aMin:number = date.getMinutes();
            let amPm: string = "PM";
            if (aHrs < 12) {amPm ="AM";}
            if (aHrs > 12) {aHrs = aHrs - 12;}
            let dHrs:string =aHrs.toLocaleString()
            let dMin:string =aMin.toLocaleString()
            if (aHrs < 10) {dHrs = "0" + dHrs;}
            if (aMin < 10) {dMin = "0" + dMin;}
            let vectorDay = DateUtils.toVectorDay(date.getDay());
            let dayAbbr = DateUtils.getDayAbbreviationForVectorDay(vectorDay);
            let monthAbbr = DateUtils.getMonthAbbreviation(date);
            let dayOfMonth = date.getDate();
            return dHrs + ':' + dMin + ' ' + amPm + " " + dayAbbr + ", " + monthAbbr + " " + dayOfMonth
        }
        else return DateUtils.toMonthSlashDayString(date); 
    }

    getTooltipText (message: QueueMessage, chat: Chat) {
        let seenString: string = "Seen by: ";
        let seenUsers: string[] = []; 
        for (let member of Array.from(chat.lastOpenedDates.keys())) {
            if (chat.lastOpenedDates.get(member)! > message.sendDate && member !== AuthenticationService.instance().getCurrentUserID() && member !== message.senderId) {
                seenUsers.push(member); 
            } 
        }
        if (seenUsers.length === 1) {
            seenString = "Seen by: " + RepService.getUserName(seenUsers[0]) + " on " + this.getDateString(chat.lastOpenedDates.get(seenUsers[0])!); 
        }
        else if (seenUsers.length === 0) {
            return "Sent on: " + this.getDateString(message.sendDate);
        }
        else {
            for (let i=0; i<seenUsers.length; i++) {
                if (i===seenUsers.length-1) {
                    seenString = seenString.concat(RepService.getUserName(seenUsers[i]));
                }
                else seenString = seenString.concat(RepService.getUserName(seenUsers[i]) + ", ");
            }
        }
        return "Sent on: " + this.getDateString(message.sendDate) + " | ".concat(seenString); 
    }

    renderMessages() {
        const theme = createMuiTheme({
            overrides: {
              MuiTooltip: {
                tooltip: {
                  fontSize: ".75em",
                }
              }
            }
          });
        let selectedChatMessageStore = MessageStore.getSpecificMessageStore(this.props.chatID);
        let chat = ChatStore.instance().getChat(this.props.chatID)!;
        let messages = selectedChatMessageStore.getMessages(); 
        if (this.hasBeenRemovedFromChat()) {
            messages = messages.filter((message) => {
                return (message.sendDate < chat.removedMembers.get(AuthenticationService.instance().getCurrentUserID())!);
            });
        }
        return (messages.map((message) => {
            let ref = null; 
            if (message.messageContent === chat.lastMessageText) {
                ref = (el: HTMLDivElement | null) => {this.messagesEnd = el;};
            }
            let displayMessage: typeof Message = MessageService.instance().toDisplayMessage(message); 
            let isTransferredOrInactive: boolean = !RepStore.activeRepStore().hasRep(message.senderId); 
            return (
                //props for queue chat bubble component: ref, message,
                <div style={{padding: displayMessage.id === 1 ? "10px" : "none"}} key={Message.id}>
                    <MuiThemeProvider theme={theme}>
                        <Tooltip title={this.getTooltipText(message, chat)}>
                            <div ref={ref}>
                                <ChatBubble message={displayMessage}/>
                                <Typography color={isTransferredOrInactive ? "error" : "textPrimary"} style={{float: displayMessage.id === 1 ? "left" : "none", paddingLeft: displayMessage.id === 1 ? "5px" : "none" }} variant="caption">{displayMessage.id === 1 ? displayMessage.senderName : ""}</Typography>
                            </div>      
                        </Tooltip>
                    </MuiThemeProvider>
                </div>
            );
        })
        ); 
    }
}