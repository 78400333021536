import React, { ChangeEvent } from "react";
import { Checkbox, TableCell } from "@material-ui/core";


const QueueCheckbox = ({ checked, onChange }) => {
  return (
   
      <Checkbox 
        className="no-padding"
        color='primary'
        checked={checked}
        onChange={onChange}
      />
  )
}



export default QueueCheckbox;
