import Appointment from '../model/appointment';
import Store from './store';
import ApptConverter from '../model/converters/appointment_converter';
import DateUtils from './date_utils';
import { AuthenticationService } from './authentication_service';
import ListenerClient from './listener_client';
import AppointmentPersistence from '../persistence/appointment_persistence';
import { SubscriptionGenerator } from '../persistence/subscription_generator';
//each appointments store will contain the appointments for a given week
//if a client wants more granularity than that, they will have to do some processing on their own
export default class AppointmentStore extends Store<Appointment> {
    //map of uid to appts for the week for that rep
    private appointments?: Map<string, Appointment[]>;

    private constructor() {
        super(new ApptConverter());
    }

    //maps weekID to the appointments for that week
    private static apptStores: Map<string, AppointmentStore> = new Map<string, AppointmentStore>();

    public static getAppoinmentStore(weekID: string): AppointmentStore {
        let store = this.apptStores.get(weekID);
        if (store === undefined) {
            this.apptStores.set(weekID, new AppointmentStore());
        }
        return this.apptStores.get(weekID)!;
    }

    public isLoaded(): boolean {
        return this.appointments !== undefined;
    }

    public updateCache(newAppointmentData: Appointment[]): void {
        this.appointments = new Map<string, Appointment[]>();
        for (let appt of newAppointmentData) {
            let repID: string = appt.repID;
            let thisRepsAppts: Appointment[];
            if (!this.appointments.has(repID)) {
                thisRepsAppts = [appt];
            } else {
                thisRepsAppts = this.appointments.get(repID)!;
                thisRepsAppts.push(appt);
            }
            this.appointments.set(repID, thisRepsAppts);
        }
    }

    public getAppointments(): Appointment[] {
        let allAppts: Appointment[] = [];
        this.appointments!.forEach((apptArr: Appointment[], _: string) => {
            allAppts = allAppts.concat(apptArr);
        });
        return allAppts;
    }

    public getAppointmentsForRep(repID: string, nonCancelledOnly: boolean = false): Appointment[] {
        let appt: Appointment[] | undefined = this.appointments!.get(repID);
        if (appt === undefined) {
            return [];
        } else {
            if (nonCancelledOnly) {
                appt = appt.filter((appt) => !appt.hasBeenCancelled);
            }
            return appt!;
        }
    }

    public clearCache(): void {
        this.appointments = undefined;
    }

    public static getAppointmentSubscriptionGenerator(weekID: string): SubscriptionGenerator {
        let firstInstantOfWeek = DateUtils.getFirstInstantOfVectorWeekForWeekID(weekID);
        let lastInstantOfWeek = DateUtils.addDays(firstInstantOfWeek, 7);
        let teamID = AuthenticationService.instance().getCurrentUserOfficeNumber();
        return AppointmentPersistence.instance().getAppointmentSubscriptionGenerator(teamID, firstInstantOfWeek, lastInstantOfWeek);
    }

    public static getListenerClientForWeekID(weekID: string) {

        if (!this.apptStores.has(weekID)) {
            this.apptStores.set(weekID, new AppointmentStore());
        }

        let teamID = AuthenticationService.instance().getCurrentUserOfficeNumber();
        let apptPersistence = AppointmentPersistence.instance();

        let firstInstantOfWeek = DateUtils.getFirstInstantOfVectorWeekForWeekID(weekID);
        let lastInstantOfWeek = DateUtils.addDays(firstInstantOfWeek, 7);

        return new ListenerClient(this.apptStores.get(weekID)!, 
            apptPersistence.getAppointmentSubscriptionGenerator(teamID, firstInstantOfWeek, lastInstantOfWeek));
    }
}