import Chat from "../model/chat";
import Store from "./store";
import ChatConverter from "../model/converters/chat_converter";
import ListenerClient from "./listener_client";
import ChatPersistence from "../persistence/chat_persistence";
import { AuthenticationService } from "./authentication_service";
import QueueMessage from "model/queue_message";

//TODO
//remove all uses of old chatsubscription generator and delete the function, using the new one instead
//remove all uses of old chatCache and implement functions with newChatCache
//add option to include managers when creating a chat that defaults to true
//update chat titles to only include managers if the include managers field is true
//update chat titles to always display member names, regardless of whether they are a manager or not
//update chat object to have include managers field
//update chat converter to to populate and update include managers field

export default class ChatStore extends Store<Chat> {
    private constructor () {
        super (new ChatConverter());

        this.getChats = this.getChats.bind(this);
    }

    
    private static sharedInstance: ChatStore = new ChatStore(); 

    public static instance () {
        return this.sharedInstance;
    }

    private chatCache?: Map<string, Chat>;

    public isLoaded(): boolean {
        return this.chatCache !== undefined;
    }

    public clearCache(): void {
        this.chatCache = undefined;
    }

    public updateCache (chats: Chat[]): void {
        if (this.chatCache === undefined) {
            this.chatCache = new Map<string, Chat>();
        }
        chats.forEach((chat) => {
            this.chatCache!.set(chat.id, chat);
        });
    }

    public getChats(): Chat[] {
        return Array.from(this.chatCache!.values()).sort((a: Chat, b: Chat) => b.lastMessageDate?.getTime()! - a.lastMessageDate?.getTime()!);
    }

    public static getChatListenerClient(): ListenerClient<ChatStore> {
        return new ListenerClient<ChatStore>(
            ChatStore.instance(), 
            ChatPersistence.instance().getChatSubscriptionGenerator(AuthenticationService.instance().getCurrentUserOfficeNumber(), AuthenticationService.instance().getCurrentUserID())
        );
    }

    public getChat (chatID: string) {
        return this.chatCache?.get(chatID); 
    }

    hasBeenRemovedFromChat(chatID: string) {
        let uid: string = AuthenticationService.instance().getCurrentUserID(); 
        return this.chatCache!.get(chatID)!.removedMembers.has(uid); 
    }
    
    public hasSeen (userID: string, message: QueueMessage) {
        let chat: Chat = ChatStore.instance().getChat(message.chatId)!;
        if (chat.lastOpenedDates.get(userID)! > message.sendDate) {
            return true; 
        }
        else return false; 
    } 
}