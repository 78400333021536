import React from 'react';
import QueueAppbar from '../components/queue_appbar';
import GuardedListenerComponent from '../components/guarded_listener_component';
import PhoneJamStore, { StoreOfPhoneJams } from '../../services/phone_jam_store';
import { CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Grid, Switch, Button, Typography, createMuiTheme, ThemeProvider, TextField } from '@material-ui/core';
import PhoneJam from '../../model/phone_jam';
import renderPhoneJamDisplayCard from '../components/phone_jam_display_card';
import RepStore from '../../services/rep_store';
import '../common.css';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import RepCollection, { CollectionType } from 'model/rep_collection';
import RepCollectionSelector from 'ui/components/rep_collection_selector';
import { AuthenticationService } from 'services/authentication_service';
import DivisionalPhoneJamStore from 'services/divisional_phone_jam_store';
import AbstractRepStore from 'services/abstract_rep_store';
import DivisionalRepStore from 'services/divisional_rep_store';



const unselectedTheme = createMuiTheme({
    palette:{
        primary: {
            main: '#fafafa',
        }
    },
    typography: {
        fontSize: 10,
    }
});


enum SortColumn {
    CallsMade = 0,
    Reached = 1,
    DemosBooked = 2,
    QueueLength = 3, 

}

interface LeaderboardsPageState {
    showSkillGrades: boolean, 
    descending: boolean,
    selectedColumn: SortColumn, 
    searchText: string, 
    organizeBy: CollectionType, 
    showCollections: boolean, 
    divisional: boolean
}

function isAggregateJam(jam: PhoneJam): boolean {
    return jam.userID === "";
}

export default class LeaderboardPage extends GuardedListenerComponent<{}, LeaderboardsPageState> {

    private currentPhoneJamStore: StoreOfPhoneJams;
    private currentRepStore: AbstractRepStore;

    constructor(props: {}) {
        super(props, true);
        this.state = {
            showSkillGrades: false,
            descending: true, 
            selectedColumn: SortColumn.CallsMade, 
            searchText: "", 
            organizeBy: CollectionType.GROUP, 
            showCollections: false, 
            divisional: false
        }
        this.currentPhoneJamStore = PhoneJamStore.phoneJamStore();
        this.currentRepStore = RepStore.activeRepStore();
        this.callsSort = this.callsSort.bind(this);
        this.reachedSort = this.reachedSort.bind(this);
        this.demosSort = this.demosSort.bind(this);
        this.updateSort = this.updateSort.bind(this);
        this.makeTableCell = this.makeTableCell.bind(this);
        this.queueSort = this.queueSort.bind(this);
    }


    makeTableCell(column: SortColumn) {
        const selectedTheme = createMuiTheme({
            palette:{
                primary: {
                    main: '#2c2c2c',
                }
            }, 
            typography: {
                fontSize: 10,
            }
        });
        //decide what text to display based on the column
        //decide whether to display the arrow and in what direction
        let name: string = "";
        if (column === SortColumn.CallsMade) name = "Calls Made";
        else if (column === SortColumn.Reached) name = "Reached";
        else if (column === SortColumn.QueueLength) name = "Queued";
        else name = "Demos Booked";
        return (<ThemeProvider theme={this.state.selectedColumn === column ? selectedTheme : unselectedTheme}>
            <TableCell >
                <a onClick={() => {
                    this.updateSort(column)
                }}>
                    <Grid container direction="row" alignItems="flex-start">
                        <Grid item>
                            {this.state.descending ? <ArrowDownwardIcon color="primary"/> : <ArrowUpwardIcon color="primary"/>}
                        </Grid>
                        <Grid item>
                            {name}
                        </Grid>
                    </Grid>
                </a> 
            </TableCell>
        </ThemeProvider>);
    }

    callsSort (a: PhoneJam, b: PhoneJam) { 
        let descendingResult = b.callsMade - a.callsMade;
        if (descendingResult === 0 && isAggregateJam(a) !== isAggregateJam(b)) {
            return isAggregateJam(a) ? -1 : 1;
        }
        return this.state.descending ? descendingResult : -descendingResult;
    };

    queueSort (a: PhoneJam, b: PhoneJam) {
        let aScore: number = a.queued; 
        let bScore: number = b.queued; 
        if (aScore === 0) {
            if (a.smartReloading) aScore++; 
        }
        if (bScore === 0) {
            if (b.smartReloading) bScore++; 
        }
        let descendingResult = bScore - aScore; 
        if (descendingResult === 0 && isAggregateJam(a) !== isAggregateJam(b)) {
            return isAggregateJam(a) ? -1 : 1;
        }
        return this.state.descending ? descendingResult : -descendingResult; 
    }
    
    reachedSort(a: PhoneJam, b: PhoneJam) {
        let descendingResult = b.reached - a.reached;
        if (descendingResult === 0 && isAggregateJam(a) !== isAggregateJam(b)) {
            return isAggregateJam(a) ? -1 : 1;
        }
        return this.state.descending ? descendingResult : -descendingResult;
    }
    
    demosSort(a: PhoneJam, b: PhoneJam) { 
        let descendingResult = b.demosBooked - a.demosBooked;
        if (descendingResult === 0 && isAggregateJam(a) !== isAggregateJam(b)) {
            return isAggregateJam(a) ? -1 : 1;
        }
        return this.state.descending ? descendingResult : -descendingResult;
    };
    
    getSorts() {
        return [this.callsSort, this.reachedSort, this.demosSort, this.queueSort];
    }


    updateSort(columnClicked: SortColumn) {
        //two cases
        if (columnClicked === this.state.selectedColumn) {
            this.setState({descending: !this.state.descending});
        }
        else {
            this.setState({descending:true,selectedColumn:columnClicked});
        }
    }
   
    getEmployedListenerClients(){
        return [PhoneJamStore.phoneJamStore().getPhoneJamListenerClient(), RepStore.getActiveRepsListenerClient()];
    }

    getPathOnRefresh() {
        return "/leaderboard";
    }

    renderBody() {
        if (!this.currentPhoneJamStore.isLoaded() || !this.currentRepStore.isLoaded()) {
            return (
                <TableRow>
                    <TableCell>
                        <CircularProgress/>
                    </TableCell>
                </TableRow>
            );
        } else {
            let collections = this.currentRepStore.getRepCollections(this.state.organizeBy, true);
            
            let phoneJams: PhoneJam[] = this.currentPhoneJamStore.getCurrentPhoneJams().slice();
            phoneJams = phoneJams.filter((element, _) => element.callsMade > 0 || element.demosBooked > 0);
            phoneJams = phoneJams.filter((element, _) => element.userName.toLowerCase().includes(this.state.searchText.toLowerCase()));
            let message: string = this.state.searchText === "" ? "No active phone jams" : "No matching phone jams";
            if (phoneJams.length === 0) {
                return <TableRow>   
                <TableCell>
                    {message}
                </TableCell>
            </TableRow>;
            } else {
                let collectionJams: PhoneJam[] = []; 
                if (this.state.showCollections) {
                    collectionJams = this.getCollectionPhoneJam(phoneJams, collections); 
                }
                let colorMap: Map<string, string> = new Map(); 
                let colors: string[] = ["magenta", "red", "green", "orange", "turquoise", "blueGreen", "blue", "purple", "darkBlue", "yellow"];
                for (let i=0; i<collectionJams.length; i++) {
                    colorMap.set(collectionJams[i].userName, colors[i%10]);
                }
                console.log(colorMap)
                
                let calls: number = 0;
                let reached: number = 0;
                let demos: number = 0;
                let queued: number = 0; 
                phoneJams.forEach((jam) => {
                    calls += jam.callsMade;
                    reached += jam.reached;
                    demos += jam.demosBooked;
                    queued += jam.queued; 
                });
                phoneJams = phoneJams.concat(collectionJams); 
                phoneJams.sort(this.getSorts()[this.state.selectedColumn]);
                let result = phoneJams.map((phoneJam: PhoneJam) => {
                    let color: string = phoneJam.userID === "" ? colorMap.get(phoneJam.userName)! : this.getColorForDisplayCard(phoneJam.userID, colorMap);
                    return renderPhoneJamDisplayCard(phoneJam, this.currentRepStore.getRep(phoneJam.userID)!, this.state.showSkillGrades, phoneJam.userID === "", color, this.state.showCollections)
                }); 

                

                return [(<TableRow key="team_total">
                    <TableCell>
                        <strong>Team Total</strong>
                    </TableCell>
                    {this.state.showSkillGrades ? <TableCell></TableCell> : null}
                    {this.state.showSkillGrades ? <TableCell></TableCell> : null}
                    {this.state.showSkillGrades ? <TableCell></TableCell> : null}
               
                    <TableCell>
                        <div className="Padding-Left-More">
                        <strong>{calls}</strong>
                        </div>
                    </TableCell>
                    <TableCell>
                        <div className="Padding-Left-More"><strong>{reached}</strong></div>
                        
                    </TableCell>
                    <TableCell>
                        <div className="Padding-Left-More">
                            <strong>{demos}</strong>
                        </div>
                    </TableCell>
                    <TableCell>
                        <div className="Padding-Left-More">
                            <strong>{queued}</strong>
                        </div>
                    </TableCell>
                </TableRow>)].concat(result);

            }
        }
    }

    getColorForDisplayCard (uid: string, colorMap: Map<string, string>): string {
        
            let rep = this.currentRepStore.getRep(uid);
            if (rep === undefined) {
                return "blue";
            } else {
                let groupTeam = this.state.organizeBy === CollectionType.GROUP ? rep.group : rep.team;
                return colorMap.get(groupTeam)!;
            }  
    }

    getCollectionPhoneJam (phoneJams: PhoneJam[], collections: RepCollection[]): PhoneJam[] {
        let collectionJams: PhoneJam[] = [];
        collections.forEach((collection) => {
            
            let uids: Set<string> = new Set<string>();
            collection.getReps().forEach((rep) => uids.add(rep.repID));
           
            let callsMade: number = 0;
            let demosBooked: number = 0;
            let reached: number= 0;
            let queued: number= 0;
            phoneJams.forEach((phoneJam) => {
               
                if (uids.has(phoneJam.userID)) {
                    callsMade += phoneJam.callsMade; 
                    demosBooked += phoneJam.demosBooked;
                    reached += phoneJam.reached; 
                    queued += phoneJam.queued; 
                }
            });
            collectionJams.push({
                callsMade: callsMade, 
                demosBooked: demosBooked, 
                callBacks: 0, 
                declines: 0, 
                reached: reached, 
                textsSent: 0, 
                startTimeStamp: new Date() , 
                lastCallTimeStamp: new Date() , 
                timeSpentOnPhoneInMilliseconds: 0, 
                totalDurationInMilliseconds: 0, 
                userID: "", 
                userName: collection.getCollectionName(), 
                queued: queued, 
                smartReloading: false, 
            })
        }); 
        return collectionJams.filter((jam) => jam.callsMade > 0 || jam.demosBooked > 0 || jam.queued > 0 );
    }

    renderGuarded() {

        this.currentPhoneJamStore = this.state.divisional ? DivisionalPhoneJamStore.instance : PhoneJamStore.phoneJamStore();
        this.currentRepStore = this.state.divisional ? DivisionalRepStore.activeRepStore() : RepStore.activeRepStore();
        return (
            <div>
                <QueueAppbar shouldDisplayMenu={true}/> 
                <div className="Padding-Left">
                    <Grid container direction='row' justify='flex-start' alignItems='center'>
                    <Grid item style={{paddingLeft: "10px", paddingRight: "10px"}}>
                        <TextField
                        value={this.state.searchText}
                        placeholder="Search..."
                        onChange={(event) => {this.setState({searchText: event.target.value})}} variant="outlined"
                        inputProps={{
                        style: {
                        height: '30px', 
                        padding: '0 14px',
                        },
                        }}
                        />
                    </Grid>
                    <Grid item style={{paddingLeft: "10px", paddingRight: "10px"}}>
                        {this.state.organizeBy === CollectionType.GROUP ? <Button color="primary" variant="contained" href="/#/create-group">
                        Create Group
                        </Button> :
                        <Button color="primary" variant="contained" href="/#/create-team">
                        Create Team
                        </Button>}
                    </Grid>
                        <Grid item style={{paddingLeft: "10px", paddingRight: "5px"}}>
                            Show Skill Grades
                        </Grid>
                        <Grid item>
                            <Switch checked={this.state.showSkillGrades} color="primary" onChange={(_) => {this.setState({showSkillGrades: !this.state.showSkillGrades})}}/>
                        </Grid>
                        <Grid item style={{paddingLeft: "10px", paddingRight: "5px"}}>
                            Show {this.state.organizeBy.toString()}
                        </Grid>
                        <Grid item>
                            <Switch checked={this.state.showCollections} color="primary" onChange={(_) => {this.setState({showCollections: !this.state.showCollections})}}/>
                        </Grid>
                        <Grid item style={{paddingLeft: "10px", paddingRight: "5px"}}>
                            Division Mode
                        </Grid>
                        <Grid item>
                            <Switch checked={this.state.divisional} color="primary" onChange={(_) => {
                                    let currentlyDivisional = this.state.divisional;
                                    this.setState({
                                        divisional: !currentlyDivisional
                                    });
                                    if (currentlyDivisional) {
                                        //make it not divisional
                                        super.refreshListenerClients([PhoneJamStore.phoneJamStore().getPhoneJamListenerClient(), RepStore.getActiveRepsListenerClient()]);
                                    } else {
                                        //make it divisional
                                        AuthenticationService.instance().getAllOfficesInDivision().then((fetchResult) => {
                                            if (fetchResult.success) {
                                                let teamIds = fetchResult.payload!.map((office) => office.id);
                                                super.refreshListenerClients([DivisionalPhoneJamStore.instance.getListenerClient(teamIds), DivisionalRepStore.getActiveRepsListenerClient(teamIds)])
                                                
                                            } else {
                                                this.setState({divisional: false})
                                            }
                                        })
                                    }
                                }}/>
                        </Grid>
                        <Grid item>
                            <RepCollectionSelector title="Show by" onCollectionTypeSelected={(collection: CollectionType) => this.setState({organizeBy: collection, showCollections: true})} />
                        </Grid>
                    </Grid>
                </div>
                <Table stickyHeader>
                    <TableHead >
                        <TableRow selected>
                            <TableCell>Name</TableCell>

                            {this.state.showSkillGrades ? <TableCell>Closing</TableCell> : null}
                            {this.state.showSkillGrades ? <TableCell>Recs</TableCell> : null}
                            {this.state.showSkillGrades ? <TableCell>Phoning</TableCell> : null}
                            {this.makeTableCell(SortColumn.CallsMade)}
                            {this.makeTableCell(SortColumn.Reached)}
                            {this.makeTableCell(SortColumn.DemosBooked)}
                            {this.makeTableCell(SortColumn.QueueLength)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderBody()}
                    </TableBody>
                </Table>
            </div>
        );
    }
}