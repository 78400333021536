import React from "react";
import { TableRow, TableCell,} from "@material-ui/core";
import PhoneJam from '../../model/phone_jam';
import Rep from "../../model/rep";
import 'ui/css/phone_jams_colors.css';

function getTitle (shouldBeBold: boolean, phoneJam: PhoneJam) {
    let millisecondDiff = Date.now() - phoneJam.lastCallTimeStamp.getTime();
    let diffInMins: number = millisecondDiff / 60000;
    let timeDescription: string;
    if (phoneJam.callsMade < 1) {
        timeDescription = "";
    } else if (diffInMins < 2) {
        timeDescription = "Just now";
    } else {
        timeDescription = diffInMins.toFixed(0) + " mins ago";
    }
    if (shouldBeBold) {
        return <strong>{"Total: " + phoneJam.userName + " - " + timeDescription}</strong>; 
    }
    else return <p style={{padding:0, margin: 0, fontWeight: 550}}>{phoneJam.userName + " - " + timeDescription}</p>; 
}

export default function renderPhoneJamDisplayCard(phoneJam: PhoneJam, rep: Rep, showSkillGrades: boolean, shouldBeBold: boolean, color: string, showGroups: boolean){
    
    
    return (

        <TableRow className={showGroups ? color + (shouldBeBold ? "" : "") : undefined}  key={phoneJam.userID === "" ? phoneJam.userName : phoneJam.userID}>

            <TableCell>
            {getTitle(shouldBeBold, phoneJam)}

            </TableCell>
            {showSkillGrades ? <TableCell style={{fontWeight: shouldBeBold ? 'bold' : 550}}>{rep?.closingGrade ?? "-"}</TableCell> : null}
            {showSkillGrades ? <TableCell style={{fontWeight: shouldBeBold ? 'bold' : 550}}>{rep?.recommendationsGrade ?? "-"}</TableCell> : null}
            {showSkillGrades ? <TableCell style={{fontWeight: shouldBeBold ? 'bold' : 550}}>{rep?.phoningGrade ?? "-"}</TableCell> : null}
            <TableCell>
                <div className="Padding-Left-More" style={{fontWeight: shouldBeBold ? 'bold' : 550}}>{phoneJam.callsMade}</div>
                
            </TableCell>
            <TableCell>
                <div className="Padding-Left-More" style={{fontWeight: shouldBeBold ? 'bold' : 550}}>{phoneJam.reached}</div>
                
            </TableCell>
            <TableCell>
                <div className="Padding-Left-More" style={{fontWeight: shouldBeBold ? 'bold' : 550}}>{phoneJam.demosBooked}</div>

                
            </TableCell>
            <TableCell>
                <div className="Padding-Left-More" style={{fontWeight: shouldBeBold ? 'bold' : 550}}>{phoneJam.queued === 0 ? phoneJam.smartReloading ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm3-19C8.14 2 5 5.14 5 9c0 2.38 1.19 4.47 3 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26c1.81-1.27 3-3.36 3-5.74 0-3.86-3.14-7-7-7zm2.85 11.1l-.85.6V16h-4v-2.3l-.85-.6C7.8 12.16 7 10.63 7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.63-.8 3.16-2.15 4.1z"/></svg> : 0 : phoneJam.queued}</div>

                
            </TableCell>
        </TableRow>
    );
}
