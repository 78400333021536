import ActionResultVoid from "../model/action_result_void";
import QueueMessage from "../model/queue_message";
import MessagePersistence from "../persistence/message_persistence";
import MessageConverter from "../model/converters/message_converter";
import { DatabaseDocument } from "../persistence/persisted_object";
import Message from "react-chat-ui";

export class MessageService {
    private constructor () {

    }
    
    private static sharedInstance: MessageService = new MessageService(); 

    public static instance() : MessageService{
        return this.sharedInstance;
    }

    public async createMessage (message: QueueMessage): Promise<ActionResultVoid> {
        let messageConverter = new MessageConverter();
        let messageData: DatabaseDocument = messageConverter.toPersistence(message);
        return await MessagePersistence.instance().createMessage (messageData);
    }

    public toDisplayMessage (message: QueueMessage): typeof Message {
        let messageConverter = new MessageConverter();
        let displayMessage: typeof Message = messageConverter.toDisplayMessage(message); 
        return displayMessage; 
    }
}