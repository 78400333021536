
import GuardedListenerComponent from "../components/guarded_listener_component";
import '../common.css';
import ListenerClient from "../../services/listener_client";
import AppointmentStore from "../../services/appointment_store";
import RepStore from '../../services/rep_store';

import DateUtils from "../../services/date_utils";
import React from "react";
import { CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Checkbox, Select, MenuItem, Typography } from "@material-ui/core";
import QueueAppbar from "../components/queue_appbar";
//import ReactTable from 'react-table';
//import "react-table/react-table.css";
//import withFixedColumns from 'react-table-hoc-fixed-columns';
//import 'react-table-hoc-fixed-columns/lib/styles.css'
import Appointment from "../../model/appointment";
import Rep from "../../model/rep";
import AppointmentService from "../../services/appointment_service";
import DaySelector from "ui/components/day_selector";
import DemoCell from "ui/components/demo_cell";
import DemosTotalRow from "ui/components/demos_total_row";
import 'ui/css/common_text.css';
import 'ui/css/common_other.css'

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { CollectionType, LAUNCH_GROUP, FAST_START_GROUP, POST_FAST_START_GROUP, DEFAULT_TEAM } from "model/rep_collection";
import RepCollectionSelector from "ui/components/rep_collection_selector";

interface DemosPageState {
  repFilterID: string,
  filterBy: CollectionType,
  useDateRange: boolean,
  collection: string
}

export default class DemosPage extends GuardedListenerComponent<{}, DemosPageState> {
    private currentListenerClient?: ListenerClient<AppointmentStore>;
    private appointmentListenerClients?: ListenerClient<AppointmentStore>[];
    private repListenerClient?: ListenerClient<RepStore>;
    private currentDay: Date = new Date();
    private dateRangeEnd: Date = new Date();
    private dayOffsetFromBeginningOfWeek: number = DateUtils.toVectorDay(this.currentDay.getDay());

    constructor(props: {}) {
        super(props);
        this.renderBody = this.renderBody.bind(this);
        this.onDayUpdated = this.onDayUpdated.bind(this);
        this.renderGroupFilter = this.renderGroupFilter.bind(this);
        this.renderTeamFilter = this.renderTeamFilter.bind(this);
        this.renderTopSelectors = this.renderTopSelectors.bind(this);
        this.state = {
          repFilterID: "All",
          useDateRange: false,
          filterBy: CollectionType.GROUP,
          collection: ""
        }
    }

    getEmployedListenerClients(): ListenerClient<any>[] {
        if (this.appointmentListenerClients === undefined) {
          let weekID = DateUtils.getWeekIDOfDate(this.currentDay);
            this.appointmentListenerClients = [AppointmentStore.getListenerClientForWeekID(weekID)];
            this.repListenerClient = RepStore.getActiveRepsListenerClient()
        }
        return [...this.appointmentListenerClients!, this.repListenerClient!];
    }
    onDayUpdated() {
      const { useDateRange } = this.state
      console.log('DEV: day updated with dates ', this.currentDay, this.dateRangeEnd);

      this.appointmentListenerClients = []
      if (useDateRange) {
        //FIXME: get whole range
        console.log('DEV: getting listener clients for date range', this.currentDay, this.dateRangeEnd);

        var counterDate = this.currentDay
        do {
          let weekID = DateUtils.getWeekIDOfDate(counterDate);
          this.appointmentListenerClients.push(AppointmentStore.getListenerClientForWeekID(weekID));
          console.log('DEV: Added listener client for weekID ', weekID, this.appointmentListenerClients);

          counterDate = DateUtils.addDays(counterDate, 7);
        } while (counterDate.getTime() < DateUtils.addDays(this.dateRangeEnd, 7).getTime());
      } else {
        let weekID = DateUtils.getWeekIDOfDate(this.currentDay);
        this.appointmentListenerClients.push(AppointmentStore.getListenerClientForWeekID(weekID));
      }
        super.refreshListenerClients(this.appointmentListenerClients);
        this.setState({});
    }
    areListenersLoaded() {
      var areLoaded: boolean = true
      this.appointmentListenerClients!.forEach(listener => {
        if (!listener.getStore().isLoaded()) areLoaded = false
      });
      if (!this.repListenerClient!.getStore().isLoaded()) areLoaded = false
      return areLoaded
    }
    getFilteredDemos() {
      const { repFilterID, useDateRange } = this.state
      var demos: Appointment[] = [];
      this.appointmentListenerClients!.forEach(listener => {
        demos = [...demos, ...listener.getStore().getAppointments()]
      });

      let demosToday: Appointment[] = demos.filter((demo) => {
        if (useDateRange) {
          let afterStart = demo.startTimeStamp.getTime() > DateUtils.startOfDay(this.currentDay).getTime();
          let beforeEnd = demo.startTimeStamp.getTime() < DateUtils.endOfDay(this.dateRangeEnd).getTime();
          //FIXME: must be between dates
          return afterStart && beforeEnd;
        } else {
          return DateUtils.isSameDayAs(demo.startTimeStamp, this.currentDay)
        }
      });
      

      let filteredDemos = (repFilterID === "All") ? demosToday : demosToday.filter((d) => {
        return d.repID === repFilterID
      })

      let currentCollectionFilter = this.state.collection;

      if (currentCollectionFilter !== undefined && currentCollectionFilter !== "") {
        if (this.state.filterBy === CollectionType.GROUP) {
          filteredDemos = filteredDemos.filter((d) => RepStore.activeRepStore().isInGroup(d.repID, currentCollectionFilter!));
        } else {
          filteredDemos = filteredDemos.filter((d) => RepStore.activeRepStore().isInTeam(d.repID, currentCollectionFilter!));
        }
      }

      //negative number means put a first, positive number means put b first
      filteredDemos.sort((a: Appointment, b: Appointment) => {
        let timeSort = a.startTimeStamp.getTime() - b.startTimeStamp.getTime();

        if (timeSort === 0) {
            return a.repName.localeCompare(b.repName);
        } else {
            return timeSort;
        }
      });

      return filteredDemos
    }
    getTextForRepID(repID: string) {
      let reps: Rep[] = RepStore.activeRepStore().getReps();
      let selectedArr = reps.filter((r) => r.repID === repID)
      let selected = selectedArr[0];
      let text = ""
      if (selected != null) {
        text = selected.firstName + " " + selected.lastName
      } else {
        text = "All"
      }
      return text
    }
    renderRepFilter() {
      const { repFilterID } = this.state;
      const text = this.getTextForRepID(repFilterID)
      let reps: Rep[] = RepStore.activeRepStore().getReps();

      if (this.state.collection !== undefined && this.state.collection !== "") {
        if (this.state.filterBy === CollectionType.GROUP) {
          reps = reps.filter((rep) => rep.group === this.state.collection!);
        } else {
          reps = reps.filter((rep) => rep.team === this.state.collection!);
        }
        
      }

      reps.sort((a, b) => {
        return (a.firstName > b.firstName) ? 1 : -1
      })

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Table-Header Header-Text" style={{ paddingLeft: 20, paddingRight: 20}}>
            Rep:
          </div>

          <Select
            name={"Rep Filter"}
            value={text}
            onChange={(event: any) => {
              const id = event.target.value;
              this.setState({ repFilterID: id });
            } }
            renderValue={(value: any) => {
              return (
                <div>{value}</div>
              )
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            {reps.map((r) => {
              return (
                <MenuItem key={r.repID} value={r.repID}>{r.firstName+" "+r.lastName}</MenuItem>
              )
            })}
          </Select>
        </div>
      )
    }
    renderDatePicker(isPrimary: boolean) {
      const { useDateRange } = this.state
      const text = (isPrimary) ? (useDateRange) ? "Demos from " : "Demos on" : "to"

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Table-Header Header-Text" style={{ paddingRight: 10}}>
             {text}
          </div>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
             autoOk
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="none"
              id={"start-date-picker-" + isPrimary.toString()}
              minDate={(!isPrimary) ? this.currentDay : DateUtils.longTimeAgo()}
              value={(isPrimary) ? this.currentDay : this.dateRangeEnd}
              onChange={(date) => {
                if (date != null) {
                  if (isPrimary) this.currentDay = DateUtils.startOfDay(date);
                  else this.dateRangeEnd = DateUtils.endOfDay(date);
                  this.onDayUpdated()
                }
              } }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      )
    }
    renderTopSelectors() {
      const { useDateRange } = this.state;
      return (
        <div className="Padding-Left" style={{display: "flex", alignItems: "center"}}>

            {this.renderDatePicker(true)}
            {(useDateRange) ? this.renderDatePicker(false) : null}
            <div style={{width: 30}} />
            <Typography>Use date range</Typography>
            <Checkbox
              checked={useDateRange}
              color="primary"
              onChange={(_) => {
                this.setState({ useDateRange: !useDateRange })
              }}
            />


            
            <div className="Padding-Left">
              <RepCollectionSelector title="Filter By" onCollectionTypeSelected={(ct) => this.setState({filterBy: ct, collection: ""})}/>
            </div>
            {this.state.filterBy === CollectionType.GROUP ? this.renderGroupFilter() : this.renderTeamFilter()}
            {this.renderRepFilter()}
        </div>
      )
    }

    renderDemoCells(demos: Appointment[]) {
      const { repFilterID } = this.state
      let reps: Rep[] = RepStore.activeRepStore().getReps();
      let managers = reps.filter((r) => r.isManager === true)

      return demos.map((a) => {
        let aHrs:number = a.startTimeStamp.getHours();
        let aMin:number = a.startTimeStamp.getMinutes();
        let amPm: string = "PM";
        if (aHrs < 12) {amPm ="AM";}
        if (aHrs > 12) {aHrs = aHrs - 12;}
        let dHrs:string =aHrs.toLocaleString()
        let dMin:string =aMin.toLocaleString()
        if (aHrs < 10) {dHrs = "0" + dHrs;}
        if (aMin < 10) {dMin = "0" + dMin;}
        let cancelledString = a.hasBeenCancelled ? " - Cancelled" : "";
        return (
            <DemoCell
              key={a.apptID}
              appointment={a}
              cancelledString={cancelledString}
              dHrs={dHrs}
              dMin={dMin}
              amPm={amPm}
              managers={managers}
            />
        );
        })
    }
    renderHeader(demos: Appointment[]) {
      const formattedTableHeader = (text: string, isFirst: boolean=false) => {
        return (
          <TableCell padding="none">
            <div className="Table-Header Header-Text" style={{ paddingLeft: (isFirst) ? 10 : 0 }}> {text}
            </div>
          </TableCell>

        )
      }
      return (
        <TableHead>
            <TableRow selected>
              {formattedTableHeader("Checked-in", true)}
              {formattedTableHeader("Coaching Call")}
              {formattedTableHeader("Time")}
              {formattedTableHeader("Rep")}
              {formattedTableHeader("Outcome")}
              {formattedTableHeader("CPO")}
              {formattedTableHeader("Notes")}
              {formattedTableHeader("Recs")}
            </TableRow>
            <DemosTotalRow demos={demos} />
        </TableHead>
      )
    }
    renderBody() {
        //negative number means put a first, positive number means put b first
        let demos = this.getFilteredDemos()
        if (demos.length === 0) {
            return <p className="Padding-Left">No Demos to Show</p>
        }
        return (
            <div>
                <Table stickyHeader padding={"none"}>
                    {this.renderHeader(demos)}
                    <TableBody>
                        {this.renderDemoCells(demos)}
                    </TableBody>
                </Table>
        </div>);
    }
    renderGuarded() {

      if (!this.areListenersLoaded()) {

        return (
          <div>
            <QueueAppbar shouldDisplayMenu={true}/>
            <CircularProgress/>
          </div>
        )
      }

      return (
        <div>
          <QueueAppbar shouldDisplayMenu={true}/>

          <div style={styles.bodyContainer}>

            {this.renderTopSelectors()}

            <div style={styles.primaryContent}>
              {!this.areListenersLoaded() ? <CircularProgress/> : this.renderBody()}
            </div>
          </div>
        </div>
      );
    }
    getPathOnRefresh(): string {
        return "/demos";
    }

    renderGroupFilter() {

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Table-Header Header-Text" style={{ paddingLeft: 20, paddingRight: 20}}>
            Group:
          </div>
          <Select
                name={"Group Filter"}
                value={this.state.collection ?? ""}
                onChange={(event: any) => {
                  const collection = event.target.value;
                  this.setState({ collection: collection });
                } }
                renderValue={(value: any) => {
                  return (
                    <div>{value ?? "All"}</div>
                  )
                }}
              >
                <MenuItem key={LAUNCH_GROUP} value={LAUNCH_GROUP}>{LAUNCH_GROUP}</MenuItem>
                <MenuItem key={FAST_START_GROUP} value={FAST_START_GROUP}>{FAST_START_GROUP}</MenuItem>
                <MenuItem key={POST_FAST_START_GROUP} value={POST_FAST_START_GROUP}>{POST_FAST_START_GROUP}</MenuItem>
                {RepStore.activeRepStore().getRepGroups().map((group) => <MenuItem key={group ?? ""} value={group ?? ""}>{group ?? "All"}</MenuItem>)}
          </Select>
        </div>
        );
    }

    renderTeamFilter() {

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="Table-Header Header-Text" style={{ paddingLeft: 20, paddingRight: 20}}>
            Team:
          </div>
          <Select
            name={"Team Filter"}
            value={this.state.collection ?? ""}
            onChange={(event: any) => {
              const collection = event.target.value;
              this.setState({ collection: collection });
            } }
            renderValue={(value: any) => {
              return (
                <div>{value === undefined || value === "" ? "All" : value}</div>
              )
            }}
          >
            <MenuItem value={DEFAULT_TEAM}>{DEFAULT_TEAM}</MenuItem>
            {RepStore.activeRepStore().getRepTeams().map((team) => <MenuItem key={team ?? ""} value={team ?? ""}>{team ?? "All"}</MenuItem>)}
          </Select>
        </div>
        );
    }
}

const styles = {
  bodyContainer: {
    marginTop: 10,
  },
  primaryContent: {
    paddingLeft: 0,
    marginRight: 0,
  }
}
