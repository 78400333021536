import Converter from "./converter";
import PhoneJam from '../phone_jam';
import { DatabaseDocument, DocumentData } from "../../persistence/persisted_object";

export default class PhoneJamConverter extends Converter<PhoneJam> {

    fromPersistence(doc: DatabaseDocument): PhoneJam {
        let data: DocumentData = doc.data;

        return {
            callsMade: data["totalCallsMade"], 
            demosBooked: data["totalBooked"], 
            callBacks: data["totalCallBacks"], 
            declines: data["totalDeclines"], 
            reached: data["totalDeclines"] + data["totalCallBacks"] + data["totalBooked"],
            textsSent: data["totalTextsSent"], 
            startTimeStamp: data["creationTimeStamp"].toDate(), 
            lastCallTimeStamp: data["lastCallTimeStamp"].toDate(), 
            timeSpentOnPhoneInMilliseconds: data["timeSpentOnPhoneInMilliseconds"], 
            totalDurationInMilliseconds: data["totalDurationInMilliseconds"],
            userID: doc.id,
            userName: data["userName"],
            queued: data["callQueueLength"], 
            smartReloading: data["smartReloading"], 
        };
    }
}