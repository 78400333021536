import { CollectionType } from "model/rep_collection";
import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";



interface RepCollectionSelectorProps {
    onCollectionTypeSelected: (newSelection: CollectionType) => void,
    title?: string
}

interface RepCollectionSelectorState {
    selectedCollection: CollectionType;
}

const DEFAULT_TITLE = "Organize By";

export default class RepCollectionSelector extends React.Component<RepCollectionSelectorProps, RepCollectionSelectorState> {
    
    constructor(props: RepCollectionSelectorProps) {
        super(props);
        this.state = {
            selectedCollection: CollectionType.GROUP
        };
    }

    render() {
        return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{this.props.title ?? DEFAULT_TITLE}</FormLabel>
            <RadioGroup row aria-label={this.props.title ?? DEFAULT_TITLE} name="Organize" value={this.state.selectedCollection} onChange={(newValue) => {
                let newCollection: CollectionType = newValue.target.value as unknown as CollectionType;
                this.setState({
                    selectedCollection: newCollection
                });
                this.props.onCollectionTypeSelected(newCollection);
            }}>
            <FormControlLabel value={CollectionType.GROUP} control={<Radio color="primary"/>} label="Groups" />
            <FormControlLabel value={CollectionType.TEAM} control={<Radio color="primary"/>} label="Teams" />
            </RadioGroup>
        </FormControl>);
    }
}