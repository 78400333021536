import GuardedListenerComponent from "../components/guarded_listener_component";
import React, { ChangeEvent } from "react";
import QueueAppbar from "../components/queue_appbar";
import { Table, TableHead, TableRow, TableBody, CircularProgress, TableCell, Select, MenuItem, FormHelperText, Grid, Menu, TextField, Button, FormControl, Switch, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import OfficeEvent from "../../model/office_event";
import RepStore from "../../services/rep_store";
import Rep from "../../model/rep";
import { AttendanceStatus } from "../../model/attendance_status";
import OfficeEventStore from "../../services/office_event_store";
import DateUtils from "../../services/date_utils";
import { OfficeEventService } from "../../services/office_event_service";
import { AuthenticationService } from "../../services/authentication_service";
import uuidv4 from 'uuid';
import AddIcon from '@material-ui/icons/Add';
import '../common.css';
import * as FloatingActionButton from 'react-floating-action-button';
import { OfficeEventType } from "../../model/office_event_type";
import { Month } from "../../model/month";
import {  Period } from "../../model/period";
import WeekSelector from "../components/week_selector";
import DeleteIcon from '@material-ui/icons/Delete';
import { CollectionType } from "model/rep_collection";
import RepCollectionSelector from "ui/components/rep_collection_selector";
import ColoredCardBackground, {BackgroundColor} from "../components/colored_card_background"; 
import AttendanceRow from "ui/components/attendance_row";

interface AttendancePageState {
    menuPosition:  EventTarget & HTMLButtonElement | null,
    eventTitle: string,
    eventType: OfficeEventType,
    numRecurrences: number,
    customName?: string,
    startMonth: Month,
    startDayOfMonth: number,
    startYear: number,
    shouldDisplayRecurrenceOptions: boolean,
    startPeriod: Period,
    startMinute: number,
    startHour: number,
    currentWeekID: string,
    eventToDelete?: OfficeEvent,
    organizeBy: CollectionType, 
    searchText: string, 
}

export default class AttendancePage extends GuardedListenerComponent<{}, AttendancePageState> {
    private currentWeekOfficeEventStore?: OfficeEventStore;

    constructor(props:{}) {
        super(props, true);

        this.state = {
            menuPosition: null,
            eventTitle: "",
            numRecurrences: 1,
            eventType: OfficeEventType.CONFERENCE,
            startDayOfMonth: new Date().getDate(),
            startMonth: new Date().getMonth(),
            startYear: new Date().getFullYear(),
            shouldDisplayRecurrenceOptions: false,
            startMinute: 0,
            startHour: 12,
            startPeriod: Period.AM,
            currentWeekID: DateUtils.getWeekIDForOffset(0),
            organizeBy: CollectionType.GROUP, 
            searchText: "", 
        }

        this.handleSwitchChange=this.handleSwitchChange.bind(this);
        this.renderHeader=this.renderHeader.bind(this);
        this.renderBody=this.renderBody.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.onEventTitleUpdated = this.onEventTitleUpdated.bind(this);
        this.onEventTypeUpdated = this.onEventTypeUpdated.bind(this);
        this.onCreateEventPressed = this.onCreateEventPressed.bind(this);
        this.onStartDayOfMonthUpdated = this.onStartDayOfMonthUpdated.bind(this);
        this.onStartMonthUpdated = this.onStartMonthUpdated.bind(this);
        this.renderStartDaysOfMonth = this.renderStartDaysOfMonth.bind(this);
        this.onStartYearUpdated = this.onStartYearUpdated.bind(this);
        this.renderCreateEventPopUp = this.renderCreateEventPopUp.bind(this);
        this.onNumRecurrencesUpdated = this.onNumRecurrencesUpdated.bind(this);
        this.onStartMinuteUpdated = this.onStartMinuteUpdated.bind(this);
        this.onStartHourUpdated = this.onStartHourUpdated.bind(this);
        this.onStartPeriodUpdated = this.onStartPeriodUpdated.bind(this);
        this.onSelectedWeekUpdated = this.onSelectedWeekUpdated.bind(this);
        this.renderDeleteDialog = this.renderDeleteDialog.bind(this);
    }

    onSelectedWeekUpdated(newWeekID: string) {
        let officeClient = OfficeEventStore.getOfficeEventListenerClient(newWeekID);
        this.currentWeekOfficeEventStore = officeClient.getStore();
        super.refreshListenerClients([RepStore.getActiveRepsListenerClient(), officeClient]);
        this.setState({
            currentWeekID: newWeekID
        });
    }

    onStartMinuteUpdated (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) {
        this.setState({
            startMinute: event.target.value as number
        })
    }

    onStartHourUpdated (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) {
        this.setState({
            startHour: event.target.value as number
        })
    }

    onStartPeriodUpdated (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) {
        this.setState({
            startPeriod: event.target.value as Period
        })
    }

    onEventTypeUpdated(event: ChangeEvent<{ name?: string | undefined; value: unknown; }>){
        this.setState({
            eventType: event.target.value as OfficeEventType
        });
    }

    handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean){
        this.setState({
            shouldDisplayRecurrenceOptions: event.target.checked,
            numRecurrences: event.target.checked ? 2 : 1
        })
    }

    onStartDayOfMonthUpdated (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) {
        this.setState({
            startDayOfMonth: event.target.value as number
        });
    }

    onStartYearUpdated (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) {
        this.setState({
            startYear: event.target.value as number
        });
    }

    onStartMonthUpdated (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) {
        this.setState({
            startMonth: event.target.value as Month
        });
    }

    onNumRecurrencesUpdated (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) {
        this.setState({
            numRecurrences: event.target.value as number
        });
    }

    onCreateEventPressed() {
        let officeEvents: OfficeEvent[] = [];
        let hour: number = this.state.startHour;
        if (this.state.startPeriod === Period.PM){
            hour = hour + 12;
        }
        let currentDate = new Date (this.state.startYear, this.state.startMonth, this.state.startDayOfMonth, hour, this.state.startMinute, 0, 0);
        const recurrenceID = uuidv4();
        for (let i=0; i<this.state.numRecurrences; i++){
            let target: OfficeEvent ={
                currentlyActive: true,
                eventType: this.state.eventType,
                title: this.state.eventTitle,
                attendanceStatuses: new Map<string, AttendanceStatus>(),
                startTimestamp: currentDate,
                weeklyReccurrences: this.state.numRecurrences,
                specificReccurrence: i,
                id: "",
                recurrenceID: recurrenceID,
                teamID: AuthenticationService.instance().getCurrentUser().teamName,
                customName: null,
                notes: new Map<string, string>(), 
            }
            officeEvents.push(target);
            currentDate = DateUtils.addDays(currentDate, 7);
        }
        OfficeEventService.instance().createOfficeEvent(officeEvents);
        this.setState({
            menuPosition: null,
        });
    }

    onEventTitleUpdated(event: React.ChangeEvent<HTMLInputElement>){
        let value: string = event.target.value;
        this.setState({
            eventTitle: value
        });
    }

    getPathOnRefresh(): string {
        return "/attendance";
    }

    handleMenuClose() {
        this.setState({menuPosition: null,})
    }

    handleMenuOpen (event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            menuPosition: event.currentTarget,
        });
    }

    getEmployedListenerClients() {
        let officeClient = OfficeEventStore.getOfficeEventListenerClient(this.state.currentWeekID);
        this.currentWeekOfficeEventStore = officeClient.getStore();
        return [RepStore.getActiveRepsListenerClient(), officeClient];
    }

    renderBody() {
        const HEADER_BACKGROUND_COLOR = '#f5f5f5';
        if ((!RepStore.activeRepStore().isLoaded()) || (!this.currentWeekOfficeEventStore!.isLoaded())) {
            return (<TableRow><TableCell><CircularProgress/></TableCell></TableRow>)
        }
        else {
            
            let events: OfficeEvent[] = this.currentWeekOfficeEventStore!.getOfficeEvents();
            let repCollections = RepStore.activeRepStore().getRepCollections(this.state.organizeBy, true);
            let confirmedCounts: number[] = new Array(events.length).fill(0);
            let allRows = repCollections.map((rc) => {
                rc.setReps(rc.getReps().filter((rep: Rep) => (rep.firstName.toLowerCase() + " " + rep.lastName.toLowerCase() + " " + rep.notes.toLowerCase() + " " + rep.phoneNumber).includes(this.state.searchText)));
                let numReps = rc.getReps().length;
                let rows = new Array(numReps);
                
                let eventIndex = 0;
                rows[0] = (<TableRow style={{backgroundColor: HEADER_BACKGROUND_COLOR}} selected>
                    <TableCell><div className={"Standard-Text Bold"}>{rc.getCollectionName() + " (" + numReps + ")"}</div></TableCell>
                    {events.map((event) => {
                        
                        let numConfirmed: number = 0; 
                        Array.from(event.attendanceStatuses.keys()).forEach((rep) => {
                            if (rc.getReps().includes(RepStore.activeRepStore().getRep(rep)) && event.attendanceStatuses.get(rep) === AttendanceStatus.CONFIRMED) {
                                numConfirmed++; 
                                confirmedCounts[eventIndex]++;
                            }
                                
                        });
                        eventIndex++;
                        return (<TableCell>{numConfirmed} Rep(s) Confirmed</TableCell>);
                    })}
                </TableRow>);
                for (let i = 0; i < numReps; i++) {
                    let rep = rc.getReps()[i];
                    rows[i + 1] = (<TableRow key={rep.repID} style={{paddingTop: 0, paddingBottom: 0}} >
                        <TableCell style={{maxHeight: 50, paddingTop: 15, paddingBottom: 15}} ><div className={"Standard-Text Bold"}>{rep.firstName + ' ' + rep.lastName}</div></TableCell>
                        {events.map((officeEvent: OfficeEvent) => {
                            let status: AttendanceStatus | undefined = officeEvent.attendanceStatuses.get(rep.repID);
                            let notesForRep: string = officeEvent.notes.get(rep.repID) ?? ""; 
                            if (status === undefined) {
                                status = AttendanceStatus.UNDECIDED;
                            }
                            let backgroundColor: BackgroundColor; 
                            if (status === AttendanceStatus.UNDECIDED) backgroundColor = BackgroundColor.GREY; 
                            else if (status === AttendanceStatus.CONFIRMED) backgroundColor = BackgroundColor.BLUE;
                            else if (status === AttendanceStatus.NO) backgroundColor = BackgroundColor.RED;
                            else if (status === AttendanceStatus.NO_SHOW) backgroundColor = BackgroundColor.DARK_RED;
                            else if (status === AttendanceStatus.YES) backgroundColor = BackgroundColor.GREEN;
                            else if (status === AttendanceStatus.LATE) backgroundColor = BackgroundColor.YELLOW;
                            else if (status === AttendanceStatus.MAYBE) backgroundColor = BackgroundColor.PURPLE; 
                            else if (status === AttendanceStatus.LEAVE_EARLY) backgroundColor = BackgroundColor.DARK_RED; 
                            else backgroundColor = BackgroundColor.NONE        
                            return (
                                <AttendanceRow rep={rep} officeEvent={officeEvent} backgroundColor={backgroundColor}></AttendanceRow>
                            );
                        })}
                    </TableRow>);
                }
                return rows;
            });
            let flattened = [<TableRow style={{backgroundColor: HEADER_BACKGROUND_COLOR, maxHeight: 40}} selected>
                <TableCell><div className={"Standard-Text Bold"}>Full Team ({RepStore.activeRepStore().getReps().length})</div></TableCell>
                {confirmedCounts.map((count) => <TableCell>{count} Rep(s) Confirmed</TableCell>)}
            </TableRow>].concat(allRows.flat());
            return flattened;
        }
    }

    renderHeader() {
        if ((!this.currentWeekOfficeEventStore!.isLoaded())){
            return (<TableCell><CircularProgress/></TableCell>);
        }
        else {
            let officeEvents: OfficeEvent[] = this.currentWeekOfficeEventStore!.getOfficeEvents();
            return officeEvents.map((event: OfficeEvent) => {

                return (
                    <TableCell key={event.id}>{event.title + " " + DateUtils.toMonthSlashDayString(event.startTimestamp)} <IconButton onClick={() => {
                        this.setState({
                            eventToDelete: event
                        });
                        console.log("just set the event to delete");
                    }}><DeleteIcon/></IconButton>
                    </TableCell>
                )
            }
            );
        }
    }

    renderDeleteDialog() {
        let eventToDelete: OfficeEvent | undefined = this.state.eventToDelete;
        if (eventToDelete === undefined) {
            return null;
        }
        let recurring: boolean = eventToDelete?.weeklyReccurrences !== undefined;
        return  (
        <Dialog open={eventToDelete !== undefined} >
            <DialogTitle>Confirm Delete {eventToDelete!.title}</DialogTitle>
            <DialogContent>
            <DialogContentText >
                Deleting this event cannot be undone.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    this.setState({
                        eventToDelete: undefined
                    });
                }} color="primary">
                    Cancel
                </Button>
                {recurring ? (<Button onClick={() => {
                    this.setState({
                        eventToDelete: undefined
                    });
                    OfficeEventService.instance().deleteOfficeEventRecurrence(eventToDelete!);
                }} color="primary" variant="outlined">
                    Delete Event and All Recurring Instances
                </Button>) : null }
                <Button onClick={() => {
                    this.setState({
                        eventToDelete: undefined
                    });
                    OfficeEventService.instance().deleteOfficeEvent(eventToDelete!);
                }} color="primary" variant="outlined">
                    Delete Event
                </Button>
            </DialogActions>
        </Dialog>
        );
    }

    renderGuarded() {
        return (
        <div>
            <QueueAppbar shouldDisplayMenu={true}/>
            <div className="padding-top">
                <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                    <Grid item>
                        <TextField
                        value={this.state.searchText}
                        placeholder="Search by name, phone number, or notes"
                        onChange={(event) => {this.setState({searchText: event.target.value})}} variant="outlined"
                        inputProps={{
                        style: {
                        height: "35px",
                        padding: '0 14px',
                        },
                        }}
                      />
                    </Grid>
                    <Grid item>
                        <WeekSelector selectedWeekID={this.state.currentWeekID} onSelectedWeekChanged={this.onSelectedWeekUpdated} component={(<RepCollectionSelector onCollectionTypeSelected={(collection: CollectionType) => this.setState({organizeBy: collection})}/>)}/>
                    </Grid>
                </Grid>
            </div>
            <Grid container direction='column' alignItems='center' justify='center'>
                {this.renderCreateEventPopUp()}
            </Grid>
            {this.renderDeleteDialog()}
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell><div className={"Standard-Text Bold"}>Attendance</div></TableCell>
                        {this.renderHeader()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.renderBody()}
                </TableBody>
            </Table>
            <FloatingActionButton.Container>
                <FloatingActionButton.Button
                    tooltip="Create Event"
                    icon="fas fa-plus"
                    styles={{backgroundColor: FloatingActionButton.darkColors.orange, color: FloatingActionButton.lightColors.white}}
                    onClick={this.handleMenuOpen}
                    >
                <AddIcon/>
                </FloatingActionButton.Button>
            </FloatingActionButton.Container>
        </div>
        );
    }

    renderCreateEventPopUp () {
        let startDate = DateUtils.getFirstInstantOfVectorWeekForWeekID(this.state.currentWeekID);
        if (this.state.shouldDisplayRecurrenceOptions){
            return (
                <Menu onClose={this.handleMenuClose} anchorEl={this.state.menuPosition} open={Boolean(this.state.menuPosition)}>
                    <MenuItem>
                        <TextField helperText='Event Title' value={this.state.eventTitle ?? ""} onChange={this.onEventTitleUpdated}></TextField>
                    </MenuItem>
                    <MenuItem>
                    <FormControl>
                            <Select value={this.state.eventType ?? ""} onChange={this.onEventTypeUpdated} >
                                <MenuItem value={OfficeEventType.PHONE_JAM}>Phone Jam</MenuItem>
                                <MenuItem value={OfficeEventType.CONFERENCE}>Conference</MenuItem>
                                <MenuItem value={OfficeEventType.TEAM_MEETING}>Team Meeting</MenuItem>
                                <MenuItem value={OfficeEventType.WORKSHOP}>Workshop</MenuItem>
                            </Select>
                            <FormHelperText>Event Type</FormHelperText>
                        </FormControl>
                    </MenuItem>
                    <MenuItem>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                        <FormControl>
                        <Grid item>
                                <Select value={this.state.startMonth} onChange={this.onStartMonthUpdated}>
                                    <MenuItem value={Month.JANUARY}>January</MenuItem>
                                    <MenuItem value={Month.FEBRUARY}>February</MenuItem>
                                    <MenuItem value={Month.MARCH}>March</MenuItem>
                                    <MenuItem value={Month.APRIL}>April</MenuItem>
                                    <MenuItem value={Month.MAY}>May</MenuItem>
                                    <MenuItem value={Month.JUNE}>June</MenuItem>
                                    <MenuItem value={Month.JULY}>July</MenuItem>
                                    <MenuItem value={Month.AUGUST}>August</MenuItem>
                                    <MenuItem value={Month.SEPTEMBER}>September</MenuItem>
                                    <MenuItem value={Month.OCTOBER}>October</MenuItem>
                                    <MenuItem value={Month.NOVEMBER}>November</MenuItem>
                                    <MenuItem value={Month.DECEMBER}>December</MenuItem>
                                </Select>
                            </Grid>
                        <FormHelperText>Date</FormHelperText>
                        </FormControl>
                        <FormControl>
                            <Grid item>
                                {this.renderStartDaysOfMonth()}
                            </Grid>
                            <FormHelperText></FormHelperText>
                            </FormControl>
                            <FormControl>
                            <Grid item>
                                <Select value={this.state.startYear} onChange={this.onStartYearUpdated}>
                                    <MenuItem value={startDate.getFullYear()}>{startDate.getFullYear().toString()}</MenuItem>
                                    <MenuItem value={startDate.getFullYear()+1}>{startDate.getFullYear()+1}</MenuItem>
                                    <MenuItem value={startDate.getFullYear()+2}>{startDate.getFullYear()+2}</MenuItem>
                                </Select>
                            </Grid>
                            <FormHelperText></FormHelperText>
                            </FormControl>
                        </Grid>
                    </MenuItem>
                    <MenuItem>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                        <FormControl>
                        <Grid item>
                                <Select value={this.state.startHour} onChange={this.onStartHourUpdated}>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={11}>11</MenuItem>
                                    <MenuItem value={12}>12</MenuItem>
                                </Select>
                            </Grid>
                        <FormHelperText>Time</FormHelperText>
                        </FormControl>
                        <Grid item>
                            <Typography>:</Typography>
                        </Grid>
                        <FormControl>
                            <Grid item>
                                <Select value={this.state.startMinute} onChange={this.onStartMinuteUpdated}>
                                    <MenuItem value={0}>00</MenuItem>
                                    <MenuItem value={5}>05</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={35}>35</MenuItem>
                                    <MenuItem value={40}>40</MenuItem>
                                    <MenuItem value={45}>45</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={55}>55</MenuItem>
                                    <MenuItem value={60}>60</MenuItem>
                                </Select>
                            </Grid>
                            <FormHelperText></FormHelperText>
                            </FormControl>
                            <FormControl>
                            <Grid item>
                                <Select value={this.state.startPeriod} onChange={this.onStartPeriodUpdated}>
                                    <MenuItem value={Period.AM}>AM</MenuItem>
                                    <MenuItem value={Period.PM}>PM</MenuItem>
                                </Select>
                            </Grid>
                            <FormHelperText></FormHelperText>
                            </FormControl>
                        </Grid>
                    </MenuItem>
                    <MenuItem>
                    <FormControl>
                        <Switch color='primary' checked={this.state.shouldDisplayRecurrenceOptions} onChange={this.handleSwitchChange}/>
                        <FormHelperText>Recur Weekly</FormHelperText>
                    </FormControl>
                    </MenuItem>
                    <FormControl>
                    <MenuItem>
                        <Select value={this.state.numRecurrences} onChange={this.onNumRecurrencesUpdated}>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={11}>11</MenuItem>
                            <MenuItem value={12}>12</MenuItem>
                            <MenuItem value={13}>13</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={16}>16</MenuItem>
                            <MenuItem value={17}>17</MenuItem>
                            <MenuItem value={18}>18</MenuItem>
                            <MenuItem value={19}>19</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={21}>21</MenuItem>
                            <MenuItem value={22}>22</MenuItem>
                            <MenuItem value={23}>23</MenuItem>
                            <MenuItem value={24}>24</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={26}>26</MenuItem>
                            <MenuItem value={27}>27</MenuItem>
                            <MenuItem value={28}>28</MenuItem>
                            <MenuItem value={29}>29</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={31}>31</MenuItem>
                            <MenuItem value={32}>32</MenuItem>
                            <MenuItem value={33}>33</MenuItem>
                            <MenuItem value={34}>34</MenuItem>
                            <MenuItem value={35}>35</MenuItem>
                            <MenuItem value={36}>36</MenuItem>
                            <MenuItem value={37}>37</MenuItem>
                            <MenuItem value={38}>38</MenuItem>
                            <MenuItem value={39}>39</MenuItem>
                            <MenuItem value={40}>40</MenuItem>
                            <MenuItem value={41}>41</MenuItem>
                            <MenuItem value={42}>42</MenuItem>
                            <MenuItem value={43}>43</MenuItem>
                            <MenuItem value={44}>44</MenuItem>
                            <MenuItem value={45}>45</MenuItem>
                            <MenuItem value={46}>46</MenuItem>
                            <MenuItem value={47}>47</MenuItem>
                            <MenuItem value={48}>48</MenuItem>
                            <MenuItem value={49}>49</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={51}>51</MenuItem>
                            <MenuItem value={52}>52</MenuItem>
                        </Select>
                    <FormHelperText>Times</FormHelperText>
                    </MenuItem>
                    </FormControl>
                    <MenuItem>
                        <Button color='primary' variant='contained' onClick={this.onCreateEventPressed}>Create Event</Button>
                    </MenuItem>

                </Menu>
            );
        }
        else {
            return (
                    <Menu onClose={this.handleMenuClose} anchorEl={this.state.menuPosition} open={Boolean(this.state.menuPosition)}>
                        <MenuItem>
                            <TextField helperText='Event Title' value={this.state.eventTitle ?? ""} onChange={this.onEventTitleUpdated}></TextField>
                        </MenuItem>
                        <MenuItem>
                        <FormControl>
                                <Select value={this.state.eventType ?? ""} onChange={this.onEventTypeUpdated} >
                                    <MenuItem value={OfficeEventType.PHONE_JAM}>Phone Jam</MenuItem>
                                    <MenuItem value={OfficeEventType.CONFERENCE}>Conference</MenuItem>
                                    <MenuItem value={OfficeEventType.TEAM_MEETING}>Team Meeting</MenuItem>
                                    <MenuItem value={OfficeEventType.WORKSHOP}>Workshop</MenuItem>
                                </Select>
                                <FormHelperText>Event Type</FormHelperText>
                            </FormControl>
                        </MenuItem>
                        <MenuItem>

                            <Grid container direction="row" justify="space-between" alignItems="center">
                            <FormControl>
                            <Grid item>
                                    <Select value={this.state.startMonth} onChange={this.onStartMonthUpdated}>
                                        <MenuItem value={Month.JANUARY}>January</MenuItem>
                                        <MenuItem value={Month.FEBRUARY}>February</MenuItem>
                                        <MenuItem value={Month.MARCH}>March</MenuItem>
                                        <MenuItem value={Month.APRIL}>April</MenuItem>
                                        <MenuItem value={Month.MAY}>May</MenuItem>
                                        <MenuItem value={Month.JUNE}>June</MenuItem>
                                        <MenuItem value={Month.JULY}>July</MenuItem>
                                        <MenuItem value={Month.AUGUST}>August</MenuItem>
                                        <MenuItem value={Month.SEPTEMBER}>September</MenuItem>
                                        <MenuItem value={Month.OCTOBER}>October</MenuItem>
                                        <MenuItem value={Month.NOVEMBER}>November</MenuItem>
                                        <MenuItem value={Month.DECEMBER}>December</MenuItem>
                                    </Select>
                                </Grid>
                                <FormHelperText>Date</FormHelperText>
                                </FormControl>
                                <FormControl>
                                <Grid item>
                                    {this.renderStartDaysOfMonth()}
                                </Grid>
                                <FormHelperText></FormHelperText>
                                </FormControl>
                                <FormControl>
                                <Grid item>
                                    <Select value={this.state.startYear} onChange={this.onStartYearUpdated}>
                                        <MenuItem value={startDate.getFullYear()}>{startDate.getFullYear().toString()}</MenuItem>
                                        <MenuItem value={startDate.getFullYear()+1}>{startDate.getFullYear()+1}</MenuItem>
                                        <MenuItem value={startDate.getFullYear()+2}>{startDate.getFullYear()+2}</MenuItem>
                                    </Select>
                                </Grid>
                                <FormHelperText></FormHelperText>
                                </FormControl>
                            </Grid>

                        </MenuItem>
                        <MenuItem>
                        <Grid container direction="row" justify="space-between" alignItems="center">
                        <FormControl>
                        <Grid item>
                                <Select value={this.state.startHour} onChange={this.onStartHourUpdated}>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={11}>11</MenuItem>
                                    <MenuItem value={12}>12</MenuItem>
                                </Select>
                            </Grid>
                        <FormHelperText>Time</FormHelperText>
                        </FormControl>
                        <Grid item>
                            <Typography>:</Typography>
                        </Grid>
                        <FormControl>
                            <Grid item>
                                <Select value={this.state.startMinute} onChange={this.onStartMinuteUpdated}>
                                    <MenuItem value={0}>00</MenuItem>
                                    <MenuItem value={5}>05</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={25}>25</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={35}>35</MenuItem>
                                    <MenuItem value={40}>40</MenuItem>
                                    <MenuItem value={45}>45</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={55}>55</MenuItem>
                                    <MenuItem value={60}>60</MenuItem>
                                </Select>
                            </Grid>
                            <FormHelperText></FormHelperText>
                            </FormControl>
                            <FormControl>
                            <Grid item>
                                <Select value={this.state.startPeriod} onChange={this.onStartPeriodUpdated}>
                                    <MenuItem value={Period.AM}>AM</MenuItem>
                                    <MenuItem value={Period.PM}>PM</MenuItem>
                                </Select>
                            </Grid>
                            <FormHelperText></FormHelperText>
                            </FormControl>
                        </Grid>
                    </MenuItem>
                        <MenuItem>
                        <FormControl>
                            <Switch color='primary' checked={this.state.shouldDisplayRecurrenceOptions} onChange={this.handleSwitchChange}/>
                            <FormHelperText>Recur Weekly</FormHelperText>
                        </FormControl>
                        </MenuItem>
                        <MenuItem>
                        <Typography color='secondary'>__________________</Typography>
                        </MenuItem>
                        <MenuItem>
                            <Button color='primary' variant='contained' onClick={this.onCreateEventPressed}>Create Event</Button>
                        </MenuItem>
                    </Menu>
            );
        }
    }

    renderStartDaysOfMonth () {
        if (this.state.startMonth === Month.SEPTEMBER || this.state.startMonth === Month.APRIL
            || this.state.startMonth === Month.JUNE || this.state.startMonth === Month.NOVEMBER) {
                return (
                    <Select value={this.state.startDayOfMonth} onChange={this.onStartDayOfMonthUpdated}>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                )
            }
        else if (this.state.startMonth === Month.FEBRUARY && this.state.startYear%4===0) {
                return (
                    <Select value={this.state.startDayOfMonth} onChange={this.onStartDayOfMonthUpdated}>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                    </Select>
                )
            }
            else if (this.state.startMonth === Month.FEBRUARY) {
                return (
                    <Select value={this.state.startDayOfMonth} onChange={this.onStartDayOfMonthUpdated}>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                    </Select>
                )
            }
            else  {
                return (
                    <Select value={this.state.startDayOfMonth} onChange={this.onStartDayOfMonthUpdated}>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={16}>16</MenuItem>
                        <MenuItem value={17}>17</MenuItem>
                        <MenuItem value={18}>18</MenuItem>
                        <MenuItem value={19}>19</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={21}>21</MenuItem>
                        <MenuItem value={22}>22</MenuItem>
                        <MenuItem value={23}>23</MenuItem>
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={26}>26</MenuItem>
                        <MenuItem value={27}>27</MenuItem>
                        <MenuItem value={28}>28</MenuItem>
                        <MenuItem value={29}>29</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={31}>31</MenuItem>
                    </Select>
                )
            }
    }

}
