import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import { OnStreamUpdate, SubscriptionGenerator, Unsubscriber } from "./subscription_generator";
import { toQueueDatatbaseDocList } from "./persistence_utls";
import { DatabaseDocument } from "./persisted_object";
import ActionResultVoid from "../model/action_result_void";

export default class AppointmentPersistence {
    private constructor() {}

    private static sharedInstance: AppointmentPersistence = new AppointmentPersistence();

    public static instance() {
        return this.sharedInstance;
    }

    public updateAppointment(doc: DatabaseDocument): Promise<ActionResultVoid> {
        return firebaseApp.firestore().collection("Appointments").doc(doc.id).update(doc.data).then(() => {
            return new ActionResultVoid(true);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public getAppointmentSubscriptionGenerator(teamID: string, startTS: Date, endTS: Date): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {

            return {
                unsubscribe: firebaseApp.firestore().collection("Appointments")
                    .where("userIsActive", "==", true)
                    .where("team", "==", teamID)
                    .where("appointmentTimeStamp", ">=", startTS)
                    .where("appointmentTimeStamp", "<", endTS)
                    .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {

                        onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                    }, (error) => {
                        onStreamUpdate([]);
                    }),
            }
        };
    }
}
