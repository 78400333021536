import React from 'react';
import QueueAppbar from '../components/queue_appbar';
import '../common.css';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link, Redirect } from 'react-router-dom';
import { AuthenticationService } from '../../services/authentication_service';
import ActionResultVoid from '../../model/action_result_void';

interface LoginPageState{
    disabledButton: boolean,
    errorMessage?: string,
    loading: boolean,
    shouldRedirectToDashboard: boolean
}

function renderErrorMessage(errorMessage?: string) {
    if (errorMessage === undefined) {
        return null;
    } else {
        return (<div>
            <p className="error">{errorMessage!}</p>
            </div>);
    }
}

export default class LoginPage extends React.Component<{}, LoginPageState> {
    private email: string = "";
    private password: string = "";

    constructor(props: {}) {
        super(props);
        this.state = {
            disabledButton: true,
            loading: false,
            shouldRedirectToDashboard: false
        };

        this.onEmailUpdated = this.onEmailUpdated.bind(this);
        this.onPasswordUpdated = this.onPasswordUpdated.bind(this);
        this.shouldBeDisabled = this.shouldBeDisabled.bind(this);

        this.login = this.login.bind(this);
        this.renderCardContent = this.renderCardContent.bind(this);
    }

    onEmailUpdated(event: React.ChangeEvent<HTMLInputElement>){
        let value: string = event.target.value;
        this.email = value;
        this.setState({disabledButton:this.shouldBeDisabled()});
    }

    onPasswordUpdated(event: React.ChangeEvent<HTMLInputElement>){
        let value: string = event.target.value;
        this.password = value;
        this.setState({disabledButton:this.shouldBeDisabled()});
    }

    shouldBeDisabled (): boolean {
        return this.email==='' || this.password==='';
    }

    renderCardContent(loading: boolean) {
        if (loading) {
            return (<CircularProgress/>);
        } else {
            return (
                <Grid container direction='column' justify='center' spacing={3}>
                    <Grid item>
                        <TextField placeholder='Email' onChange={this.onEmailUpdated} value={this.email}/>
                    </Grid>
                    <Grid item>
                        <TextField placeholder='Password' type="password" onChange={this.onPasswordUpdated} value={this.password}/>
                    </Grid>
                    {renderErrorMessage(this.state.errorMessage)}
                        <Button variant='contained' color='primary' disabled={this.state.disabledButton} onClick={this.login}>
                            Log In
                        </Button>

                </Grid>
            );
        }
    }

    login() {
        this.setState({
            errorMessage: undefined,
            loading: true,
        });
        AuthenticationService.instance().login(this.email, this.password).then((result: ActionResultVoid) => {
            if (result.success) {
                this.setState({
                    loading: false,
                    shouldRedirectToDashboard: true
                });
            } else {
                this.setState({
                    errorMessage: result.errorMessage!,
                    loading: false
                });
            }
        });
    }

    render() {
        if (this.state.shouldRedirectToDashboard) {
            return (<Redirect to="/reps"/>);
        } else {
            return (
                <div className='App'>
                    <QueueAppbar shouldDisplayMenu={false}/>
                    <br/><br/><br/>

                      <Grid container direction='column' justify='center' alignContent='center' alignItems='center'>
                          <Grid item>
                              <Card>
                                  <CardContent>
                                      {this.renderCardContent(this.state.loading)}
                                  </CardContent>
                              </Card>
                          </Grid>
                          <br/>
                          <Grid item>
                              <Typography>New to Queue or starting a new office?</Typography>
                          </Grid>
                          <Grid item>
                              <Link to="/signup" className="plain_link">Create New Office</Link>
                          </Grid>
                      </Grid>

                </div>
            )
        }
    }
}
