import React from 'react';

const KPI = ({ value, label }) => {

  var valText = `${value}`
  if (value > 1000000) {
    valText = `${Math.round(value/100000)/10}M`
  }else if (value > 10000) {
    valText = `${Math.round(value/100)/10}K`
  }

  return (
    <div style={styles.container}>
      <div style={styles.kpiText}>
        {valText}
      </div>
      <div style={styles.labelText}>
        {label}
      </div>
    </div>
  )
}

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  kpiText: {
    fontSize: 20,
    textAlign: 'center'
  },
  labelText: {
    fontSize: 10,
    textAlign: 'center',
  },
}

export default KPI;
