import React from 'react';
import Team from 'model/team';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@material-ui/core';
import { AuthenticationService } from 'services/authentication_service';
import TeamService from 'services/team_service';

interface OleanOfficeNumberConfirmationDialogProps {
    team: Team
    onConfirmed?: () => void
}

interface OleanOfficeNumberConfirmationDialogState {
    officeNumber: string
}

export default class OleanOfficeNumberConfirmationDialog extends React.Component<OleanOfficeNumberConfirmationDialogProps, OleanOfficeNumberConfirmationDialogState> {

    constructor(props: OleanOfficeNumberConfirmationDialogProps) {
        super(props);
        this.state = {
            officeNumber: this.props.team.vectorOfficeNumber ?? ""
        };
    }

    render() {
        let currentTextLength: number = this.state.officeNumber.length;
        let existsError: boolean = currentTextLength > 0 && currentTextLength !== 4;

        return <Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true}>
        <DialogTitle>Please Confirm Your 4-Digit Olean Office Number</DialogTitle>
        <DialogContent>
        <DialogContentText >
            Find yours <a href="https://www.vectorconnect.com/stats/myInfo" target="_blank" rel="noopener noreferrer">here</a> under 'office'
        </DialogContentText>
        <TextField 
                variant="outlined" 
                value={this.state.officeNumber}
                placeholder={"Ex. 22K9"} 
                error={existsError} 
                helperText={existsError ? "Olean office number must be 4 digits" : undefined} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({
                        officeNumber: event.target.value
                    });
                }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                TeamService.instance().confirmOleanOfficeNumber(this.props.team.id, this.state.officeNumber).then((value) => {
                    if (value.success) {
                        AuthenticationService.instance().setUserVectorOfficeNumber(this.state.officeNumber);
                    }
                    if (value.success && this.props.onConfirmed !== undefined) {
                        this.props.onConfirmed();
                    }
                });
            }} color="primary" variant="outlined" disabled={this.state.officeNumber.length !== 4}>
                Confirm
            </Button>
        </DialogActions>
    </Dialog>;
    }

}