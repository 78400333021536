import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import { OnStreamUpdate, SubscriptionGenerator, Unsubscriber } from "./subscription_generator";
import { toQueueDatatbaseDocList, toQueueDatatbaseDoc, commitBatch } from "./persistence_utls";
import { DatabaseDocument } from "./persisted_object";
import ActionResult from "../model/action_result";
import ActionResultVoid from "../model/action_result_void";

export default class RepPersistence {
    

    private constructor() {}

    private static sharedInstance: RepPersistence = new RepPersistence();

    public static instance() {
        return this.sharedInstance;
    }

    public getRepSubscriptionGenerator(teamID: string, active: boolean): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {
            return {
                unsubscribe: firebaseApp.firestore().collection("Users")
                    .where("officeNumber", "==", teamID)
                    .where("active", "==", active)
                    .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                        onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                    }, (error) => {
                        onStreamUpdate([]);
                    }),
            }
        };
    }

    public async updateRep (doc: DatabaseDocument) : Promise<ActionResultVoid> {
       return firebaseApp.firestore().collection("Users").doc(doc.id).update(doc.data).then(() => {
        return new ActionResultVoid(true, );
    }).catch((error) => {
        return new ActionResultVoid(false, error.message);
    });
    }

    public async updateReps(docs: DatabaseDocument[]) : Promise<ActionResultVoid> {
        let batch = firebaseApp.firestore().batch();

        let currentBatchLength = 0;
        for (let doc of docs) {
            batch.update(firebaseApp.firestore().collection("Users").doc(doc.id), doc.data);
            currentBatchLength++;
            if (currentBatchLength === 500) {
                await batch.commit();
                batch = firebaseApp.firestore().batch();
                currentBatchLength = 0;
            }
        }

        return batch.commit().then(() => {
            return new ActionResultVoid(true);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public async getRep(uid:string): Promise<ActionResult<DatabaseDocument>> {
        //let snapshot: firebase.firestore.DocumentSnapshot;
        return firebaseApp.firestore().collection("Users").doc(uid).get()
           .then(snapshot => {
            if (!snapshot.exists) {
                //not found
                return new ActionResult<DatabaseDocument>(false,"Rep Not Found");
            } else {
                //success
                return new ActionResult(true,undefined,toQueueDatatbaseDoc(snapshot));
            }
          })
          //failure
          .catch(err => {
            return new ActionResult<DatabaseDocument>(false,err.message);
          });
    }


    public async toggleActive(uid: string, active: boolean): Promise<ActionResultVoid> {
        try {
            let userRef = firebaseApp.firestore().collection("Users").doc(uid);
            let currentPhoneTimeRef = firebaseApp.firestore().collection("Current Phone Times").doc(uid);
            let batch = firebaseApp.firestore().batch();

            batch.update(userRef, {
                "active": active,
                "markedInactiveTimeStamp": (active) ? null : new Date(),
            });

            let currentPhoneTimeSnap = await currentPhoneTimeRef.get();

            if (currentPhoneTimeSnap.exists) {
                batch.update(currentPhoneTimeRef, {
                    "userIsActive": active
                });
            }

            let batchSize = 2;

            let apptQuerySnap = await firebaseApp.firestore().collection("Appointments").where("userID", "==", uid).get();
            console.log("apptQuerySnap");
            console.log(apptQuerySnap);
            if (!apptQuerySnap.empty) {
                console.log("not empty");
                    for (const apptSnap of apptQuerySnap.docs) {
                    if (apptSnap.exists) {
                        console.log("updating appt " + apptSnap.id);
                        if (batchSize === 500) {
                            await batch.commit();
                            batchSize = 0;
                            batch = firebaseApp.firestore().batch();
                        }
                        batch.update(apptSnap.ref, {
                            "userIsActive": active
                        });
                        batchSize++;
                        
                    }
                }
            }

            await batch.commit();

            return new ActionResultVoid(true);
        } catch (error) {
            console.log(error);
            return new ActionResultVoid(false, String(error));
        }

    }

    public async renameGroup(teamID: string, group: string, newName: string): Promise<ActionResultVoid> {
        return firebaseApp.firestore().collection("Users").where("officeNumber", "==", teamID).where("group", "==", group).get().then((groupQueryResult) => {
           
            if (groupQueryResult.empty) {
                return new ActionResultVoid(false, "Found no reps in office " + teamID + " and group " + group);
            } else {
                let batch = firebaseApp.firestore().batch();
                groupQueryResult.docs.forEach((repSnapshot) => {
                    batch.update(repSnapshot.ref, {
                        "group": newName
                    });
                });

                return commitBatch(batch);
            }
        }).catch((error) => new ActionResultVoid(false, "Unable to load reps in office " + teamID + " and group " + group + ": " + error.toString()));
    }

    public async renameTeam(teamID: string, team: string, newName: string): Promise<ActionResultVoid> {
        return firebaseApp.firestore().collection("Users").where("officeNumber", "==", teamID).where("team", "==", team).get().then((teamQueryResult) => {
           
            if (teamQueryResult.empty) {
                return new ActionResultVoid(false, "Found no reps in office " + teamID + " and team " + team);
            } else {
                let batch = firebaseApp.firestore().batch();
                teamQueryResult.docs.forEach((repSnapshot) => {
                    batch.update(repSnapshot.ref, {
                        "team": newName
                    });
                });

                return commitBatch(batch);
            }
        }).catch((error) => new ActionResultVoid(false, "Unable to load reps in office " + teamID + " and team " + team + ": " + error.toString()));
    }

    public getDivisionalRepSubscriptionGenerator(teamIDs: string[],): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {
            let subscriptions: (() => void)[] = [];
            //group team ids into partitions of size 10
            let teamIDPartitions: string[][] = [];
            let partitionIndex = 0;
            for (let x = 0; x < teamIDs.length; x++) {
                if (teamIDPartitions.length < partitionIndex + 1) {
                    teamIDPartitions.push([teamIDs[x]])
                } else {
                    teamIDPartitions[partitionIndex].push(teamIDs[x]);
                }
                if (x % 10 === 9) {
                    partitionIndex++;
                }
            }
            teamIDPartitions.forEach((partition) => {
                subscriptions.push(firebaseApp.firestore().collection("Users")
                .where("officeNumber", "in", partition)
                .where("active", "==", true)
                .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                    onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                }, (error) => {
                    onStreamUpdate([]);
                }))
            });
            return {
                unsubscribe: () => {
                    subscriptions.forEach((sub) => sub())
                }
            }
        };
    }

}