import React from 'react';
import '../common.css';
import Rep from '../../model/rep';
import QueueAppbar from '../components/queue_appbar';
import GuardedListenerComponent from '../components/guarded_listener_component';
import RepStore from '../../services/rep_store';
import { CircularProgress, Grid, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button, Badge, createMuiTheme, IconButton } from '@material-ui/core';
import RepDisplayCard, { getRepTableRowElements } from '../components/rep_display_card'
import PdiWeekStore from '../../services/pdi_week_store';
import AppointmentStore from '../../services/appointment_store';
import ListenerClient from '../../services/listener_client';
import DateUtils from '../../services/date_utils';
import WeekSelector from '../components/week_selector';
import { StarterKitStatus } from '../../model/starter_kit_status';
import { QueueColor } from '../../model/queue_color';
import { ThemeProvider } from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import 'ui/css/common_text.css';
import 'ui/css/common_other.css';
import RepShelf from '../components/rep_shelf';
import QueueCheckbox from 'ui/components/common/QueueCheckbox';
import ViewWithShelf from 'ui/components/common/ViewWithShelf';
import TeamStore from 'services/team_store';
import RepCollection, {CollectionType, isDefaultGroup, isDefaultTeam} from 'model/rep_collection';
import { PdiStatus } from 'model/pdi_status';
import RepCollectionSelector from 'ui/components/rep_collection_selector';
import {Virtuoso} from 'react-virtuoso';
import RenameRepCollectionDialog from 'ui/components/rename_rep_collection_dialog';
import PdiProgressBar from 'ui/components/pdi_progress_bar';

interface RepsPageState {
    weekID: string,
    searchText: string,
    selectedRows: Set<string>,
    organizeBy: CollectionType,
    screenWidth: number,
    screenHeight: number,
    repCollectionToRename?: string,
}

interface Row {
  isGroup: boolean
  elements: any[]
}

const HEADER_BACKGROUND_COLOR = '#f5f5f5';

function tableify(row: Row, screenWidth: number, tableHead: boolean = false): any {
  let length = row.elements.length;
  for (let i = 0; i < length; i++) {
    row.elements[i] = tableCellify(i, row.elements[i], screenWidth, tableHead);
  }
  if (tableHead) {
    return <TableHead className="no-padding" >
      <TableRow style={{backgroundColor: HEADER_BACKGROUND_COLOR}}className="no-padding">
        {row.elements}
      </TableRow>
    </TableHead>;
  } else {
    return <Table className="no-padding"><TableBody className="no-padding"><TableRow style={{backgroundColor: row.isGroup ? HEADER_BACKGROUND_COLOR : undefined}} selected={row.isGroup} className="no-padding">
    {row.elements}
  </TableRow></TableBody></Table>;
  }
}

function tableCellify(index: number, element: any, screenWidth: number, tableHead: boolean): any {
    return <TableCell style={{
      width: getWidthForIndex(index, screenWidth), height: tableHead ? 35 : 50, padding: 0, margin: 0
    }}>{element}</TableCell>;
  
}

function getWidthForIndex(index: number, screenWidth: number): number {
  const CHECKBOX_MIN_WIDTH = 30;
  const NAME_MIN_WIDTH = 115;
  const PDI_MIN_WIDTH = 150;
  const DEMO_GOALS_MIN_WIDTH = 50;
  const TOTAL_MIN_WIDTH = CHECKBOX_MIN_WIDTH + NAME_MIN_WIDTH + PDI_MIN_WIDTH + (DEMO_GOALS_MIN_WIDTH * 7); 
  let screenWidthAdjustment = Math.max(screenWidth - TOTAL_MIN_WIDTH, 0) / 10;
  if (index === 0) {
    return CHECKBOX_MIN_WIDTH + screenWidthAdjustment;
  } else if (index === 1) {
    return NAME_MIN_WIDTH + screenWidthAdjustment;
  } else if (index === 2) {
    return PDI_MIN_WIDTH + screenWidthAdjustment;
  } else {
    return DEMO_GOALS_MIN_WIDTH + screenWidthAdjustment;
  }
}

function renderAppointmentDay (day: number, appointmentWeek: number[], dailyGoals: number[], withCell: boolean = true) {

  let appointments = appointmentWeek[day];
  let goals = dailyGoals[day];
  if (withCell) {
    return (
      <TableCell>
        <p className="Standard-Text">{appointments}/{goals}</p>
      </TableCell>
    );
  } else {
    return (
        <p className="Standard-Text">{appointments}/{goals}</p>
    );
  }
}

export default class RepsPage extends GuardedListenerComponent<{}, RepsPageState> {
    private pdiWeekStore?: PdiWeekStore;
    private appointmentStore?: AppointmentStore;
    private pdiWeekListenerClient?: ListenerClient<PdiWeekStore>;
    private appointmentListenerClient?: ListenerClient<AppointmentStore>;
    private initializedListenerClients: boolean = false;
    private ALLSELECT: string = '__all'
    private lastResize: Date = new Date();

    componentDidMount() {
      super.componentDidMount();
      window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
      super.componentWillUnmount();
      window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
      let now = new Date();
      if (now.getTime() - this.lastResize.getTime() > 1000) {
        this.lastResize = now;
        this.setState({
          screenWidth: window.screen.width,
          screenHeight: window.innerHeight
        })
      }
    }

    constructor(props:{}) {
        super(props, true);

        this.state = {
            weekID: DateUtils.getWeekIDForOffset(0),
            searchText: "",
            selectedRows: new Set<string>(),
            organizeBy: CollectionType.GROUP,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height
        };

        this.updateSelectedWeekID = this.updateSelectedWeekID.bind(this);
        this.renderWeekSelectorAddOn = this.renderWeekSelectorAddOn.bind(this);
        this.renderRepCell = this.renderRepCell.bind(this);
        this.renderInactiveRepsButton = this.renderInactiveRepsButton.bind(this);
        this.renderInactiveRepsBadge = this.renderInactiveRepsBadge.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.getIdTypeHelper = this.getIdTypeHelper.bind(this);
        this.renderTransferRequestButton = this.renderTransferRequestButton.bind(this);
        this.getGroupTitleRowElements = this.getGroupTitleRowElements.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    getEmployedListenerClients() {
        if (!this.initializedListenerClients) {
            this.pdiWeekListenerClient = PdiWeekStore.getListenerClientForWeekID(this.state.weekID);
            this.appointmentListenerClient = AppointmentStore.getListenerClientForWeekID(this.state.weekID);
            this.pdiWeekStore = this.pdiWeekListenerClient.getStore();
            this.appointmentStore = this.appointmentListenerClient.getStore();
            this.initializedListenerClients = true;
        }

        return [RepStore.getActiveRepsListenerClient(), RepStore.getInactiveRepsListenerClient(), this.pdiWeekListenerClient!, this.appointmentListenerClient!, TeamStore.getTeamListenerClient()];
    }

    getPathOnRefresh() {
        return "/reps";
    }

    getGroupTitleRowElements(groupName: string, reps: Rep[], demoCounts: number[], demoGoals: number[], isFullTeam: boolean = false) {
      

      const showEditIcon = ((this.state.organizeBy === CollectionType.GROUP && !isDefaultGroup(groupName)) || (this.state.organizeBy === CollectionType.TEAM && !isDefaultTeam(groupName))) && !isFullTeam;
      return [
          <QueueCheckbox
            checked={this.isSelected(groupName, '')}
            onChange={() => this.onSelect(groupName) }
          />,
    
          <div className={"Standard-Text Bold"}>
            {groupName + " (" + reps.length.toString() + ")"}
            {showEditIcon ? <IconButton onClick={() => this.setState({repCollectionToRename: groupName})}>
              <EditIcon/>
            </IconButton> : null}
          </div>,
          <PdiProgressBar reps={reps} weekID={this.state.weekID}/>,
    
          renderAppointmentDay(0, demoCounts, demoGoals, false),
          renderAppointmentDay(1, demoCounts, demoGoals, false),
          renderAppointmentDay(2, demoCounts, demoGoals, false),
          renderAppointmentDay(3, demoCounts, demoGoals, false),
          renderAppointmentDay(4, demoCounts, demoGoals, false),
          renderAppointmentDay(5, demoCounts, demoGoals, false),
          renderAppointmentDay(6, demoCounts, demoGoals, false),
    
      ];
    }

    updateSelectedWeekID(weekID: string) {
        this.pdiWeekListenerClient = PdiWeekStore.getListenerClientForWeekID(weekID);
        this.pdiWeekStore = this.pdiWeekListenerClient.getStore();
        this.appointmentListenerClient = AppointmentStore.getListenerClientForWeekID(weekID);
        this.appointmentStore = this.appointmentListenerClient.getStore();
        super.refreshListenerClients(this.getEmployedListenerClients());
        this.setState({
            weekID: weekID,
        });
    }

    isSelected(groupId: string, repId: string) {
      const { selectedRows } = this.state;
      return selectedRows.has(repId) || selectedRows.has(groupId) || selectedRows.has(this.ALLSELECT);
    }

    onSelect(id: string) {
      const { selectedRows } = this.state;
      const exists = selectedRows.has(id);
      const type = this.getIdTypeHelper(id);
      let reps: Rep[] = RepStore.activeRepStore().getReps();

      if (type === 'rep') {
        if (!exists) {
          selectedRows.add(id);
        } else {
          selectedRows.delete(id);
          selectedRows.delete(this.ALLSELECT);
          const repGroup = reps.filter(rep => rep.repID === id)[0].group;
          selectedRows.delete(repGroup);
        }
      }

      else if (type === 'group') {
        if (!exists) {
          reps.forEach(rep => {
            if (rep.group === id) { selectedRows.add(rep.repID) }
          });
          selectedRows.add(id);
        } else {
          reps.forEach(rep => {
            if (rep.group === id) { selectedRows.delete(rep.repID) }
          });
          selectedRows.delete(id);
          selectedRows.delete(this.ALLSELECT);
        }
      }

      else { //type=== 'all'
        if (!exists) {
          reps.forEach(rep => {
            selectedRows.add(rep.repID);
            selectedRows.add(rep.group);
          });
          selectedRows.add(id);
        } else {
          reps.forEach(rep => {
            selectedRows.delete(rep.repID);
            selectedRows.delete(rep.group);
          });
          selectedRows.delete(id);
        }
      }

      this.setState({ selectedRows: selectedRows })
    }
    getIdTypeHelper(id: string) {
      if (id === this.ALLSELECT) return 'all';
      let reps: Rep[] = RepStore.activeRepStore().getReps();
      var type: string = '';
      reps.forEach(rep => {
        if (rep.group === id) type = 'group';
        if (rep.repID === id) type = 'rep';
      });

      return type;

    }

    //Rendering functions
    renderGuarded() {
      if (!RepStore.activeRepStore().isLoaded() || !this.appointmentStore!.isLoaded()) {
          return (
            <div className="App">
              <QueueAppbar shouldDisplayMenu/>
              <CircularProgress></CircularProgress>
            </div>
          )
      }

      const { selectedRows } = this.state;
      let reps: Rep[] = RepStore.activeRepStore().getReps();
      const selectedReps = reps.filter(rep => selectedRows.has(rep.repID));

      return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          <div className="page-header">
            <QueueAppbar shouldDisplayMenu/>
          </div>
          <div className="page-content"><ViewWithShelf
                primaryView={this.renderWeekSelectorAndTable()}
                shelfView={this.renderRepShelf()}
                isOpen={selectedReps.length > 0}
              /></div>
          <RenameRepCollectionDialog
            onClose={() => this.setState({repCollectionToRename: undefined})}
            collectionToRename={this.state.repCollectionToRename}
            collectionType={this.state.organizeBy}
          />
        </div>
      );
    }

    renderWeekSelectorAndTable() {
      let today = new Date();
      let currentVectorDay: number = DateUtils.toVectorDay(today.getDay());
      let onCurrentWeek: boolean = DateUtils.getWeekIDForOffset(0) === this.state.weekID;
      let firstInstantOfWeek: Date = DateUtils.getFirstInstantOfVectorWeekForWeekID(this.state.weekID);

      return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', padding: 0, margin: 0}}>
          <div className="page-header">
              <div style={{ paddingLeft: 10 }}>
              <WeekSelector selectedWeekID={this.state.weekID} onSelectedWeekChanged={this.updateSelectedWeekID} component={this.renderWeekSelectorAddOn()}
              />
              </div>
    
              <Table stickyHeader padding={'none'}>
                {tableify({
                  isGroup: false,
                  elements: [
                    <QueueCheckbox
                        checked={this.isSelected('', '')}
                        onChange={() => this.onSelect(this.ALLSELECT)}
                      />,
                      <div className="Table-Header Header-Text">Name</div>,
                      <div className="Table-Header Header-Text">PDI Today</div>,
                      buildDayTableCell(0, onCurrentWeek, currentVectorDay, DateUtils.addDays(firstInstantOfWeek, 0)),
                      buildDayTableCell(1, onCurrentWeek, currentVectorDay, DateUtils.addDays(firstInstantOfWeek, 1)),
                      buildDayTableCell(2, onCurrentWeek, currentVectorDay, DateUtils.addDays(firstInstantOfWeek, 2)),
                      buildDayTableCell(3, onCurrentWeek, currentVectorDay, DateUtils.addDays(firstInstantOfWeek, 3)),
                      buildDayTableCell(4, onCurrentWeek, currentVectorDay, DateUtils.addDays(firstInstantOfWeek, 4)),
                      buildDayTableCell(5, onCurrentWeek, currentVectorDay, DateUtils.addDays(firstInstantOfWeek, 5)),
                      buildDayTableCell(6, onCurrentWeek, currentVectorDay, DateUtils.addDays(firstInstantOfWeek, 6)),
                  ]
                }, this.state.selectedRows.size > 0 ? this.state.screenWidth * .7 : this.state.screenWidth, true)}
  
                <TableBody>
                
                </TableBody>
                  
              </Table>
              </div>
                <div className="page-content" style={{padding: '0px', margin:'0px'}}>{this.renderBody()}</div>
                </div>
        
        
      )
    }
    renderWeekSelectorAddOn() {
        let height = 30;
        let badgeNumber: number = 0;
        let reps: Rep[] = [];
        if (!RepStore.inactiveRepStore().isLoaded()){
            return (
                <CircularProgress></CircularProgress>
            )
        }
        else {
            reps = RepStore.inactiveRepStore().getReps();
        }
        for (let rep of reps) {
            if (rep.starterKitStatus === StarterKitStatus.NOTRETURNED) badgeNumber++;
        }
        return (
            <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                <Grid item>
                    <TextField
                      value={this.state.searchText}
                      placeholder="Search by name, phone number, or notes"
                      onChange={(event) => {this.setState({searchText: event.target.value})}} variant="outlined"
                      inputProps={{
                      style: {
                       height,
                       padding: '0 14px',
                     },
                   }}
                      />
                </Grid>
                <Grid item>
                    {this.state.organizeBy === CollectionType.GROUP ? <Button color="primary" variant="contained" href="/#/create-group">
                      Create Group
                    </Button> :
                    <Button color="primary" variant="contained" href="/#/create-team">
                      Create Team
                    </Button>}
                </Grid>
                <Grid item>
                    {this.renderInactiveRepsBadge(badgeNumber)}
                </Grid>
                <Grid item>
                  {this.renderTransferRequestButton()}
                </Grid>
                <Grid item>
                   <RepCollectionSelector onCollectionTypeSelected={(collection: CollectionType) => this.setState({organizeBy: collection})} />
                </Grid>
            </Grid>
        )
    }

    renderTransferRequestButton() {
      let badgeNumber = 0;
      if (TeamStore.instance().isLoaded()) {
        badgeNumber = TeamStore.instance().getTeam().pendingTransferRequestCount;
      }
      let newTheme = createMuiTheme ({
        palette: {
            primary: {
                main: QueueColor.RED,
            },
        }
      });
      return (
            <ThemeProvider theme={newTheme}>
                <Badge badgeContent={badgeNumber} color="primary">
                  <Button color="primary" variant="outlined" href="/#/transfer-requests">Transfer Requests</Button>
                </Badge>
            </ThemeProvider>
      )
    }
    renderBody() {
        if (!RepStore.activeRepStore().isLoaded() || !this.appointmentStore!.isLoaded() || !this.pdiWeekStore!.isLoaded()) {
            return (<TableRow><TableCell><CircularProgress/></TableCell></TableRow>);
        } else {
            let collections = RepStore.activeRepStore().getRepCollections(this.state.organizeBy, true);
            if (this.state.searchText !== "") {
              let query = this.state.searchText.toLowerCase();
              collections.forEach((rc: RepCollection) => {
                rc.setReps(rc.getReps().filter((rep: Rep) => (rep.firstName.toLowerCase() + " " + rep.lastName.toLowerCase() + " " + rep.notes.toLowerCase() + " " + rep.phoneNumber).includes(query)));
              });
              collections = collections.filter((rc: RepCollection) => rc.getReps().length > 0);
            }

            //aggregate data for the group/team summary card
            let fullOfficeDemoGoals = new Array<number>(7).fill(0);
            let fullOfficeDemoCounts = new Array<number>(7).fill(0);

            let tableRows: Row[][] = collections.map((rc: RepCollection) => {
              let numReps = rc.getReps().length;
              let rows: Row[] = new Array(numReps + 1);

              //aggregate data for the group/team summary card
              let totalDemoCounts = new Array<number>(7).fill(0);
              let totalDemoGoals = new Array<number>(7).fill(0);
              
              //create a row for each rep and update the aggregate data
              for (let i = 0; i < numReps; i++) {
                let rep = rc.getReps()[i];
                let pdiWeek = this.pdiWeekStore!.getPdiWeekForRep(rep.repID);
                //get appointments and organize into an array of counts for each day of the week
                let appointments = this.appointmentStore!.getAppointmentsForRep(rep.repID, true);
                let appointmentWeek = new Array(7).fill(0);
                appointments.forEach(appointment => {
                  let appointmentDate = appointment.startTimeStamp;
                  let vectorDay = DateUtils.toVectorDay(appointmentDate.getDay())
                  appointmentWeek[vectorDay] = appointmentWeek[vectorDay]+1;
                });
                //update totals
                for (let j = 0; j < 7; j++) {
                  totalDemoGoals[j] = totalDemoGoals[j] + (pdiWeek.dailyGoals[j] ?? 0);
                  totalDemoCounts[j] = totalDemoCounts[j] + appointmentWeek[j];
                  fullOfficeDemoGoals[j] = fullOfficeDemoGoals[j] + (pdiWeek.dailyGoals[j] ?? 0);
                  fullOfficeDemoCounts[j] = fullOfficeDemoCounts[j] + appointmentWeek[j];
                }
                
                rows[i + 1] = {
                  isGroup: false, 
                  elements: getRepTableRowElements({
                    rep: rep,
                    pdiWeek: pdiWeek,
                    appointments: appointments,
                    weekID: this.state.weekID,
                    isChecked: this.isSelected(rep.group, rep.repID),
                    onCheck: () => this.onSelect(rep.repID),
                  })
                };
              }
              rows[0] = {
                isGroup: true,
                elements: this.getGroupTitleRowElements(rc.getCollectionName(), rc.getReps(), totalDemoCounts, totalDemoGoals)
              };
              return rows;
            });

            tableRows = [[{
              isGroup: true,
              elements: this.getGroupTitleRowElements("Full Team", RepStore.activeRepStore().getReps(), fullOfficeDemoCounts, fullOfficeDemoGoals, true)
            }]].concat(tableRows);
            let flattenedTableRows: Row[] = tableRows.flat();

            let finalTableRows = new Array(flattenedTableRows.length);
            for (let z = 0; z < flattenedTableRows.length; z++) {
              finalTableRows[z] = tableify(flattenedTableRows[z], this.state.selectedRows.size > 0 ? this.state.screenWidth * .7 : this.state.screenWidth);
            }
            
            // if (this.state.searchText != "") {
            //   let query: string = this.state.searchText.toLowerCase();
            //   reps = reps.filter((r: Rep) => {
            //       let search: string = r.firstName.toLowerCase() + " " + r.lastName.toLowerCase() + " " + r.notes.toLowerCase() + " " + r.phoneNumber;
            //       return search.includes(query);
            //   });
            // }
            return <Virtuoso
              totalCount={finalTableRows.length}
              item={(index) => finalTableRows[index]}
              itemHeight={50}
              style={{padding: 0, margin: 0, height: '100%'}}
            />
        }
    }
    renderInactiveRepsBadge (badgeNumber: number) {
        let newTheme = createMuiTheme ({
            palette: {
                primary: {
                    main: QueueColor.RED,
                },
            }
        });
        return (
                <ThemeProvider theme={newTheme}>
                    <Badge badgeContent={badgeNumber} color="primary">
                        {this.renderInactiveRepsButton()}
                    </Badge>
                </ThemeProvider>
        )
    }
    renderInactiveRepsButton () {
        return (
            <Button color="primary" variant="outlined" href="/#/inactive-reps">Inactive Reps</Button>
        )
    }

    // buildGroupTitleTableRow(groupName: string, numReps: number, numReached: number, numUnreached: number, demoCounts: number[], demoGoals: number[]) {
    //   let reachedPercent;
    //   let unreachedPercent; 
    //   if (numReps === 0) {
    //     reachedPercent = "0";
    //     unreachedPercent = "0";
    //   } else {
    //     reachedPercent = (numReached / numReps * 100).toFixed();
    //     unreachedPercent = (numUnreached / numReps * 100).toFixed();
    //   }
    //   return (
    //     <TableRow key={groupName} selected={true}>
    //       <QueueCheckbox
    //         checked={this.isSelected(groupName, '')}
    //         onChange={() => this.onSelect(groupName) }
    //       />

    //       <TableCell><div className={"Standard-Text Bold"}>{groupName}</div></TableCell>
    //       <TableCell>
           
    //       </TableCell>

    //       {renderAppointmentDay(0, demoCounts, demoGoals)}
    //       {renderAppointmentDay(1, demoCounts, demoGoals)}
    //       {renderAppointmentDay(2, demoCounts, demoGoals)}
    //       {renderAppointmentDay(3, demoCounts, demoGoals)}
    //       {renderAppointmentDay(4, demoCounts, demoGoals)}
    //       {renderAppointmentDay(5, demoCounts, demoGoals)}
    //       {renderAppointmentDay(6, demoCounts, demoGoals)}
    //     </TableRow>
    //   );
    // }
    renderRepCell(rep: Rep) {

                let pdiWeekForRep = this.pdiWeekStore!.getPdiWeekForRep(rep.repID);
                let appointmentWeekForRep = this.appointmentStore!.getAppointmentsForRep(rep.repID, true)
                return (<RepDisplayCard
                    key={rep.repID + "card"}
                    rep={rep} pdiWeek={pdiWeekForRep}
                    appointments={appointmentWeekForRep}
                    weekID={this.state.weekID}
                    onCheck={() => this.onSelect(rep.repID)}
                    isChecked={this.isSelected(rep.repID, rep.group)}
                    ></RepDisplayCard>);
    }

    renderRepShelf() {
      const { selectedRows } = this.state;
      if (selectedRows.size < 1) return <div />;
      let reps: Rep[] = RepStore.activeRepStore().getReps();
      const selectedReps = reps.filter(rep => {
        return selectedRows.has(rep.repID) && rep.isActive;
      })


      return (
        <RepShelf
          selectedRows={selectedRows}
          selectedReps={selectedReps}
          appointmentStore={this.appointmentStore}
          clearSelectedReps={() => {
            this.setState({selectedRows: new Set<string>()});
          }}
        />
      )
    }

}


function buildDayTableCell(vectorDay: number, viewingCurrentWeek: boolean, todaysVectorDay: number, date: Date) {
    if (date.getHours() === 23) {
      date = DateUtils.addDays(date, 1/24);
    } else if (date.getHours() === 1) {
      date = DateUtils.addDays(date, - 1/24);
    }
    let className: string | undefined;
    if (!viewingCurrentWeek || vectorDay !== todaysVectorDay) {
        className = "Table-Header Header-Text";
    } else {
        className = "Table-Header Header-Text Selected"
    }

    return <div className={className}>{DateUtils.getDayAbbreviationForVectorDay(vectorDay) + " " + DateUtils.toMonthSlashDayString(date)}</div>
}

const styles = {
  bodyContainer: {
    marginTop: 10,
  },
  primaryContent: {
    marginLeft: 0,
    marginRight: 0,
  }
}
