import ActionResultVoid from "../model/action_result_void";
import Rep from "../model/rep";
import RepPersistence from "../persistence/rep_persistence";
import RepConverter from "../model/converters/rep_converter";
import { DatabaseDocument } from "../persistence/persisted_object";
import RepStore from "./rep_store";
import { isDefaultGroup, isDefaultTeam } from "model/rep_collection";
import { AuthenticationService } from "./authentication_service";




export class RepService {
    private constructor () {

    }

    private static sharedInstance: RepService = new RepService(); 

    public static instance(): RepService {
        return this.sharedInstance;
    }

    //this method assumes that both the inactive and the active rep store are already loaded, if they are not, calling this method will throw an exception
    public static getUserName(uid: string): string {
        if (RepStore.activeRepStore().hasRep(uid)) {
            return RepStore.activeRepStore().getRep(uid).firstName + " " + RepStore.activeRepStore().getRep(uid).lastName;
        }
        else if (RepStore.inactiveRepStore().hasRep(uid)) {
            return RepStore.inactiveRepStore().getRep(uid).firstName + " " + RepStore.inactiveRepStore().getRep(uid).lastName + " (Inactive)";
        }
        else {
            return "Unknown (Transferred)"; 
        }
    }

    public async updateRep (rep: Rep): Promise<ActionResultVoid> {
        let repConverter = new RepConverter();
        let updatedRepData: DatabaseDocument = repConverter.toPersistence(rep);
        return await RepPersistence.instance().updateRep(updatedRepData);
    }

    public async updateReps(reps: Set<Rep>): Promise<ActionResultVoid> {
        let repConverter = new RepConverter();
        let docs: DatabaseDocument[] = [];
        reps.forEach((rep: Rep) => {
            docs.push(repConverter.toPersistence(rep));
        });
        return await RepPersistence.instance().updateReps(docs);
    }

    public async renameGroup(group: string, newName: string): Promise<ActionResultVoid> {
        if (isDefaultGroup(group)) {
            return new ActionResultVoid(false, "Cannot rename a default group");
        } else if (isDefaultGroup(newName)) {
            return new ActionResultVoid(false, "New name cannot be a default group");
        } else {
            return await RepPersistence.instance().renameGroup(AuthenticationService.instance().getCurrentUserOfficeNumber(), group, newName);
        }
    }

    public async renameTeam(team: string, newName: string): Promise<ActionResultVoid> {
        if (isDefaultTeam(team)) {
            return new ActionResultVoid(false, "Cannot rename a default team");
        } else if (isDefaultTeam(newName)) {
            return new ActionResultVoid(false, "New name cannot be a default team");
        } else {
            return await RepPersistence.instance().renameTeam(AuthenticationService.instance().getCurrentUserOfficeNumber(), team, newName);
        }
    }

    public async toggleActive(rep: Rep): Promise<ActionResultVoid> {
        console.log("toggle active");
        return RepPersistence.instance().toggleActive(rep.repID, !rep.isActive);
    }
}