import PhoneTime from "model/phone_time";
import Store from "./store";
import PhoneTimeConverter from "model/converters/phone_time_converter";
import ListenerClient from "./listener_client";
import PhoneTimePersistence from "persistence/phone_time_persistence";
import { AuthenticationService } from "./authentication_service";
import DateUtils from "./date_utils";

export default class PhoneTimeStore extends Store<PhoneTime> {
  private constructor() {
    super (new PhoneTimeConverter());

    this.phoneTimeCache = new Map<string, PhoneTime>();

    this.getPhoneTimes = this.getPhoneTimes.bind(this);
    this.getPhoneTime = this.getPhoneTime.bind(this);
  }

  //MARK: - Private vars & functions for internal fuctioning
  private phoneTimeCache?: Map<string, PhoneTime>;
  private static phoneTimeStores: Map<string, PhoneTimeStore> = new Map<string, PhoneTimeStore>();
  private getPhoneTime(id: string): PhoneTime {
    return this.phoneTimeCache!.get(id)!;
  }
  private getPhoneTimes(): PhoneTime[] {
    let toReturn: PhoneTime[] = [];
    if (this.phoneTimeCache) {
      this.phoneTimeCache.forEach(( value: PhoneTime, _: string) => {
        toReturn.push(value);
      });
    }
    return toReturn;
  }
  private static getPhoneTimeStoreForRep(repId: string) {
    if (!PhoneTimeStore.phoneTimeStores.has(repId)) {

      PhoneTimeStore.phoneTimeStores.set(repId, new PhoneTimeStore());
    }
    return PhoneTimeStore.phoneTimeStores.get(repId)!;
  }
  private static getPhoneTimeStoresForReps(repIds: string[]) {
    let toReturn: PhoneTimeStore[] = [];
    for (const repId of repIds) {
      const store = PhoneTimeStore.getPhoneTimeStoreForRep(repId);
      toReturn.push(store)
    }
    return toReturn;
  }

  public static getPhoneTimesForReps ( repIds: string[] ): PhoneTime[] {
    let toReturn: PhoneTime[] = [];

    let stores: PhoneTimeStore[] = PhoneTimeStore.getPhoneTimeStoresForReps(repIds);


    for (let i = 0; i < stores.length; i++) {
      const store: PhoneTimeStore = stores[i];
      const phoneTimes: PhoneTime[] = store.getPhoneTimes();
      toReturn = toReturn.concat(phoneTimes);
    }
    return toReturn;
  }

  //MARK - Specific data pulls
  public static getTotalCallsForReps(repIds: string[]): number {

    const phoneTimes = PhoneTimeStore.getPhoneTimesForReps(repIds);

    var toReturn = 0;
    for (let i = 0; i < phoneTimes.length; i++) {
      toReturn = toReturn + (phoneTimes[i].totalCallsMade ?? 0);
    }
    return toReturn;
  }
  public static getTotalAnsweredForReps( repIds: string[]): number {
    const phoneTimes = PhoneTimeStore.getPhoneTimesForReps(repIds);
    var toReturn = 0;
    for (let i = 0; i < phoneTimes.length; i++) {
      toReturn = toReturn + (phoneTimes[i].totalBooked ?? 0) + (phoneTimes[i].totalDeclines ?? 0) + (phoneTimes[i].totalCallBacks ?? 0);
    }
    return toReturn;
  }
  public static getTotalBookedForReps( repIds: string[]): number {
    const phoneTimes = PhoneTimeStore.getPhoneTimesForReps(repIds);
    var toReturn = 0;
    for (let i = 0; i < phoneTimes.length; i++) {
      toReturn = toReturn + (phoneTimes[i].totalBooked ?? 0);
    }
    return toReturn;
  }
  public static getCallsPerHourForReps(repIds: string[]): number {
    const phoneTimes = PhoneTimeStore.getPhoneTimesForReps(repIds);
    var calls = 0;
    var phoningTime = 0;
    for (let i = 0; i < phoneTimes.length; i++) {
      calls = calls + (phoneTimes[i].totalCallsMade ?? 0);
      phoningTime = phoningTime + (phoneTimes[i].totalDurationInMilliseconds ?? 0);
    }
    const hours = phoningTime / 1000 / 60 / 60;
    const ratio = (hours > 0) ? calls / hours : 0;
    return ratio;
  }

  public static getCallsPerDemoForReps(repIds: string[]): number {
    const phoneTimes = PhoneTimeStore.getPhoneTimesForReps(repIds);
    var calls = 0;
    var demos = 0;
    for (let i = 0; i < phoneTimes.length; i++) {
      calls = calls + (phoneTimes[i].totalCallsMade ?? 0);
      demos = demos + (phoneTimes[i].totalBooked ?? 0);
    }
    const ratio = (demos > 0) ? (calls / demos) : 0;
    return ratio;
  }

  public static getAnswerRate(repIds: string[]): number {
    const phoneTimes = PhoneTimeStore.getPhoneTimesForReps(repIds);
    var calls = 0;
    var answered = 0;
    for (let i = 0; i < phoneTimes.length; i++) {
      calls = calls + (phoneTimes[i].totalCallsMade ?? 0);
      answered = answered + (phoneTimes[i].totalBooked ?? 0) + (phoneTimes[i].totalDeclines ?? 0) + (phoneTimes[i].totalCallBacks ?? 0);
    }
    return (calls > 0) ? answered / calls : 0;
  }
  public static getScheduleRate(repIds: string[]): number {
    const phoneTimes = PhoneTimeStore.getPhoneTimesForReps(repIds);
    var scheduled = 0;
    var answered = 0;
    for (let i = 0; i < phoneTimes.length; i++) {
      scheduled = scheduled + (phoneTimes[i].totalBooked ?? 0);
      answered = answered + (phoneTimes[i].totalBooked ?? 0) + (phoneTimes[i].totalDeclines ?? 0) + (phoneTimes[i].totalCallBacks ?? 0);
    }
    return (answered > 0) ? scheduled / answered : 0;
  }


  //MARK: - Public functions called to start, end, and interact with store
  public isLoaded(): boolean {
    return this.phoneTimeCache !== undefined;
  }

  public clearCache(): void {
    this.phoneTimeCache = undefined;
  }

  public updateCache(phoneTimes: PhoneTime[]): void {

    this.phoneTimeCache = new Map<string, PhoneTime>();
    for (let phoneTime of phoneTimes) {
      this.phoneTimeCache.set(phoneTime.id, phoneTime);
    }
  }

  public static getPhoneTimeListenerClient (repId: string, startDate: Date, endDate: Date): ListenerClient<PhoneTimeStore> {
    const store = PhoneTimeStore.getPhoneTimeStoreForRep(repId);
    const subscriptionGenerator = PhoneTimePersistence.instance().getSubscriptionGenerator(repId, startDate, endDate);

    return new ListenerClient<PhoneTimeStore>(
      store, subscriptionGenerator
    )
  }

}
