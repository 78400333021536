import React from "react";
import { CollectionType, isDefaultGroup, isDefaultTeam } from "model/rep_collection";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, CircularProgress } from "@material-ui/core";
import { RepService } from "services/rep_service";

interface RenameRepCollectionDialogState {
    newRepCollectionName: string,
    loading: boolean
}

interface RenameRepCollectionDialogProps {
    onClose: () => void,
    collectionToRename?: string,
    collectionType: CollectionType
}


export default class RenameRepCollectionDialog extends React.Component<RenameRepCollectionDialogProps, RenameRepCollectionDialogState> {

    constructor(props: RenameRepCollectionDialogProps) {
        super(props);
        this.state = {newRepCollectionName: "", loading: false};
    }

    render() {
        
        return <Dialog open={this.props.collectionToRename !== undefined} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{this.state.loading ? "Renaming..." : "Rename " + (this.props.collectionType === CollectionType.GROUP ? "Group " : "Team ")}</DialogTitle>
      {this.state.loading ? <CircularProgress/> : <DialogContent>
        <DialogContentText>
          {"Enter a new name for " + this.props.collectionToRename!}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Name"
          fullWidth
          value={this.state.newRepCollectionName}
          onChange={(changeEvent) => this.setState({newRepCollectionName: changeEvent.target.value})}
        />
      </DialogContent>}
      {this.state.loading ? null : <DialogActions>
        <Button onClick={this.props.onClose} color="primary">
          Cancel
        </Button>
        <Button disabled={this.state.newRepCollectionName === "" || (this.props.collectionType === CollectionType.GROUP && isDefaultGroup(this.state.newRepCollectionName)) || (this.props.collectionType === CollectionType.TEAM && isDefaultTeam(this.state.newRepCollectionName))} onClick={() => {
          if (this.state.newRepCollectionName === "" || this.state.newRepCollectionName === this.props.collectionToRename!) {
              return;
          }
          this.setState({loading: true});
          if (this.props.collectionType === CollectionType.GROUP) {
            RepService.instance().renameGroup(this.props.collectionToRename!, this.state.newRepCollectionName!).then((_) => {
                this.setState({newRepCollectionName: "", loading: false})
                this.props.onClose();
            });
          } else {
            RepService.instance().renameTeam(this.props.collectionToRename!, this.state.newRepCollectionName!).then((_) => {
                this.setState({newRepCollectionName: "", loading: false})
                this.props.onClose();
            });
          }
        }} color="primary" variant="contained">
          Save Changes
        </Button>
      </DialogActions>}
    </Dialog>
    }
}