import GuardedListenerComponent from "../components/guarded_listener_component";
import RepStore from "../../services/rep_store";
import React from "react";
import QueueAppbar from "../components/queue_appbar";
import { TextField, CircularProgress, Grid, Button, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import "../common.css";
import Rep from "../../model/rep";
import { RepService } from "../../services/rep_service";
import { Redirect } from "react-router-dom";
import { isDefaultGroup } from "model/rep_collection";

interface CreateGroupPageState {
    groupName: string,
    shouldRedirectToReps: boolean,
    repsToAdd: Set<Rep>
}

export default class CreateGroupPage extends GuardedListenerComponent<{}, CreateGroupPageState> {
    
    constructor(props: {}) {
        super(props, true);

        this.state = {
            groupName: "",
            shouldRedirectToReps: false,
            repsToAdd: new Set<Rep>()
        };
    }

    getPathOnRefresh() {
        return "/create-group";
    }

    getEmployedListenerClients() {
        return [RepStore.getActiveRepsListenerClient()];
    }

    renderGuarded() {
        let repStore = RepStore.activeRepStore();
        if (this.state.shouldRedirectToReps) {
            return <Redirect to="/reps"/>
        }
        
        if (!repStore.isLoaded()) {
            return (
                <div>
                    <QueueAppbar shouldDisplayMenu={true}/>
                    <div className="centered">
                        <CircularProgress/>
                    </div>
                </div>
            );
        }
        let groupExists = repStore.hasGroup(this.state.groupName);
        let illegalName = this.state.groupName === "DEFAULT";
        let hasError: boolean = groupExists || illegalName || isDefaultGroup(this.state.groupName);
        let errorText;
        if (groupExists) {
            errorText = "Group Already Exists";
        } else if (illegalName) {
            errorText = "Cannot name a group 'DEFAULT'";
        } else if (isDefaultGroup(this.state.groupName)) {
            errorText = "This is already a default group in Queue Sales Team";
        }
        let allReps = repStore.getReps();
        allReps = allReps.sort((a: Rep, b: Rep) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()));
        return (
            <div>
            <QueueAppbar shouldDisplayMenu={true}/>
            <br/>
            <br/>
            <div className="Padding-Left">
                <Grid container direction='row' alignItems='center' spacing={2}>
                    <Grid item>
                        <h1>New Group</h1>
                    </Grid>
                    <Grid item>
                        <TextField 
                            value={this.state.groupName} 
                            variant="outlined"
                            onChange={(event: any) => this.setState({groupName: event.target.value})} 
                            error={hasError} 
                            placeholder="New Group Name" 
                            label={hasError ? errorText : ""}
                        />
                    </Grid>
                    <Grid item>
                        <Button disabled={hasError || (this.state.groupName.length < 1)} color="primary" variant="contained" 
                            onClick={() => {
                                this.state.repsToAdd.forEach((rep: Rep) => {
                                    rep.group = this.state.groupName;
                                });
                                RepService.instance().updateReps(this.state.repsToAdd);
                                this.setState({shouldRedirectToReps: true});
                            }}>Finish</Button>
                    </Grid>
                </Grid>
            </div>
            <Table stickyHeader>
                <TableHead >
                    <TableRow>
                        <TableCell>Rep</TableCell>
                        <TableCell>Current Group</TableCell>
                        <TableCell>
                            Add to {this.state.groupName === "" ? "New Group" : this.state.groupName}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allReps.map((rep: Rep) => {
                        return (
                            <TableRow key={rep.repID}>
                                <TableCell>
                                    {rep.firstName + " " + rep.lastName}
                                </TableCell>
                                <TableCell>
                                    {rep.group}
                                </TableCell>
                                <TableCell>
                                    <Button 
                                        variant={this.state.repsToAdd.has(rep) ? "outlined" : "contained"} 
                                        disabled={hasError || (this.state.groupName.length < 1)}
                                        onClick={() => {
                                            let repsToAddCopy = new Set(this.state.repsToAdd);
                                            if (this.state.repsToAdd.has(rep)) {
                                                repsToAddCopy.delete(rep);
                                            } else {
                                                repsToAddCopy.add(rep);
                                            }
                                            this.setState({repsToAdd: repsToAddCopy});
                                        }}
                                        >
                                        {this.state.repsToAdd.has(rep) ? "Remove" : "Add"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            </div>
        );
    }
}
