import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LaunchPage from './ui/pages/launch_page';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import SignUpPage from './ui/pages/signup_page';
import LandingPage from './ui/pages/landing_page';
import LoginPage from './ui/pages/login_page';
import TutorialPage from './ui/pages/tutorial_page';
import LeaderboardPage from './ui/pages/leaderboard_page';

import {
  Route,
  HashRouter,
  Redirect
} from 'react-router-dom';
import RepsPage from './ui/pages/reps_page';
import AttendancePage from './ui/pages/attendance_page';
import PlanSelectionPageWrapper from './ui/pages/plan_selection_page_wrapper';
import CreateGroupPage from './ui/pages/create_group_page';
import CreateTeamPage from './ui/pages/create_team_page';
import InactiveRepsPage from './ui/pages/inactive_reps_page';
import MessagePage from './ui/pages/message_page';
import DemosPage from './ui/pages/demos_page';
import TransferRequestPage from 'ui/pages/transfer_request_page';
import MessagePageDelegator from 'ui/components/message_page_delegator';
import SettingsPage from 'ui/pages/settings_page';
import ChangeTeamsPage from 'ui/pages/change_teams_page';
import AnalyitcsEmbedPage from 'ui/pages/analytics_embed';

export const DEFAULT_FONT_SIZE = 10;

export const CURRENT_APPLICATION_VERSION_NUMBER = 1;

export const CURRENT_APPLICATION_VERSION_STRING = "1.0.0";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#336D99',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: "#d32f2f"
    }
  },
  typography: {
    fontSize: DEFAULT_FONT_SIZE,
  },
  overrides:{
    MuiTableRow: {
        root: { //for the body
            padding: "2px 2px"
        },
    }
}
  
});

function Home() {
  return <Redirect to="/reps"/>
}

function Main() {
  //commitCorrections();

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <React.Fragment>
        <div style={{height: '100vh'}}>
          <Route exact path="/" component={Home}/>
          <Route path="/welcome" component={LandingPage}/>
          <Route path="/launch" component={LaunchPage}/>
          <Route path="/signup" component={SignUpPage}/>
          <Route path="/login" component={LoginPage}/>
          <Route path="/tutorial" component={TutorialPage}/>
          <Route path="/leaderboard" component={LeaderboardPage}/>
          <Route path="/reps" component={RepsPage}/>
          <Route path="/plans" component={PlanSelectionPageWrapper}/>
          <Route path="/attendance" component={AttendancePage}/>
          <Route path="/create-group" component={CreateGroupPage}/>
          <Route path="/create-team" component={CreateTeamPage}/>
          <Route path="/inactive-reps" component={InactiveRepsPage}/>
          <Route path="/messages" component={MessagePageDelegator}/>
          <Route path="/demos" component={DemosPage}/>
          <Route path="/transfer-requests" component={TransferRequestPage}/>
          <Route path='/settings' component={SettingsPage}/>
          <Route path="/team-select" component={ChangeTeamsPage}/>
          <Route path="/analytics" component={AnalyitcsEmbedPage}/>
        </div>
        </React.Fragment>
      </HashRouter>
    </ThemeProvider>
  );
}


ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
