import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import { OnStreamUpdate, SubscriptionGenerator, Unsubscriber } from "./subscription_generator";
import { DatabaseDocument } from "./persisted_object";
import ActionResult from "../model/action_result";
import ActionResultVoid from "model/action_result_void";

export default class TeamPersistence {
    

    private constructor() {}

    private static sharedInstance: TeamPersistence = new TeamPersistence();

    public static instance() {
        return this.sharedInstance;
    }

    public async updateTeam(teamDoc: DatabaseDocument): Promise<ActionResultVoid> {
        return firebaseApp.firestore().collection("Teams").doc(teamDoc.id).update(teamDoc.data)
        .then(() => {
            return new ActionResultVoid(true,);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public async getTeam(teamID: string) : Promise<ActionResult<DatabaseDocument>> {
        return firebaseApp.firestore().collection("Teams").doc(teamID).get().then((doc: firebase.firestore.DocumentSnapshot) => {
            return new ActionResult<DatabaseDocument>(true, undefined, {
                id: doc.id,
                data: doc.data()!
            });
        }, (error) => {
            return new ActionResult<DatabaseDocument>(false, error.toString());
        });
    }

    public confirmOleanOfficeNumber(teamID: string, officeNumber: string) : Promise<ActionResultVoid> {
        return firebaseApp.firestore().collection("Teams").doc(teamID).update({
            "vectorOfficeNumber": officeNumber,
            "vectorOfficeNumberConfirmed": true
        }).then((_) => {
            return new ActionResultVoid(true)
        }, (error) => {
            return new ActionResultVoid(false, error.toString());
        });
    }



    public getTeamSubscriptionGenerator(teamID: string): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {
            return {
                unsubscribe: firebaseApp.firestore().collection("Teams").doc(teamID)
                    .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
                        onStreamUpdate([{
                            data: snapshot.data()!,
                            id: snapshot.id
                        }]);
                    }, (error) => {
                        onStreamUpdate([]);
                    }),
            }
        };
    }
}