import OfficeEvent from "../office_event";
import Converter from './converter';
import { DatabaseDocument, DocumentData } from "../../persistence/persisted_object";
import { AttendanceStatus, fromString } from "../attendance_status";

function toAttendanceStatusMap(data: DocumentData) : Map<string, AttendanceStatus> {
    let entries = Object.entries(data);
    let map = new Map();
    for (let entry of entries) {
        map.set(entry[0], fromString(entry[1]));
    }
    return map;
}

function toNotesMap (data: DocumentData) : Map <string, string> {
    let entries = Object.entries(data); 
    let map = new Map(); 
    for (let entry of entries) {
        map.set(entry[0], entry[1]); 
    }
    return map; 
}

export default class OfficeEventConverter extends Converter<OfficeEvent> {

    fromPersistence(doc: DatabaseDocument): OfficeEvent {
        let data = doc.data;
        let id = doc.id;
        let resultOfficeEvent: OfficeEvent = {
            currentlyActive: data["currentlyActive"],
            eventType: data["eventType"],
            title: data["title"],
            attendanceStatuses: toAttendanceStatusMap(data["attendanceStatuses"]),
            startTimestamp: data["startTimeStamp"].toDate(),
            specificReccurrence: data["specificRecurrence"],
            weeklyReccurrences: data["weeklyRecurrences"],
            recurrenceID: data["recurrenceID"], 
            id: id,
            teamID: data["teamID"],
            customName: data["customName"], 
            notes: data["notes"]===undefined ? new Map<string, string>() : toNotesMap(data["notes"]), 
        };
        return resultOfficeEvent;
    } 

    toPersistence(officeEvent: OfficeEvent): DatabaseDocument {
        let data: DocumentData = { 
            "specificRecurrence": officeEvent.specificReccurrence,
            "weeklyRecurrences": officeEvent.weeklyReccurrences,
            "recurrenceID": officeEvent.recurrenceID,
            "currentlyActive": officeEvent.currentlyActive,
            "eventType": officeEvent.eventType,
            "title": officeEvent.title,
            "attendanceStatuses": super.toDocumentData(officeEvent.attendanceStatuses),
            "startTimeStamp": officeEvent.startTimestamp,
            "teamID": officeEvent.teamID,
            "customName": officeEvent.customName, 
            "notes": super.toDocumentData(officeEvent.notes), 
        };
         let resultDatabaseDocument: DatabaseDocument = {
             id: officeEvent.id, 
             data: data,
         };
         return resultDatabaseDocument;
    }
    
    
}