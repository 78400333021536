import Rep, { sortByFirstName } from '../model/rep';
import RepConverter from '../model/converters/rep_converter';
import ListenerClient from './listener_client';
import RepPersistence from '../persistence/rep_persistence';
import { AuthenticationService } from './authentication_service';
import RepCollection, { CollectionType, repCollectionSort, isDefaultGroup, isDefaultTeam } from 'model/rep_collection';
import AbstractRepStore from './abstract_rep_store';

export default class RepStore extends AbstractRepStore {
    private constructor() {
        super(new RepConverter());
        this.getRep = this.getRep.bind(this);
        this.getReps = this.getReps.bind(this);
    }
    
    private static sharedActiveRepStoreInstance: RepStore = new RepStore();
    private static sharedInactiveRepStoreInstance: RepStore = new RepStore();

    public static activeRepStore(): RepStore {
        return RepStore.sharedActiveRepStoreInstance;
    }

    public static inactiveRepStore(): RepStore {
        return RepStore.sharedInactiveRepStoreInstance;
    }

    private repsByID?: Map<string, Rep>;
    private repsByGroup?: Map<string, Rep[]>;
    private repsByTeam?: Map<string, Rep[]>;

    public isLoaded(): boolean {
        return this.repsByID !== undefined && this.repsByGroup !== undefined && this.repsByTeam !== undefined;
    }

    public clearCache(): void {
        this.repsByID = undefined;
        this.repsByGroup = undefined;
        this.repsByTeam = undefined;
    }

    public updateCache (reps: Rep[]): void {
        //init cache variables
        this.repsByID = new Map<string, Rep>();
        this.repsByGroup = new Map<string, Rep[]>();
        this.repsByTeam = new Map<string, Rep[]>();

        //iterate through incoming data and handle setting up each variable
        for (let rep of reps) {
            //repsByID
            this.repsByID.set(rep.repID, rep);

            //groups
            if (!this.repsByGroup!.has(rep.group)) {
                this.repsByGroup!.set(rep.group, [rep]);
            } else {
                this.repsByGroup!.get(rep.group)!.push(rep);
            }

            //teams
            if (!this.repsByTeam!.has(rep.team)) {
                this.repsByTeam!.set(rep.team, [rep]);
            } else {
                this.repsByTeam!.get(rep.team)!.push(rep);
            }
        }
        this.repsByGroup!.forEach((reps, _, __) => {
            reps.sort(sortByFirstName);
        });
        this.repsByTeam!.forEach((reps, _, __) => {
            reps.sort(sortByFirstName);
        });

    }

    public hasGroup(group: string) {
        return new Set(Array.from(this.repsByGroup!.keys())).has(group) || isDefaultGroup(group);
    }

    public hasTeam(team: string) {
        return new Set(Array.from(this.repsByTeam!.keys())).has(team) || isDefaultTeam(team);
    }

    public getRepGroups(stripDefaultGroups: boolean = true): (string | null)[] {
        let groups : Array<string | null>;
        if (stripDefaultGroups) {
            groups = Array.from(this.repsByGroup!.keys()).filter((group: string) => !isDefaultGroup(group));
            groups.push(null);
        } else {
            groups =  Array.from(this.repsByGroup!.keys());
        }
        return groups;
    }

    public getRepTeams(stripDefaultTeams: boolean = true) : (string | null)[] {
        let teams: Array<string | null>;
        if (stripDefaultTeams) {
            teams = Array.from(this.repsByTeam!.keys()).filter((team: string) => !isDefaultTeam(team));
            teams.push(null);
        } else {
            teams = Array.from(this.repsByTeam!.keys());
        }
        return teams;
    }

    public hasRep(repID: string): boolean {
        return this.repsByID!.has(repID); 
    }

    //returns list of rep collections with reps sorted alphabetically
    public getRepCollections(collectionType: CollectionType, sortCollections: boolean): RepCollection[] {
        let repCollections: RepCollection[] = [];
        if (collectionType == CollectionType.GROUP) {
            this.repsByGroup!.forEach((reps, group, _) => {
                repCollections.push(new RepCollection(CollectionType.GROUP, group, reps, false));
            });
        } else {
            this.repsByTeam!.forEach((reps, team, _) => {
                repCollections.push(new RepCollection(CollectionType.TEAM, team, reps, false));
            });
        }
        if (sortCollections) {
            repCollections.sort(repCollectionSort);
        }
        return repCollections;
    }

    public isInGroup(repID: string, group: string): boolean {
        if (!this.repsByID!.has(repID)) {
            return false;
        } else {
            return group === this.repsByID!.get(repID)!.group;
        }
    }

    public isInTeam(repID: string, team: string): boolean {
        if (!this.repsByID!.has(repID)) {
            return false;
        } else {
            return team === this.repsByID!.get(repID)!.team;
        }
    }

    public getRepsInTeam(team: string): Rep[] {
        if (!(this.repsByTeam!.has(team))) {
            return [];
        } else {
            return this.repsByTeam!.get(team)!;
        }
     }

    public getRepsInGroup(group: string): Rep[] {
       if (!(this.repsByGroup!.has(group))) {
           return [];
       } else {
           return this.repsByGroup!.get(group)!;
       }
    }

    public getRep(repID: string): Rep {
        return this.repsByID!.get(repID)!;
    }

    public getReps(): Rep[] {
        return Array.from(this.repsByID!.values());
    }

    public static getActiveRepsListenerClient(): ListenerClient<RepStore> {
        return new ListenerClient<RepStore>(
            RepStore.activeRepStore(),
            RepPersistence.instance().getRepSubscriptionGenerator(AuthenticationService.instance().getCurrentUserOfficeNumber(), true)
        );
    }

    public static getInactiveRepsListenerClient(): ListenerClient<RepStore> {
        return new ListenerClient<RepStore>(
            RepStore.inactiveRepStore(),
            RepPersistence.instance().getRepSubscriptionGenerator(AuthenticationService.instance().getCurrentUserOfficeNumber(), false)
        );
    }
}
