import ActionResult from "model/action_result";
import { firebaseApp } from "./firebase_app";

export interface OfficeInfo {
    name: string,
    id: string,
    vectorOfficeNumber: string,
    manager: string
}

export default class DivisionalPersistence {
    private constructor() {}

    public static instance: DivisionalPersistence = new DivisionalPersistence();

    public async getAllOfficesInDivision(vectorOfficeNumber: string): Promise<ActionResult<OfficeInfo[]>> {
        if (vectorOfficeNumber.length !== 4) {
            return new ActionResult(false, "Your office number is incorrect, please update it and try again");
        }
        let allOfficeNumbers: OfficeInfo[];
        let divisionCode: string = vectorOfficeNumber.slice(0, 2);
        try {
         allOfficeNumbers = (await firebaseApp.firestore().collection("Teams").orderBy("vectorOfficeNumber").get()).docs.filter((doc) => doc.data()!["queue"] !== undefined && doc.data()!["queue"] !== null && doc.data()!["queue"]["subscribed"]).map((doc) => {
             return {
                 name: doc.data()!["name"],
                 id: doc.id,
                 vectorOfficeNumber: doc.data()!["vectorOfficeNumber"],
                 manager: doc.data()!["manager"]
             };
         });

        return new ActionResult(true, undefined, allOfficeNumbers.filter((office) => office.vectorOfficeNumber.length === 4 && office.vectorOfficeNumber.slice(0, 2) === divisionCode));
        } catch (e) {
            console.log(e);
            return new ActionResult(false, e.toString());
        }
    }
}