import Chat from "../chat";
import Converter from './converter';
import { DatabaseDocument, DocumentData } from "../../persistence/persisted_object";

export default class ChatConverter extends Converter<Chat> {
    fromPersistence(doc: DatabaseDocument): Chat {
        let data = doc.data;
        let id = doc.id;
        if (super.isUndefinedOrNull(data["lastMessageText"])) {
            data["lastMessageText"] = null;
        }
        if (super.isUndefinedOrNull(data["lastMessageDate"])) {
            data["lastMessageDate"] = null;
        }
        if (super.isUndefinedOrNull(data["lastMessageSender"])) {
            data["lastMessageSender"] = null;
        }
        let resultChat: Chat = {
            id: id, 
            teamid: data["teamID"],
            name: data["name"] ?? null,
            lastOpenedDates: this.toMap(data["lastOpenedDates"]),
            members: data["members"],
            removedMembers: this.toMap(data["removedMembers"]) ?? this.toMap(new Map()),
            lastMessageText: data["lastMessageText"],
            lastMessageDate: data["lastMessageDate"]?.toDate(),
            lastMessageSender: data["lastMessageSender"],
            includeManagers: data["includeManagers"],
        };
        return resultChat;
    } 
 
    toPersistence(chat: Chat): DatabaseDocument {
        let lastOpenedDates: DocumentData = {};
        chat.lastOpenedDates.forEach((value, key) => {
            lastOpenedDates[key] = value;
        });
        let removedMembers: DocumentData = {}; 
        chat.removedMembers.forEach((value, key) => {
            removedMembers[key] = value; 
        });
        let data: DocumentData = { 
            "id": chat.id,
            "teamID": chat.teamid,
            "name": chat.name,
            "lastOpenedDates": lastOpenedDates,
            "members": chat.members,
            "removedMembers": removedMembers,
            "includeManagers": chat.includeManagers, 
        };
        if (chat.lastMessageDate !== null) {
            data["lastMessageDate"] = chat.lastMessageDate;
        }
        if (chat.lastMessageSender !== null) {
            data["lastMessageSender"] = chat.lastMessageSender;
        }
        if (chat.lastMessageText !== null) {
            data["lastMessageText"] = chat.lastMessageText;
        }
         let resultDatabaseDocument: DatabaseDocument = {
             id: chat.id, 
             data: data,
         };
         return resultDatabaseDocument;
    }

    toMap(data: DocumentData) : Map<string, Date> {
        let entries = Object.entries(data);
        let map = new Map();
        for (let entry of entries) {
            map.set(entry[0], (entry[1]).toDate());
        }
        return map; 
    }
}