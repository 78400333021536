import logo from '../assets/small logo.png';
import React from 'react';

import 'ui/css/common_other.css';

export default function QueueLogo (){
    return (
      <img src={logo} className={"Logo-Image"} alt="Queue Logo">
      </img>
    )
  }
