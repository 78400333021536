import PhoneTime from 'model/phone_time';
import Converter from './converter';
import { DatabaseDocument, DocumentData } from "../../persistence/persisted_object";

export default class PhoneTimeConverter extends Converter<PhoneTime> {

  fromPersistence(doc: DatabaseDocument): PhoneTime {
    let data = doc.data;
    let id = doc.id;
    let resultPhoneTime: PhoneTime = {
      creationTimeStamp: data["creationTimeStamp"].toDate(),
      eventType: data["eventType"],
      lastCallTimeStamp: data["lastCallTimeStamp"],
      timeSpentOnPhoneInMilliseconds: data["timeSpentOnPhoneInMilliseconds"],
      totalBooked: data["totalBooked"],
      totalCallBacks: data["totalCallBacks"],
      totalDeclines: data["totalDeclines"],
      totalDurationInMilliseconds: data["totalDurationInMilliseconds"],
      totalTextsSent: data["totalTextsSent"],
      totalVoicemails: data["totalVoicemails"],
      id: id,
      totalCallsMade: data["totalCallsMade"],
    };
    return resultPhoneTime;
  }

  toPersistence(phoneTime: PhoneTime): DatabaseDocument {
    let data: DocumentData = {
      "creationTimeStamp": phoneTime.creationTimeStamp,
      "eventType": phoneTime.eventType,
      "lastCallTimeStamp": phoneTime.lastCallTimeStamp,
      "timeSpentOnPhoneInMilliseconds": phoneTime.timeSpentOnPhoneInMilliseconds,
      "totalBooked": phoneTime.totalBooked,
      "totalCallBacks": phoneTime.totalCallBacks,
      "totalDeclines": phoneTime.totalDeclines,
      "totalDurationInMilliseconds": phoneTime.totalDurationInMilliseconds,
      "totalTextsSent": phoneTime.totalTextsSent,
      "totalVoicemails": phoneTime.totalVoicemails,
      "totalCallsMade": phoneTime.totalCallsMade,
    };
    let resultDatabaseDocument: DatabaseDocument = {
      id: phoneTime.id,
      data: data,
    }
    return resultDatabaseDocument;
  }


}
