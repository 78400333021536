import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import { OnStreamUpdate, SubscriptionGenerator, Unsubscriber } from "./subscription_generator";
import { toQueueDatatbaseDocList } from "./persistence_utls";
import { DatabaseDocument } from "./persisted_object";
import ActionResultVoid from "../model/action_result_void";

export default class PdiPersistence {
    private constructor() {}

    private static sharedInstance: PdiPersistence = new PdiPersistence();

    public static instance() {
        return this.sharedInstance;
    }

    public getPdiSubscriptionGenerator(weekID: string, teamID: string): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {
            return {
                unsubscribe: firebaseApp.firestore().collection("Goals").doc(weekID).collection("UserGoals")
                    .where("officeNumber", "==", teamID)
                    .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                        onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                    }, (error) => {
                        onStreamUpdate([]);
                    }),
            }
        };
    }

    public updateGoals(weekID: string, doc: DatabaseDocument): Promise<ActionResultVoid> {
        
        return firebaseApp.firestore().collection("Goals").doc(weekID).collection("UserGoals").doc(doc.id).set(doc.data, {merge: true}).then(() => {
            return new ActionResultVoid(true);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public setGoals(weekID: string, doc: DatabaseDocument): Promise<ActionResultVoid> {
       
        return firebaseApp.firestore().collection("Goals").doc(weekID).collection("UserGoals").doc(doc.id).set(doc.data, {merge: true}).then(() => {
            return new ActionResultVoid(true);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }
}