import React from "react";
import Team from "../../model/team";
import TeamStore from "../../services/team_store";
import ListenerClient from "../../services/listener_client";
import { Dialog, DialogTitle, DialogContentText, DialogActions, Button, DialogContent } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import '../common.css';
import { AuthenticationService } from "../../services/authentication_service";
import OleanOfficeNumberConfirmationDialog from "./olean_office_number_confirmation_dialog";

interface PaymentGuardComponentState {
    shouldRedirectToPlans: boolean,
    shouldRedirectToLogin: boolean,
    visible: boolean,
    paymentNoticeVisible: boolean
}

//February 1st, 2020
const REQUIRE_PAYMENT_DATE = new Date(1580533200000);

export default class PaymentGuardComponent extends React.Component<{}, PaymentGuardComponentState> {
    private client?: ListenerClient<TeamStore>;

    constructor(props: {}) {
        super(props);
        this.state = {
            shouldRedirectToPlans: false,
            shouldRedirectToLogin: false,
            visible: true,
            paymentNoticeVisible: true
        };
        this.redirectToPlans = this.redirectToPlans.bind(this);
    }

    componentDidMount() {
        this.client = TeamStore.getTeamListenerClient();
        this.client.updateSubscriptionGenerator();
        this.client!.getStore().getListener().beginListening(()=>{
            this.setState({});
        }, this.client!);
    }

    componentWillUnmount() {
        this.client!.getStore().getListener().terminateSubscription(this.client!);
    }

    redirectToPlans() {
        this.setState({
            shouldRedirectToPlans: true
        });
    }

    render() {
        if (this.state.shouldRedirectToPlans) {
            return (<Redirect to="/plans"/>);
        } else if (this.state.shouldRedirectToLogin) {
            return (<Redirect to="/login"/>);
        }
        let teamStore: TeamStore = TeamStore.instance();
        
        if (!teamStore.isLoaded()) {
            return null;
        } else {
            //if the team does not have a plan, we make them get one
            let team: Team = teamStore.getTeam();
            if (!team.hasQueueOfficeSubscription) {
                let now = Date.now();
                let requirePaymentDateMillis = REQUIRE_PAYMENT_DATE.getTime();
                if (now < requirePaymentDateMillis) {
                    return <Dialog open={this.state.visible}>
                        <DialogTitle>Queue's Universal Free Trial Ends February 1st</DialogTitle>
                        <DialogContent>
                        <DialogContentText >
                            This is an exciting time for Vector and for Queue!
                            The Queue Universal Free Trial is expiring on February 1st, but you will still get one more month free once you've selected a plan.
                            Once the  is up, the price will be the same as what you're used to paying with WebPDI, and you'll have all of its features and more!
                            <br/>
                            <br/>
                            <strong>Select a plan now to stop seeing this message.</strong>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({
                                    visible: false
                                });
                            }} color="primary">
                                Dismiss
                            </Button>
                            <Button onClick={this.redirectToPlans} color="primary">
                                See Plans
                            </Button>
                        </DialogActions>
                    </Dialog>
                } else {
                    return <Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true}>
                        <DialogTitle>Select an Office Plan to Continue Using Queue</DialogTitle>
                        <DialogContent>
                        <DialogContentText >
                            Boost your reps' productivity! 
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                AuthenticationService.instance().logout().then((_) => {
                                    this.setState({
                                        shouldRedirectToLogin: true
                                    });
                                });
                                
                            }} color="primary">
                                Log Out
                            </Button>
                            <Button onClick={this.redirectToPlans} color="primary" variant="outlined">
                                See Plans
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            } else if (team.delinquent) {
                return <Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true}>
                        <DialogTitle>Please Update Your Billing Information to Continue Using Queue</DialogTitle>
                        <DialogContent>
                        <DialogContentText >
                            Payment has failed multiple times on this account and we have made multiple attempts to reach you. 
                            Please check the email on your account to find an invoice for Queue Sales Team where you can update your billing information and pay your balance. 
                            Once you've paid your balance please email queue.app.team@gmail.com to regain access.
                            If you have any questions, please email queue.app.team@gmail.com and we will respond promptly. 
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            
                            <Button onClick={() => {
                                AuthenticationService.instance().logout().then((_) => {
                                    this.setState({
                                        shouldRedirectToLogin: true
                                    });
                                });
                                
                            }} color="primary">
                                Log Out
                            </Button>
                        </DialogActions>
                    </Dialog>
            } else if (team.overdue) {
                return <Dialog open={this.state.paymentNoticeVisible} disableBackdropClick={true} disableEscapeKeyDown={true}>
                        <DialogTitle>Please Update Your Billing Information to Continue Using Queue</DialogTitle>
                        <DialogContent>
                        <DialogContentText >
                        The credit card charge for your Queue subscription has failed. Please contact us at queue.app.team@gmail.com if you would like to update your billing information, or contact your bank to allow the charge. In order to retain access to Queue, please resolve this issue.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={() => {
                                this.setState({
                                    paymentNoticeVisible: false
                                });
                            }} color="primary">
                                Dismiss
                            </Button>
                            <Button onClick={() => {
                                AuthenticationService.instance().logout().then((_) => {
                                    this.setState({
                                        shouldRedirectToLogin: true
                                    });
                                });
                                
                            }} color="primary">
                                Log Out
                            </Button>
                        </DialogActions>
                    </Dialog>
            } else if (!team.officeNumberConfirmed) {
                return <OleanOfficeNumberConfirmationDialog team={team}/>;
            } else {
                return null;
            }
        }

    }
}