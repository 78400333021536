import Converter from "./converter";
import Rep from '../rep';
import { DatabaseDocument, DocumentData } from "../../persistence/persisted_object";
import { StarterKitStatus } from "../starter_kit_status";
import { DEFAULT_TEAM, LAUNCH_GROUP, FAST_START_GROUP, POST_FAST_START_GROUP, isDefaultTeam, isDefaultGroup } from "model/rep_collection";

export default class RepConverter extends Converter<Rep> {

    getGroupForStartDate(startDate: Date): string {
        const now = new Date();
        const oneDayInMilliseconds: number = 86400000;
        const daysForLaunch:number = 6;
        const daysForFastStart: number = 11;
        const diff = now.getTime() - startDate.getTime();
        if (diff < daysForLaunch * oneDayInMilliseconds) {
            return LAUNCH_GROUP;
        } else if (diff < daysForFastStart * oneDayInMilliseconds) {
            return FAST_START_GROUP;
        } else {
            
            return POST_FAST_START_GROUP;
        }
    }

    fromPersistence(doc: DatabaseDocument): Rep {
        let data: DocumentData = doc.data;

        if (super.isUndefinedOrNull(data["manager"])){
            data["manager"] = false;
        }
        if (super.isUndefinedOrNull(data["notes"])){
            data["notes"] = "";
        }
        if (super.isUndefinedOrNull(data["currentBrainstormNames"])) {
            data["currentBrainstormNames"] = 0;
        }

        if (super.isUndefinedOrNull(data["allTimeBrainstormNames"])) {
            data["allTimeBrainstormNames"] = 0;
        }

        if (super.isUndefinedOrNull(data["starterKitStatus"])) {
            data["starterKitStatus"] = StarterKitStatus.NOTRETURNED;
        }

        if (super.isUndefinedOrNull(data["contactCount"])) {
            data["contactCount"] = 0;
        }

        let lastSignIn: Date;
        if (super.isUndefinedOrNull(data["lastSignIn"])) {
            lastSignIn = new Date();
        } else {
            lastSignIn = data["lastSignIn"].toDate();
        }

        return {
            repID: doc.id,
            isActive: data["active"],
            markedInactiveTimeStamp: data["markedInactiveTimeStamp"]?.toDate() ?? null,
            contactCount: data["contactCount"],
            firstName: data["firstName"],
            lastName: data["lastName"],
            novice: data["novice"],
            phoneNumber: data["phoneNumber"],
            registrationTimeStamp: data["registrationTimeStamp"]?.toDate() ?? new Date().setFullYear(2019),
            totalCallsMade: data["totalCallsMade"],
            totalCallBacks: data["totalCallBacks"],
            totalAppointmentsBooked: data["totalDemosBooked"],
            totalDeclines: data["totalDeclines"],
            currentBrainstormListSize: data["currentBrainstormNames"],
            allTimeBrainstormListSize: data["allTimeBrainstormNames"],
            isManager: data["manager"],
            officeNumber: data["officeNumber"],
            lastSignOn: lastSignIn,
            teamName: data["officeName"],
            notes: data["notes"],
            phoningGrade: data["phoningGrade"] ?? null,
            recommendationsGrade: data["recommendationsGrade"] ?? null,
            closingGrade: data["closingGrade"] ?? null,
            group: data["group"] ?? this.getGroupForStartDate(data["registrationTimeStamp"]?.toDate() ?? new Date().setFullYear(2019)), 
            starterKitStatus: data["starterKitStatus"], 
            team: data["team"] ?? DEFAULT_TEAM,
            salesTeamWebsiteVersionNumber: data["salesTeamWebsiteVersionNumber"] ?? null,
            salesTeamWebsiteVersionString: data["salesTeamWebsiteVersionString"] ?? null
        };
    }

    toPersistence(rep: Rep) : DatabaseDocument{
        let data: DocumentData = {
            "active": rep.isActive,
            "markedInactiveTimeStamp": rep.markedInactiveTimeStamp,
            "contactCount": rep.contactCount,
            "firstName": rep.firstName, 
            "lastName": rep.lastName,
            "novice": rep.novice,
            "phoneNumber": rep.phoneNumber,
            "registrationTimeStamp": rep.registrationTimeStamp,
            "totalCallsMade": rep.totalCallsMade,
            "totalCallBacks": rep.totalCallBacks,
            "totalDemosBooked": rep.totalAppointmentsBooked,
            "totalDeclines": rep.totalDeclines,
            "currentBrainstormNames": rep.currentBrainstormListSize,
            "allTimeBrainstormNames": rep.allTimeBrainstormListSize,
            "manager": rep.isManager,
            "officeNumber": rep.officeNumber,
            "lastSignIn": rep.lastSignOn,
            "officeName": rep.teamName,
            "notes": rep.notes,
            "phoningGrade": rep.phoningGrade,
            "recommendationsGrade": rep.recommendationsGrade,
            "closingGrade": rep.closingGrade,
            "group": isDefaultGroup(rep.group) || rep.group === "" ? null : rep.group,
            "team": isDefaultTeam(rep.team) || rep.team === "" ? null : rep.team,
            "starterKitStatus": rep.starterKitStatus,
            "salesTeamWebsiteVersionNumber": rep.salesTeamWebsiteVersionNumber,
            "salesTeamWebsiteVersionString": rep.salesTeamWebsiteVersionString
        }
        let resultDatabaseDocument: DatabaseDocument = {
            id: rep.repID,
            data: data, 
        };
        
        return resultDatabaseDocument;
    }
}