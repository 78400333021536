import PhoneJam from '../model/phone_jam';
import Store from './store';
import PhoneJamConverter from '../model/converters/phone_jam_converter';
import ListenerClient from './listener_client';
import PhoneJamPersistence from '../persistence/phone_jam_persistence';
import { AuthenticationService } from './authentication_service';

export abstract class StoreOfPhoneJams extends Store<PhoneJam> {
    abstract getCurrentPhoneJams(): PhoneJam[];
}

export default class PhoneJamStore extends StoreOfPhoneJams {
    private constructor() {
        super(new PhoneJamConverter());
        this.getCurrentPhoneJams = this.getCurrentPhoneJams.bind(this);
    }
    private static sharedPhoneJamStoreInstance: PhoneJamStore = new PhoneJamStore();

    public static phoneJamStore(): PhoneJamStore {
        return PhoneJamStore.sharedPhoneJamStoreInstance;
    }


    private phoneJamCache?: PhoneJam[];

    public isLoaded(): boolean {
        return this.phoneJamCache !== undefined;
    }

    public updateCache (phoneJams: PhoneJam[]): void {
        this.phoneJamCache = phoneJams;
    }

    public getCurrentPhoneJams(): PhoneJam[] {
        const now: Date = new Date();
        return this.phoneJamCache!.filter((value: PhoneJam) => {
            return (now.getTime() - value.lastCallTimeStamp.getTime()) <= 10800000; //3 hours in milliseconds
        });
    }

    public clearCache(): void {
        this.phoneJamCache = undefined;
    }

    public getPhoneJamListenerClient(): ListenerClient<PhoneJamStore> {
        return new ListenerClient<PhoneJamStore>(
            PhoneJamStore.phoneJamStore(), 
            PhoneJamPersistence.instance().getPhoneJamSubscriptionGenerator(AuthenticationService.instance().getCurrentUserOfficeNumber())
        );
    }
}