// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDCCQhUMZsB5xKUPkVkMCw9RRenewSxe8Q",
    authDomain: "queue-ignite.firebaseapp.com",
    databaseURL: "https://queue-ignite.firebaseio.com",
    projectId: "queue-ignite",
    storageBucket: "queue-ignite.appspot.com",
    messagingSenderId: "457111089150",
    appId: "1:457111089150:web:e8139d64ca4c3943b79aaf"
  };

export const firebaseApp = firebase.initializeApp(firebaseConfig);