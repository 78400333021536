export default class DateUtils {

    public static getWeekIDForOffset(offset: number) {
        return this.getWeekIDOfDate(this.addDays(new Date(), offset * 7));
    }

    public static getFirstInstantOfVectorWeekForOffset(offset: number) {
        let now = new Date();
        let dateInTargetWeek = this.addDays(now, offset * 7);
        return this.getFirstInstantOfVectorWeekForDate(dateInTargetWeek);
    }

    public static getFirstInstantOfVectorWeekForWeekID(weekID: string) {
        console.log("get first instant for weekID: " + weekID);
        let components = weekID.split("-");
        let date = new Date();
        date.setMilliseconds(0);
        date.setSeconds(0);
        date.setMinutes(0);
        date.setHours(0);
        date.setDate(1);
        date.setMonth(0);
        date.setFullYear(parseInt(components[0]));
    
        date.setMonth(parseInt(components[1]) - 1);
        date.setDate(parseInt(components[2]));
        return date;
    }

    public static getWeekIDOfDate(date: Date) {
        let firstMidnightOfWeek: Date = this.getFirstInstantOfVectorWeekForDate(date);
        return firstMidnightOfWeek.getFullYear() + "-" + (firstMidnightOfWeek.getMonth() + 1) + "-" + firstMidnightOfWeek.getDate();
    }

    public static getFirstInstantOfVectorWeekForDate(date: Date) {
        console.log("inside getFirstInstantOfVectorWeekForDate");
        console.log(date);
        let vectorDay: number = this.toVectorDay(date.getDay());
        console.log("vector day");
        console.log(vectorDay);
        let lastMidnight: Date = this.toLastMidnight(date);
        console.log(lastMidnight);
        let lastMidnightMillis: number = lastMidnight.getTime();
        console.log( new Date(lastMidnightMillis - (vectorDay * 86400000)));
        let newDate =  new Date(lastMidnightMillis - (vectorDay * 86400000)); 
        if (newDate.getHours() === 23) {
            newDate = DateUtils.addDays(newDate, 1/24);
        } else if (newDate.getHours() === 1) {
            newDate = DateUtils.addDays(newDate, - 1/24);
        }
        return newDate;
    }

    public static addDays(date: Date, days: number): Date {
        return new Date(date.getTime() + (days * 86400000));
    }

    public static tomorrow(): Date {
      return DateUtils.addDays(DateUtils.toLastMidnight(new Date()), 1);
    }

    public static today(): Date {
      return DateUtils.toLastMidnight(new Date())
    }

    public static startOfDay(date: Date): Date {
      return DateUtils.toLastMidnight(date);
    }

    public static endOfDay(date: Date): Date {
      return new Date(DateUtils.startOfDay(DateUtils.addDays(date, 1)).getTime() - 1);
    }

    public static lastWeek(): Date {
      return DateUtils.addDays(DateUtils.tomorrow(), -7);
    }

    public static lastMonth(): Date {
      return DateUtils.addDays(DateUtils.tomorrow(), -30);
    }

    public static longTimeAgo(): Date {
      return DateUtils.addDays(DateUtils.today(), (-1 * 365 * 100));
    }

    public static longTimeFromNow(): Date {
      return DateUtils.addDays(DateUtils.today(), (365 * 100));
    }

    public static lastYear(): Date {
      return DateUtils.addDays(DateUtils.tomorrow(), -365);
    }

    public static start(): Date {
      return new Date (2000, 0, 0, 0, 0, 0, 0);
    }

    public static toLastMidnight(date: Date) {
        let lastMidnight = new Date(date);
        lastMidnight.setHours(0);
        lastMidnight.setMinutes(0);
        lastMidnight.setSeconds(0);
        lastMidnight.setMilliseconds(0);
        return lastMidnight;
    }

    public static toVectorDay(weekDay: number): number {
        if (weekDay < 2) {
            return weekDay + 5;
        } else {
            return weekDay - 2;
        }
    }

    public static isSameDayAs(a: Date, b: Date): boolean {
        return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
    }

    public static toMonthSlashDayString(date: Date): string {
        return (date.getMonth() + 1).toString() + "/" + date.getDate().toString();
    }

    public static getDayAbbreviationForVectorDay(day: number): string {
        if (day === 0) {
            return "Tu";
        } else if (day === 1) {
            return "Wed";
        } else if (day === 2) {
            return "Th";
        } else if (day === 3) {
            return "Fri";
        } else if (day === 4) {
            return "Sat";
        } else if (day === 5) {
            return "Sun";
        } else if (day === 6) {
            return "Mon";
        } else {
            throw new Error("unrecognized day");
        }
    }

    public static getMonthAbbreviation(date: Date): string {
        let month = date.getMonth();
        if (month === 0) {
            return "Jan";
        } else if (month === 1) {
            return "Feb";
        } else if (month === 2) {
            return "Mar";
        } else if (month === 3) {
            return "Apr";
        } else if (month === 4) {
            return "May";
        } else if (month === 5) {
            return "Jun";
        } else if (month === 6) {
            return "July";
        } else if (month === 7) {
            return "Aug";
        } else if (month === 8) {
            return "Sep";
        } else if (month === 9) {
            return "Oct";
        } else if (month === 10) {
            return "Nov";
        } else if (month === 11) {
            return "Dec";
        } else {
            throw new Error("unrecognized month");
        }
    }
}
