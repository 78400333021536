import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import { OnStreamUpdate, SubscriptionGenerator, Unsubscriber } from "./subscription_generator";
import { toQueueDatatbaseDocList, toQueueDatatbaseDoc } from "./persistence_utls";
import { DatabaseDocument } from "./persisted_object";
import ActionResult from "../model/action_result";
import ActionResultVoid from "../model/action_result_void";

export default class MessagePersistence {
    private constructor () {

    }

    private static sharedInstance: MessagePersistence = new MessagePersistence(); 

    public static instance () {
        return this.sharedInstance;
    }

    // public async getMessages (teamID: string, numMessages: number): Promise<ActionResult<DatabaseDocument>> {
    //     return firebaseApp.firestore().collection("Messages").where("teamid", "==", teamID).get()
    //        .then(snapshot => {
    //         if (!snapshot.exists) {
    //             //not found
    //             return new ActionResult<DatabaseDocument>(false,"Message Not Found");
    //         } else {
    //             //success
    //             return new ActionResult(true,undefined,toQueueDatatbaseDoc(snapshot));
    //         }
    //       })
    //       //failure
    //       .catch(err => {
    //         return new ActionResult<DatabaseDocument>(false,err.message);
    //       });
    // }

    public async updateMessage (doc: DatabaseDocument) : Promise<ActionResultVoid> {
        return firebaseApp.firestore().collection("Messages").doc(doc.id).update(doc.data).then(() => {
         return new ActionResultVoid(true, );
     }).catch((error) => {
         return new ActionResultVoid(false, error.message);
     });
    }

    public async createMessage (doc: DatabaseDocument) : Promise<ActionResultVoid> {

        return firebaseApp.firestore().collection("Messages").doc().set(doc.data).then(() => {
         return new ActionResultVoid(true, );
     }).catch((error) => {
         return new ActionResultVoid(false, error.message);
     });
    }

    public getMessageSubscriptionGenerator (chatID: string, limit: number): SubscriptionGenerator {
        return (onStreamUpdate:  OnStreamUpdate):Unsubscriber => {
            return {
                unsubscribe: firebaseApp.firestore().collection("Messages")
                    .where("chatID", "==", chatID)
                    .orderBy("sendDate", "desc")
                    // .limit(limit)
                    .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                        onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                    }, (error) => {
                        console.log(error.message);
                        onStreamUpdate([]);
                    }),
            }
        };
    }
}