import React from "react"; 
import { Card, Typography, Tooltip, Badge, Button, Grid } from "@material-ui/core";
import Chat from "model/chat";
import { AuthenticationService } from "services/authentication_service";
import '../common.css';
import { ChatService } from "services/chat_service";

interface ChatDisplayCardProps {
    chat: Chat,
    names: string[],
    raised: boolean,
    onSelected: (chatID: string) => void,
}

export default class ChatDisplayCard extends React.Component<ChatDisplayCardProps, {}> {
    constructor (props: ChatDisplayCardProps) {
        super(props); 
        this.getTooltipText = this.getTooltipText.bind(this);
        this.getPreviewTextForChat = this.getPreviewTextForChat.bind(this);  
    }

    render () { 
            return (  
                <Card style={{maxWidth: "100%"}} raised={this.props.raised}>
                    <Button fullWidth onClick={() => {
                        this.props.onSelected(this.props.chat.id);
                    }}>  
                        <Tooltip disableHoverListener={this.shouldDisableHoverListener()} title={this.getTooltipText()}>
                            <Badge variant="dot" color='primary' invisible={this.shouldBeInvisible()}>
                                <Grid container direction="column" justify='center' alignItems='center'>
                                    <Grid item>
                                        <Typography noWrap className="MadPadding" variant='h5'>{this.props.chat.name ?? ChatService.getDisplayNameForChat(this.props.chat)}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{maxWidth: "90%"}} align='center' noWrap className='MadPadding' variant="subtitle1">{this.getPreviewTextForChat()}</Typography>
                                    </Grid>
                                </Grid>
                            </Badge>
                        </Tooltip>
                    </Button>
                </Card> 
            ); 
    }

    getPreviewTextForChat () {
        if (this.props.chat.removedMembers.has(AuthenticationService.instance().getCurrentUserID())) {
            return "You have been removed from this chat."
        }
        else return (this.props.chat.lastMessageText ?? ""); 
    }

    shouldBeInvisible (): boolean {
        return !(this.props.chat.lastMessageDate !== null && this.props.chat.lastMessageDate > this.props.chat.lastOpenedDates.get(AuthenticationService.instance().getCurrentUserID())!);
    }

    getTooltipText () {
        let unknownMessage: string = "Unknown reps have been transferred to a different office"; 
        if (this.props.chat.name === null && !(ChatService.getDisplayNameForChat(this.props.chat).search("Unknown") === -1)) return unknownMessage;  
        else return ""; 
    }

    shouldDisableHoverListener () {
        return this.props.chat.name !== null;
    }
}