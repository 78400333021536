import { SubscriptionGenerator, OnStreamUpdate, Unsubscriber } from "./subscription_generator";import { firebaseApp } from "./firebase_app";
import { toQueueDatatbaseDocList, toQueueDatatbaseDoc } from "./persistence_utls";
import ActionResultVoid from "model/action_result_void";
import TransferRequestConverter from "model/converters/transfer_request_converter";
import { RequestStatus } from "model/request_status";
import TeamConverter from "model/converters/team_converter";
import Team from "model/team";


export default class TransferRequestPersistence {
    private constructor() {}

    private static sharedInstance: TransferRequestPersistence = new TransferRequestPersistence();

    public static instance(): TransferRequestPersistence {
        return this.sharedInstance;
    }

    public getRequestsToOfficeSubscriptionGenerator(teamID: string): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {
            return {
                unsubscribe: firebaseApp.firestore().collection("TransferRequests")
                    .where("toOfficeID", "==", teamID)
                    .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                        onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                    }, (error) => {
                        onStreamUpdate([]);
                    }),
            }
        };
    }

    public getRequestsFromOfficeSubscriptionGenerator(teamID: string): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {
            return {
                unsubscribe: firebaseApp.firestore().collection("TransferRequests")
                    .where("fromOfficeID", "==", teamID)
                    .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                        onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                    }, (error) => {
                        onStreamUpdate([]);
                    }),
            }
        };
    }

    public async acceptRequest(requestID: string, resolvedBy: string, toTeamID: string, fromTeamID: string): Promise<ActionResultVoid> {
        let requestRef = firebaseApp.firestore().collection("TransferRequests").doc(requestID);
        let toTeamRef = firebaseApp.firestore().collection("Teams").doc(toTeamID);
        let fromTeamRef = firebaseApp.firestore().collection("Teams").doc(fromTeamID);

        try {
            const toTeam_1 = await firebaseApp.firestore().runTransaction(async (t): Promise<Team> => {
                //make sure there is an existing pending request
                let requestSnap = await t.get(requestRef);
                if (requestSnap.exists) {
                    let request = new TransferRequestConverter().fromPersistence(toQueueDatatbaseDoc(requestSnap));
                    if (request.status !== RequestStatus.Pending) {
                        return Promise.reject("Can only accept a pending request. This request has already been resolved by " + (request.resolvedBy!));
                    }
                }
                else {
                    return Promise.reject("This user does not have any requests to act on");
                }
                let teamConverter: TeamConverter = new TeamConverter();
                //get the current pending request counts for both teams
                let toTeamSnap = await t.get(toTeamRef);
                let toTeam = teamConverter.fromPersistence(toQueueDatatbaseDoc(toTeamSnap));
                let toTeamRequestCount = toTeam.pendingTransferRequestCount;
                let fromTeamSnap = await t.get(fromTeamRef);
                let fromTeamRequestCount = teamConverter.fromPersistence(toQueueDatatbaseDoc(fromTeamSnap)).pendingTransferRequestCount;
                t.update(requestRef, {
                    "status": RequestStatus.Approved,
                    "resolvedBy": resolvedBy
                });
                t.update(toTeamRef, {
                    "pendingTransferRequestCount": toTeamRequestCount - 1
                });
                t.update(fromTeamRef, {
                    "pendingTransferRequestCount": fromTeamRequestCount - 1
                });
                return Promise.resolve(toTeam);
            });
            return this.executeTrasnfer(requestID, toTeam_1.id, toTeam_1.name, toTeam_1.division, toTeam_1.region);
        }
        catch (error) {
            return new ActionResultVoid(false, String(error));
        }
    }

    public async declineRequest(requestID: string, resolvedBy: string, toTeamID: string, fromTeamID: string) : Promise<ActionResultVoid> {
        let requestRef = firebaseApp.firestore().collection("TransferRequests").doc(requestID);
        let toTeamRef = firebaseApp.firestore().collection("Teams").doc(toTeamID);
        let fromTeamRef = firebaseApp.firestore().collection("Teams").doc(fromTeamID);

        try {
            await firebaseApp.firestore().runTransaction(async (t): Promise<Team> => {
                //make sure there is an existing pending request
                let requestSnap = await t.get(requestRef);
                if (requestSnap.exists) {
                    let request = new TransferRequestConverter().fromPersistence(toQueueDatatbaseDoc(requestSnap));
                    if (request.status !== RequestStatus.Pending) {
                        return Promise.reject("Can only decline a pending request. This request has already been resolved by " + (request.resolvedBy!));
                    }
                }
                else {
                    return Promise.reject("This user does not have any requests to act on");
                }
                let teamConverter: TeamConverter = new TeamConverter();
                //get the current pending request counts for both teams
                let toTeamSnap = await t.get(toTeamRef);
                let toTeam = teamConverter.fromPersistence(toQueueDatatbaseDoc(toTeamSnap));
                let toTeamRequestCount = toTeam.pendingTransferRequestCount;
                let fromTeamSnap = await t.get(fromTeamRef);
                let fromTeamRequestCount = teamConverter.fromPersistence(toQueueDatatbaseDoc(fromTeamSnap)).pendingTransferRequestCount;
                t.update(requestRef, {
                    "status": RequestStatus.Denied,
                    "resolvedBy": resolvedBy
                });
                t.update(toTeamRef, {
                    "pendingTransferRequestCount": toTeamRequestCount - 1
                });
                t.update(fromTeamRef, {
                    "pendingTransferRequestCount": fromTeamRequestCount - 1
                });
                return Promise.resolve(toTeam);
            });
            return new ActionResultVoid(true);
        }
        catch (error) {
            return new ActionResultVoid(false, String(error));
        }
    }

    public async executeTrasnfer(userID: string, officeID: string, officeName: string, division: string, region: string): Promise<ActionResultVoid> {
        let batch = firebaseApp.firestore().batch();
        let userRef = firebaseApp.firestore().collection("Users").doc(userID);
        let userPhoningRef = firebaseApp.firestore().collection("Current Phone Times").doc(userID);


        batch.update(userRef, {
            "officeNumber": officeID,
            "officeName": officeName,
            "division": division,
            "region": region,
            "active": true
        });

        //update the current phone time if it exists
        let userPhoningSnap = await userPhoningRef.get();
        if (userPhoningSnap.exists) {
            batch.update(userPhoningRef, {
                "team": officeID,
                "region": region,
                "division": division
            });
        }

        //get appointments
        let pdiQuerySnap = await firebaseApp.firestore().collectionGroup("UserGoals").where("uid", "==", userID).get();
        //write appointments
        if (!pdiQuerySnap.empty) {
            pdiQuerySnap.docs.forEach((pdiSnap) => {
                if (pdiSnap.exists) {
                    batch.update(pdiSnap.ref, {
                        "officeNumber": officeID,
                        "region": region,
                        "teamName": officeID,
                        "division": division
                    });
                }
            });
        }
    
        //get pdi data
        let apptQuerySnap = await firebaseApp.firestore().collection("Appointments").where("userID", "==", userID).get();
        //write pdi data
        if (!apptQuerySnap.empty) {
            apptQuerySnap.docs.forEach((apptSnap) => {
                if (apptSnap.exists) {
                    batch.update(apptSnap.ref, {
                        "region": region,
                        "division": division,
                        "team": officeID
                    });
                }
            })
        }
        //commit
        return batch.commit().then(() => {
           
            return new ActionResultVoid(true)
        }).catch((error) => {
            return new ActionResultVoid(false, error.message)
        });
    }

    
}