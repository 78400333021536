import GuardedListenerComponent from "ui/components/guarded_listener_component";
import { OfficeInfo } from "persistence/divisional_persistence";
import React from "react";
import TeamStore from "services/team_store";
import QueueAppbar from "ui/components/queue_appbar";
import Team from "model/team";
import { Button, Select, MenuItem, CircularProgress } from "@material-ui/core";
import { Link, Redirect } from 'react-router-dom';
import OleanOfficeNumberConfirmationDialog from "ui/components/olean_office_number_confirmation_dialog";
import { AuthenticationService } from "services/authentication_service";


interface ChangeTeamsPageState {
    loaded: boolean,
    offices?: OfficeInfo[], 
    showUpdateOfficeNumberDialog: boolean,
    shouldRedirectTo?: string
}


export default class ChangeTeamsPage extends GuardedListenerComponent<{}, ChangeTeamsPageState> {
    private initiatedQuery: boolean;
    constructor(props: {}) {
        super(props);
        this.state = {
            loaded: false,
            showUpdateOfficeNumberDialog: false
        }
        this.renderUpdateOfficeNumberButton = this.renderUpdateOfficeNumberButton.bind(this);
        this.initiatedQuery = false;
    }

    getPathOnRefresh() {
        return "/team-select";
    }

    getEmployedListenerClients() {
        return [TeamStore.getTeamListenerClient(true)];
    }

    renderUpdateOfficeNumberButton() {
        return <Button onClick={() => this.setState({showUpdateOfficeNumberDialog: true})}>Update Office Number</Button>
    }

    componentDidMount() {
        super.componentDidMount();
        TeamStore.instance().clearCache();
    }

    renderGuarded() {
        let body;
        let teamStore = TeamStore.instance();
        if (this.state.shouldRedirectTo !== undefined) {
            return <Redirect to={this.state.shouldRedirectTo!}/>
        }
        if (!this.initiatedQuery) {
            this.initiatedQuery = true;
            AuthenticationService.instance().getAllOfficesInDivision().then((getOfficesResult) => {
                if (getOfficesResult.success) {
                    this.setState({
                        loaded: true,
                        offices: getOfficesResult.payload!
                    });
                }
            })
        }
        if (!teamStore.isLoaded()) {
            return <CircularProgress/>;
        } else {
            let team: Team = teamStore.getTeam();
            if (team.silverCup) {
                if (team.vectorOfficeNumber !== null && team.vectorOfficeNumber!.length === 4) {
                    if (team.vectorOfficeNumber!.slice(2, 4) === "99" ) {
                        ///dvm
                        if (this.state.loaded) {
                            body = <div className="centered">
                                <h1>Select Team</h1>
                                <Select onChange={(event) => {
                                    let newOfficeId: string = event.target.value as string;
                                    if (newOfficeId === "ALL") {
                                        AuthenticationService.instance().setDivisionMode(true, (location: string) => this.setState({shouldRedirectTo: location}));
                                    } else {
                                        AuthenticationService.instance().setUserOfficeNumberOverride(newOfficeId, (location: string) => this.setState({shouldRedirectTo: location}));
                                    }
                                }}>
                                    {this.state.offices!.map((office) => <MenuItem id={office.id} value={office.id}>{office.name + ", " + office.manager}</MenuItem>)}
                                </Select>
                            </div>
                        } else {
                            body = <CircularProgress/>
                        }
                    } else {
                        ///not a dvm
                        body = <div className="centered">
                            <h1>Office switching is dvm only feature. If you are a dvm, then please update your office number</h1>
                            {this.renderUpdateOfficeNumberButton()}
                        </div>
                    }
                } else {
                    ///invalid office number
                    body = <div className="centered">
                        <h1>There seems to be a problem with your office number, to enable office switching, please confirm your office Number</h1>
                        {this.renderUpdateOfficeNumberButton()}
                    </div>
                }
            } else {
                body = <div className="centered"><h1>Office switching is a silver cup edition DVM-Only feature. <Link to="/plans">Learn more</Link></h1></div>
            }
        }
        return <div>
            <QueueAppbar shouldDisplayMenu={true}/> 
                {this.state.showUpdateOfficeNumberDialog ? <OleanOfficeNumberConfirmationDialog team={teamStore.getTeam()} onConfirmed={() => {
                    this.initiatedQuery = false;
                    this.setState({showUpdateOfficeNumberDialog: false});
                }}/> : null}
            {body}
        </div>;
    }
}