import React from 'react';
import { TableRow, TableCell, TableBody, FormControlLabel, Checkbox, MenuItem, Select, TextField, Typography, Grid, Button } from "@material-ui/core";
import Appointment from "model/appointment";
import Rep from "model/rep";
import AppointmentService from "../../services/appointment_service";
import DateUtils from "../../services/date_utils";
import NumberInput from "ui/components/common/NumberInput";
import ColoredCardBackground, { BackgroundColor } from "ui/components/colored_card_background";
import "../css/demos.css";


interface DemoCellProps {
  appointment: Appointment,
  managers: Rep[],
  cancelledString: string,
  dHrs: string,
  dMin: string,
  amPm: string
}



export default class DemoCell extends React.Component<DemoCellProps, {}> {
  private details: string;
  private lastKnownDetails: string;

  constructor(props: DemoCellProps) {
    super(props);
    this.details = this.props.appointment.details ?? "";
    this.lastKnownDetails = this.props.appointment.details;
    this.renderCPO = this.renderCPO.bind(this);
    this.renderCalledIn = this.renderCalledIn.bind(this);
    this.renderCheckbox = this.renderCheckbox.bind(this);
    this.renderDetails = this.renderDetails.bind(this);
    this.renderOutcome = this.renderOutcome.bind(this);
    this.renderRecs = this.renderRecs.bind(this);
    this.renderRep = this.renderRep.bind(this);
    this.renderTime = this.renderTime.bind(this);
  }

  render() {
    const { appointment, managers } = this.props;
    const menuItems = managers.map((rep: Rep) => {
      return (
        <MenuItem value={rep.repID}>
          {rep.firstName + " " + rep.lastName}
        </MenuItem>
      )
    });
    return (
      <TableRow key={appointment.apptID}>
          {this.renderCheckbox()}
          <TableCell>
            <Select
            value={appointment.calledInName}
            onChange={(event: any) => {
              const managerID: string = event.target.value
              const managerArr = managers.filter((m) => m.repID === managerID)
              const manager = managerArr[0]

              if (manager != null) {
                appointment.calledInName = manager.firstName + " " + manager.lastName
                appointment.calledInID = manager.repID
                appointment.hasBeenCalledIn = true;
                this.setState({});
                AppointmentService.instance().updateAppointment(appointment);
              } else {
                appointment.calledInName = ''
                appointment.calledInID = ''
                appointment.hasBeenCalledIn = false;
                this.setState({});
                AppointmentService.instance().updateAppointment(appointment);
              }
            } }
            renderValue={(value: any) => {
              return (
                <div>{appointment.calledInName}</div>
              )
            }}
          >
          {menuItems}
          <MenuItem value={"None"}>-</MenuItem>
        </Select>
          </TableCell>
          {this.renderTime()}
          {this.renderRep()}
          {this.renderOutcome()}
          {this.renderCPO()}
          {this.renderDetails()}
          {this.renderRecs()}
      </TableRow>
    )
  }

  renderCheckbox() {
    const {appointment } = this.props;

    

    return (
      <TableCell>

      <Checkbox
         checked={appointment.hasBeenCalledIn}
         onChange={(_) => {
              appointment.hasBeenCalledIn = !appointment.hasBeenCalledIn ;
              if (!appointment.hasBeenCalledIn) {
                appointment.calledInName = ''
                appointment.calledInID = ''
              }
              this.setState({});
              AppointmentService.instance().updateAppointment(appointment);
         } }
         name="Called In"
         color="primary"
      />
      
      </TableCell>
    )
  }
  renderTime() {
    const { dHrs, dMin, amPm } = this.props
    let startStamp = this.props.appointment.startTimeStamp;
    let vectorDay = DateUtils.toVectorDay(startStamp.getDay());
    let dayAbbr = DateUtils.getDayAbbreviationForVectorDay(vectorDay);
    let monthAbbr = DateUtils.getMonthAbbreviation(startStamp);
    let dayOfMonth = startStamp.getDate();

    return (
      <TableCell>
        <Typography>
          {' ' + dHrs + ':' + dMin + ' ' + amPm + " " + dayAbbr + ", " + monthAbbr + " " + dayOfMonth}</Typography>
      </TableCell>
    )
  }
  renderRep() {
    const { appointment, cancelledString, dHrs, dMin, amPm } = this.props;
    return (
      <TableCell>
        <Typography>
       {appointment.repName}
        </Typography>
       </TableCell>
    )
  }
  renderOutcome() {


    const {appointment} = this.props;
    let color = BackgroundColor.NONE ;
    if (appointment.outcome === 'Cancelled' || appointment.outcome === 'No sale' || appointment.outcome === "No Show") {
      color = BackgroundColor.RED
    } else if (appointment.outcome === 'Maybe' || appointment.outcome === 'Rescheduled') {
      color = BackgroundColor.YELLOW
    } else if (appointment.outcome === 'Sale') {
      color = BackgroundColor.GREEN
    } else if (appointment.outcome === 'Need Update') {
      color = BackgroundColor.PURPLE
    }

    return (
      <TableCell>
        <ColoredCardBackground color={color} kids={
          [
            <div style={{ backgroundColor: color, borderRadius: 5 }}>
              <Select
                value={appointment.outcome ?? ""}
                onChange={(event: any) => {
                  const outcome = event.target.value;
                  appointment.outcome = outcome
                  if (outcome === 'Cancelled') {
                    appointment.hasBeenCancelled = true
                  } else {
                    appointment.hasBeenCancelled = false;
                  }
                  this.setState({});
                  AppointmentService.instance().updateAppointment(appointment);
                } }
              >
                <MenuItem value={"Sale"}>Sale</MenuItem>
                <MenuItem value={"No sale"}>No sale</MenuItem>
                <MenuItem value={"Maybe"}>Thinking about it</MenuItem>
                <MenuItem value={"No Show"}>No Show</MenuItem>
                <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                <MenuItem value={"Rescheduled"}>Rescheduled</MenuItem>
                <MenuItem value={"Need Update"}>Need Update</MenuItem>
                <MenuItem value={""}>-</MenuItem>
              </Select>
            </div>
          ]
        }/>

      </TableCell>
    )
  }
  renderCPO() {
    const {appointment} = this.props;
    return (
      <TableCell>
        <div style={{width: 50}}>
          <NumberInput
            value={appointment.cpo ?? ""}
            onChange={(value: number) => {
              appointment.cpo = (value > 0) ? value : 0
              this.setState({});
              AppointmentService.instance().updateAppointment(appointment);
            } }
          />
        </div>
      </TableCell>
    )
  }
  renderDetails() {
    const {appointment} = this.props;
    if (this.lastKnownDetails !== appointment.details) {
      this.details = appointment.details;
      this.lastKnownDetails = appointment.details;
    }
    return (
      <TableCell>
      <div style={{paddingRight: 10 }}>
        <Grid container direction="row"spacing={2}>
          <Grid item>
            <TextField
              value={this.details}
              multiline={true}
              rowsMax={3}
              fullWidth={true}
              onChange={(event) => {
                const value = event.target.value
                this.details = value;
                this.setState({
                });
              }}
              type={"text"}
              variant={"standard"}
              margin={"dense"}
            />
          </Grid>
          <Grid item>
           {this.details != (this.props.appointment.details ?? "") ? <Button variant="outlined" color="primary" onClick={() => {
             this.props.appointment.details = this.details;
             this.lastKnownDetails = this.details;
             this.setState({});
             AppointmentService.instance().updateAppointment(this.props.appointment);
           }}>Save</Button> : null } 
          </Grid>
        </Grid>
      
      </div>
      </TableCell>
    )
  }
  renderRecs() {
    const {appointment} = this.props;
    return (
      <TableCell>
      <div style={{width: 50}}>
      <NumberInput
        value={appointment.recCount ?? ""}
        onChange={(value: number) => {
          appointment.recCount = (value < 0) ? 0 : value
          this.setState({});
          AppointmentService.instance().updateAppointment(appointment);
        } }
      />
      </div>
      </TableCell>
    )
  }
  renderCalledIn() {
    const {appointment, managers } = this.props;

    const menuItems = managers.map((rep: Rep) => {
      return (
        <MenuItem key={rep.repID} value={rep.repID}>
          {rep.firstName + " " + rep.lastName}
        </MenuItem>
      )
    })

    return (
      <TableCell>
        <Select
          value={appointment.calledInName ?? "None"}
          onChange={(event: any) => {
            const managerID: string = event.target.value
            const managerArr = managers.filter((m) => m.repID === managerID)
            const manager = managerArr[0]

            if (manager != null) {
              appointment.calledInName = manager.firstName + " " + manager.lastName
              appointment.calledInID = manager.repID
              this.setState({});
              AppointmentService.instance().updateAppointment(appointment);
            } else {
              appointment.calledInName = ''
              appointment.calledInID = ''
              this.setState({});
              AppointmentService.instance().updateAppointment(appointment);
            }
          } }
          renderValue={(value: any) => {
            return (
              <div>{appointment.calledInName}</div>
            )
          }}
        >
          {menuItems}
          <MenuItem value={"None"}>-</MenuItem>
        </Select>
      </TableCell>
    )
  }

}


const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  }
}
