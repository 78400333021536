import React from 'react';
import {AuthenticationService} from '../../services/authentication_service';
import { Redirect } from 'react-router-dom';
import AsyncLoader from '../pages/async_loader';
import { firebaseApp } from '../../persistence/firebase_app';
import "firebase/auth";
import "firebase/firestore";
import * as firebase from "firebase/app";
import Loader from '../pages/loader_page';
import qs from 'qs';

export default abstract class GuardedComponent<T,K> extends React.Component<T,K> {

    private resolvedAuthState: boolean = false;
    private unsubscribe?: firebase.Unsubscribe;

    constructor(props: T) {
        super(props);
        this.renderGuarded = this.renderGuarded.bind(this);
        this.getPathOnRefresh = this.getPathOnRefresh.bind(this);
        AuthenticationService.instance().registerCurrentScreen(() => this.setState({}));
    }

    componentDidMount() {
        this.unsubscribe = firebaseApp.auth().onAuthStateChanged((user: firebase.User | null) => {
            this.resolvedAuthState = true;
            this.unsubscribe!();
            this.setState({});
        });
    }

    componentWillUnmount() {
        this.unsubscribe!();
    }
    
    abstract renderGuarded(): React.ReactElement;

    abstract getPathOnRefresh(): string;

    render() {
        if (!AuthenticationService.instance().isAuthenticated()) {
            if (this.resolvedAuthState) {
                return (<Redirect to="/login"/>);
            } else {
                return (<Loader/>);
            }
        } else if (!AuthenticationService.instance().hasDataRequiredToFunction()) {
            return (<AsyncLoader asyncTask={async () => await AuthenticationService.instance().loadDataRequiredToFunction()} redirectPathOnTaskFailed={"/login"} redirectPathOnTaskSuccess={this.getPathOnRefresh()}/>);        } else {
            return this.renderGuarded();
        }
    }
}