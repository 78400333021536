import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import ActionResultVoid from "../model/action_result_void";
import { DatabaseDocument } from "./persisted_object";
import { toQueueDatatbaseDocList } from "./persistence_utls";
import { SubscriptionGenerator, OnStreamUpdate, Unsubscriber } from "./subscription_generator";

export default class OfficeEventPersistence {
    private constructor () {

    }

    private static sharedInstance: OfficeEventPersistence = new OfficeEventPersistence();

    public static instance () {
        return this.sharedInstance;
    }

    public async deleteOfficeEvent(teamID: string, eventID: string): Promise<ActionResultVoid> {
        return firebaseApp.firestore().collection("Teams").doc(teamID).collection("OfficeEvents")
            .doc(eventID).delete().then(() => {
                return new ActionResultVoid(true, );
            }).catch((error) => {
                return new ActionResultVoid(false, error.message);
            });
    }

    public async deleteOfficeEventRecurrence(teamID: string, recurrenceID: string): Promise<ActionResultVoid> {
        let batch: firebase.firestore.WriteBatch = firebaseApp.firestore().batch();
        return firebaseApp.firestore().collection("Teams").doc(teamID).collection("OfficeEvents")
            .where("recurrenceID", "==", recurrenceID).get().then((recurrenceQuerySnap) => {
                if (recurrenceQuerySnap.empty) {
                    return new ActionResultVoid(false, "Recurrence not found");
                } else {
                    recurrenceQuerySnap.docs.forEach((eventDoc) => {
                        batch.delete(eventDoc.ref);
                    });
                    return batch.commit().then(() => {
                        return new ActionResultVoid(true);
                    }).catch((error) => {
                        return new ActionResultVoid(false, error.message);
                    });
                }
            }).catch((error) => {
                return new ActionResultVoid(false, error.message);
            });
    }

    public async updateOfficeEvent (doc: DatabaseDocument, teamID: string): Promise <ActionResultVoid> {
        return firebaseApp.firestore().collection("Teams").doc(teamID).collection("OfficeEvents")
        .doc(doc.id).update(doc.data).then(() => {
            return new ActionResultVoid(true, );
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public async createOfficeEvent (docs: DatabaseDocument[], teamID: string): Promise <ActionResultVoid> {
        let batch: firebase.firestore.WriteBatch = firebaseApp.firestore().batch();
        for (let i: number = 0; i < docs.length; i++) {
            let reference = firebaseApp.firestore().collection("Teams").doc(teamID).collection("OfficeEvents").doc();
            batch.set(reference, docs[i].data);
        }
        return batch.commit().then(() => {
            return new ActionResultVoid(true);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public getOfficeEventSubscriptionGenerator (teamID: string, startDate: Date, endDate: Date): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {
            return {
                unsubscribe: firebaseApp.firestore().collection("Teams").doc(teamID).collection("OfficeEvents")
                    .where("startTimeStamp", ">=", startDate)
                    .where("startTimeStamp", "<=", endDate)
                    .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                        onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                    }, (error) => {
                        onStreamUpdate([]);
                    }),
            }
        };
    }

}