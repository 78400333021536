export enum AttendanceStatus {
    CONFIRMED = "Confirmed", //green
    NO_SHOW = "No Show", //red
    YES = "Yes", //blue
    NO = "No", //grey
    UNDECIDED = "Undecided", //white
    LATE = "Late", //yellow
    LEAVE_EARLY = "Leave Early", //dark red
    MAYBE = "Maybe", //purple

}

export function fromString(s: string) {
    if (s === "Showed") {
        return AttendanceStatus.CONFIRMED;
    }else if (s === AttendanceStatus.NO_SHOW.toString()) {
        return AttendanceStatus.NO_SHOW;
    } else if (s === "Coming") {
        return AttendanceStatus.YES;
    } else if (s === "Not Coming") {
        return AttendanceStatus.NO;
    } else if (s === "Unknown") {
        return AttendanceStatus.UNDECIDED;
    } else if (s === "Maybe") {
        return AttendanceStatus.UNDECIDED;
    } else if (s === AttendanceStatus.LATE.toString()) {
        return AttendanceStatus.LATE;
    } else if (s === AttendanceStatus.CONFIRMED.toString()) {
        return AttendanceStatus.CONFIRMED;
    } else if (s === AttendanceStatus.YES.toString()) {
        return AttendanceStatus.YES;
    } else if (s === AttendanceStatus.NO.toString()) {
        return AttendanceStatus.NO;
    } else if (s === AttendanceStatus.UNDECIDED.toString()) {
        return AttendanceStatus.UNDECIDED;
    } else if (s === AttendanceStatus.LATE.toString()) {
        return AttendanceStatus.LATE;
    } else if (s === AttendanceStatus.LEAVE_EARLY.toString()) {
        return AttendanceStatus.LEAVE_EARLY; 
    } else if (s === AttendanceStatus.MAYBE.toString()){
        return AttendanceStatus.MAYBE; 
    } else {
        throw new Error("not recognized");
    }
}
