import Converter from "./converter";
import PdiWeek from '../pdi_week';
import { DatabaseDocument, DocumentData } from "../../persistence/persisted_object";
import { PdiStatus } from "../pdi_status";

export default class PDIConverter extends Converter<PdiWeek> {

    fromPersistence(doc: DatabaseDocument): PdiWeek {
        let data: DocumentData = doc.data;
        let dailyGoals:(number|null)[]=[];
        let dailyStatuses:(PdiStatus | null)[] = [];
        
        for ( let x=0; x<7; x++) {
            dailyGoals.push( data[x.toString()] ?? null);
            if (data[x.toString() + "status"] === "PdiStatus.TextedOnce") dailyStatuses.push(PdiStatus.TextedNoResponse);
            else dailyStatuses.push(data[x.toString() + "status"] ?? null);
        }
               
        return {
            userID: doc.id,
            dailyGoals: dailyGoals,
            dailyStatuses: dailyStatuses,
            teamID: data["officeNumber"]
        };
    }

    toPersistence(pdiWeek: PdiWeek): DatabaseDocument {

        let data: DocumentData = {
            "officeNumber": pdiWeek.teamID, 
            "uid": pdiWeek.userID
        };

        for ( let x=0; x<7; x++) {
            data[x.toString()] = pdiWeek.dailyGoals[x];
            data[x.toString() + "status"] = pdiWeek.dailyStatuses[x];
        }

        return {
            id: pdiWeek.userID,
            data: data,
        };
    }
}