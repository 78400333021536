import { StarterKitStatus } from "./starter_kit_status";

export default interface Rep {
    repID: string, 
    isActive: boolean, 
    markedInactiveTimeStamp: Date | null,
    contactCount: number, 
    firstName: string, 
    lastName: string,
    novice: boolean, 
    phoneNumber: string, 
    registrationTimeStamp: Date,
    totalCallsMade: number, 
    totalCallBacks: number, 
    totalAppointmentsBooked: number, 
    totalDeclines: number, 
    currentBrainstormListSize: number, 
    allTimeBrainstormListSize: number, 
    isManager: boolean, 
    officeNumber: string,
    lastSignOn: Date, 
    teamName: string,
    notes: string,
    closingGrade: string | null,
    recommendationsGrade: string | null,
    phoningGrade: string | null,
    group: string,
    team: string,
    starterKitStatus: StarterKitStatus | null,
    salesTeamWebsiteVersionNumber: number | null;
    salesTeamWebsiteVersionString: string | null;
}

export const sortByFirstName = (a:Rep, b: Rep): number => {
    let firstNameComparison = a.firstName.localeCompare(b.firstName);
    if (firstNameComparison === 0) {
        return a.lastName.localeCompare(b.lastName);
    } else {
        return firstNameComparison;
    }
}

export const sortByLastName = (a:Rep, b: Rep): number => {
    let lastNameComparison = a.lastName.localeCompare(b.lastName);
    if (lastNameComparison === 0) {
        return a.firstName.localeCompare(b.firstName);
    } else {
        return lastNameComparison;
    }
}

