import Rep from '../model/rep';
import Store from './store';
import RepCollection, { CollectionType, } from 'model/rep_collection';

export default abstract class AbstractRepStore extends Store<Rep> {

    public abstract hasGroup(group: string): boolean;

    public abstract hasTeam(team: string): boolean;
    public abstract getRepGroups(stripDefaultGroups: boolean): (string | null)[];

    public abstract getRepTeams(stripDefaultTeams: boolean) : (string | null)[];

    public abstract hasRep(repID: string): boolean;

    //returns list of rep collections with reps sorted alphabetically
    public abstract getRepCollections(collectionType: CollectionType, sortCollections: boolean): RepCollection[];

    public abstract isInGroup(repID: string, group: string): boolean;

    public abstract isInTeam(repID: string, team: string): boolean;

    public abstract getRepsInTeam(team: string): Rep[];

    public abstract getRepsInGroup(group: string): Rep[];

    public abstract getRep(repID: string): Rep;

    public abstract getReps(): Rep[];
}
