import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import { OnStreamUpdate, SubscriptionGenerator, Unsubscriber } from "./subscription_generator";
import { toQueueDatatbaseDocList } from "./persistence_utls";

export default class PhoneTimePersistence {
  private constructor() {}

  private static sharedInstance: PhoneTimePersistence =
    new PhoneTimePersistence();

  public static instance() {
    return this.sharedInstance;
  }

  public getSubscriptionGenerator(repId: string, startDate: Date, endDate: Date): SubscriptionGenerator {
    console.log('Debug getting subscription generator for dates: ', startDate, ' to ', endDate);

    return (onStreamUpdate: OnStreamUpdate):  Unsubscriber => {
      return {
        unsubscribe: firebaseApp.firestore().collection('Users').doc(repId).collection('Events')
          .where('creationTimeStamp', '>=', startDate)
          .where('creationTimeStamp', '<=', endDate)
          .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {

            onStreamUpdate(toQueueDatatbaseDocList(snapshot));
            }, (error) => {
              onStreamUpdate([]);
            }
          )
      }
    }
  }

}
