import {AccountInfo, AccountPersistence} from '../persistence/account_persistence';
//import {BillingPersistence} from '../persistence/billing_persistence';
import ActionResultVoid from '../model/action_result_void';
import ManagerSignUpInfo from '../model/manager_sign_up_info';
import ActionResult from '../model/action_result';
import RepPersistence from '../persistence/rep_persistence';
import { DatabaseDocument } from '../persistence/persisted_object';
import RepConverter from '../model/converters/rep_converter';
import Rep from '../model/rep';
import { StarterKitStatus } from '../model/starter_kit_status';
import TransferRequestPersistence from 'persistence/transfer_request_persistence';
import { DEFAULT_TEAM } from 'model/rep_collection';
import { CURRENT_APPLICATION_VERSION_NUMBER, CURRENT_APPLICATION_VERSION_STRING } from 'index';
import DivisionalPersistence, { OfficeInfo } from 'persistence/divisional_persistence';
import TeamPersistence from 'persistence/team_persistence';
import TeamConverter from 'model/converters/team_converter';
import Team from 'model/team';
import Store from './store';



//layer to intermediate between the ui and the persistence
export class AuthenticationService {

    private constructor(){
        this.loadDataRequiredToFunction = this.loadDataRequiredToFunction.bind(this);
    }

    private static sharedInstance: AuthenticationService = new AuthenticationService();

    public static instance(): AuthenticationService {
        return this.sharedInstance;
    }

    private allOfficesInDivision?: OfficeInfo[];

    private divisionMode: boolean = false;

    private currentUser?: Rep;

    private currentUserVectorOfficeNumber?: string;

    private officeNumberOverride?: string;

    private allStores: Set<Store<any>> = new Set();

    private currentScreenRedraw?: () => void;

    public inDivisionMode(): boolean {
        return this.divisionMode;
    }



    private clearAllStores(): void {
        this.allStores.forEach((store) => store.clearCache());
    }

    public registerStore(store: Store<any>): void {
        this.allStores.add(store);
    }

    public registerCurrentScreen(redrawCallback: () => void) {
        this.currentScreenRedraw = redrawCallback;
    }

    public isAuthenticated(): boolean {
        return AccountPersistence.instance().getCurrentUser() !== null;
    }

    public async getAllOfficesInDivision(refresh: boolean = true): Promise<ActionResult<OfficeInfo[]>> {
            let divisionalFetchResult = await DivisionalPersistence.instance.getAllOfficesInDivision(this.currentUserVectorOfficeNumber!);
            if (!divisionalFetchResult.success) {
                return divisionalFetchResult;
            } else {
                this.allOfficesInDivision = divisionalFetchResult.payload!;
            }
        return new ActionResult(true, undefined, this.allOfficesInDivision!);
    }

    public getCurrentUser(): Rep {
        return this.currentUser!
    }

    public hasDataRequiredToFunction(): boolean {
        return this.currentUser !== undefined;
    }

    public getCurrentUserFullName(): string {
        return this.currentUser!.firstName + " " + this.currentUser!.lastName;
    }

    public getCurrentUserOfficeNumber(ignoreOverride: boolean = false): string {
        return ignoreOverride ? this.currentUser!.officeNumber! : this.officeNumberOverride ?? this.currentUser!.officeNumber!;
    }

    public setUserVectorOfficeNumber(newNumber: string, ): void {
        this.currentUserVectorOfficeNumber = newNumber;
        
    }

    public setUserOfficeNumberOverride(newNumber: string, setRedirect: (location: string) => void): void {
        this.officeNumberOverride = newNumber;
        this.divisionMode = false;
        setRedirect("/reps");
    }

    public setDivisionMode(inDivisionMode: boolean, setRedirect: (location: string) => void) {
        this.divisionMode = true;
        this.officeNumberOverride = undefined;
        setRedirect("/reps");
    }

    public resetUserOfficeNumberOverride(setRedirect: (location: string) => void): void {
        this.divisionMode = false;
        this.officeNumberOverride = undefined;
        setRedirect("/reps");
    }

    public getCurrentUserID(): string {
        return AccountPersistence.instance().getCurrentUser()!.userID;
    }

    public getCurrentUserEmail(): string {
        return AccountPersistence.instance().getCurrentUser()!.email;
    }

    public async loadDataRequiredToFunction(): Promise<ActionResultVoid> {
        return await RepPersistence.instance().getRep(this.getCurrentUserID()).then(async (loadRepResult: ActionResult<DatabaseDocument>) => {
            if (loadRepResult.success) {
                let repDatabaseDocument: DatabaseDocument = loadRepResult.payload!;
                console.log("load rep succeeded")
                let rep: Rep = new RepConverter().fromPersistence(repDatabaseDocument);
                this.currentUser = rep;
                let teamFetchResult = await TeamPersistence.instance().getTeam(rep.officeNumber);
                if (teamFetchResult.success) {
                    let team: Team = new TeamConverter().fromPersistence(teamFetchResult.payload!);
                    console.log("assigning vector office number")
                    this.currentUserVectorOfficeNumber = team.vectorOfficeNumber ?? "";
                } else {
                    return teamFetchResult;
                }
                return new ActionResultVoid(true);
            } else {
                return new ActionResultVoid(false, loadRepResult.errorMessage!);
            }
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public async login(email: string, password: string): Promise<ActionResultVoid> {

        return AccountPersistence.instance().login(email.trim(), password)
        .then((actionResult: ActionResultVoid) => {
            if (actionResult.success) {
                return RepPersistence.instance().getRep(this.getCurrentUserID())
                .then((repResult: ActionResult<DatabaseDocument>) => {
                    if (repResult.success) {
                        let thisRep: Rep = new RepConverter().fromPersistence(repResult.payload!);
                        if (thisRep.isManager) {
                            this.currentUser = thisRep;
                            return new ActionResultVoid(true);
                        } else {
                            this.logout();
                            return new ActionResultVoid(false, "You must be a manager or have manager access to sign into Queue Sales Team. If you are an AM or PSM, please ask your manager to grant you manager access.");
                        }
                    } else {
                        return new ActionResultVoid(false,"Rep Not Found");
                    }
                });
            } else {
                return actionResult;
            }

        });
    }

    public async logout(): Promise<ActionResultVoid> {
        return AccountPersistence.instance().signOut();
    }

    public async managerLoginNewOffice(signUpData: ManagerSignUpInfo): Promise<ActionResultVoid> {
      let loginResult: ActionResult<AccountInfo> = await AccountPersistence.instance().login(signUpData.email, signUpData.password);

      if (loginResult.success) {
        let loadExistingProfileResult: ActionResult<DatabaseDocument> = await RepPersistence.instance().getRep(this.getCurrentUserID());

        if (loadExistingProfileResult.success) {
          let repConverter = new RepConverter();
          let repObject = repConverter.fromPersistence(loadExistingProfileResult.payload!)
          let initializationResult: ActionResult<string> = await AccountPersistence.instance().createNewOfficeForManager(signUpData, repObject, this.getCurrentUserID());
          if (initializationResult.success) {
              initializationResult = await TransferRequestPersistence.instance().executeTrasnfer(this.getCurrentUserID(), initializationResult.payload!, signUpData.officeName, signUpData.division, signUpData.region);
          }

          if (initializationResult.success) {
            let reloadProfileResult: ActionResult<DatabaseDocument> = await RepPersistence.instance().getRep(this.getCurrentUserID());

            if (reloadProfileResult.success) {
              let repConverter = new RepConverter();
              let repObject = repConverter.fromPersistence(reloadProfileResult.payload!)
              this.currentUser = repObject;
              return new ActionResultVoid(true);
            }
            return new ActionResultVoid(false,
                "We were able to create your account, but there was problem loading your profile. Please try logging in again or call us at (201) 739-6630");
          } else {
            return new ActionResultVoid(false,
                "We were able to create your account, but there was problem initializing your data, please try creating an account with a different email, or contact us at queue.app.team@gmail.com");
          }
        } else {
          //Unable to find their profile
          return new ActionResultVoid(false,
              "We had trouble loading your rep account. We are very sorry about this. Please contact us at queue.app.team@gmail.com for help");
        }


      } else {
        return loginResult;
      }
    }

    public async managerSignUp(signUpData: ManagerSignUpInfo): Promise<ActionResultVoid> {
        //make sure all fields have no leading or trailing whitespace
        signUpData = {
            firstName: signUpData.firstName.trim(),
            lastName: signUpData.lastName.trim(),
            email: signUpData.email.trim(), 
            phoneNumber: signUpData.phoneNumber.trim(),
            password: signUpData.password.trim(), 
            confirmPassword: signUpData.confirmPassword.trim(),
            officeName: signUpData.officeName.trim(),
            officeNumber: signUpData.officeNumber.trim(),
            division: signUpData.division.trim(),
            region: signUpData.region.trim()
        };
        //first register the user with auth
        let registrationResult: ActionResult<AccountInfo> = await AccountPersistence.instance().registerUser(signUpData.email, signUpData.password);
        if (registrationResult.success) {
            
            //then initialize their team and their data in the db
            let initializationResult: ActionResult<string> = await AccountPersistence.instance().initializeManagerData(signUpData, registrationResult.payload!.userID);
            if (initializationResult.success) {
                this.currentUser = {
                    repID: this.getCurrentUserID(),
                    isActive: true,
                    markedInactiveTimeStamp: null,
                    contactCount: 0,
                    firstName: signUpData.firstName,
                    lastName: signUpData.lastName,
                    novice: true,
                    phoneNumber: signUpData.phoneNumber,
                    registrationTimeStamp: new Date(),
                    totalCallsMade: 0,
                    totalCallBacks: 0,
                    totalAppointmentsBooked: 0,
                    totalDeclines: 0,
                    currentBrainstormListSize: 0,
                    allTimeBrainstormListSize: 0,
                    isManager: true,
                    lastSignOn: new Date(),
                    officeNumber: initializationResult.payload!,
                    teamName: signUpData.officeName,
                    notes: "",
                    closingGrade: null,
                    recommendationsGrade: null,
                    phoningGrade: null,
                    group: "Managers",
                    team: DEFAULT_TEAM,
                    starterKitStatus: StarterKitStatus.NOTRETURNED, 
                    salesTeamWebsiteVersionNumber: CURRENT_APPLICATION_VERSION_NUMBER,
                    salesTeamWebsiteVersionString: CURRENT_APPLICATION_VERSION_STRING
                };
                return new ActionResultVoid(true)
            } else {
                return new ActionResultVoid(false, 
                    "We were able to create your account, but there was problem initializing your data, please try again with a different email, or contact us at queue.app.team@gmail.com");
            }

        } else {
            return registrationResult;
        }
    }

}
