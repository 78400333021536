import React, { ChangeEvent } from "react";
import { Grid, Typography, IconButton, Button, createMuiTheme, TableCell, TableRow, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Select, MenuItem, FormHelperText, Switch, Input } from "@material-ui/core";
import Rep from '../../model/rep';
import PdiWeek from "../../model/pdi_week";
import Appointment from "../../model/appointment";
import {PdiStatus} from "../../model/pdi_status";
import DateUtils from "../../services/date_utils";
import { Create} from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import { RepService } from "../../services/rep_service";
import  RepStore from "../../services/rep_store";
import PdiWeekService from "../../services/pdi_service";
import '../common.css';
import { QueueColor } from "../../model/queue_color";

import QueueCheckbox from 'ui/components/common/QueueCheckbox';

import 'ui/css/common_other.css';
import ColoredCardBackground, { BackgroundColor } from "./colored_card_background";

interface RepDisplayCardProps {
    rep: Rep,
    pdiWeek: PdiWeek,
    appointments: Appointment[],
    weekID: string,
    isChecked: boolean,
    onCheck: () => void,
}

interface DemoGoalCellProps {
    pdiWeek: PdiWeek,
    rep: Rep,
    appointments: Appointment[],
    day: number, 
    weekID: string
}

interface PdiStatusCellProps {
    weekID: string, 
    pdiWeek: PdiWeek
}

function getInputItem(width: number) {
    const style = (width === 0) ? {} : { width: width };

    return <Input
      style={style}
      disableUnderline={true}
    />
}

export function getRepTableRowElements(props: RepDisplayCardProps): any[] {
    return [
        <QueueCheckbox
            checked={props.isChecked}
            onChange={props.onCheck}
        />,
        <React.Fragment>
            <div className={"Standard-Text Bold"}>
                {props.rep.firstName + " " + props.rep.lastName}
            </div>
            <div className="Standard-Text">
                {"(" + props.rep.phoneNumber.substring(0,3) + ") " + props.rep.phoneNumber.substring(3,6) + "-" + props.rep.phoneNumber.substring(6,10)}
            </div>
        </React.Fragment>,
        <PdiStatusCell weekID={props.weekID} pdiWeek={props.pdiWeek}/>,
        <DemoGoalCell pdiWeek={props.pdiWeek} rep={props.rep} appointments={props.appointments} day={0} weekID={props.weekID}/>,
        <DemoGoalCell pdiWeek={props.pdiWeek} rep={props.rep} appointments={props.appointments} day={1} weekID={props.weekID}/>,
        <DemoGoalCell pdiWeek={props.pdiWeek} rep={props.rep} appointments={props.appointments} day={2} weekID={props.weekID}/>,
        <DemoGoalCell pdiWeek={props.pdiWeek} rep={props.rep} appointments={props.appointments} day={3} weekID={props.weekID}/>,
        <DemoGoalCell pdiWeek={props.pdiWeek} rep={props.rep} appointments={props.appointments} day={4} weekID={props.weekID}/>,
        <DemoGoalCell pdiWeek={props.pdiWeek} rep={props.rep} appointments={props.appointments} day={5} weekID={props.weekID}/>,
        <DemoGoalCell pdiWeek={props.pdiWeek} rep={props.rep} appointments={props.appointments} day={6} weekID={props.weekID}/>
    ];
}

class PdiStatusCell extends React.Component<PdiStatusCellProps, {}> {

    

    render() {
        let currentVectorDay = DateUtils.toVectorDay(new Date().getDay());
        let isCurrentWeek = DateUtils.getWeekIDForOffset(0) === this.props.weekID;
        let status = this.props.pdiWeek.dailyStatuses[currentVectorDay] ?? PdiStatus.NotTried;
        return isCurrentWeek ?
            <ColoredCardBackground
            color={status === PdiStatus.NotTried ? BackgroundColor.NONE : (status === PdiStatus.NoAnswer || status === PdiStatus.TextedNoResponse ? BackgroundColor.YELLOW : BackgroundColor.GREEN)}
            kids={[<Select
                input={getInputItem(128)}
                value={status}
                onChange={(event: ChangeEvent<{value: unknown}>) => {
                    let newValue = event.target.value as PdiStatus;
                    this.props.pdiWeek.dailyStatuses[currentVectorDay] = newValue;
                    this.setState({});
                    PdiWeekService.instance().updatePdiWeek(this.props.pdiWeek, this.props.weekID);
                }}
                >
                    <MenuItem value={PdiStatus.NotTried}>-</MenuItem>
                    <MenuItem value={PdiStatus.TextedNoResponse}>Texted (No Response)</MenuItem>
                    <MenuItem value={PdiStatus.TextedResponse}>Texted (Got Response)</MenuItem>
                    <MenuItem value={PdiStatus.NoAnswer}>No Answer</MenuItem>
                    <MenuItem value={PdiStatus.LeftMessage}>Left Message</MenuItem>
                    <MenuItem value={PdiStatus.Reached}>Reached (Outgoing)</MenuItem>
                    <MenuItem value={PdiStatus.CalledIn}>Reached (Incoming)</MenuItem>
                </Select>]}
            />
            : null;
    }
}

class DemoGoalCell extends React.Component<DemoGoalCellProps, {}> {

    constructor(props: DemoGoalCellProps) {
        super(props);
        this.renderPdiWeekData = this.renderPdiWeekData.bind(this);
        this.onGoalsUpdated = this.onGoalsUpdated.bind(this);
    }

    

    renderPdiWeekData (dayOfWeek: number) {

        return (
          <Select
          input={getInputItem(28)}
          value={this.props.pdiWeek.dailyGoals[dayOfWeek] ?? -1}
          onChange={(event: any) => {
              let newValue = event.target.value as number;
              if (newValue === -1) {
                  this.onGoalsUpdated(dayOfWeek, null);
              } else {
                  this.onGoalsUpdated(dayOfWeek, newValue);
              }
              }}
          renderValue={(value: any) => {
            return <div >{(value >= 0) ? value : '-'}</div>
          }}
    
          >
              <MenuItem value={-1}>-</MenuItem>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
              <MenuItem value={13}>13</MenuItem>
              <MenuItem value={14}>14</MenuItem>
              <MenuItem value={15}>15</MenuItem>
          </Select>
        );
    }

    onGoalsUpdated(dayOfWeek: number, value: number | null) {
        let oldGoals = this.props.pdiWeek.dailyGoals.slice();
        this.props.pdiWeek.dailyGoals[dayOfWeek] = value;
        this.setState({});
        //value entered is actually a number, update the database
        if (oldGoals.every((goal) => goal === null) && this.props.pdiWeek.dailyStatuses.every((status) => status === null)) {
            //every goal is null, so the doc doesnt exist in the database, we must create it
            PdiWeekService.instance().createPdiWeek(this.props.pdiWeek, this.props.weekID).then((result) => console.log(result));
        } else {
            //every goal is live, so we can just write
            PdiWeekService.instance().updatePdiWeek(this.props.pdiWeek, this.props.weekID).then((result) => console.log(result));;
        }
    }

    render() {
        let appointmentWeek: number[] = [0,0,0,0,0,0,0];
        this.props.appointments.forEach(appointment => {
            let appointmentDate = appointment.startTimeStamp;
            let vectorDay = DateUtils.toVectorDay(appointmentDate.getDay())
            appointmentWeek[vectorDay]= appointmentWeek[vectorDay]+1;
        });
        let appointmentsToday = appointmentWeek[this.props.day];
        let goalToday = this.props.pdiWeek.dailyGoals[this.props.day];
    

    
        let color: BackgroundColor;
        if (goalToday === null) {
            // goals not set
            color = BackgroundColor.NONE;
        } else {
            let difference: number = appointmentsToday - goalToday;
            if (difference >= 0) {
                color = BackgroundColor.GREEN;
            } else if (appointmentsToday === 0) {
                color = BackgroundColor.RED;
            } else {
                color = BackgroundColor.YELLOW;
            }
        }
        return (
            <ColoredCardBackground color={color} kids={[<p className="Standard-Text">{appointmentsToday}</p>,<p className="Standard-Text">/</p>,this.renderPdiWeekData(this.props.day)]}/>
        );
    }
}

class RepDisplayCard extends React.Component<RepDisplayCardProps, {}>{
    //private currentPdiWeek: PdiWeek;

    constructor (props: RepDisplayCardProps){
    super (props);

    this.renderPdiWeekData = this.renderPdiWeekData.bind(this);
    this.onGoalsUpdated = this.onGoalsUpdated.bind(this);
    this.renderAppointmentDay = this.renderAppointmentDay.bind(this);

  }

  renderAppointmentDay (day: number, appointmentWeek: number[]) {

    let appointments = appointmentWeek[day];
    let goals = this.props.pdiWeek.dailyGoals[day];
    

    
    let color: BackgroundColor;
    if (goals === null) {
        // goals not set
        color = BackgroundColor.NONE;
    } else {
        let difference: number = appointments - goals;
        if (difference >= 0) {
            color = BackgroundColor.GREEN;
        } else if (appointments === 0) {
            color = BackgroundColor.RED;
        } else {
            color = BackgroundColor.YELLOW;
        }
    }
    return (
      <TableCell>
          <ColoredCardBackground color={color} kids={[<p className="Standard-Text">{appointments}</p>,<p className="Standard-Text">/</p>,this.renderPdiWeekData(day)]}/>
      </TableCell>
    );
  }

  getInputItem(width: number) {
    const style = (width === 0) ? {} : { width: width };

    return <Input
      style={style}
      disableUnderline={true}
    />
  }
  renderPdiWeekData (dayOfWeek: number) {

    return (
      <Select
      input={this.getInputItem(28)}
      value={this.props.pdiWeek.dailyGoals[dayOfWeek] ?? -1}
      onChange={(event: any) => {
          let newValue = event.target.value as number;
          if (newValue === -1) {
              this.onGoalsUpdated(dayOfWeek, null);
          } else {
              this.onGoalsUpdated(dayOfWeek, newValue);
          }
          }}
      renderValue={(value: any) => {
        return <div >{(value >= 0) ? value : '-'}</div>
      }}

      >
          <MenuItem value={-1}>-</MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={11}>11</MenuItem>
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={13}>13</MenuItem>
          <MenuItem value={14}>14</MenuItem>
          <MenuItem value={15}>15</MenuItem>
      </Select>
    );
}
  onGoalsUpdated(dayOfWeek: number, value: number | null) {
        let oldGoals = this.props.pdiWeek.dailyGoals.slice();
        this.props.pdiWeek.dailyGoals[dayOfWeek] = value;
        this.setState({});
        //value entered is actually a number, update the database
        if (oldGoals.every((goal) => goal === null) && this.props.pdiWeek.dailyStatuses.every((status) => status === null)) {
            //every goal is null, so the doc doesnt exist in the database, we must create it
            PdiWeekService.instance().createPdiWeek(this.props.pdiWeek, this.props.weekID).then((result) => console.log(result));
        } else {
            //every goal is live, so we can just write
            PdiWeekService.instance().updatePdiWeek(this.props.pdiWeek, this.props.weekID).then((result) => console.log(result));;
        }
    }

 render () {
    let appointmentWeek: number[] = [0,0,0,0,0,0,0];
    const { isChecked, onCheck, appointments } = this.props;
    appointments.forEach(appointment => {
       let appointmentDate = appointment.startTimeStamp;
       let vectorDay = DateUtils.toVectorDay(appointmentDate.getDay())
       appointmentWeek[vectorDay]= appointmentWeek[vectorDay]+1;

    });
    let currentVectorDay = DateUtils.toVectorDay(new Date().getDay());
    let isCurrentWeek = DateUtils.getWeekIDForOffset(0) === this.props.weekID;
    let status = this.props.pdiWeek.dailyStatuses[currentVectorDay] ?? PdiStatus.NotTried;
    return (
        <React.Fragment>
            <TableRow key={this.props.rep.repID} className="Table-Row">
                <QueueCheckbox
                  checked={isChecked}
                  onChange={onCheck}
                />
              <TableCell>
                  <div className={"Standard-Text Bold"}>
                      {this.props.rep.firstName + " " + this.props.rep.lastName}
                  </div>
                  <div className="Standard-Text">
                      {"(" + this.props.rep.phoneNumber.substring(0,3) + ") " + this.props.rep.phoneNumber.substring(3,6) + "-" + this.props.rep.phoneNumber.substring(6,10)}
                  </div>
              </TableCell>

              <TableCell>
                {isCurrentWeek ?
                    <ColoredCardBackground
                    color={status === PdiStatus.NotTried ? BackgroundColor.NONE : (status === PdiStatus.NoAnswer || status === PdiStatus.TextedNoResponse ? BackgroundColor.YELLOW : BackgroundColor.GREEN)}
                    kids={[<Select
                        input={this.getInputItem(128)}
                        value={status}
                        onChange={(event: ChangeEvent<{value: unknown}>) => {
                            let newValue = event.target.value as PdiStatus;
                            this.props.pdiWeek.dailyStatuses[currentVectorDay] = newValue;
                            this.setState({});
                            PdiWeekService.instance().updatePdiWeek(this.props.pdiWeek, this.props.weekID);
                        }}
                        >
                            <MenuItem value={PdiStatus.NotTried}>-</MenuItem>
                            <MenuItem value={PdiStatus.TextedNoResponse}>Texted (No Response)</MenuItem>
                            <MenuItem value={PdiStatus.TextedResponse}>Texted (Got Response)</MenuItem>
                            <MenuItem value={PdiStatus.NoAnswer}>No Answer</MenuItem>
                            <MenuItem value={PdiStatus.LeftMessage}>Left Message</MenuItem>
                            <MenuItem value={PdiStatus.Reached}>Reached (Outgoing)</MenuItem>
                            <MenuItem value={PdiStatus.CalledIn}>Reached (Incoming)</MenuItem>
                        </Select>]}
                    />
                    : null
                }
              </TableCell>

              {this.renderAppointmentDay(0, appointmentWeek)}
              {this.renderAppointmentDay(1, appointmentWeek)}
              {this.renderAppointmentDay(2, appointmentWeek)}
              {this.renderAppointmentDay(3, appointmentWeek)}
              {this.renderAppointmentDay(4, appointmentWeek)}
              {this.renderAppointmentDay(5, appointmentWeek)}
              {this.renderAppointmentDay(6, appointmentWeek)}
            </TableRow>
        </React.Fragment>
        );
  }

 
}

export default RepDisplayCard;
