import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import { OnStreamUpdate, SubscriptionGenerator, Unsubscriber } from "./subscription_generator";
import { toQueueDatatbaseDocList } from "./persistence_utls";
import { DatabaseDocument } from "./persisted_object";
import ActionResultVoid from "../model/action_result_void";
import Chat from "model/chat";
import ChatConverter from "model/converters/chat_converter";

export default class ChatPersistence {
    private constructor() {}

    private static sharedInstance: ChatPersistence = new ChatPersistence();

    public static instance() {
        return this.sharedInstance; 
    }

    public async updateChat (doc: DatabaseDocument) : Promise<ActionResultVoid> {
        return firebaseApp.firestore().collection("Chats").doc(doc.id).update(doc.data).then(() => {
         return new ActionResultVoid(true, );
     }).catch((error) => {
         return new ActionResultVoid(false, error.message);
        });
    }
    
    public async createNewChat(chatDoc: DatabaseDocument, messageDoc: DatabaseDocument): Promise<ActionResultVoid> {
        //create chat object
        //create initial message object
        //with a batch write
        let batch: firebase.firestore.WriteBatch = firebaseApp.firestore().batch(); 
        let chatRef = firebaseApp.firestore().collection("Chats").doc(chatDoc.id);
        let messageRef = firebaseApp.firestore().collection("Messages").doc();
        let messageData = messageDoc.data; 
        messageData["chatID"] = chatRef.id;
        batch.set(messageRef, messageData);
        batch.set(chatRef, chatDoc.data);
        return batch.commit().then(() => {
            return new ActionResultVoid(true);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public async sendNewMessage (chatDoc: DatabaseDocument, messageDoc: DatabaseDocument): Promise<ActionResultVoid> {
        let batch: firebase.firestore.WriteBatch = firebaseApp.firestore().batch(); 
        let chatRef = firebaseApp.firestore().collection("Chats").doc(chatDoc.id); 
        let msgRef = firebaseApp.firestore().collection("Messages").doc(); 
        batch.set(msgRef, messageDoc.data);
        batch.update(chatRef, chatDoc.data);
        return batch.commit().then(() => {
            return new ActionResultVoid(true); 
        }).catch((error) => {
            return new ActionResultVoid(false, error.message); 
        });
    }

    public async markSeen(dateSeen: Date, chatID: string, userID: string): Promise<ActionResultVoid> {
        //query all messages that have been sent in this chat that the user has not seen
        //use batch write to show that the user has seen them
        //syntax for querying array membership: citiesRef.where("regions", "array-contains", "west_coast")
        let chatRef = firebase.firestore().collection("Chats").doc(chatID);
        return firebase.firestore().runTransaction(async (transaction) => {
            let chatDoc = await transaction.get(chatRef);
            if (!chatDoc.exists) {
                return new ActionResultVoid(false, "Chat doesn't exist.");
            }
            var newLastOpenedDates = chatDoc.data()!["lastOpenedDates"];
            newLastOpenedDates[userID] = dateSeen; 
            transaction.update(chatRef, {"lastOpenedDates": newLastOpenedDates});
            return new ActionResultVoid(true)
        }).then(() => {
            return new ActionResultVoid(true);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public getChatID (): string {
        return firebase.firestore().collection("Chats").doc().id; 
    }

    public getChatSubscriptionGenerator (teamID: string, userID: string): SubscriptionGenerator {
        return (onStreamUpdate:  OnStreamUpdate):Unsubscriber => {

            let unsubscribeFromManagerChats = firebaseApp.firestore().collection("Chats")
            .where("teamID", "==", teamID).where("includeManagers", "==", true)
            .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                onStreamUpdate(toQueueDatatbaseDocList(snapshot));
            }, (error) => {
                onStreamUpdate([]);
            });
            let unsubscribeFromUserChats = firebase.firestore().collection("Chats").where("teamID", "==", teamID).where("members", "array-contains", userID)
            .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                if (!snapshot.empty) {
                    onStreamUpdate(snapshot.docs.map((chatDoc) => {
                        return {
                            id: chatDoc.id,
                            data: chatDoc.data()
                        };
                    }));
                } else {
                    onStreamUpdate([]);
                }
            }, (error) => {
                onStreamUpdate([]);
            });

            return {
                unsubscribe: () => {
                    unsubscribeFromManagerChats();
                    unsubscribeFromUserChats();
                }
            }
        };
    }


}