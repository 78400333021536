import TransferRequest from "model/transfer_request";
import Store from "./store";
import TransferRequestConverter from "model/converters/transfer_request_converter";
import ListenerClient from "./listener_client";
import TransferRequestPersistence from "persistence/transfer_request_persistence";
import { AuthenticationService } from "./authentication_service";
import { RequestStatus } from "model/request_status";




export default class TransferRequestStore extends Store<TransferRequest> {
    private constructor() {
        super(new TransferRequestConverter());
        this.getPendingRequests = this.getPendingRequests.bind(this);
    }

    private static toThisOfficeRequestStoreInstance: TransferRequestStore = new TransferRequestStore();
    private static fromThisOfficeRequestStoreInstance: TransferRequestStore = new TransferRequestStore();

    public static toThisOfficeInstance(): TransferRequestStore {
        return this.toThisOfficeRequestStoreInstance;
    }

    public static fromThisOfficeInstance(): TransferRequestStore {
        return this.fromThisOfficeRequestStoreInstance;
    }

    private requestCache?: TransferRequest[];

    updateCache(newData: TransferRequest[]): void {
        this.requestCache = newData;
    }

    public clearCache(): void {
        this.requestCache = undefined;
    }

    isLoaded(): boolean {
        return this.requestCache !== undefined;
    }

    getPendingRequests(): TransferRequest[] {
        return this.requestCache!.filter((t) => t.status === RequestStatus.Pending);
    }

    getRequests(): TransferRequest[] {
        return this.requestCache!;
    }
    
    public static toThisOfficeListenerClient(): ListenerClient<TransferRequestStore> {
        return new ListenerClient<TransferRequestStore>(
            this.toThisOfficeInstance(), 
            TransferRequestPersistence.instance().getRequestsToOfficeSubscriptionGenerator(AuthenticationService.instance().getCurrentUserOfficeNumber())
        );
    }

    public static fromThisOfficeListenerClient(): ListenerClient<TransferRequestStore> {
        return new ListenerClient<TransferRequestStore>(
            this.fromThisOfficeInstance(), 
            TransferRequestPersistence.instance().getRequestsFromOfficeSubscriptionGenerator(AuthenticationService.instance().getCurrentUserOfficeNumber())
        );
    }
    
}