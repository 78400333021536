import QueueMessage from "../queue_message";
import Converter from './converter';
import { DatabaseDocument, DocumentData } from "../../persistence/persisted_object";
import {Message} from "react-chat-ui" ;
import { AuthenticationService } from "services/authentication_service";
import { RepService } from "services/rep_service";
import RepStore from "services/rep_store";
import Rep from "model/rep";

export default class MessageConverter extends Converter<QueueMessage> {
    fromPersistence(doc: DatabaseDocument): QueueMessage {
        let data = doc.data;
        let id = doc.id;
        let resultMessage: QueueMessage = {
            id: id, 
            chatId: data["chatID"],
            senderId: data["senderID"],
            messageContent: data["messageContent"],
            sendDate: data["sendDate"].toDate(),
            
        };
        return resultMessage;
    } 

    toPersistence(message: QueueMessage): DatabaseDocument {
        let data: DocumentData = { 
            "chatID": message.chatId,
            "senderID": message.senderId,
            "messageContent": message.messageContent,
            "sendDate": message.sendDate,
            
        };
         let resultDatabaseDocument: DatabaseDocument = {
             id: message.id, 
             data: data,
         };
         return resultDatabaseDocument;
    }

    toDisplayMessage (messageToConvert: QueueMessage): typeof Message {
        let idForMessage: number; 
        if (messageToConvert.senderId === AuthenticationService.instance().getCurrentUserID()) {
            idForMessage = 0;
        }
        else {
            idForMessage = 1;
        } 
        let nameOfSender: string = RepService.getUserName(messageToConvert.senderId);
        let displayMessage: typeof Message = {
            id: idForMessage, 
            message: messageToConvert.messageContent,
            senderName: nameOfSender,
        }
        return displayMessage; 
    }
}