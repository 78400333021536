import React from 'react';
import { TableRow, TableHead, TableCell, TableBody, FormControlLabel, Checkbox, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import Appointment from "model/appointment";
import Rep from "model/rep";

interface DemosTotalRowProps {
  demos: Appointment[]
}

export default class DemosTotalRow extends React.Component<DemosTotalRowProps> {

  render() {
    const { demos } = this.props
    let total = demos.length
    var hasBeenCalledIn = 0
    var recCount = 0
    var cpo = 0
    for (let index = 0; index < demos.length; index++) {
      const demo = demos[index];
      if (demo.hasBeenCalledIn === true) hasBeenCalledIn++
      if (demo.cpo > 0) cpo = cpo + Number(demo.cpo)
      if (demo.recCount > 0) recCount = recCount + Number(demo.recCount)
    }

    return (
      <TableRow key={"total_row"}>
        <TableCell>
            <div style={{ marginLeft: 10, height: 30, alignItems: "center", display: "flex" }}>
            {`${hasBeenCalledIn} / ${total}`}
            </div>
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />

        <TableCell>
          <Typography>
          {cpo}
          </Typography>
        </TableCell>

        <TableCell />

        <TableCell>
          <Typography>
          {recCount}
          </Typography>
        </TableCell>

        <TableCell />
      </TableRow>
    )

  }
}

const styles = {
  leftContainer: {
    height: 30,
    paddingRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'blue',
  }
}
