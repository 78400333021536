import React from 'react';

import Sticky from 'react-sticky-el';

import GuardedListenerComponent from './guarded_listener_component';

import { Grid, Select, MenuItem, FormHelperText, Button, CircularProgress, IconButton} from '@material-ui/core';
import * as moment from 'moment';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import PhoneTimeStore from 'services/phone_time_store';
import RepStore from 'services/rep_store';
import { RepService } from "services/rep_service";

import RepConverter from 'model/converters/rep_converter';
import KPI from 'ui/components/common/KPI';
import Funnel from 'ui/components/common/Funnel';
import Notes from 'ui/components/common/Notes';
import TimeSelector from 'ui/components/common/TimeSelector';
import TeamStore from 'services/team_store';
import DateUtils from "services/date_utils";
import {isDefaultGroup, isDefaultTeam, DEFAULT_TEAM} from "model/rep_collection";
import * as util from 'utility';
import 'ui/css/common_text.css';
import EditRepDialog from './edit_rep_dialog';

class RepShelf extends GuardedListenerComponent {

  constructor(props) {
    super(props);

    this.phonetimeStores = [];
    this.timeValues = ['Today', 'Week', 'Month', 'Year', 'Custom'];

    this.state = {
      selectedTime: 'Week',
      startDate: DateUtils.lastWeek(),
      endDate: DateUtils.tomorrow(),
      selectedReps: props.selectedReps,
      clearSelectedReps: props.clearSelectedReps,
      showEditRepDialog: false,
    }
  }

  forceDataRefresh() {
    console.log('DEV: Force data refresh executing in  rep shelf');
    super.refreshListenerClients(this.getEmployedListenerClients());
  }
  updateSelectedTime(selectedTime) {

    var startDate = new Date();
    var endDate = new Date();

    switch (selectedTime) {
      case 'All Time':
        startDate = DateUtils.start();
        endDate = DateUtils.tomorrow();
        break;
      case 'Year':
        startDate = DateUtils.lastYear();
        endDate = DateUtils.tomorrow();
        break;
      case 'Month':
        startDate = DateUtils.lastMonth();
        endDate = DateUtils.tomorrow();
        break;
      case 'Week':
        startDate = DateUtils.lastWeek();
        endDate = DateUtils.tomorrow();
        break;
      case 'Today':
        startDate = DateUtils.today();
        endDate = DateUtils.tomorrow();
        break;
      default:
        startDate = DateUtils.lastWeek();
        endDate = DateUtils.tomorrow();
    }
    this.state.selectedTime = selectedTime;
    this.state.startDate = startDate;
    this.state.endDate = endDate;

    this.setState({ selectedTime, startDate, endDate });
    this.forceDataRefresh();
    //update listener clients
  }

  renderHeader() {
    const { selectedRows, selectedReps, clearSelectedReps } = this.props;
    if (!selectedRows || !selectedReps || selectedReps.length < 1) return null;

    let text = ''
    if (selectedReps === null || selectedReps.length === 0) {
      text = "None selected";
    } else if (selectedReps.length === 1) {
      const rep = selectedReps[0];
      text = `${rep.firstName} ${rep.lastName}`;
    } else if (selectedRows.has('__all')) {
      text = `Full office (${selectedReps.length})`;
    } else {
      text = `${selectedReps.length} Reps Selected`
    }

    return (
      <div style={{display: "flex"}}>
        <IconButton style={{flex: "0 0 50px"}} onClick={clearSelectedReps}>
          <CloseIcon/>
        </IconButton>
        <div
          style={styles.headerContainer}
          className={'Title-Text'}
        >
          {text}
        </div>
        {selectedReps.length !== 1 ? null : 
        <IconButton style={{flex: "0 0 50px"}} onClick={() => {
          this.setState({
            showEditRepDialog: true
          });
        }}>
          <EditIcon/>
        </IconButton>}
      </div>
    )
  }
  renderContactInfo() {
    const { selectedReps } = this.props;
    if (selectedReps.length > 1) return;
    const selectedRep = selectedReps[0];

    const phoneString = util.getFormattedPhone(selectedRep.phoneNumber);
    const date = selectedRep.lastSignOn;
    const dateString = DateUtils.toMonthSlashDayString(date);

    let today = new Date();
    let differenceDays = ((today.getTime()-date.getTime())/86400000);
    const colorClass = (differenceDays>=1) ? 'Old' : 'Recent';

    return (
      <div style={styles.contactInfoContainer}>
            <div className={'Standard-Text Faded'}>
              {phoneString}
            </div>

            <div style={{ width: 20 }} />
            <div className={`Standard-Text Faded ${colorClass}`}>
              {`Last sign-in ${dateString}`}

            </div>
      </div>
    )
  }
  renderKPIs() {
    const { selectedReps, appointmentStore } = this.props;
    var contactCount = 0;
    var appointmentCount = 0;
    var brainstormCount = 0;
    selectedReps.forEach((rep, i) => {
      contactCount += rep.contactCount;
      appointmentCount += appointmentStore.getAppointmentsForRep(rep.repID).length;
      brainstormCount += rep.currentBrainstormListSize;
    });

    return (
      <div style={styles.KPIRow}>
        <KPI value={brainstormCount} label={'Brainstorm list'} />
        <KPI value={contactCount} label={'Leads'}/>
        <KPI value={appointmentCount} label={'Appointments this Week'}/>
      </div>
    );
  }
  renderPercentages() {
    const { selectedReps } = this.props;
    let repIds = []
    for (var rep of selectedReps) {
      repIds.push(rep.repID);
    }

    const callsPerDemo = PhoneTimeStore.getCallsPerDemoForReps(repIds);
    const answerRate = PhoneTimeStore.getAnswerRate(repIds);
    const scheduleRate = PhoneTimeStore.getScheduleRate(repIds);

    return (
      <div style={styles.KPIRow}>
        <KPI value={Math.round(callsPerDemo*10)/10} label={'Calls Per Demo'}/>
        <KPI value={`${Math.round(answerRate*100)}%`} label={'Answer Percentage'}/>
        <KPI value={`${Math.round(scheduleRate*100)}%`} label={'Book Percentage'}/>
      </div>
    );
  }
  renderFunnel() {
    const { selectedReps } = this.props;
    let repIds = []
    for (var rep of selectedReps) {
      repIds.push(rep.repID);
    }

    const calls = PhoneTimeStore.getTotalCallsForReps(repIds);
    const answered = PhoneTimeStore.getTotalAnsweredForReps(repIds);
    const booked = PhoneTimeStore.getTotalBookedForReps(repIds);

    const values = [calls, answered, booked];
    const labels = ['Calls', 'Answered', 'Booked'];
    return (
      <Funnel
        values={values}
        labels={labels}
      />
    )
  }
  renderNotes() {
    const { selectedReps } = this.props;
    if (selectedReps.length !== 1) return null;
    const rep = selectedReps[0];

    return (
      <Notes rep={rep} />
    );
  }
  renderGroup() {
    const { selectedReps } = this.props;
    
    if (selectedReps.length > 1) {
      let group;

      let firstGroup = selectedReps[0].group;
      if (selectedReps.every((rep) => rep.group === firstGroup || (isDefaultGroup(firstGroup) && isDefaultGroup(rep.group)))) {
        group = firstGroup;
      }

      return <div style={styles.groupContainer}>
          <div className='Header-Text'>Group</div>
          <Select
            value={isDefaultGroup(group) ? "DEFAULT" : group}
            onChange={
              (event) => {
                const group = event.target.value;
                
                selectedReps.forEach((rep) => {
                  if (group !== "DEFAULT") {
                    rep.group = group;
                  } else {
                    rep.group = new RepConverter().getGroupForStartDate(rep.registrationTimeStamp);
                  }
                });
                this.setState({});
                RepService.instance().updateReps(selectedReps);
              }
            }
            >
            {
              Array.from(RepStore.activeRepStore().getRepGroups()).map((group) => {
                return <MenuItem key={group} value={group ?? "DEFAULT"}>{group ?? "DEFAULT"}</MenuItem>
              })
            }
          </Select>

        </div>;

    } else if (selectedReps.length === 1) {
      const rep = selectedReps[0];

      const { group } = rep

      return (
        <div style={styles.groupContainer}>
          <div className='Header-Text'>Group</div>
          <Select
            value={isDefaultGroup(group) ? "DEFAULT" : group}
            onChange={
              (event) => {
                const group = event.target.value;
                if (group !== "DEFAULT") {
                  rep.group = group;
                } else {
                  rep.group = new RepConverter().getGroupForStartDate(rep.registrationTimeStamp);
                }
                this.setState({});
                RepService.instance().updateRep(rep);
              }
            }
            >
            {
              Array.from(RepStore.activeRepStore().getRepGroups()).map((group) => {
                return <MenuItem key={group} value={group ?? "DEFAULT" }>{group ?? "DEFAULT"}</MenuItem>
              })
            }
          </Select>

        </div>
      );
    } else return null;
  }

  renderTeam() {
    const { selectedReps } = this.props;
    
    if (selectedReps.length > 1) {
      let team;

      let firstTeam = selectedReps[0].team;
      if (selectedReps.every((rep) => rep.team === firstTeam || (isDefaultTeam(firstTeam) && isDefaultTeam(rep.team)))) {
        team = firstTeam;
      }

      return <div style={styles.groupContainer}>
          <div className='Header-Text'>Team</div>
          <Select
            value={isDefaultTeam(team) ? "DEFAULT" : team}
            onChange={
              (event) => {
                const team = event.target.value;
                
                selectedReps.forEach((rep) => {
                  if (team !== "DEFAULT") {
                    rep.team = team;
                  } else {
                    rep.team = DEFAULT_TEAM;
                  }
                });
                this.setState({});
                RepService.instance().updateReps(selectedReps);
              }
            }
            >
            {
              Array.from(RepStore.activeRepStore().getRepTeams()).map((team) => {
                return <MenuItem key={team} value={team ?? "DEFAULT"}>{team ?? "DEFAULT"}</MenuItem>
              })
            }
          </Select>

        </div>;

    } else if (selectedReps.length === 1) {
      const rep = selectedReps[0];

      const { team } = rep

      return (
        <div style={styles.groupContainer}>
          <div className='Header-Text'>Team</div>
          <Select
            value={isDefaultTeam(team) ? "DEFAULT" : team}
            onChange={
              (event) => {
                const team = event.target.value;
                if (team !== "DEFAULT") {
                  rep.team = team;
                } else {
                  rep.team = DEFAULT_TEAM;
                }
                this.setState({});
                RepService.instance().updateRep(rep);
              }
            }
            >
            {
              Array.from(RepStore.activeRepStore().getRepTeams()).map((team) => {
                return <MenuItem key={team} value={team ?? "DEFAULT" }>{team ?? "DEFAULT"}</MenuItem>
              })
            }
          </Select>

        </div>
      );
    } else return null;
  }

  renderSkillGrades() {
    const { selectedReps } = this.props;
    if (selectedReps.length !== 1) return null;
    const rep = selectedReps[0];

    const { recommendationsGrade, closingGrade, phoningGrade } = rep;
    const dropDown = (description, value, onUpdate) => {
      return (
        <div style={{ marginRight: 10 }}>
        <Select value={value} onChange={(event) => {
            let newValue = event.target.value;
            onUpdate(newValue);
        }}>
            <MenuItem value="A+">A+</MenuItem>
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="C">C</MenuItem>
            <MenuItem value="D">D</MenuItem>
            <MenuItem value="F">F</MenuItem>
            <MenuItem value="-">-</MenuItem>
        </Select>
        <FormHelperText>{description}</FormHelperText>
        </div>
      )
    }

    return (
      <div style={styles.skillGradesContainer}>
        <div className="Header-Text">Skill Grades</div>

        <div style={{ display: 'flex'}}>
          {dropDown("Recs", recommendationsGrade, (value) => {
            rep.recommendationsGrade = value;
            this.setState({})
            RepService.instance().updateRep(rep);
          })}
          {dropDown("Closing", closingGrade, (value) => {
            rep.closingGrade = value;
            this.setState({})
            RepService.instance().updateRep(rep);
          })}
          {dropDown("Phoning", phoningGrade, (value) => {
            rep.phoningGrade = value;
            this.setState({})
            RepService.instance().updateRep(rep);
          })}
        </div>
      </div>
    )
  }
  renderSetManager() {
    const { selectedReps } = this.props;
    if (selectedReps.length > 1) return;
    const rep = selectedReps[0];
    //make sure that the office admin can't have their manager access revoked - 4/22/2020
    let team = TeamStore.instance().getTeam();

    return (
      <div style={{ float: 'left' }}>
        {rep.repID === team.admin ? "Office Administrator" : <Button
          variant='outlined'
          onClick={
            () => {
              rep.isManager = !rep.isManager;
              RepService.instance().updateRep(rep);
            }
          }
        >
        {(rep.isManager) ? 'Disable Manager Access' : 'Grant Manager Access'}
        </Button>}

      </div>
    )
  }
  renderSetInactive() {
    const { selectedReps } = this.props;
    if (selectedReps.length > 1) return;
    const rep = selectedReps[0];

    return (
      <div style={{ float: 'right'}}>
        <Button
          variant='outlined'
          onClick={
            () => {
              RepService.instance().toggleActive(rep);
            }
          }
        >
          {(rep.isActive) ? 'Mark Inactive' : 'Mark Active'}
        </Button>
      </div>
    )
  }
  renderTimeUnitSelector() {

    const { selectedTime } = this.state

    return (
      <TimeSelector
        values={this.timeValues}
        selectedValue={selectedTime}
        onValueChanged={(selectedTime) => {
          this.updateSelectedTime(selectedTime);
        }}
       />
    )

  }
  renderCustomDatePickers() {
    const { selectedTime, startDate, endDate } = this.state

    if (selectedTime !== 'Custom') return null;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container direction="row" justify="space-around">
         <KeyboardDatePicker
         style={{ width: '40%' }}
          autoOk="true"
           disableToolbar
           variant="inline"
           format="MM/dd/yyyy"
           margin="normal"
           maxDate={endDate}
           endDateMessage={'Range needs to be at least 1 day'}
           id="start-date-picker"
           label="Start (including this day)"
           value={startDate}
           onChange={(date) => {
             this.state.startDate = new Date(date)
             this.forceDataRefresh()
           }}
           KeyboardButtonProps={{
             'aria-label': 'change date',
           }}
         />
         <KeyboardDatePicker
          style={{ width: '40%' }}
          autoOk="true"
           disableToolbar
           variant="inline"
           format="MM/dd/yyyy"
           margin="normal"
           id="end-date-picker"
           label="End (including this day)"
           minDate={startDate}
           minDateMessage={'Range needs to be at least 1 day'}
           value={endDate}
           onChange={(date) => {
             this.state.endDate = moment(date).endOf('day').toDate()
             this.forceDataRefresh()
           }}
           KeyboardButtonProps={{
             'aria-label': 'change date',
           }}
         />
       </Grid>
      </MuiPickersUtilsProvider>
    )
  }
  getEmployedListenerClients() {
    //get selected reps
    const { selectedReps } = this.props;
    const { startDate, endDate } = this.state;
    let listeners = [];
    this.phoneTimeStores = []
    selectedReps.forEach((rep, i) => {
      const { repID } = rep;
      const listener = PhoneTimeStore.getPhoneTimeListenerClient(repID, startDate, endDate)
      listeners.push(listener);
      this.phoneTimeStores.push(listener.getStore());
    });

    return listeners;

  }
  getPathOnRefresh() {
      return "/reps";
  }

  renderGuarded () {
    const { selectedRows, selectedReps } = this.props;
    if (!selectedRows || !selectedReps || selectedReps.length < 1) return null;

    if (selectedReps != this.state.selectedReps) {
      this.state.selectedReps = selectedReps
      console.log('DEV: forcing data refresh on reps in rep shelf');
      this.forceDataRefresh();
    }

    let loading = false
    this.phoneTimeStores.forEach((store, i) => {
      if (!store.isLoaded()) {
        loading = true
      }
    });

    return (
      <Sticky>
      <div style={styles.container}>

        {this.renderHeader()}

        {this.renderContactInfo()}

        {this.renderKPIs()}

        {this.renderTimeUnitSelector()}

        {(loading) ? <CircularProgress /> : <div />}

        {this.renderCustomDatePickers()}

        {this.renderPercentages()}

        {this.renderFunnel()}

        {this.renderNotes()}

        <Grid container direction="row" spacing={2}>
          <Grid item>
            {this.renderGroup()}
          </Grid>
          <Grid item>
            {this.renderTeam()}
          </Grid>
        </Grid>
        {this.renderSkillGrades()}

        <div style={{ display: 'inline-block', marginTop: 10, width: '100%' }}>
          {this.renderSetManager()}
          {this.renderSetInactive()}
        </div>
        {
          this.state.showEditRepDialog ? <EditRepDialog onClose={() => this.setState({showEditRepDialog: false})} rep={this.state.selectedReps[0]} /> : null
        }
      </div>
      </Sticky>

    )
  }
}

const styles = {
  container: {
    overflow: 'hidden',
    padding: 20,
    boxShadow: '-1px 1px 10px 1px gray',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 20,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    maxHeight: "80vh",
    overflow: "auto",
    minWidth: "30vh"
  },
  headerContainer: {
    textAlign: 'center',
    margin: 5,
    flex: "1 1 auto"
  },
  contactInfoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  KPIRow: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: 16 ,
  },
  groupContainer: {
    marginTop: 10,
  },
  skillGradesContainer: {
    marginTop: 10
  },
}

export default RepShelf;
