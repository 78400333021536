import ActionResultVoid from "../model/action_result_void";
import OfficeEvent from "../model/office_event"
import {DatabaseDocument} from "../persistence/persisted_object";
import OfficeEventConverter from "../model/converters/office_event_converter";
import OfficeEventPersistence from "../persistence/office_event_persistence";
import { AuthenticationService } from "./authentication_service";

export class OfficeEventService {
    private constructor () {

    }

    private static sharedInstance: OfficeEventService = new OfficeEventService();

    public static instance(): OfficeEventService {
        return this.sharedInstance;
    }

    public async createOfficeEvent (officeEvents: OfficeEvent[]): Promise<ActionResultVoid> {
        let officeEventConverter = new OfficeEventConverter(); 
        let newOfficeEvents: DatabaseDocument[] = [];
        for (let i=0; i<officeEvents.length; i++)
        {
            newOfficeEvents.push(officeEventConverter.toPersistence(officeEvents[i]));
        }
        return await OfficeEventPersistence.instance().createOfficeEvent(newOfficeEvents, AuthenticationService.instance().getCurrentUserOfficeNumber());
    }

    public async updateOfficeEvent(officeEvent: OfficeEvent): Promise<ActionResultVoid> {
        let officeEventConverter = new OfficeEventConverter();
        let updatedOfficeEventData: DatabaseDocument = officeEventConverter.toPersistence(officeEvent);
        return await OfficeEventPersistence.instance().updateOfficeEvent(updatedOfficeEventData, AuthenticationService.instance().getCurrentUserOfficeNumber());
    }

    public async deleteOfficeEvent(officeEvent: OfficeEvent): Promise<ActionResultVoid> {
        return await OfficeEventPersistence.instance().deleteOfficeEvent(AuthenticationService.instance().getCurrentUserOfficeNumber(), officeEvent.id);
    }

    public async deleteOfficeEventRecurrence(officeEvent: OfficeEvent): Promise<ActionResultVoid> {
        return await OfficeEventPersistence.instance().deleteOfficeEventRecurrence(AuthenticationService.instance().getCurrentUserOfficeNumber(), officeEvent.recurrenceID);
    }

}