import GuardedListenerComponent from "ui/components/guarded_listener_component";
import ListenerClient from "services/listener_client";
import React from 'react';
import QueueAppbar from "ui/components/queue_appbar";
import TransferRequestStore from "services/transfer_request_store";
import { CircularProgress, Table, TableHead, TableRow, TableCell, TableBody, Button, createMuiTheme, ThemeProvider } from "@material-ui/core";
import TransferRequest from "model/transfer_request";
import TransferRequestService from "services/transfer_request_service";
import { AuthenticationService } from "services/authentication_service";
import { DEFAULT_FONT_SIZE } from "index";



export default class TransferRequestPage extends GuardedListenerComponent<{}, {}> {

    constructor(props: {}) {
        super(props);
        this.renderBody = this.renderBody.bind(this);
    }

    getEmployedListenerClients(): ListenerClient<any>[] {
        return [TransferRequestStore.fromThisOfficeListenerClient(), TransferRequestStore.toThisOfficeListenerClient()];
    }

    renderGuarded() {
        return <div> 
            <QueueAppbar shouldDisplayMenu/>
            {TransferRequestStore.toThisOfficeInstance().isLoaded() && TransferRequestStore.fromThisOfficeInstance().isLoaded() ? this.renderBody() : <CircularProgress/>}
        </div>;
    }

    renderBody() {
        let allRequests = TransferRequestStore.toThisOfficeInstance().getPendingRequests().concat(TransferRequestStore.fromThisOfficeInstance().getPendingRequests());

        allRequests.sort((a, b) => a.userName.localeCompare(b.userName));

        return <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Incoming/Outgoing
                    </TableCell>
                    <TableCell>
                        To/From
                    </TableCell>
                    <TableCell>
                        Approve
                    </TableCell>
                    <TableCell>
                        Decline
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {allRequests.length === 0 ? <div>No transfer requests to show. Reps can request office transfers from 'Settings' in Queue.</div> : allRequests.map(this.renderTransferRequestRow)}
            </TableBody>
        </Table>
    }

    renderTransferRequestRow(t: TransferRequest) {
        let outgoing = t.fromOfficeID === AuthenticationService.instance().getCurrentUserOfficeNumber();

        const declineTheme = createMuiTheme({
            palette: {
              primary: {
                main: '#e63939',
              },
            },
            typography: {
              fontSize: DEFAULT_FONT_SIZE,
            }
          });

        return <TableRow key={t.userID}>
            <TableCell>
                <p><strong>{t.userName}</strong></p>
                {t.userEmail +  " - " + t.userPhoneNumber}
            </TableCell>
            <TableCell>
                {outgoing ? "Outgoing Transfer" : "Incoming Transfer"}
            </TableCell>
            <TableCell>
                {outgoing ? "To " + t.toOfficeName + " - " + (t.toOfficeDivision ?? "") : "From " + t.fromOfficeName + " - " + (t.fromOfficeDivision ?? "")}
            </TableCell>
            <TableCell>
                <Button variant="outlined" color="primary" onClick={() => {
                    TransferRequestService.instance().approveRequest(t);
                }}>
                    Approve Request
                </Button>
            </TableCell>
            <TableCell>
                <ThemeProvider theme={declineTheme}>
                <Button variant="outlined" color="primary" onClick={() => {
                    TransferRequestService.instance().declineRequest(t);
                }}>
                    Decline Request
                </Button>
                </ThemeProvider>
            </TableCell>
        </TableRow>
    }

    getPathOnRefresh(): string {
        return "/transfer-requests";
    }
    
}