import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import ManagerSignUpInfo from "../model/manager_sign_up_info";
import ActionResultVoid from "../model/action_result_void";
import ActionResult from "../model/action_result";

import Rep from 'model/rep';
import { CURRENT_APPLICATION_VERSION_NUMBER, CURRENT_APPLICATION_VERSION_STRING } from "index";


//this file will be interact with our own database and record stripe data into the user accounts

export interface AccountInfo {
    email: string,
    userID: string,
}

export interface Team {
    managerName: string,
    division: string,
    region: string,
    officeName:string
}

export class AccountPersistence {
    private constructor(){}

    private static sharedInstance: AccountPersistence = new AccountPersistence();

    public static instance(): AccountPersistence {
        return this.sharedInstance;
    }

    public getCurrentUser(): AccountInfo | null{
        let currentUser: firebase.User | null = firebaseApp.auth().currentUser;
        if (currentUser === null) {
            return null;
        } else {
            return {
                email: currentUser!.email!,
                userID: currentUser!.uid
            };
        }
    }

    public async login(email: string, password: string): Promise<ActionResultVoid> {
    
        return firebaseApp.auth().signInWithEmailAndPassword(email, password).then((result: firebase.auth.UserCredential) => {
            return new ActionResultVoid(true);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

    public async registerUser(email: string, password: string): Promise<ActionResult<AccountInfo>> {
        //this method should create a new user in our authentication system with their email and password
        return firebaseApp.auth().createUserWithEmailAndPassword(email, password).then((result: firebase.auth.UserCredential) => {
            let info: AccountInfo = {
                email: email,
                userID: result.user!.uid
            };
            return new ActionResult(true, undefined, info);
        }).catch((error) => {
            return new ActionResult(false, error.message);
        });
    }

    //FIXME: createNewOfficeForManager has not been tested
    public async createNewOfficeForManager(signUpData: ManagerSignUpInfo, rep: Rep, userID: string): Promise<ActionResult<string>> {
      let batch: firebase.firestore.WriteBatch = firebaseApp.firestore().batch();

      const teamDocumentDatabaseLocation: firebase.firestore.DocumentReference =
          firebase.firestore().collection('Teams').doc();
      const userDocumentDatabaseLocation: firebase.firestore.DocumentReference =
          firebase.firestore().collection("Users").doc(userID);

      batch.set(teamDocumentDatabaseLocation, {
          "manager": rep.firstName + " " + rep.lastName,
          "division":signUpData.division,
          "name":signUpData.officeName,
          "region":signUpData.region,
          "complexImport":true,
          "vectorOfficeNumber":signUpData.officeNumber,
          "admin":userID
      });

      batch.update(userDocumentDatabaseLocation, {
          "officeNumber":teamDocumentDatabaseLocation.id,
          "officeName":signUpData.officeName,
          "region":signUpData.region,
          "division":signUpData.division,
          "manager":true,
          "active": true,
          "vectorOfficeNumber":signUpData.officeNumber,
          "lastSignOn": new Date(),
          "email": signUpData.email,
      });

      return batch.commit().then(() => {
          return new ActionResult<string>(true, undefined, teamDocumentDatabaseLocation.id);
      }).catch((error) => {
          return new ActionResult<string>(false, error.message);
      });
    }

    public async initializeManagerData(signUpData: ManagerSignUpInfo, userID: string): Promise<ActionResult<string>> {
        let batch: firebase.firestore.WriteBatch = firebaseApp.firestore().batch();

            const teamDocumentDatabaseLocation: firebase.firestore.DocumentReference =
                firebase.firestore().collection('Teams').doc();
            const userDocumentDatabaseLocation: firebase.firestore.DocumentReference =
                firebase.firestore().collection("Users").doc(userID);

            batch.set(teamDocumentDatabaseLocation, {
                "manager":signUpData.firstName + " " + signUpData.lastName,
                "division":signUpData.division,
                "name":signUpData.officeName,
                "region":signUpData.region,
                "complexImport":true,
                "vectorOfficeNumber":signUpData.officeNumber,
                "admin":userID
            });

            batch.set(userDocumentDatabaseLocation, {
                "firstName":signUpData.firstName,
                "lastName":signUpData.lastName,
                "email":signUpData.email,
                "phoneNumber":signUpData.phoneNumber,
                "officeNumber":teamDocumentDatabaseLocation.id,
                "officeName":signUpData.officeName,
                "region":signUpData.region,
                "division":signUpData.division,
                "registrationTimeStamp": firebase.firestore.Timestamp.now() ,
                "manager":true,
                "active": true,
                "novice": true,
                "saveToCalendar": true,
                "vectorOfficeNumber":signUpData.officeNumber,
                "totalCallsMade": 0,
                "totalDemosBooked": 0,
                "totalCallBacks": 0,
                "totalDeclines": 0,
                "contactCount": 0,
                "complexImport": true,
                "salesTeamWebsiteVersionNumber": CURRENT_APPLICATION_VERSION_NUMBER,
                "salesTeamWebsiteVersionString": CURRENT_APPLICATION_VERSION_STRING
            });

            return batch.commit().then(() => {
                return new ActionResult<string>(true, undefined, teamDocumentDatabaseLocation.id);
            }).catch((error) => {
                return new ActionResult<string>(false, error.message);
            });
    }


    public async saveBillingInfo(): Promise<void> {
        //this method should save userID to Team's collection
        //this method should billing info to Users collection
    }

    public async signOut(): Promise<ActionResultVoid> {
        return firebaseApp.auth().signOut().then(() => {
            return new ActionResultVoid(true);
        }).catch((error) => {
            return new ActionResultVoid(false, error.message);
        });
    }

} // Class
