import * as firebase from "firebase/app";
import { DatabaseDocument } from "./persisted_object";
import ActionResultVoid from "model/action_result_void";

export function toQueueDatatbaseDocList(querySnapshot: firebase.firestore.QuerySnapshot) {
    return querySnapshot.docs.map(toQueueDatatbaseDoc);
}

export function toQueueDatatbaseDoc(doc: firebase.firestore.DocumentSnapshot): DatabaseDocument {
    return {
        id: doc.id,
        data: doc.data()!
    };
}

export function commitBatch(batch: firebase.firestore.WriteBatch): Promise<ActionResultVoid> {
    return batch.commit().then(() => {
        return new ActionResultVoid(true);
    }).catch((error) => {
        return new ActionResultVoid(false, error.message);
    });
}