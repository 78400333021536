import Converter from "./converter";
import { DatabaseDocument, DocumentData } from "../../persistence/persisted_object";
import  Team  from "../team";

export default class TeamConverter extends Converter<Team> {


    fromPersistence(object: DatabaseDocument): Team {
        let data: DocumentData = object.data;
        let hasQueue: boolean;
        let queueCarrier: string | null;
        let queueCarrierName: string | null;
        let plan: string | null;

        if (super.isUndefinedOrNull(data["queue"])) {
            hasQueue = false;
            queueCarrier = null;
            queueCarrierName = null;
            plan = null;
        } else {
            hasQueue = data["queue"]["subscribed"];
            queueCarrier = data["queue"]["subscriptionCarrier"];
            queueCarrierName = data["queue"]["subscriptionCarrierName"];
            plan = data["queue"]["plan"];
        }    

        //For testing purposes, this allows us to use full website functionality without needing to use accounts with active subscriptions.
        // if (process.env.NODE_ENV !== 'production') {
        //   hasQueue = false;
        // }
        return {
            region: data["region"],
            division: data["division"],
            manager: data["manager"],
            name: data["name"],
            hasEasyOrderSubscription: data["hasEasyOrderSubscription"] ?? false,
            hasQueueOfficeSubscription: hasQueue,
            queueSubscriptionCarrier: queueCarrier,
            queueSubscriptionCarrierName: queueCarrierName,
            id: object.id,
            plan: plan,
            pendingTransferRequestCount: data["pendingTransferRequestCount"] ?? 0,
            admin: data["admin"] ?? null, 
            messageBeta: data["messageBeta"] ?? false, 
            delinquent: data["delinquent"] ?? false,
            overdue: data["overdue"] ?? false,
            officeNumberConfirmed: data["vectorOfficeNumberConfirmed"] ?? false,
            allowRepsCheckout: data["repsCheckout"] ?? false, 
            repsCheckoutDate: data["repsCheckoutDate"]?.toDate() ?? new Date(), 
            silverCup: data["hasSilverCup"] ?? false,
            vectorOfficeNumber: data["vectorOfficeNumber"] ?? undefined,
            basicYearlyDiscounted: data["basicYearlyDiscounted"] ?? false
        };
    }


    
    toPersistence (team: Team) {
        let data: DocumentData = {
            "division": team.division, 
            "hasEasyOrderSubscription": team.hasEasyOrderSubscription, 
            "manager": team.manager, 
            "name": team.name, 
            "region": team.region, 
            "hasQueueOfficeSubscription": team.hasQueueOfficeSubscription, 
            "queueSubscriptionCarrier": team.queueSubscriptionCarrier, 
            "queueSubscriptionCarrierName": team.queueSubscriptionCarrierName, 
            "id": team.id, 
            "plan": team.plan, 
            "pendingTransferRequestCount": team.pendingTransferRequestCount, 
            "admin": team.admin, 
            "messageBeta": team.messageBeta, 
            "repsCheckout": team.allowRepsCheckout, 
            "repsCheckoutDate": team.repsCheckoutDate, 
            "hasSilverCup": team.silverCup,
            "basicYearlyDiscounted": team.basicYearlyDiscounted
        }
        let resultDatabaseDocument: DatabaseDocument = {
            id: team.id, 
            data: data, 
        }
        return resultDatabaseDocument; 
    }

}
