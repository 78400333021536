import React from 'react';
import 'ui/css/common_text.css';
import { Card, Grid } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

export enum BackgroundColor {
    BLUE = "Blue-Background", //confirmed
    RED = "Red-Background", //no 
    DARK_RED = "Dark-Red-Background", //no show
    GREEN = "Green-Background", //yes
    YELLOW = "Orange-Background", //late
    NONE = "Standard-Background",
    GREY = "Grey-Background", 
    PURPLE = "Purple-Background" //undecided??
}

interface ColoredCardBackgroundProps {
    color: BackgroundColor,
    kids: React.ReactNode[]
}

export default class ColoredCardBackground extends React.Component<ColoredCardBackgroundProps, {}> {


    render() {
        return <div className={this.props.color}>
            <Grid container direction="row" justify="center" alignItems="center">
                {this.props.kids.map((child) => {
                    return <Grid item key={uuidv4()}>{child}</Grid>;
                })}
            </Grid>
        </div>;
    }
}
