import React from 'react'
import { TextField } from '@material-ui/core';


const NumberInput = ({
  value, onChange
}) => {
  return (
    <div>
      <TextField
        value={value}
        onChange={(event) => {
          const value = event.target.value
          onChange(value)
        }}
        type={"number"}
        variant={"standard"}
        margin={"dense"}
      />
    </div>
  )
}

const styles = {
  container: {
    padding: 5,
    borderRadius: 5
  }
}

export default NumberInput;
