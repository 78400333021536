
import React from 'react';
import { Paper, TextField, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

interface MessageBuilderTextFieldProps {
    messageText: string,
    onSubmitted: (value: string) => void,
    onChanged: (value: string) => void,
    shouldBeDisabled: boolean,   
}

export default class MessageBuilderTextField extends React.Component<MessageBuilderTextFieldProps> {
    render() {
        return (
            <Paper style={{width: "100%"}}>
                <TextField disabled={this.props.shouldBeDisabled} style={{width: "95%", paddingLeft: "10px"}} fullWidth placeholder='Send a message' value={this.props.messageText} onKeyDown={(key) => {
                    if (key.keyCode === 13) this.props.onSubmitted(this.props.messageText);
                }}onChange={(changeEvent) => {
                    this.props.onChanged(changeEvent.target.value);
                }}>{this.props.messageText}</TextField>
                <IconButton style={{paddingLeft: "30px"}} type='submit' disabled={this.props.shouldBeDisabled} edge="end" aria-label="Send" onClick={() => {
                    this.props.onSubmitted(this.props.messageText);
                }}>
                    <SendIcon/>
                </IconButton>
            </Paper>
        );
    }
}