import Store from "./store";

import Team from "../model/team";
import TeamConverter from "../model/converters/team_converter";
import ListenerClient from "./listener_client";
import TeamPersistence from "../persistence/team_persistence";
import { AuthenticationService } from "./authentication_service";

export default class TeamStore extends Store<Team> {
    private static sharedInstance: TeamStore = new TeamStore();
    private constructor() {
        super(new TeamConverter());
    }

    public static instance() {
        return this.sharedInstance;
    }

    private team?: Team;

    updateCache(newData: Team[]): void {
        this.team = newData[0];
    }    
    
    isLoaded(): boolean {
        return this.team !== undefined;
    }

    public clearCache(): void {
        this.team = undefined;
    }

    public getTeam(): Team {
        return this.team!
    }

    public static getTeamListenerClient(ignoreOverride: boolean = false): ListenerClient<TeamStore> {
        return new ListenerClient<TeamStore>(
            TeamStore.instance(), 
            TeamPersistence.instance().getTeamSubscriptionGenerator(AuthenticationService.instance().getCurrentUserOfficeNumber(ignoreOverride))
        );
    }

    
}