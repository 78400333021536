import TransferRequest from "model/transfer_request";
import ActionResultVoid from "model/action_result_void";
import TransferRequestPersistence from "persistence/transfer_request_persistence";
import { AuthenticationService } from "./authentication_service";




export default class TransferRequestService {
    private constructor() {}

    private static sharedInstance: TransferRequestService = new TransferRequestService();

    public static instance(): TransferRequestService {
        return this.sharedInstance;
    }

    public async approveRequest(t: TransferRequest): Promise<ActionResultVoid> {
        let currentUserName = AuthenticationService.instance().getCurrentUserFullName();
        let result = await TransferRequestPersistence.instance().acceptRequest(t.userID, currentUserName, t.toOfficeID, t.fromOfficeID);
        console.log(result);
        return result;
    }

    public async declineRequest(t: TransferRequest): Promise<ActionResultVoid> {
        let currentUserName = AuthenticationService.instance().getCurrentUserFullName();
        let result = await TransferRequestPersistence.instance().declineRequest(t.userID, currentUserName, t.toOfficeID, t.fromOfficeID);
        console.log(result);
        return result;
    }
}