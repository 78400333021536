import ActionResultVoid from "model/action_result_void";
import React from 'react';
import RepStore from "services/rep_store";
import Rep from "model/rep";
import ChatStore from "services/chat_store";
import Chat from "model/chat";
import MessagePage from "ui/pages/message_page";
import { Chip, TextField, Snackbar, FormControl, FormLabel, RadioGroup, Switch, FormControlLabel, FormHelperText } from "@material-ui/core";
import {Autocomplete, AutocompleteInputChangeReason, AutocompleteChangeReason, Alert } from "@material-ui/lab";
import MessageBuilderTextField from "./message_builder_text_field";
import { ChatService } from "services/chat_service";
import { RepService } from "services/rep_service";
import { Receipt, StayPrimaryLandscape } from "@material-ui/icons";
import { AuthenticationService } from "services/authentication_service";

interface CreateChatComponentState {
    toText: string,
    messageText: string,
    sendAsGroupChat: boolean,
    recipients: Set<Recipient>,
    sendAsGroupChatAllowed: boolean, 
    shouldDisplayErrorSnackbar: boolean,
    includeManagers: boolean,
}

interface CreateChatComponentProps {
    onCreateChatCompleted: (chatIDs: string[]) => void,
    updateErrorSnackbarText: (newValue: boolean, errorText: string) => void, 
}

interface Recipient {
    displayName: string,
    chatID?: string,
    reps: string[],  //array of IDs for the recipients of this message
    isDefault: boolean
}

//filter suggested recipients based on current address bar text
function getSuggestedRecipients(alreadySelected: Set<Recipient>): Recipient[] {
    //pull from default
    //pull from chats and build 'except set' with every chat that has only one member
    //pull from reps
    let chatRecipients: Recipient[] = getChatRecipients();
    let except: Set<string> = new Set(); 
    except.add(AuthenticationService.instance().getCurrentUserID());
    chatRecipients.forEach(recipient => {  
        if (recipient.reps.length == 1) except.add(recipient.reps[0]);
    });
    let allRecipients: Recipient[] = getDefaultRecipients().concat(chatRecipients);
    allRecipients = allRecipients.concat(getRepRecipients(except)); 
    return allRecipients.filter((rec) => {
        let shouldIncludeRecipient: boolean = true; 
        for (let recipient of alreadySelected) {
            if (recipientEquals(recipient, rec)) shouldIncludeRecipient = false; 
        };
        return shouldIncludeRecipient; 
    });
}

function getRepRecipients(except: Set<string>) {
    //makes a recipient for every active rep in the store
    //returns reps whose ID is not contained in except
    //reps.filter((rep) => !except.contains(rep.repID));
    let reps: Rep[] = RepStore.activeRepStore().getReps();
    reps = reps.filter((rep) => !except.has(rep.repID));
    let recipients: Recipient[] = []; 
    reps.forEach(rep => {
        recipients.push({
            displayName: rep.firstName + " " + rep.lastName, 
            chatID: undefined, 
            reps: [rep.repID], 
            isDefault: false,
        });
    });
    return recipients;  
}

function getChatRecipients() {
    //makes a recipient for every chat in the store
    let chats: Chat [] = ChatStore.instance().getChats(); 
    let recipients: Recipient[] = []; 
    chats.forEach(chat => {
        recipients.push({
            displayName: chat.name ?? ChatService.getDisplayNameForChat(chat), 
            chatID: chat.id, 
            reps: chat.members,
            isDefault: false, 
        });
    });
    return recipients; 
}

function getDefaultRecipients() {
    //makes a recipient for "ALL REPS" and every group and team
    let teams = RepStore.activeRepStore().getRepTeams(false); 
    let groups = RepStore.activeRepStore().getRepGroups(false); 
    let recipients: Recipient[] = []; 
    teams.forEach(team => {
        if (!(team === null)) {
            let teamMembers: string[] = [];
            RepStore.activeRepStore().getRepsInTeam(team).forEach((member) => {
                teamMembers.push(member.repID);
            });
            recipients.push({
                displayName: team, 
                chatID: undefined, 
                reps: teamMembers, 
                isDefault: true,
            });
        }
    });
    groups.forEach(group => {
        if (!(group===null)) {
            let groupMembers: string[] = []; 
            RepStore.activeRepStore().getRepsInGroup(group).forEach(member => {
                groupMembers.push(member.repID);
            });
            recipients.push({
                displayName: group, 
                chatID: undefined, 
                reps: groupMembers, 
                isDefault: true,
            });
        }
    });
    return recipients; 
}

function recipientEquals (a: Recipient, b: Recipient) {
    if (a.chatID === undefined && b.chatID === undefined) {
        //both recipients are either individual reps or groups/teams
        if (a.isDefault && b.isDefault) {
            //both are groups/teams
            return a.displayName === b.displayName;
        } else if (!a.isDefault && !b.isDefault) {
            //both are individual reps
            return a.reps[0] === b.reps[0];
        } else {
            //one is a group/team and one is an individual rep
            return false;
        }
    } else {
        return a.chatID === b.chatID;
    }
}

function setEquals(a: Set<any>, b: Set<any>): boolean {
    if (a.size !== b.size) return false;
    let equal = true;
    a.forEach((element) => {
        if (!b.has(element)) {
            equal = false;
        }
    })
    return equal;
}

//assume that all required stores are loaded
export default class CreateChatComponent extends React.Component<CreateChatComponentProps, CreateChatComponentState> {
    private OFFICE_MANAGER_RECIPIENT: Recipient = {
        displayName: "Managers", 
        reps: [], 
        isDefault: false, 
    };

    constructor(props: CreateChatComponentProps) {
        super(props);

        this.state = {
            toText: "",
            messageText: "",
            sendAsGroupChat: false,
            recipients: new Set<Recipient>(),
            sendAsGroupChatAllowed: true,
            shouldDisplayErrorSnackbar: false, 
            includeManagers: true, 
        }

        this.renderCreateChatHeader = this.renderCreateChatHeader.bind(this);
        this.renderMessageBar = this.renderMessageBar.bind(this);
        this.onMessageTextChanged = this.onMessageTextChanged.bind(this); 
        this.onMessageTextSubmitted = this.onMessageTextSubmitted.bind(this);
        this.onToTextUpdated = this.onToTextUpdated.bind(this);
        this.onAutocompleteChanged = this.onAutocompleteChanged.bind(this); 
    }

    onMessageTextChanged (value: string) {
        this.setState({messageText: value});
    }

    onMessageTextSubmitted (value: string) {
        //create chat, send message, empty string, take the user into the new chat, exit the create chat component
        let reps: Set<string> = new Set();
        reps.add(AuthenticationService.instance().getCurrentUserID()); 
        if (this.state.recipients.size === 0 && this.state.messageText === "") {
            this.props.updateErrorSnackbarText(true, "Please select a recipient.\n Please enter message text."); 
            return; 
        }
        else if (this.state.recipients.size === 0) {
            this.props.updateErrorSnackbarText(true, "Please select a recipient."); 
            return;
        } 
        else if (this.state.messageText === "") {
            this.props.updateErrorSnackbarText(true, "Please enter message text."); 
            return;
        } 
        for (let recipient of this.state.recipients) {
            recipient.reps.forEach((rep) => {
                reps.add(rep); 
            });
        }
        reps.delete(AuthenticationService.instance().getCurrentUserID()); 
        if (this.state.sendAsGroupChat) {
            if (!(this.state.includeManagers)) {
                reps.add(AuthenticationService.instance().getCurrentUserID());
            }
            let idForNewChat: string = ChatService.instance().generateChatID(); 
            let chats = ChatStore.instance().getChats();
            let repArray = Array.from(reps); 
            let shouldCreateChat: boolean = true; 
            let chatIDToPropogate: string = idForNewChat;
            chats.forEach((chat) => {
                if (shouldCreateChat && chat.members.length === repArray.length) {
                    if (setEquals(new Set(chat.members), new Set(repArray))) {
                        ChatService.instance().sendNewMessage(chat, this.state.messageText);
                        shouldCreateChat = false;
                        chatIDToPropogate = chat.id;
                    }
                }
            });
            if (shouldCreateChat) ChatService.instance().createNewChat(repArray, this.state.messageText, this.state.includeManagers, idForNewChat);
            this.setState({messageText: ""});
            this.props.onCreateChatCompleted([chatIDToPropogate]);
        }
        else {
            let chats: Chat[] = ChatStore.instance().getChats(); 
            let existingChats: Chat[] = [];
            let repsWithoutExistingChats: string[] = []; 
            let didHaveExistingChat: boolean; 
            reps.forEach((rep) => {
                didHaveExistingChat = false;
                chats.forEach((chat) => {
                    if (this.state.includeManagers) {
                        if (chat.members.length === 1 && chat.members.includes(rep) && chat.includeManagers) {
                            existingChats.push(chat);
                            didHaveExistingChat = true; 
                        }
                    }
                    else {
                        if (chat.members.length === 2 && chat.members.includes(rep) && !chat.includeManagers && chat.members.includes(AuthenticationService.instance().getCurrentUserID())) {
                            existingChats.push(chat);
                            didHaveExistingChat = true; 
                        }
                    }
                });
                if (!didHaveExistingChat) repsWithoutExistingChats.push(rep); 
            });
            let chatIDsForCallback: string[] = []; 
            for (let i=0; i<repsWithoutExistingChats.length; i++) {
                let idForNewChat: string = ChatService.instance().generateChatID(); 
                if (!this.state.includeManagers) {
                    ChatService.instance().createNewChat([repsWithoutExistingChats[i], AuthenticationService.instance().getCurrentUserID()], this.state.messageText, this.state.includeManagers, idForNewChat);
                }
                else {
                    ChatService.instance().createNewChat([repsWithoutExistingChats[i]], this.state.messageText, this.state.includeManagers, idForNewChat);
                } 
                chatIDsForCallback.push(idForNewChat); 
            }
            existingChats.forEach((chat) => {
                if (chat.includeManagers === this.state.includeManagers) {
                    ChatService.instance().sendNewMessage(chat, this.state.messageText); 
                    chatIDsForCallback.push(chat.id);
                }
                else {
                    let newChatID: string = ChatService.instance().generateChatID(); 
                    ChatService.instance().createNewChat(chat.members, this.state.messageText, this.state.includeManagers, newChatID);
                    chatIDsForCallback.push(newChatID); 
                }
            });
            this.setState({messageText: ""})
            this.props.onCreateChatCompleted(chatIDsForCallback);
        }
    } 

    render() {
        return (
            <div className="flutter-column">
                <div className="flutter-flex-element">
                    {this.renderCreateChatHeader()}
                    <FormControl variant='filled' component="fieldset">
                        <FormHelperText variant='filled' style={{paddingLeft: "10px"}}>{this.getHelperText()}</FormHelperText>
                        <FormControlLabel style={this.getStyleForSwitch()} label="Send As Group Chat" labelPlacement="start" control={<Switch color="primary" checked={this.state.sendAsGroupChat} disabled={!this.state.sendAsGroupChatAllowed} onChange={(event: React.ChangeEvent, checked: boolean) => {
                            this.setState({sendAsGroupChat: checked});
                        }}></Switch>} />
                        <FormControlLabel style={this.getStyleForSwitch()} label="Include Managers" labelPlacement="start" control={<Switch color="primary" checked={this.state.includeManagers} onChange={(event: React.ChangeEvent, checked: boolean) => {
                            this.setState({includeManagers: checked});
                        }}></Switch>} />
                    </FormControl>
                </div>  
                <div className="flutter-flex-expanded-less">
                
                </div>
                <div className="flutter-flex-footer">
                    {this.renderMessageBar()}
                </div>
            </div>
        );
    }

    getStyleForSwitch () {
        if (this.state.includeManagers && this.state.sendAsGroupChat) {
            return undefined;
        }
        else if (this.state.includeManagers) {
            return {
                paddingLeft: "435px",
            };
        }
        else if (this.state.sendAsGroupChat){
            return {
                paddingLeft: "435px",
            };
        }
        else {
            return {
                paddingLeft: "440px", 
            }
        }
    }

    getHelperText () {
        if (this.state.includeManagers && this.state.sendAsGroupChat) {
            return "Your message will be sent in a group chat with the selected recipients. Office managers will be able to see and send messages in the chat.";
        }
        else if (this.state.includeManagers) {
            return "Your message will be sent individually to all recipients. Office managers will be able to see and send messages in the chats.";
        }
        else if (this.state.sendAsGroupChat){
            return "Your message will be sent in a group chat to all selected recipients.";
        }
        else {
            return "Your message will be sent individually to all recipients."; 
        }
    }

    getChipPropsForAutoComplete () {
        return ({
            color: "primary",
        });
    }
    
    renderCreateChatHeader() {
        return (
            <Autocomplete freeSolo ChipProps={this.getChipPropsForAutoComplete()} value={this.getValueForAutocomplete()} multiple fullWidth onInputChange={this.onToTextUpdated} options={getSuggestedRecipients(this.state.recipients)} onChange={this.onAutocompleteChanged}
                getOptionLabel={(option) => {
                if (!(option.displayName === "")) return option.displayName;  
                else return ChatService.getDisplayNameForChat(ChatStore.instance().getChat(option.chatID!)!);}
            } disableCloseOnSelect filterSelectedOptions inputValue={this.state.toText} renderInput={(params) => <TextField {...params} label="To..." variant="outlined" />}/>
        );
    }

    renderMessageBar() {
        return (
            <MessageBuilderTextField  messageText={this.state.messageText} onChanged={this.onMessageTextChanged} onSubmitted={this.onMessageTextSubmitted} shouldBeDisabled={false}/>
        );
    }

    getValueForAutocomplete () {
        if (this.state.includeManagers) {
            let arr = [this.OFFICE_MANAGER_RECIPIENT];
            arr = arr.concat(Array.from(this.state.recipients));
            return arr;
        } else {
            return (Array.from(this.state.recipients));
        }
    }

    onAutocompleteChanged (event: React.ChangeEvent<{}>, recipients:  (string| Recipient)[], reason: AutocompleteChangeReason) {
        let recs: Set<Recipient> = new Set<Recipient>();
        let shouldIncludeManagers = this.state.includeManagers;
        let sawOfficeManagers = false;
        recipients.forEach((r) => {
            if (typeof r !== "string") {
                if (r !== this.OFFICE_MANAGER_RECIPIENT) {
                    let recipientAlreadyIncluded = false;
                    recs.forEach((rec) => {
                        if (recipientEquals(r, rec)) {
                            recipientAlreadyIncluded = true;
                        }
                    })
                    if (!recipientAlreadyIncluded) {
                        recs.add({  
                            displayName: r.chatID === undefined ? r.displayName : ChatService.getDisplayNameForChat(ChatStore.instance().getChat(r.chatID!)!, false), 
                            chatID: r.chatID, 
                            reps: r.reps, 
                            isDefault: r.isDefault,
                        });  
                        if (r.chatID !== undefined) {
                            let chat = ChatStore.instance().getChat(r.chatID!)!;
                            if (chat.includeManagers) {
                                shouldIncludeManagers = true;
                            }
                        }
                    }
                } else {
                    sawOfficeManagers = true;
                }
            }  
        });
        let sendAsGCAllowed: boolean = true; 
        let officeManagersWereJustRemoved = !sawOfficeManagers && this.state.includeManagers;
        for (let recipient of recs) {
            if (recipient.isDefault) sendAsGCAllowed = false; 
        }
        if (recs.size === 1) sendAsGCAllowed = false; 
        this.setState({
            recipients: recs,
            includeManagers: officeManagersWereJustRemoved ? false : shouldIncludeManagers,
            sendAsGroupChat: sendAsGCAllowed ? this.state.sendAsGroupChat : false,
            sendAsGroupChatAllowed: sendAsGCAllowed!,
            toText: recs.size > this.state.recipients.size ? "" : this.state.toText
        });
    }

    onToTextUpdated (event: React.ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) {
        if (reason !== "reset") {
            this.setState({toText: value});
        }
    }
}

