import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import QueueLogo from '../components/queue_logo';
import '../common.css';


const Loader = () => {
    return (<div className="App">
    <Grid container direction="column" alignItems="center" spacing={5}>
        <Grid item>
            <QueueLogo/>
        </Grid>
        <Grid item>
            <CircularProgress/>
        </Grid>
    </Grid>
</div>)
}

export default Loader;