import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { NavigateNext, NavigateBefore } from "@material-ui/icons";
import DateUtils from "../../services/date_utils";
import 'ui/common.css';
import 'ui/css/common_text.css';


interface WeekSelectorProps {
    selectedWeekID: string;
    onSelectedWeekChanged: (newWeekID: string) => void;
    component?: any
}


export default class WeekSelector extends React.Component<WeekSelectorProps, {}> {

    constructor(props: WeekSelectorProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(delta: number) {
        console.log("=============================================")
        console.log("adding weeks: " + delta);
        let selectedWeekStart = DateUtils.getFirstInstantOfVectorWeekForWeekID(this.props.selectedWeekID);
        console.log("current week start: " + selectedWeekStart);
        let newWeekStart: Date = DateUtils.addDays(selectedWeekStart, 7 * delta);
        if (newWeekStart.getHours() === 23) {
            newWeekStart = DateUtils.addDays(newWeekStart, 1/24);
        } else if (newWeekStart.getHours() === 1) {
            newWeekStart = DateUtils.addDays(newWeekStart, - 1/24);
        }
        console.log("new week start: " + newWeekStart);
        let newWeekID: string = DateUtils.getWeekIDOfDate(newWeekStart);
        console.log("new week id: " + newWeekID);
        this.props.onSelectedWeekChanged(newWeekID);
    }

    render() {
        let currentWeekStart = DateUtils.getFirstInstantOfVectorWeekForWeekID(this.props.selectedWeekID);
        let currentWeekEnd = DateUtils.addDays(currentWeekStart, 6);
        let day1 = DateUtils.toMonthSlashDayString(currentWeekStart);
        let day2 = DateUtils.toMonthSlashDayString(currentWeekEnd);
        return <Grid container direction='row' justify='flex-start' alignItems='center'>
            <Grid item>
                <div>
                  <div className="Header-Text">Tab Week: {day1}-{day2}</div>
                </div>
            </Grid>
            <Grid item>
                <IconButton onClick={() => {
                    this.handleChange(-1);
                }}>
                    <NavigateBefore/>
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={() => {
                    this.handleChange(1);
                }}>
                    <NavigateNext/>
                </IconButton>
            </Grid>
            {(this.props.component === undefined) ? null : <Grid item>
                {this.props.component!}
            </Grid>}
        </Grid>
    }

}
