import React from 'react'; 

import TeamStore from 'services/team_store';
import MessagePage from 'ui/pages/message_page';
import { Grid, Typography, CircularProgress, } from '@material-ui/core';

import BetaScreenshot from '../assets/messaging_beta_screenshot.png';
import { Link } from 'react-router-dom';
import QueueAppbar from 'ui/components/queue_appbar';
import GuardedListenerComponent from 'ui/components/guarded_listener_component';

export default class AnalyitcsEmbedPage extends GuardedListenerComponent<{}, {}> {
    constructor(props: {}) {
        super(props);
        
    }
    
    getEmployedListenerClients () {
        return [TeamStore.getTeamListenerClient()];
    }

    getPathOnRefresh () {
        return "/analytics"; 
    }

    renderGuarded () {
        if (!TeamStore.instance().isLoaded()) {
            return (
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Grid item className="padding-vertical">
                        <CircularProgress/>
                    </Grid>
                </Grid>
            );
        }
        if (TeamStore.instance().getTeam().silverCup) {
            return (
                <React.Fragment>
                    <QueueAppbar shouldDisplayMenu/>
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item> 
                            <h2 style={{textAlign: "center"}}>Use Analytics to get deep office, division, and national insights</h2>
                            <h3 style={{textAlign: "center"}}>Go full screen by clicking the box in the bottom right corner of the dashboard</h3>
                        </Grid>
                        <Grid item style={{paddingTop: "5px"}}> 
                        <div className='iframe-container'>
                            <iframe width="900" height="500" src="https://datastudio.google.com/embed/reporting/f3b1898d-7132-401e-aa41-39b24977d881/page/MvVQB" allowFullScreen></iframe>
                        </div>
                        </Grid>
                        <Grid item style={{paddingTop: "15px"}}> 
                        <div className='iframe-container'>
                            <iframe width="900" height="500" src="https://datastudio.google.com/embed/reporting/f3b1898d-7132-401e-aa41-39b24977d881/page/EpLWB" allowFullScreen></iframe>
                        </div>
                        </Grid>
                        <Grid item style={{paddingTop: "15px"}}> 
                        <div className='iframe-container'>
                            <iframe width="900" height="500" src="https://datastudio.google.com/embed/reporting/f3b1898d-7132-401e-aa41-39b24977d881/page/RyISB" allowFullScreen></iframe>
                        </div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
        else {
            console.log(TeamStore.instance().getTeam());
            return (
                <React.Fragment>
                    <QueueAppbar shouldDisplayMenu/>
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item> 
                            <h2 style={{textAlign: "center"}}>Use Analytics to get deep office, division, and national insights</h2>
                            <p style={{textAlign: "center"}}>Analytics is a Silver Cup Feature. <Link to="/plans">Learn More</Link></p>
                        </Grid>
                        <Grid item style={{paddingTop: "15px"}}> 
                        <div className='iframe-container'>
                        <iframe width="900" height="500" frameBorder="0" title="Introducing Queue Analytics" src="https://www.youtube.com/embed/tuZUiuVi1I4" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
} 