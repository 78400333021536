import React from "react";
import PlanSelectionPage from './plan_selection_page';
import { Elements, StripeProvider } from "react-stripe-elements";
import BillingService from "../../services/billing_service";



export default class PlanSelectionPageWrapper extends React.Component {
    render() {
        return (
            <StripeProvider apiKey={BillingService.sharedInstance().getPublishableKey()}>

            
            <Elements>

                <PlanSelectionPage stripe={null} elements={null}/>
            </Elements>
            </StripeProvider>
        );
    }
}