import Appointment from "../model/appointment";
import ActionResultVoid from "../model/action_result_void";
import AppointmentPersistence from "../persistence/appointment_persistence";
import ApptConverter from "../model/converters/appointment_converter";



export default class AppointmentService {
    
    private constructor() {}
    
    private static sharedInstance: AppointmentService = new AppointmentService();

    public static instance(): AppointmentService {
        return this.sharedInstance
    }

    public updateAppointment(a: Appointment): Promise<ActionResultVoid> {
        return AppointmentPersistence.instance().updateAppointment(new ApptConverter().toPersistence(a));
    }
    
}