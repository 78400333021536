import React from "react";
import PdiWeekStore from "services/pdi_week_store";
import DateUtils from "services/date_utils";
import PdiWeek from "model/pdi_week";
import { PdiStatus } from "model/pdi_status";
import Rep from "model/rep";


interface PdiProgressBarProps {
    reps: Rep[],
    weekID: string
}


export default class PdiProgressBar extends React.Component<PdiProgressBarProps> {
    
    render() {
        const pdiStore = PdiWeekStore.getPdiWeekStore(this.props.weekID);

        if (!pdiStore.isLoaded() || DateUtils.getWeekIDForOffset(0) !== this.props.weekID) {
            return null;
        }

        let currentVectorDay = DateUtils.toVectorDay(new Date().getDay());

        let totalReps = this.props.reps.length;
        let totalTried = 0;
        let totalReached = 0;
        this.props.reps.forEach((r) => {
            const pdiWeek: PdiWeek = pdiStore.getPdiWeekForRep(r.repID);
            const currentPdiResult = pdiWeek.dailyStatuses[currentVectorDay];
            if (currentPdiResult === PdiStatus.CalledIn || currentPdiResult === PdiStatus.Reached) {
                totalReached++;
            } else if (currentPdiResult === PdiStatus.TextedNoResponse || currentPdiResult === PdiStatus.TextedResponse || currentPdiResult === PdiStatus.NoAnswer) {
                totalTried++;
            }
        });

        let reachedPercentage: number = 0;
        let triedPercentage: number = 0;

        if (totalReps !== 0) {
            reachedPercentage = totalReached / totalReps * 100;
            triedPercentage = totalTried / totalReps * 100;
        } 

        const width = 120;
        const height = 15;

        return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
        <g>
          <rect fill="#a5ebb1" x={0} y={0} width={width * (reachedPercentage / 100) } height={height} />
          <rect fill="#ffff7f" x={width * (reachedPercentage / 100)} y={0} width={width * (triedPercentage / 100)} height={height} />
          <rect fill="#aaaaaa" x={width * (triedPercentage / 100) + width * (reachedPercentage / 100)} y={0} width={width - width * (triedPercentage / 100) - width * (reachedPercentage / 100)} height={height} />
        </g>
      </svg>
    }
}