import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import {firebaseApp} from './firebase_app';
import { OnStreamUpdate, SubscriptionGenerator, Unsubscriber } from "./subscription_generator";
import { toQueueDatatbaseDocList } from "./persistence_utls";

export default class PhoneJamPersistence {
    private constructor() {}

    private static sharedInstance: PhoneJamPersistence = new PhoneJamPersistence();

    public static instance() {
        return this.sharedInstance;
    }

    public getPhoneJamSubscriptionGenerator(teamID: string,): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {
            return {
                unsubscribe: firebaseApp.firestore().collection("Current Phone Times")
                    .where("team", "==", teamID)
                    .where("userIsActive", "==", true)
                    .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                        onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                    }, (error) => {
                        onStreamUpdate([]);
                    }),
            }
        };
    }

    public getDivisionalPhoneJamSubscriptionGenerator(teamIDs: string[],): SubscriptionGenerator {
        return (onStreamUpdate: OnStreamUpdate):Unsubscriber => {
            let subscriptions: (() => void)[] = [];
            //group team ids into partitions of size 10
            let teamIDPartitions: string[][] = [];
            let partitionIndex = 0;
            for (let x = 0; x < teamIDs.length; x++) {
                if (teamIDPartitions.length < partitionIndex + 1) {
                    teamIDPartitions.push([teamIDs[x]])
                } else {
                    teamIDPartitions[partitionIndex].push(teamIDs[x]);
                }
                if (x % 10 === 9) {
                    partitionIndex++;
                }
            }
            teamIDPartitions.forEach((partition) => {
                subscriptions.push(firebaseApp.firestore().collection("Current Phone Times")
                .where("team", "in", partition)
                .where("userIsActive", "==", true)
                .onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
                    onStreamUpdate(toQueueDatatbaseDocList(snapshot));
                }, (error) => {
                    onStreamUpdate([]);
                }))
            });
            return {
                unsubscribe: () => {
                    subscriptions.forEach((sub) => sub())
                }
            }
        };
    }
}
