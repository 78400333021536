import PdiWeek from '../model/pdi_week';
import Store from './store';
import PDIConverter from '../model/converters/pdi_converter';
import ListenerClient from './listener_client';
import PdiPersistence from '../persistence/pdi_persistence';
import { AuthenticationService } from './authentication_service';
import PdiWeekService from './pdi_service';


export default class PdiWeekStore extends Store<PdiWeek> {

    private constructor(weekID: string) {
        super(new PDIConverter())
        this.weekID = weekID;
    }

    private weekID: string;

    private pdiWeekMap?: Map<string, PdiWeek>; //map of repID => pdi week for that rep (each pdiWeekStore is only for one week)

    private static pdiWeekStores: Map<string, PdiWeekStore> = new Map<string, PdiWeekStore>(); //map of weekID => PdiWeekStore for that week

    public isLoaded(): boolean {
        //console.log(this.pdiWeekMap);
        return this.pdiWeekMap !== undefined;
    
    }

    public clearCache(): void {
        this.pdiWeekMap = undefined;
    }

    public updateCache(newPdiWeekData: PdiWeek[]): void {
        //iterate through, and add the pdiweeks to the proper locations
        this.pdiWeekMap = new Map<string, PdiWeek>();
        for (let pdiWeek of newPdiWeekData) {
            this.pdiWeekMap!.set(pdiWeek.userID, pdiWeek);
        }
        //console.log(this.pdiWeekMap);
    }

    public getPdiWeeks(): PdiWeek[] {
        return Array.from(this.pdiWeekMap!.values());
    }

    public getPdiWeekForRep(repID: string): PdiWeek {
        let pdiWeek: PdiWeek | undefined = this.pdiWeekMap!.get(repID);
        if (pdiWeek === undefined) {
            return PdiWeekService.instance().generateBlankPdiWeekForRep(repID);
        } else {
            return pdiWeek!;
        }
    }

    public static getPdiWeekStore(weekID: string): PdiWeekStore {
        let store = this.pdiWeekStores.get(weekID);
        if (store === undefined) {
            this.pdiWeekStores.set(weekID, new PdiWeekStore(weekID));
        }
        return this.pdiWeekStores.get(weekID)!;
    }

    public static getListenerClientForWeekID(weekID: string) {

        if (!this.pdiWeekStores.has(weekID)) {
            this.pdiWeekStores.set(weekID, new PdiWeekStore(weekID));
        }

        let teamID: string = AuthenticationService.instance().getCurrentUserOfficeNumber();
        return new ListenerClient(this.pdiWeekStores.get(weekID)!, PdiPersistence.instance().getPdiSubscriptionGenerator(weekID, teamID));
    }

}