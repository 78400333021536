import Team from '../model/team';
import TeamConverter from '../model/converters/team_converter';
import ActionResult from '../model/action_result';
import {AuthenticationService} from './authentication_service';
import TeamPersistence from '../persistence/team_persistence';
import ActionResultVoid from 'model/action_result_void';

export default class TeamService {
    private constructor() {}
    private static sharedInstance: TeamService = new TeamService();
    public static instance() {
        return this.sharedInstance;
    }

    public async updateTeam(team: Team): Promise<ActionResultVoid> {
        return TeamPersistence.instance().updateTeam(new TeamConverter().toPersistence(team));
    }

    public async getTeam(): Promise<ActionResult<Team>> {
        return TeamPersistence.instance().getTeam(AuthenticationService.instance().getCurrentUserOfficeNumber()).then((result) => {
            if (result.success) {
                return new ActionResult(true, undefined, new TeamConverter().fromPersistence(result.payload!));
            } else {
                return new ActionResult(false, result.errorMessage!);
            }
        });
    }


    public async confirmOleanOfficeNumber(teamID: string, officeNumber: string): Promise<ActionResultVoid> {
        return await TeamPersistence.instance().confirmOleanOfficeNumber(teamID, officeNumber.toLocaleUpperCase());
    }

}