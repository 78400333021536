import Rep, { sortByFirstName } from "./rep";

    export const LAUNCH_GROUP = "Launch";
    export const FAST_START_GROUP = "Fast Start";
    export const POST_FAST_START_GROUP = "Post Fast Start";
    export const DEFAULT_TEAM = "Default Team";

export default class RepCollection {
    private collectionName: string;
    private collectionType: CollectionType;
    ///an always sorted group of reps
    private reps: Rep[];

    constructor(collectionType: CollectionType,collectionName: string, reps: Rep[], sortReps: boolean) {
        this.collectionName = collectionName;
        this.collectionType = collectionType;
        this.reps = reps;
        
        this.getCollectionType = this.getCollectionType.bind(this);
        this.getReps = this.getReps.bind(this);
        this.getCollectionName = this.getCollectionName.bind(this);
        this.sortReps = this.sortReps.bind(this);
        this.setReps = this.setReps.bind(this);
        if (sortReps) {
            this.sortReps();
        }
    }

    public setReps(reps: Rep[]) {
        this.reps = reps;
    }

    private sortReps(): void {
        this.reps.sort(sortByFirstName);
    }

    public getCollectionType(): CollectionType {
        return this.collectionType;
    }

    public getReps(): Rep[] {
        return this.reps;
    }

    public getCollectionName(): string {
        return this.collectionName;
    }

}

export enum CollectionType {
    GROUP = "Groups",
    TEAM = "Teams"
}

export const repCollectionSort = (a: RepCollection, b: RepCollection): number => {
    if (a.getCollectionType() !== b.getCollectionType()) {
        return a.getCollectionType().localeCompare(b.getCollectionType());
    } else if (a.getCollectionType() === CollectionType.GROUP && b.getCollectionType() === CollectionType.GROUP) {
        return groupSort(a.getCollectionName(), b.getCollectionName());
    } else {
        return teamSort(a.getCollectionName(), b.getCollectionName());
    }
}

const teamSort = (a: string, b: string): number => {
    if (a === b) {
        return 0;
    } else if (!isDefaultTeam(a) && !isDefaultTeam(b)) {
        return a.localeCompare(b);
    } else {
        return isDefaultTeam(a) ? -1 : 1;
    }
}

const groupSort = (a: string, b: string): number => {
    if (isDefaultGroup(a) && isDefaultGroup(b)) {
        return defaultGroupNameSort(a, b);
    } else if (!isDefaultGroup(a) && !isDefaultGroup(b)) {
        return a.localeCompare(b);
    } else {
        return isDefaultGroup(a) ? -1 : 1;
    }
}

const defaultGroupNameSort = (a: string, b: string): number => {
    if (a === b) {
        return 0;
    } else if (a === LAUNCH_GROUP && (b === POST_FAST_START_GROUP || b === FAST_START_GROUP)) {
        return -1;
    } else if (a === FAST_START_GROUP && b !== LAUNCH_GROUP) {
        return -1;
    } else {
        return 1;
    }
}

export const isDefaultGroup = (group: string): boolean => {
    return group === LAUNCH_GROUP || group === FAST_START_GROUP || group === POST_FAST_START_GROUP;
}

export const isDefaultTeam = (team: string): boolean => {
    return team === DEFAULT_TEAM;
}