import React from 'react';
import '../common.css';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import QueueLogo from '../components/queue_logo';
import { Button } from '@material-ui/core';





class LaunchPage extends React.Component{



  render() {
      return (

        <div className="App">
          <Grid container direction="column" alignItems="center" justify="center" spacing={5}>
            <Grid item>
              <QueueLogo/>
            </Grid>
            <Grid item>
              <h1>Welcome to Queue</h1>
            </Grid>
            <Grid item>
              <p className="centered">Start <strong>boosting your reps' productivity</strong> today!</p>
              <br/>
              <iframe title="Launchpage_video_1" width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLNTG4_8ZjVUQUsjLu31-LCuTgKwQfLkbn" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              <p className="centered">Only $69 / month</p>
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center" spacing={1}>
                <Grid item>
                  <Button variant="contained" color="primary" href="/#/signup">Sign up</Button>
                </Grid>
                </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center" spacing={1}>
                <Grid item>
                  Already have an account?
                </Grid>
                <Grid item>
                  <Link to="/login" className="plain_link">Login</Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
}

export default LaunchPage;
