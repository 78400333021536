import QueueListener from './queue_listener';
import Converter from '../model/converters/converter';
import { AuthenticationService } from './authentication_service';

export default abstract class Store<T> {
    private listener: QueueListener<T>;
  
    constructor(converter: Converter<T>) {
      this.updateCache = this.updateCache.bind(this);
      this.isLoaded = this.isLoaded.bind(this);
      this.listener = new QueueListener<T>(converter, this.updateCache);
      AuthenticationService.instance().registerStore(this);
    }
  
    abstract updateCache(newData: T[]):void;
  
    abstract isLoaded(): boolean;
  
    getListener(): QueueListener<T>  {
      return this.listener;
    }

    abstract clearCache(): void;
  
  }