  import GuardedListenerComponent from "../components/guarded_listener_component";
import ListenerClient from "../../services/listener_client";
import RepStore from "../../services/rep_store";
import QueueAppbar from "../components/queue_appbar";
import React, { ChangeEvent } from 'react';
import { CircularProgress, TextField, Table, TableHead, TableRow, TableCell, TableBody , Button, Select, MenuItem, Typography, Badge, Grid, Menu, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";
import Rep from "../../model/rep";
import '../common.css';
import { RepService } from "../../services/rep_service";
import { StarterKitStatus } from "../../model/starter_kit_status";
import ColoredCardBackground, { BackgroundColor } from "ui/components/colored_card_background";

interface InactiveRepsPageState {
    searchText: string,
    starterKitDropdownPosition: EventTarget & HTMLButtonElement | null,
    confirmMarkAllAsReturnedOpen: boolean
}

export default class InactiveRepsPage extends GuardedListenerComponent<{}, InactiveRepsPageState> {


    constructor(props: {}) {
        super(props);
        this.state = {
            searchText: "",
            starterKitDropdownPosition: null,
            confirmMarkAllAsReturnedOpen: false,
        };

        this.handleDropdownOpen = this.handleDropdownOpen.bind(this);
        this.handleDropdownClose = this.handleDropdownClose.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.openConfirmDialog = this.openConfirmDialog.bind(this);
        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
        this.confirmMarkAllAsReturned = this.confirmMarkAllAsReturned.bind(this);
    }

    confirmMarkAllAsReturned () {
        let inactiveRepStore : RepStore = RepStore.inactiveRepStore();
        if (!inactiveRepStore.isLoaded()) {
            this.closeConfirmDialog();
            return;
        } else {
            this.closeConfirmDialog();
            let reps: Rep[] = inactiveRepStore.getReps();
            for (let rep of reps) {
                rep.starterKitStatus = StarterKitStatus.RETURNEDPURCHASED; 
            }
            RepService.instance().updateReps(new Set(reps));
        }
    }

    closeConfirmDialog (){
        this.setState({confirmMarkAllAsReturnedOpen: false,});
    }
    
    openConfirmDialog (){
        this.setState({confirmMarkAllAsReturnedOpen: true});
    }

    getEmployedListenerClients(): ListenerClient<any>[] {
        return [RepStore.getInactiveRepsListenerClient()];
    }

    handleDropdownClose (){
        this.setState({starterKitDropdownPosition: null,});
    }

    handleDropdownOpen (event: React.MouseEvent<HTMLButtonElement>){
        this.setState({starterKitDropdownPosition: event.currentTarget,});
    }

    renderBody() {
        let inactiveRepStore : RepStore = RepStore.inactiveRepStore();
        let badgeNumber: number = 0;
        if (!inactiveRepStore.isLoaded()) {
            return (
                <CircularProgress/>
            );
        } else {
            let reps: Rep[] = inactiveRepStore.getReps();
            for (let rep of reps) {
                if (rep.starterKitStatus === StarterKitStatus.NOTRETURNED) {
                    badgeNumber++;
                }
            }
            reps = reps.filter((rep) => (rep.firstName.toLowerCase() +  " " + rep.lastName.toLowerCase()).includes(this.state.searchText.toLowerCase()));
            reps.sort((a, b) => b.lastSignOn.getTime() - a.lastSignOn.getTime());
            return (
                <div>
                    <div className="MadPadding">
                        <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                            <Grid item>
                                <TextField value={this.state.searchText} placeholder="Search..." onChange={(event) => {this.setState({searchText: event.target.value})}} variant="outlined"/> 
                            </Grid>
                            <Grid item>
                                <Button color='primary' variant="outlined" onClick={this.openConfirmDialog}>
                                    MARK ALL KITS AS RETURNED
                                </Button>
                                <Dialog open={this.state.confirmMarkAllAsReturnedOpen} onBackdropClick={this.closeConfirmDialog}>
                                    <DialogTitle>Are you sure you want to mark all kits as returned?</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText >
                                            This action cannot be undone.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button color="primary" variant="outlined" onClick={this.closeConfirmDialog}>
                                            Cancel
                                        </Button>
                                        <Button color="primary" onClick={this.confirmMarkAllAsReturned}> 
                                            Confirm
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid> 
                        </Grid>
                    </div>
                    <Table>
                        <TableHead>
                            <TableRow selected>
                                <TableCell>
                                    Name
                                </TableCell>
                                <TableCell>
                                    Last Sign In
                                </TableCell>
                                <TableCell>
                                    <Badge badgeContent={badgeNumber} color="primary">
                                        <Typography>Starter Kit Status</Typography>
                                    </Badge>
                                </TableCell>
                                <TableCell>
                                    Options
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reps.map((rep: Rep) => {
                                return (
                                    <TableRow key={rep.repID}>
                                        <TableCell>
                                            <div>
                                                <strong>{rep.firstName + " " + rep.lastName}</strong>
                                            </div>
                                            <div className="Small-Text">
                                                {"(" + rep.phoneNumber.substring(0,3) + ") " + rep.phoneNumber.substring(3,6) + "-" + rep.phoneNumber.substring(6,10)}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {rep.lastSignOn.toDateString()}
                                        </TableCell>
                                        <TableCell>
                                            <ColoredCardBackground color={rep.starterKitStatus === StarterKitStatus.NOTRETURNED ? BackgroundColor.RED : BackgroundColor.GREEN} kids={[<Select value={rep.starterKitStatus} onChange={(event: ChangeEvent<{value: unknown}>) => {
                                    rep.starterKitStatus = event.target.value as StarterKitStatus;
                                    this.setState({});
                                    RepService.instance().updateRep(rep);}}>
                                                <MenuItem value={StarterKitStatus.NOTRETURNED}>Not Returned</MenuItem>
                                                <MenuItem value={StarterKitStatus.RETURNEDPURCHASED}>Returned / Purchased</MenuItem>
                                            </Select>]}/>
                                            
                                        </TableCell>
                                        <TableCell>
                                            <Button color="primary" variant="outlined"onClick={
                                                () => {
                                                    RepService.instance().toggleActive(rep);
                                                }
                                            }>Reactivate</Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            );
        }
    }

    renderGuarded() {
        return (
        <div>
            <QueueAppbar shouldDisplayMenu={true}/>
            {this.renderBody()}
        </div>);
    }

    getPathOnRefresh(): string {
        return "/inactive-reps";
    }


}
