import React from 'react';
import QueueLogo from './queue_logo'
import { Toolbar, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AuthenticationService } from '../../services/authentication_service';
import { Link, Redirect } from 'react-router-dom';
import '../common.css';
import Banner from 'react-js-banner';
import TeamStore from 'services/team_store';

interface QueueAppbarState{
    menuPosition:  EventTarget & HTMLButtonElement | null,
    shouldRedirect: boolean,
    redirectPath?: string,
}

interface QueueAppbarProps{
    shouldDisplayMenu: boolean,
}

export default class QueueAppbar extends React.Component<QueueAppbarProps,QueueAppbarState>{


    constructor(props:QueueAppbarProps){
        super(props);

            this.state={
                menuPosition: null,
                shouldRedirect: false
            };

        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleLogOut = this.handleLogOut.bind(this);
        this.renderMenuButton = this.renderMenuButton.bind(this);
        this.renderNametag = this.renderNametag.bind(this);
        this.handleHomeButtonClick = this.handleHomeButtonClick.bind(this);
        this.renderRepsPageButton = this.renderRepsPageButton.bind(this);
        this.renderAttendanceButton = this.renderAttendanceButton.bind(this);
        this.handleManagePlans = this.handleManagePlans.bind(this);
        this.renderDemosButton = this.renderDemosButton.bind(this);
        }

    handleMenuOpen (event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            menuPosition: event.currentTarget,
        });

    }

    handleClose () {
        this.setState({
            menuPosition: null,
        });
    }

    handleLogOut() {
        AuthenticationService.instance().logout();
        this.setState({
            menuPosition: null,
            shouldRedirect: true,
            redirectPath: "/login"
        });

    }

    renderMenuButton() {
        if (this.props.shouldDisplayMenu) {
            return (
                <Grid item>
                     <IconButton onClick={this.handleMenuOpen}><MenuIcon/></IconButton>
                </Grid>
            );
        } else {
            return null;
        }
    }

    renderLeaderboardButton(){
        if (this.props.shouldDisplayMenu) {
            return (
                <Link to="/leaderboard" className="plain_link">Phoning</Link>
            );
        } else {
            return null;
        }
    }

    renderChangeTeamButton() {
        if (this.props.shouldDisplayMenu) {
            return (
                <Link to="/team-select" className="plain_link">Switch Office</Link>
            );
        } else {
            return null;
        }
    }

    renderRepsPageButton(){
        if (this.props.shouldDisplayMenu) {
            return (
                <Link to="/reps" className="plain_link">Reps</Link>
            );
        } else {
            return null;
        }
    }

    renderAttendanceButton(){
        if (this.props.shouldDisplayMenu) {
            return (
                <Link to="/attendance" className="plain_link">Attendance</Link>
            );
        } else {
            return null;
        }
    }

    renderMessagesButton(){
        if (this.props.shouldDisplayMenu) {
            return (
                <Link to="/messages" className="plain_link">Messages</Link>
        )}
        else {
            return null;
        }
    }

    renderDemosButton(){
        if (this.props.shouldDisplayMenu) {
            return (
                <Link to="/demos" className="plain_link">Demos</Link>
            );
        } else {
            return null;
        }
    }
    
    handleHomeButtonClick(){
        return (
            <Redirect to="/"/>
        );
    }

    renderQueueLogo(){
        if (this.props.shouldDisplayMenu){
            return(
                <Link to='/' className='plain_link'><QueueLogo/></Link>
            )
        } else {
            return (
                <QueueLogo/>
            )
        }
    }

    renderNametag(){
        if (this.props.shouldDisplayMenu && AuthenticationService.instance().hasDataRequiredToFunction()) {
            return (
                <div>
                    <Link to="/team-select" className="plain_link">
                      {AuthenticationService.instance().getCurrentUser().firstName + " " + AuthenticationService.instance().getCurrentUser().lastName + " | " + AuthenticationService.instance().getCurrentUser().teamName}
                    </Link>
                </div>
            );
        }
        else {
            return (<Typography></Typography>);
        }
    }

    handleManagePlans() {
        this.setState({
            shouldRedirect: true,
            redirectPath: "/plans"
        });
    }

    render() {
        if (this.state.shouldRedirect) {
            return (
                <Redirect to={this.state.redirectPath!}/>
            );
        } else {
            return(
                <React.Fragment >
                <AppBar position='static' color='secondary'>
                    <Toolbar>
                        <Grid container direction='row' justify='space-between' alignItems='center'>
                            <Grid item>
                                {this.renderQueueLogo()}
                            </Grid>
                            <Grid item>
                                {this.renderRepsPageButton()}
                            </Grid>
                            <Grid item>
                                {this.renderLeaderboardButton()}
                            </Grid>
                            <Grid item>
                                {this.renderAttendanceButton()}
                            </Grid>
                            <Grid item> 
                                {this.renderMessagesButton()}
                            </Grid>
                            <Grid item>
                                {this.renderDemosButton()}
                            </Grid>
                            
                            <Grid item>
                                <Grid container direction='row' justify='flex-end' alignItems='center'>
                                    <Grid item>
                                        <Grid container direction='column' justify='center' alignItems='center'>
                                            {this.renderNametag()}
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {this.renderMenuButton()}
                                        <Menu onClose={this.handleClose} anchorEl={this.state.menuPosition} open={Boolean(this.state.menuPosition)}>
                                            <MenuItem onClick={() => {
                                                this.setState({
                                                    shouldRedirect: true, 
                                                    redirectPath: "/settings"
                                                });
                                            }}>Activate Rep Demo Checkouts</MenuItem>
                                            <MenuItem onClick={() => {
                                                this.setState({
                                                    shouldRedirect: true,
                                                    redirectPath: "/analytics"
                                                });
                                            }}>Analytics</MenuItem>
                                            <MenuItem onClick={this.handleManagePlans}>Billing</MenuItem>
                                            <MenuItem><a className="grey_link" target="_blank" rel="noopener noreferrer" href="mailto:queue.app.team@gmail.com">Contact Us</a></MenuItem>
                                            <MenuItem onClick={() => {
                                                this.setState({
                                                    shouldRedirect: true,
                                                    redirectPath: "/welcome"
                                                });
                                            }}>Manager Resources</MenuItem>
                                            <MenuItem onClick={() => {
                                                this.setState({
                                                    shouldRedirect: true, 
                                                    redirectPath: "/team-select"
                                                });
                                            }}>Switch Offices</MenuItem>
                                            <MenuItem><a className="grey_link" href="https://docs.google.com/presentation/d/e/2PACX-1vRZ0PSQtDHd9l3zR8nnID5Z9zmSRY9O7_OAmy6aKjrvnD3IQFZK1mRd8806Fuz0qiQQKxd0XvDep6Vl/pub?start=false&loop=false&delayms=60000" target="_blank" rel="noopener noreferrer">Silver Cup Edition</a></MenuItem>
                                            <MenuItem onClick={() => {
                                                this.setState({
                                                    shouldRedirect: true,
                                                    redirectPath: "/tutorial"
                                                });
                                            }}>Tutorial Videos</MenuItem>
                                            <MenuItem onClick={this.handleLogOut}>Log Out</MenuItem>

                                        </Menu>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                {this.props.shouldDisplayMenu && AuthenticationService.instance().getCurrentUserOfficeNumber() !== AuthenticationService.instance().getCurrentUserOfficeNumber(true) ? <Banner 
                    title={TeamStore.instance().isLoaded() ? "Viewing " + TeamStore.instance().getTeam().name + " | " + TeamStore.instance().getTeam().manager : ""} 
                    css={{color: "#FFF", backgroundColor: "#336D99", margin: 0, fontSize: 12}} 
                /> : null}
                
                </React.Fragment>
            );
        }
    }

}
