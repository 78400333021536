import Converter from "./converter";import TransferRequest from "model/transfer_request";
import { DatabaseDocument } from "persistence/persisted_object";


export default class TransferRequestConverter extends Converter<TransferRequest> {

    fromPersistence(doc: DatabaseDocument): TransferRequest {
        let data = doc.data;
        return {
            userID: doc.id,
            userName: data["userName"],
            userPhoneNumber: data["userPhoneNumber"],
            userEmail: data["userEmail"],
            status: data["status"],
            toOfficeID: data["toOfficeID"],
            toOfficeName: data["toOfficeName"],
            toOfficeDivision: data["toOfficeDivision"],
            toOfficeManager: data["toOfficeManager"],
            fromOfficeID: data["fromOfficeID"],
            fromOfficeName: data["fromOfficeName"],
            fromOfficeDivision: data["fromOfficeDivision"],
            resolvedBy: data["resolvedBy"] ?? undefined,
        }
    }

    toPersistence(t: TransferRequest): DatabaseDocument {
        let data = {
            "userName": t.userName,
            "userPhoneNumber": t.userPhoneNumber,
            "userEmail": t.userEmail,
            "status": t.status,
            "toOfficeID": t.toOfficeID,
            "toOfficeName": t.toOfficeName,
            "toOfficeDivision": t.toOfficeDivision,
            "toOfficeManager": t.toOfficeManager,
            "fromOfficeID": t.fromOfficeID,
            "fromOfficeName": t.fromOfficeName,
            "fromOfficeDivision": t.fromOfficeDivision,
            "resolvedBy": t.resolvedBy
        }

        return {
            id: t.userID,
            data: data
        };
    }
    
}