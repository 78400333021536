import React from 'react';
import ChatStore from '../../services/chat_store';
import GuardedListenerComponent from '../components/guarded_listener_component';
import MessageStore from '../../services/message_store';
import '../common.css';
import QueueAppbar from '../components/queue_appbar';
import { CircularProgress, Grid, Typography, IconButton, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions, Toolbar, Paper, Menu, MenuItem, Box, Tooltip, Card, ListItem, ListItemText, List, Snackbar } from '@material-ui/core';
import ChatDisplayCard from '../components/chat_display_card';
import RepStore from 'services/rep_store';
import Loader from './loader_page';
import {ChatFeed, Message, ChatBubble} from "react-chat-ui";
import { MessageService } from 'services/message_service';
import Chat from 'model/chat';
import SendIcon from '@material-ui/icons/Send';
import QueueMessage from "../../model/queue_message";
import { AuthenticationService } from 'services/authentication_service';
import Rep from '../../model/rep';
import { ChatService } from 'services/chat_service';
import CreateChatComponent from 'ui/components/create_chat_component';
import ChatView from 'ui/components/chat_view';
import { RepService } from 'services/rep_service';
import MessageBuilderTextField from 'ui/components/message_builder_text_field';
import { Alert } from '@material-ui/lab';
import Banner from 'react-js-banner';


interface MessagePageState {
    selectedChatID?: string,
    chatDialogOpen: boolean,
    menuPosition: EventTarget & HTMLButtonElement | null, 
    shouldDisplayEditChatOptions: boolean, 
    shouldDisplayCreateChatOptions: boolean,
    messageText: string, 
    customChatName: string,
    searchText: string,
    addedChatMembers: Rep[],
    shouldDisplayErrorSnackbar: boolean, 
    errorText: string, 
}

export default class MessagePage extends GuardedListenerComponent<{}, MessagePageState> { 
    constructor(props: {}) {
        super(props, true);
        this.state = {
            selectedChatID: undefined, 
            chatDialogOpen: false, 
            menuPosition: null, 
            shouldDisplayEditChatOptions: false,
            shouldDisplayCreateChatOptions: false, 
            messageText: "", 
            customChatName: "",
            searchText: "", 
            addedChatMembers: [], 
            shouldDisplayErrorSnackbar: false,
            errorText: "",  
        }
        this.renderChats = this.renderChats.bind(this);
        this.chatIsSelected = this.chatIsSelected.bind(this);
        this.onChatSelected = this.onChatSelected.bind(this);
        this.renderRightDiv = this.renderRightDiv.bind(this);  
        this.getDialogContentForChat = this.getDialogContentForChat.bind(this); 
        this.onTextMessageChanged =  this.onTextMessageChanged.bind(this);
        this.renderActiveRepsMenu = this.renderActiveRepsMenu.bind(this); 
        this.handleMenuOpen = this.handleMenuOpen.bind(this); 
        this.onEditChatNamePressed = this.onEditChatNamePressed.bind(this); 
        this.onChatNameChanged = this.onChatNameChanged.bind(this); 
        this.renderEditChatNameContent = this.renderEditChatNameContent.bind(this); 
        this.onCreateChatCompleted = this.onCreateChatCompleted.bind(this); 
        this.onUpdateSnackbarText = this.onUpdateSnackbarText.bind(this); 
    }

    handleMenuOpen (event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            menuPosition: event.currentTarget,
        });
    }

    onChatSelected(chatID: string): void {
        if (this.state.selectedChatID !== chatID) {
            super.refreshListenerClients([RepStore.getInactiveRepsListenerClient(), RepStore.getActiveRepsListenerClient(), ChatStore.getChatListenerClient(), MessageStore.getMessageListenerClient(chatID)]);
            this.setState({
                selectedChatID: chatID,
                messageText: "",
            });
        } 
    }

    getPathOnRefresh() {
        return "/messages";
    }

    getEmployedListenerClients () {
        if (this.state.selectedChatID === undefined) {
            return [RepStore.getInactiveRepsListenerClient(), RepStore.getActiveRepsListenerClient(), ChatStore.getChatListenerClient()]
        }
        else {
            return [RepStore.getInactiveRepsListenerClient(), RepStore.getActiveRepsListenerClient(), ChatStore.getChatListenerClient(), MessageStore.getMessageListenerClient(this.state.selectedChatID)];
        }
    }

    renderGuarded() {   
        return (
            <React.Fragment>
                <QueueAppbar shouldDisplayMenu/>
                <Banner title="Note: Reps must have Queue version 1.26.1 to use Messaging" css={{color: "#FFF", backgroundColor: "#e88b00", margin: 0, fontSize: 12}} visibleTime={4000} />
                <div id="content">
                    <div style={{maxHeight: "95%"}} id="left">
                        <Card>
                            <Button variant='contained' color='primary' fullWidth onClick={() => {
                                this.setState({selectedChatID: undefined, shouldDisplayCreateChatOptions: true,});
                            }}>
                                <Typography variant='h5' className='RealMadPadding'>New Message</Typography>
                            </Button>
                        </Card>
                        {this.renderChats()}
                    </div>
                    <div id="right">
                        {this.renderRightDiv()}
                    </div>
                </div>

            </React.Fragment>
        );
    };

    renderRightDiv() {
        if (this.state.selectedChatID !== undefined) {
            if (!(ChatStore.instance().isLoaded()) || !(MessageStore.getSpecificMessageStore(this.state.selectedChatID!).isLoaded()) || ChatStore.instance().getChat(this.state.selectedChatID!) === undefined) { 
                return (
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item className="padding-vertical">
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                );
            }
            else { 
                return (
                    <React.Fragment>
                        <Dialog open={this.state.chatDialogOpen} aria-labelledby="form-dialog-title" onClose={() => {this.setState({chatDialogOpen: false,});}} > 
                            <DialogTitle id="form-dialog-title"><Typography color='primary'>{ChatService.getDisplayNameForChat(ChatStore.instance().getChat(this.state.selectedChatID!)!)}</Typography></DialogTitle>
                            <DialogContent> 
                                {this.renderEditChatNameContent()}
                                {this.getDialogContentForChat()}
                            </DialogContent>
                            <DialogActions> 
                                <Button variant='contained' color='primary' onClick={this.onEditChatNamePressed}>Edit Chat Name</Button>
                                <Button variant='contained' color='primary' onClick={this.handleMenuOpen}>Add Members</Button>
                                <Button variant='outlined' color='primary' onClick={() => {this.setState({chatDialogOpen: false,});}}>Close</Button>
                            </DialogActions>
                        </Dialog>
                        <ChatView updateErrorSnackbarText={this.onUpdateSnackbarText} onMessageHeaderClicked={() => {this.setState({chatDialogOpen: true});}} chatID={this.state.selectedChatID}/>
                        <Snackbar open={this.state.shouldDisplayErrorSnackbar} autoHideDuration={5000} onClose={() => {this.setState({shouldDisplayErrorSnackbar: false})}} >
                            <Alert severity="error">{this.state.errorText}</Alert>
                        </Snackbar>
                    </React.Fragment>
                );
            }
        }
        else if (this.state.shouldDisplayCreateChatOptions) {
            if (!(ChatStore.instance().isLoaded())) {
                return (
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                );
            }
            else return (
                <React.Fragment>
                    <CreateChatComponent updateErrorSnackbarText={this.onUpdateSnackbarText} onCreateChatCompleted={this.onCreateChatCompleted} />
                    <Snackbar open={this.state.shouldDisplayErrorSnackbar} autoHideDuration={5000} onClose={() => {this.setState({shouldDisplayErrorSnackbar: false})}} >
                        <Alert severity="error">{this.state.errorText}</Alert>
                    </Snackbar>
                </React.Fragment>
            );
        }
        else return null; 
    }

    onUpdateSnackbarText (newValue: boolean, errorText: string) {
        this.setState({
            errorText: errorText, 
            shouldDisplayErrorSnackbar: newValue, 
        });
    }

    onCreateChatCompleted (chatIDs: string[]) {
        chatIDs.forEach((chatID) => {
            console.log("marking seen " + chatID); 
            ChatService.instance().markSeen(chatID); 
        });
        this.onChatSelected(chatIDs[chatIDs.length-1]);
    }

    renderChats() { 
        if ((!ChatStore.instance().isLoaded()) || (!RepStore.activeRepStore().isLoaded()) || (!RepStore.inactiveRepStore().isLoaded())){
            return (
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Grid item>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            );
        }
        else {
            let chats = ChatStore.instance().getChats(); 
            return (chats.map((chat) => {
                return (
                    <div key={chat.id} className="MessageCard">
                        <ChatDisplayCard chat={chat} raised={this.state.selectedChatID === chat.id} onSelected={this.onChatSelected} names={chat.members.map((member) => RepService.getUserName(member))}/>
                    </div>
                );
            })
        );
        }
    }

    renderActiveRepsList() {
        let reps: Rep[] = RepStore.activeRepStore().getReps();
        return (reps.map((rep) => {
            return (
                <ListItem button>
                    <Tooltip title="Click to add member to chat">
                        <ListItemText>{RepService.getUserName(rep.repID)}</ListItemText>
                    </Tooltip>
                </ListItem>
            )
        })
        );
    }

    renderAddedMembers () {
        return (this.state.addedChatMembers.map((member) => {
            return (
                <ListItem button>
                    <Tooltip title="Click to remove member">
                        <ListItemText>{RepService.getUserName(member.repID)}</ListItemText>
                    </Tooltip>
                </ListItem>
            )
        })
        );
    }

    renderEditChatNameContent() {
        if (this.state.shouldDisplayEditChatOptions) {
            return (
                <Grid container direction="row" justify="space-between" alignItems='center'>
                    <Grid item>
                        <TextField placeholder="Enter a chat name" onKeyDown={(key) => {
                    if (key.keyCode === 13) {
                        let chat: Chat = ChatStore.instance().getChat(this.state.selectedChatID!)!;
                        chat.name = this.state.customChatName; 
                        this.setState({shouldDisplayEditChatOptions: false, customChatName: ""});
                        ChatService.instance().updateChat(chat); 
                    }}} value={this.state.customChatName} onChange={this.onChatNameChanged}>{this.state.customChatName}</TextField>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' color='primary' onClick={() => {
                            let chat: Chat = ChatStore.instance().getChat(this.state.selectedChatID!)!;
                            chat.name = this.state.customChatName; 
                            this.setState({shouldDisplayEditChatOptions: false, customChatName: ""});
                            ChatService.instance().updateChat(chat); 
                        }}>Save Changes</Button>
                    </Grid>
                </Grid>
            );
        }
        return null;
    }

    getDialogContentForChat () {
        if (this.state.selectedChatID === undefined) {
            return null; 
        }
        else {
            let chat: Chat = ChatStore.instance().getChat(this.state.selectedChatID!)!;
            let members: string[] = chat.members;
            members = members.filter((member) => {
                return (!(chat.removedMembers.has(member)));
            });
            return (members.map((member) => 
                    <div key={member}>
                        <Grid className='padding-vertical' container direction="row" alignItems='center' justify='space-between'>
                            <Grid item className='Padding-Right'>
                                <Typography>{RepService.getUserName(member)}</Typography>
                            </Grid>
                            <Grid item className='Padding-Left'> 
                                <Button color='primary' variant='outlined' onClick={() => {
                                    let chat: Chat = ChatStore.instance().getChat(this.state.selectedChatID!)!;
                                    let newRemovedMembers = new Map(); 
                                    if (!(chat.removedMembers === undefined)) {
                                        newRemovedMembers = chat.removedMembers;
                                    }
                                    newRemovedMembers.set(member, new Date());
                                    chat.removedMembers = newRemovedMembers;
                                    ChatService.instance().updateChat(chat);
                                }}>Remove Member</Button>
                            </Grid>
                        </Grid>
                        <Menu onClose={() => {
                            this.setState({
                                menuPosition: null,  
                            })
                        }}
                        open={Boolean(this.state.menuPosition)} anchorEl={this.state.menuPosition}> 
                            <MenuItem><Typography color='primary' align='center' variant='h5'>ACTIVE REPS</Typography></MenuItem>
                            {this.renderActiveRepsMenu()}
                        </Menu>
                    </div>
               ));
        }  
    }

    renderActiveRepsMenu () {
        let activeRepStore: RepStore = RepStore.activeRepStore();
        if (!activeRepStore.isLoaded()) {
            return (
                <CircularProgress/>
            )
        }
        else {
            let activeReps: Rep[] = activeRepStore.getReps(); 
            activeReps.sort((a: Rep, b: Rep) =>  a.firstName.localeCompare(b.firstName));
            return (activeReps.map((rep: Rep) => <MenuItem key={rep.repID}> 
                        <Grid container direction='row' justify='space-between' alignItems='center'>
                            <Grid item><Typography>{RepService.getUserName(rep.repID)}</Typography></Grid>
                            <Grid item><Button variant='outlined' color='primary' onClick={() => {
                                let chat: Chat = ChatStore.instance().getChat(this.state.selectedChatID!)!;
                                let newLastOpenedDates: Map<string,Date> = chat.lastOpenedDates;
                                let newMembers: string[] = chat.members; 
                                newMembers.push(rep.repID); 
                                newLastOpenedDates.set(rep.repID, new Date());
                                chat.lastOpenedDates = newLastOpenedDates;
                                chat.members = newMembers; 
                                ChatService.instance().updateChat(chat);
                            }}>Add Member</Button></Grid>
                        </Grid> 
                    </MenuItem>
                ));
        }
    }

    onEditChatNamePressed () {
        this.setState({
            shouldDisplayEditChatOptions: true, 
        });
    }

    onChatNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let value: string = event.target.value; 
        this.setState({customChatName: value}); 
    }

    onTextMessageChanged(event: React.ChangeEvent<HTMLInputElement>){
        let value: string = event.target.value;
        this.setState({messageText: value});
    }

    chatIsSelected(id: string): boolean  {
        return (this.state.selectedChatID === id); 
    }
}   