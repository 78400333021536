import React from 'react';

const FunnelRow = ({ value, label, scale }) => {

  return (
    <div style={styles.container}>
      <div style={styles.labelStyle}>{label}</div>

      <div style={styles.valueOuterContainer}>
        <div style={{ textAlign: 'center',
            verticalAlign: 'center',
            backgroundColor: '#336d99',
            borderRadius: 10,
            fontSize: 10,
            paddingTop: 5,
            paddingBottom: 5,
            height: 12,
            color: 'white',
            margin: 'auto',
            alignItems: 'center',
            width: scale,
            minWidth: 20, 
            }}>{value}</div>
      </div>

    </div>
  )
}

const styles = {
  container: {
    height: 25,
    display: 'flex',
    margin: 5,
    alignItems: 'center'
  },
  labelStyle: {
    fontSize: 10,
    width: 50
  },
  valueOuterContainer: {
    flex: 5,
    alignItems: 'center'
  },
  valueStyle: {
    textAlign: 'center',
    verticalAlign: 'center',
    backgroundColor: '#336d99',
    borderRadius: 10,
    fontSize: 10,
    paddingTop: 5,
    paddingBottom: 5,
    height: 12,
    color: 'white',
    margin: 'auto',
    alignItems: 'center'
  }


}

export default FunnelRow;
