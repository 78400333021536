import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import QueueAppbar from '../components/queue_appbar';
import '../common.css';
import { CircularProgress, Checkbox } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link, Redirect } from 'react-router-dom';
import { AuthenticationService } from '../../services/authentication_service';
import ManagerSignUpInfo from '../../model/manager_sign_up_info';
import ActionResultVoid from '../../model/action_result_void';


interface SignUpPageState {
    passwordsDontMatch: boolean,
    disabledButton: boolean;
    loading: boolean,
    errorMessage?: string,
    shouldRedirectToPlans: boolean,
    isSchoolEmail: boolean,
    shouldRedirectHome: boolean,
}

export default class SignUpPage extends React.Component<{}, SignUpPageState> {
    private firstName: string = "";
    private lastName: string = "";
    private email: string = "";
    private password: string = "";
    private phoneNumber: string = "";
    private officeName: string = "";
    private officeNumber: string = "";
    private division: string = "";
    private region: string = "";
    private confirmPassword: string = "";
    private alreadyHasAccount: boolean = false;

    constructor(props: {}) {
        super(props);

        this.state = {
            passwordsDontMatch: false,
            disabledButton: true,
            loading: false,
            shouldRedirectToPlans: false,
            isSchoolEmail: false,
            shouldRedirectHome: false,
        };

        this.onFirstNameUpdated = this.onFirstNameUpdated.bind(this);
        this.triggerSignUp = this.triggerSignUp.bind(this);
        this.onLastNameUpdated = this.onLastNameUpdated.bind(this);
        this.onEmailUpdated = this.onEmailUpdated.bind(this);
        this.onConfirmPasswordUpdated = this.onConfirmPasswordUpdated.bind(this);
        this.onRegionUpdated = this.onRegionUpdated.bind(this);
        this.onDivisionUpdated = this.onDivisionUpdated.bind(this);
        this.onPhoneNumberUpdated = this.onPhoneNumberUpdated.bind(this);
        this.onOfficeNameUpdated = this.onOfficeNameUpdated.bind(this);
        this.onPasswordUpdated = this.onPasswordUpdated.bind(this);
        this.onOfficeNumberUpdated = this.onOfficeNumberUpdated.bind(this);
        this.passwordsMatch = this.passwordsMatch.bind(this);
        this.shouldBeDisabled = this.shouldBeDisabled.bind(this);
        this.renderCards = this.renderCards.bind(this);
        this.renderPasswordErrorMessage = this.renderPasswordErrorMessage.bind(this);
        this.updateIsSchoolEmail = this.updateIsSchoolEmail.bind(this);
        this.renderGlobalErrorMessage = this.renderGlobalErrorMessage.bind(this);
        this.onToggleAlreadyHasAccount = this.onToggleAlreadyHasAccount.bind(this);
    }

    onFirstNameUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.firstName = value;
        this.setState({
            disabledButton: this.shouldBeDisabled()
        });
    }

    onOfficeNumberUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.officeNumber = value;
        this.setState({disabledButton:this.shouldBeDisabled()});
    }

    onLastNameUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.lastName = value;
        this.setState({disabledButton:this.shouldBeDisabled()});
    }

    onEmailUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.email = value;
        this.setState({disabledButton:this.shouldBeDisabled(), isSchoolEmail:this.updateIsSchoolEmail()});
    }

    onPasswordUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.password = value;
        this.setState({passwordsDontMatch:!this.passwordsMatch(), disabledButton:this.shouldBeDisabled()});
    }

    onRegionUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.region = value;
        this.setState({disabledButton:this.shouldBeDisabled()});
    }

    onDivisionUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.division = value;
        this.setState({disabledButton:this.shouldBeDisabled()});
    }

    onOfficeNameUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.officeName = value;
        this.setState({disabledButton:this.shouldBeDisabled()});
    }

    onPhoneNumberUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.phoneNumber = value;
        this.setState({disabledButton:this.shouldBeDisabled()});
    }

    onConfirmPasswordUpdated(event: React.ChangeEvent<HTMLInputElement>): void{
        let value: string = event.target.value;
        this.confirmPassword = value;
        this.setState({passwordsDontMatch:!this.passwordsMatch(), disabledButton:this.shouldBeDisabled()});
    }

    passwordsMatch() : boolean{
        return this.confirmPassword===this.password || this.alreadyHasAccount;
    }

    shouldBeDisabled() : boolean{
        return !this.passwordsMatch() || this.email.substring(this.email.length-3)==="edu" || this.password.length<8 || this.password==="" || this.region==="" || this.division==="" || this.officeName==="" || this.officeNumber==="" ||
        (!this.alreadyHasAccount && (this.phoneNumber==="" || this.email==="" || this.firstName==="" || this.lastName===""));
    }

    triggerSignUp(): void {
        this.setState({
            errorMessage: undefined,
            loading: true
        });
        const signUpData: ManagerSignUpInfo = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
            confirmPassword: this.confirmPassword,
            phoneNumber: this.phoneNumber,
            division: this.division,
            region: this.region,
            officeName: this.officeName,
            officeNumber: this.officeNumber
        }

        if (this.alreadyHasAccount) {
          AuthenticationService.instance().managerLoginNewOffice(signUpData).then((result: ActionResultVoid) => {
            if (result.success) {
              this.setState({
                loading: false,
                shouldRedirectHome: true,
              })
            } else {
              this.setState({
                loading: false,
                errorMessage: result.errorMessage
              })
            }

          })
        } else {
          AuthenticationService.instance().managerSignUp(signUpData).then((result: ActionResultVoid) => {
              if (result.success) {
                  this.setState({
                      loading: false,
                      shouldRedirectToPlans: true
                  });
              } else {
                  this.setState({
                      loading: false,
                      errorMessage: result.errorMessage!
                  });
              }
          });
        }
    }

    updateIsSchoolEmail() {
       if (this.email.substring(this.email.length-3)==="edu") return true;
       else return false;
    }

    onToggleAlreadyHasAccount() {
      this.alreadyHasAccount = !this.alreadyHasAccount
      this.setState({
        disabledButton: this.shouldBeDisabled()
       });
    }

    renderPasswordErrorMessage() {
        if (this.password.length<8) {
            return ("Passwords must be 8 characters or more");
        } else if(this.state.passwordsDontMatch) {
            return ("Passwords must match");
        }
        else {
            return null;
        }
    }

    renderGlobalErrorMessage() {
        if (this.state.errorMessage === undefined) {
            return null;
        } else {
            return <p className="ErrorMessage">{this.state.errorMessage!}</p>
        }
    }

    renderCreateTeamCard() {
      return (
        <Card>
          <CardContent>
            <Grid container direction="column" spacing={2} >
              <Grid item>
                  <Typography>
                      Create your team
                  </Typography>
              </Grid>
              <Grid item>
                  <TextField placeholder="Office Name" onChange={this.onOfficeNameUpdated} value={this.officeName}/>
              </Grid>
              <Grid item>
                  <TextField placeholder="Office Number" onChange={this.onOfficeNumberUpdated} value={this.officeNumber}/>
              </Grid>
              <Grid item>
                  <TextField placeholder="Division" onChange={this.onDivisionUpdated} value={this.division}/>
              </Grid>
              <Grid item>
                  <TextField placeholder="Region" onChange={this.onRegionUpdated} value={this.region}/>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )
    }

    renderCreateAccountCard() {
      let errorMessage = this.renderPasswordErrorMessage();
      const alreadyHasAccount = this.alreadyHasAccount
      return (
        <Card>
          <CardContent>
              <Grid container direction="column" spacing={2} >
                <Grid item>
                    <Typography>
                        Create your account
                    </Typography>
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Checkbox
                    checked={alreadyHasAccount}
                    onChange={this.onToggleAlreadyHasAccount}
                    color='primary'
                  />
                  <Typography>
                    Already have an account
                  </Typography>
                </Grid>

                {(alreadyHasAccount) ? null : <Grid item>
                    <TextField placeholder="First Name" onChange={this.onFirstNameUpdated} value={this.firstName}/>
                </Grid>}

                {(alreadyHasAccount) ? null : <Grid item>
                    <TextField placeholder="Last Name" onChange={this.onLastNameUpdated} value={this.lastName}/>
                </Grid>}

                {(alreadyHasAccount) ? null : <Grid item>
                    <TextField placeholder="Phone Number" onChange={this.onPhoneNumberUpdated} value={this.phoneNumber}/>
                </Grid>}

                <Grid item>
                    <TextField placeholder="Email" error={this.state.isSchoolEmail} helperText={'Same username for rep app   and EasyOrders'} onChange={this.onEmailUpdated} value={this.email}/>
                </Grid>
                <Grid item>
                    <TextField type="password" placeholder="Password" onChange={this.onPasswordUpdated} value={this.password}/>
                </Grid>

                {(alreadyHasAccount) ? null :   <Grid item>
                    <TextField error={this.state.passwordsDontMatch} helperText={errorMessage} type="password" placeholder="Confirm Password" onChange={this.onConfirmPasswordUpdated} value={this.confirmPassword}/>
                </Grid>}

              </Grid>
          </CardContent>
        </Card>
      )
    }
    renderCards() {
      let errorMessage = this.renderPasswordErrorMessage();
      return (
        <div>
            {this.renderCreateTeamCard()}
            <br/>
            {this.renderCreateAccountCard()}
        </div>);
    }

    render() {
      if (this.state.shouldRedirectToPlans) {
          return (<Redirect to="/plans"/>);
      } else if (this.state.shouldRedirectHome) {
        return (<Redirect to="/reps" />);
      } else {
        return (
          <div>
            <QueueAppbar shouldDisplayMenu={false}/>
            <Grid container direction="column" alignItems="center" justify="center" spacing={2}>
              <Grid item>
                  <h1>Manager Sign Up</h1>
              </Grid >
              <Grid item>
                  <h3>Welcome Aboard</h3>
              </Grid>
              <Grid item>
                  {this.renderGlobalErrorMessage()}
              </Grid>
              <Grid item>
                  {this.state.loading ? <CircularProgress/> : this.renderCards()}
              </Grid>
              <Grid item>
                  {this.renderGlobalErrorMessage()}
              </Grid>
              <Grid item>
                  <Button variant='contained' disabled={this.state.disabledButton} color='primary' onClick={this.triggerSignUp}>
                      Sign Up
                  </Button>
              </Grid>
              <Grid item>
                  <Typography>Not creating a new office?</Typography>
              </Grid>
              <Grid item>
                  <Link to="/login" className="plain_link">Login Instead</Link>
              </Grid>
            </Grid>
          </div>
        );
      }
    }
}
