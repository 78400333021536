import React from 'react';

import {useSpring, animated} from 'react-spring';


const ViewWithShelf = ({ primaryView, shelfView, isOpen }) => {


  const shelfProps = useSpring({
    width: (isOpen) ? '30%' : '0%',
    zIndex: 100,
    position: 'relative',
    right: 0,
    height: '100%'
  });
  const mainProps = useSpring({
    width: (isOpen) ? '70%' : '100%',
    zIndex: 0,
    position: 'relative',
    height: '100%'
  });

  return (
    <div style={{
      display: 'flex',
      height: '100%',
    }}>
              <animated.div style={mainProps}>
                {primaryView}
              </animated.div>
              <animated.div style={shelfProps}>
                {shelfView}
              </animated.div>
            </div>
    
  )
}

export default ViewWithShelf;

