import ListenerClient from '../../services/listener_client';
import Store from '../../services/store';
import { AuthenticationService } from '../../services/authentication_service';
import { firebaseApp } from '../../persistence/firebase_app';
import "firebase/auth";
import "firebase/firestore";
import * as firebase from "firebase/app";
import React from 'react';
import { Redirect } from 'react-router';
import Loader from '../pages/loader_page';
import AsyncLoader from '../pages/async_loader';
import PaymentGuardComponent from './payment_guard_component';


export default abstract class GuardedListenerComponent<T,K> extends React.Component<T,K> {
    private resolvedAuthState: boolean = false;
    private unsubscribe?: firebase.Unsubscribe;
    private clientsNotStarted: boolean = true;
    private clients?: ListenerClient<any>[];
    private guardPayment: boolean;

    constructor(props: T, guardPayment?: boolean) {
        super(props);
        if (guardPayment !== undefined) {
            this.guardPayment = guardPayment;
        } else {
            this.guardPayment = false;
        }
        this.onUpdateCallback = this.onUpdateCallback.bind(this);
        this.getEmployedListenerClients = this.getEmployedListenerClients.bind(this);
        this.renderGuarded = this.renderGuarded.bind(this);
        this.getPathOnRefresh = this.getPathOnRefresh.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.refreshListenerClients = this.refreshListenerClients.bind(this);
        this.startClients = this.startClients.bind(this);
        this.stopClients = this.stopClients.bind(this);
        AuthenticationService.instance().registerCurrentScreen(() => this.setState({}));
    }

    abstract getEmployedListenerClients(): ListenerClient<any>[];

    abstract renderGuarded(): React.ReactElement;

    abstract getPathOnRefresh(): string;

    componentDidMount() {
        this.unsubscribe = firebaseApp.auth().onAuthStateChanged((user: firebase.User | null) => {
            this.resolvedAuthState = true;
            this.unsubscribe!();
            this.setState({});
        });
    }

    componentWillUnmount() {
        this.unsubscribe!();
        this.stopClients(this.clients);
    }

    onUpdateCallback() {
        this.setState({});
    }

    refreshListenerClients(clients: ListenerClient<any>[]) {
        this.stopClients(this.clients);
        this.startClients(clients);
    }

    private startClients(clients: ListenerClient<any>[]) {
        this.clientsNotStarted = false;
        this.clients = clients;
        for (let client of this.clients!) {
            client.updateSubscriptionGenerator();
            let store: Store<any> = client.getStore();
            store.getListener().beginListening(this.onUpdateCallback, client);
        }
    }

    private stopClients(clients?: ListenerClient<any>[]) {
        if (clients !== undefined) {
            for (let client of clients!) {
                let store: Store<any> = client.getStore();
                store.getListener().terminateSubscription(client);
            } 
        }
    }

    render() {
        if (AuthenticationService.instance().isAuthenticated() && AuthenticationService.instance().hasDataRequiredToFunction() && this.clientsNotStarted) {
            this.startClients(this.getEmployedListenerClients());
        }
        if (!AuthenticationService.instance().isAuthenticated()) {
            if (this.resolvedAuthState) {
                return (<Redirect to="/login"/>);
            } else {
                return (<Loader/>);
            }
        } else if (!AuthenticationService.instance().hasDataRequiredToFunction()) {
            return (<AsyncLoader asyncTask={async () => await AuthenticationService.instance().loadDataRequiredToFunction()} redirectPathOnTaskFailed={"/login"} redirectPathOnTaskSuccess={this.getPathOnRefresh()}/>);
        } else {
            return <div style={{height: '100%'}}>
                {this.guardPayment ? <PaymentGuardComponent/> : null}
                {this.renderGuarded()}
            </div>;
        }
    }
}