import React from 'react';

import { Button, Tooltip } from '@material-ui/core';
import { withStyles, makeStyles } from "@material-ui/core/styles";

import { QueueColor } from 'model/queue_color';

const getToolTipForTimeValue = (value) => {
  switch (value) {
    case 'Year':
      return "Past Year"
    case 'Month':
      return "Past Month"
    case 'Week':
      return "Past 7 Days"
    case 'Today':
      return "Today"
    default:
      return "Pick Any Two Dates"
  }
}

const TimeSelector = ({ values, selectedValue, onValueChanged }) => {

  const buttons = values.map((value) => {
    return (
      <Tooltip key={value} title={getToolTipForTimeValue(value)}>
        <Button
          key={value}
          variant='outlined'
          size="small"
          style={{
            borderRadius: 0,
            fontSize: 8,
            maxWidth: '20%',
            minWidth: '20%',
            backgroundColor: (value===selectedValue) ? QueueColor.PRIMARY : 'white',
            color: (value===selectedValue) ? 'white' : 'black'
          }}
          onClick={() => onValueChanged(value)}
          >
          {value}
        </Button>
      </Tooltip>
    )
  })
  return (
    <div style={styles.container}>
      {buttons}
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    marginBottom: 10,
  },
};

export default TimeSelector;
