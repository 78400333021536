import React from 'react'; 
import GuardedListenerComponent from './guarded_listener_component';
import TeamStore from 'services/team_store';
import MessagePage from 'ui/pages/message_page';
import { Grid, Typography, CircularProgress, } from '@material-ui/core';
import QueueAppbar from './queue_appbar';
import BetaScreenshot from '../assets/messaging_beta_screenshot.png';
import { Link } from 'react-router-dom';

export default class MessagePageDelegator extends GuardedListenerComponent<{}, {}> {
    constructor(props: {}) {
        super(props);
        
    }
    
    getEmployedListenerClients () {
        return [TeamStore.getTeamListenerClient()];
    }

    getPathOnRefresh () {
        return "/messages"; 
    }

    renderGuarded () {
        if (!TeamStore.instance().isLoaded()) {
            return (
                <Grid container direction='column' justify='center' alignItems='center'>
                    <Grid item className="padding-vertical">
                        <CircularProgress/>
                    </Grid>
                </Grid>
            );
        }
        if (TeamStore.instance().getTeam().messageBeta || TeamStore.instance().getTeam().silverCup) {
            return (
                <MessagePage/>
            );
        }
        else {
            console.log(TeamStore.instance().getTeam());
            return (
                <React.Fragment>
                    <QueueAppbar shouldDisplayMenu/>
                    <Grid container direction='column' justify='center' alignItems='center'>
                        <Grid item> 
                            <h2 style={{textAlign: "center"}}>Use Queue's Messaging System to send message blasts to Groups or Teams and keep AM's in the Loop</h2>
                            <p style={{textAlign: "center"}}>Messaging is a Silver Cup Feature. <Link to="/plans">Learn More</Link></p>
                        </Grid>
                        <Grid item style={{paddingTop: "15px"}}> 
                        <div className='iframe-container'>
                        <iframe width="900" height="500" frameBorder="0" title="Messaging System Demo" src="https://www.youtube.com/embed/UwMQWiPh9fk" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        </Grid>
                    </Grid>
                </React.Fragment>
            );
        }
    }
} 