import React, { ChangeEvent } from "react"; 
import ColoredCardBackground, { BackgroundColor } from "./colored_card_background";
import { TableCell, MenuItem, FormHelperText, Select, TextField, Button } from "@material-ui/core";
import { AttendanceStatus } from "model/attendance_status";
import OfficeEvent from "model/office_event";
import Rep from "model/rep";
import { OfficeEventService } from "services/office_event_service";

interface AttendanceRowProps {
    rep: Rep, 
    officeEvent: OfficeEvent,
    backgroundColor: BackgroundColor,  
}
 
interface AttendanceRowState {
    notes: string, 
    status: AttendanceStatus, 
    didChange: boolean, 
}

export default class AttendanceRow extends React.Component<AttendanceRowProps, AttendanceRowState> {
    constructor(props: AttendanceRowProps) {
        super(props); 
        this.state ={
            notes: this.props.officeEvent.notes.get(this.props.rep.repID) ?? "", 
            status: this.props.officeEvent.attendanceStatuses.get(this.props.rep.repID) ?? AttendanceStatus.UNDECIDED, 
            didChange: false, 
        }
        this.renderSaveButton  = this.renderSaveButton.bind(this); 
    }


    render () {
        return (
            <TableCell style={{paddingRight: "10px"}} key={this.props.rep.repID + "|" + this.props.officeEvent.id}>
                                        <div style={{ marginInline: "10px", paddingTop: "10px", display: "inline-block"}}>
                                            <ColoredCardBackground  color={this.props.backgroundColor} kids={[
                                                <Select  value={this.state.status} onChange={(event: ChangeEvent<{value: unknown}>) => {
                                                let newValue = (event.target.value as AttendanceStatus);
                                                this.setState({status: newValue, didChange: true, });
                                                }}>
                                                <MenuItem value={AttendanceStatus.UNDECIDED}>Undecided</MenuItem>
                                                <MenuItem value={AttendanceStatus.CONFIRMED}>Confirmed</MenuItem>
                                                <MenuItem value={AttendanceStatus.NO_SHOW}>No Show</MenuItem>
                                                <MenuItem value={AttendanceStatus.YES}>Yes</MenuItem>
                                                <MenuItem value={AttendanceStatus.NO}>No</MenuItem>
                                                <MenuItem value={AttendanceStatus.LATE}>Late</MenuItem>
                                                <MenuItem value={AttendanceStatus.MAYBE}>Maybe</MenuItem>
                                                <MenuItem value={AttendanceStatus.LEAVE_EARLY}>Leaving Early</MenuItem>
                                                </Select>
                                            ]}/>
                                            <FormHelperText>Attendance Status</FormHelperText>
                                        </div>
                                        
                                    
                                        <TextField style={{paddingTop: "10px", marginInline: "10px", display: "inline-block"}} placeholder="Notes" value={this.state.notes} onChange={(newValue) => {
                                            this.setState({notes: newValue.target.value, didChange: true,}); 
                                        }}>{this.state.notes}</TextField>
                                    {this.renderSaveButton()}
                                
                            </TableCell>
        );
    }

    renderSaveButton () {
        return (this.state.didChange ? <Button style={{ marginInline: "10px",}} variant='contained' color='primary' onClick={(_) => {
            this.props.officeEvent.notes.set(this.props.rep.repID, this.state.notes);
            this.props.officeEvent.attendanceStatuses.set(this.props.rep.repID, this.state.status);
            OfficeEventService.instance().updateOfficeEvent(this.props.officeEvent);
            this.setState({didChange: false})}}>Save Changes</Button> : null); 
    }

}