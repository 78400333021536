import PdiWeek from "../model/pdi_week";
import ActionResultVoid from "../model/action_result_void";
import { AuthenticationService } from "./authentication_service";
import Rep from "../model/rep";
import PdiPersistence from "../persistence/pdi_persistence";
import { DatabaseDocument } from "../persistence/persisted_object";
import PDIConverter from "../model/converters/pdi_converter";
import { SubscriptionGenerator } from "../persistence/subscription_generator";




export default class PdiWeekService {
    private static sharedInstance: PdiWeekService = new PdiWeekService();
    public static instance(): PdiWeekService {
        return this.sharedInstance;
    }
    private constructor() {}

    public updatePdiWeek(pdiWeek: PdiWeek, weekID: string): Promise<ActionResultVoid> {
        let doc: DatabaseDocument = new PDIConverter().toPersistence(pdiWeek);
        return PdiPersistence.instance().updateGoals(weekID, doc);
    }

    public createPdiWeek(pdiWeek: PdiWeek, weekID: string): Promise<ActionResultVoid> {
        let doc: DatabaseDocument = new PDIConverter().toPersistence(pdiWeek);
        return PdiPersistence.instance().setGoals(weekID, doc);
    }

    public generateBlankPdiWeekForRep(repID: string): PdiWeek {

        let currentRep: Rep = AuthenticationService.instance().getCurrentUser();

        let newBlankPdiWeek: PdiWeek = {
            userID: repID,
            teamID: currentRep.officeNumber,
            dailyGoals: [null, null, null, null, null, null, null],
            dailyStatuses: [null, null, null, null, null, null, null,]
        }

        return newBlankPdiWeek;
    }

    public getPdiWeekSubscriptionGenerator(weekID: string): SubscriptionGenerator {
        return PdiPersistence.instance().getPdiSubscriptionGenerator(weekID, AuthenticationService.instance().getCurrentUserOfficeNumber());
    }
}