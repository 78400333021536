import axios from 'axios';
import ActionResultVoid from '../model/action_result_void';
import ActionResult from '../model/action_result';
import { Token } from 'react-stripe-checkout';

const CLOUD_FUNCTIONS_ENDPOINT = "https://us-central1-queue-ignite.cloudfunctions.net/subscribeCustomer";

export class BillingPersistence {
    private static sharedInstance: BillingPersistence = new BillingPersistence();
    private constructor() {}

    public static instance(): BillingPersistence {
        return this.sharedInstance;
    }

    public async createPaymentMethod(token: Token, email: string, stripe: any): Promise<ActionResult<string>> {
        return stripe.createPaymentMethod({
            type: 'card',
            card: {token: token.id},
            billing_details: {
              email: email,
            },
          }).then((result: any) => {
              if (result.error) {
                return new ActionResult<string>(false, result.error.message);
              } else {
                  return new ActionResult<string>(true, undefined, result.paymentMethod.id);
              }
        });
    }

    public async subscribeCustomer(
        toPlan: string,
        withToken: string,
        uid: string,
        phoneNumber: string,
        officeNumber: string,
        email: string,
        fullName: string,
        referrerEmail?: string,
        ): Promise<ActionResultVoid> {
            let data;
            if (referrerEmail === undefined) {
                data = {
                    paymentMethodID: withToken,
                    fullName: fullName,
                    email: email,
                    uid: uid,
                    officeNumber: officeNumber,
                    paymentPlanID: toPlan,
                    phoneNumber: phoneNumber
                };
            } else {
                data = {
                    paymentMethodID: withToken,
                    fullName: fullName,
                    email: email,
                    uid: uid,
                    officeNumber: officeNumber,
                    paymentPlanID: toPlan,
                    referrerEmail: referrerEmail,
                    phoneNumber: phoneNumber
                };
            }

            return axios.post(CLOUD_FUNCTIONS_ENDPOINT, data).then((response) => {
               
                return new ActionResultVoid(true);
            }, (reason) => {
               
                return new ActionResultVoid(false, reason.message);
            });
    }

}
