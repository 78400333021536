import React from 'react';
import FunnelRow from 'ui/components/common/FunnelRow';

const Funnel = ({ values, labels }) => {

  const max = values[0];
  const items = values.map((value, i) => {
    const label = labels[i];
    const scale = value / (max || 1);
    const scaleText = `${Math.round(scale * 100)}%`
    return (<FunnelRow key={label} value={value} label={label} scale={scaleText} />);
  });

  return (
    <div>
      {items}
    </div>
  )
}

export default Funnel;
