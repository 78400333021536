import QueueAppbar from 'ui/components/queue_appbar';
import React from 'react';
import { Grid, Typography, Switch, Button, CircularProgress } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import GuardedListenerComponent from 'ui/components/guarded_listener_component';
import TeamStore from 'services/team_store';
import TeamService from 'services/team_service';
import Team from 'model/team';
import { Link } from 'react-router-dom';

interface SettingsPageState {
    requireRepsToCheckOutOfDemos?: boolean,
    requireAfterDate?: Date, 
    loading: boolean
}

export default class SettingsPage extends GuardedListenerComponent<{}, SettingsPageState> {
    constructor (props: {}) {
        super (props);
        this.state = {
            loading: false
        };
        this.onSaveChangesPressed = this.onSaveChangesPressed.bind(this);
        this.renderRepsCheckoutSelector = this.renderRepsCheckoutSelector.bind(this);
        this.hasPendingChanges = this.hasPendingChanges.bind(this);
        this.renderBody = this.renderBody.bind(this);
    }

    getPathOnRefresh () {
        return "/settings"; 
    }

    hasPendingChanges(): boolean {
        return this.state.requireAfterDate !== undefined || this.state.requireRepsToCheckOutOfDemos !== undefined;
    }

    renderGuarded() {
            return (
                <React.Fragment>
                    <QueueAppbar shouldDisplayMenu/>
                    {TeamStore.instance().isLoaded() || this.state.loading ? this.renderBody(TeamStore.instance().getTeam()) : <CircularProgress/>}
                </React.Fragment>  
            );
        
    }

    renderBody(team: Team) {
        if (team.silverCup) {
            return <Grid container style={{maxWidth: "96%", paddingTop: "20px"}} direction='column' justify='center' alignItems='center'>
            <Grid item><Typography variant='h3'>Configure Rep Demo Checkouts</Typography></Grid>
            <Grid item style={{paddingTop: "20px"}}>
                {this.renderRepsCheckoutSelector()}
            </Grid>
            <Grid item style={{paddingTop: "20px"}}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/PcQoDB-obLo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Grid>
            <Grid item style={{padding: "30px"}}>
                <Button variant='contained'  color='primary' disabled={!this.hasPendingChanges()} onClick={this.onSaveChangesPressed}>Save Changes</Button>
            </Grid>
            </Grid> 
        } else {
            return <Grid container direction='column' justify='center' alignItems='center'>
            <Grid item> 
                <h2 style={{textAlign: "center"}}>Use Automatic Rep Demo Checkouts to have reps log the outcome of all of their demos</h2>
                <p style={{textAlign: "center"}}>Rep Demo Checkouts is a Silver Cup Feature. <Link to="/plans">Learn More</Link></p>
            </Grid>
            <Grid item style={{paddingTop: "15px"}}> 
            <div className='iframe-container'>
            <iframe width="900" height="500" frameBorder="0" title="Rep Demo Checkouts Tutorial" src="https://www.youtube.com/embed/PcQoDB-obLo" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            </Grid>
        </Grid>
        }
    }

    onSaveChangesPressed() { 
        //team store must be loaded to modify settings
        if (!TeamStore.instance().isLoaded()) return;
        //if you require reps to check out of demos, you MUST set a require after date
        if (this.state.requireRepsToCheckOutOfDemos !== undefined && this.state.requireRepsToCheckOutOfDemos && this.state.requireAfterDate === undefined) return;

        let currentTeam = TeamStore.instance().getTeam();

        if (this.state.requireRepsToCheckOutOfDemos !== undefined) {
            currentTeam.allowRepsCheckout = this.state.requireRepsToCheckOutOfDemos;
        }

        if (this.state.requireAfterDate! !== undefined) {
            currentTeam.repsCheckoutDate = this.state.requireAfterDate;
        }

        this.setState({
            requireAfterDate: undefined,
            requireRepsToCheckOutOfDemos: undefined,
            loading: true
        });

        TeamService.instance().updateTeam(currentTeam).then((result) => {
            this.setState({
                loading: false
            })
        }); 
    }

    renderRepsCheckoutSelector () {
        let team = TeamStore.instance().getTeam();
        let displayDateSelector = this.state.requireRepsToCheckOutOfDemos ?? team.allowRepsCheckout;
            return (
                <Grid item>
                    <Grid container direction='row' justify='space-between' alignItems='center'>
                        <Grid item><Typography>Require All Reps to Log Out of Every Demo from their App</Typography></Grid>
                        <Grid item><Switch color='primary' checked={this.state.requireRepsToCheckOutOfDemos ?? team.allowRepsCheckout} onChange={(_, checked) => {
                            this.setState({
                                requireAfterDate: checked ? new Date() : undefined,
                                requireRepsToCheckOutOfDemos: checked
                            });
                            }}></Switch></Grid>
                    </Grid>
                    {
                        displayDateSelector ? <Grid item>
                        <Typography> Demos taking place on or after this date must be logged out by your reps. We strongly recommmend choosing a date in the future so you have time to explain the program to your team. </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="none"
                        id="start-date-picker"
                        minDate={new Date()}
                        value={this.state.requireAfterDate ?? team.repsCheckoutDate}
                        onChange={(date) => {
                            if (date != null) {
                                this.setState({
                                    requireAfterDate: date
                                });
                            }
                        } }
                        KeyboardButtonProps={{
                            'aria-label': 'Change date',
                        }}
                        />
                        </MuiPickersUtilsProvider>
                    </Grid> : null
                    }
                    
                </Grid>
            );
    }

    getEmployedListenerClients () {
        return [TeamStore.getTeamListenerClient()];
    }
}